import React, { useEffect, useRef, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl"; // or "const mapboxgl = require('mapbox-gl');"
import axios from "axios";
import { Button } from "@mui/material";

const AsmMonitorByShivam = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [lng, setLng] = useState(72.978146);
  const [lat, setLat] = useState(19.217302);

  // const [zoom, setZoom] = useState(12.3);
  const [zoom, setZoom] = useState(12.5);
  const [wait, setWait] = useState(false);

  const [asmMonitorData, setAsmMonitorData] = useState([]);

  const getData = async () => {
    try {
      setWait(false);

      const res2 = await axios.get("/api/v1/admin/asm/location");
      setAsmMonitorData(res2.data.data);

      setWait(true);
    } catch (error) {}
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    // console.log(busyPartnersData, availablePartnersData);
    if (wait) {
      if (map.current) return; // initialize map only once
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v12",
        center: [lng, lat],
        zoom: zoom,
        accessToken:
          "pk.eyJ1IjoieWJpdGVzIiwiYSI6ImNsZmdveXJ2YTBsamkzeW5wanY1bjBkMnkifQ.nQdGB1L5C8Vd-qLV3vKfSA",
      });
      map.current.on("load", () => {
        map.current.addSource("available", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: asmMonitorData,
          },
        });
        // Add a layer showing the available.
        map.current.addLayer({
          id: "available",
          type: "circle",
          source: "available",
          paint: {
            "circle-color": "green",
            "circle-radius": 6,
            "circle-stroke-width": 1,
            "circle-stroke-color": "#ffffff",
          },
        });
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        });

        map.current.on("mouseenter", "available", (e) => {
          map.current.getCanvas().style.cursor = "pointer";
          const coordinates = e.features[0].geometry.coordinates.slice();
          const description = e.features[0].properties.description;

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          popup.setLngLat(coordinates).setHTML(description).addTo(map.current);
        });

        map.current.on("mouseleave", "available", () => {
          map.current.getCanvas().style.cursor = "";
          popup.remove();
        });
      });

      // ll
      //   map.current.on("load", () => {
      //     map.current.addSource("offline", {
      //       type: "geojson",
      //       data: {
      //         type: "FeatureCollection",
      //         features: offlinePartnersData,
      //       },
      //     });
      //     // Add a layer showing the offline.
      //     map.current.addLayer({
      //       id: "offline",
      //       type: "circle",
      //       source: "offline",
      //       paint: {
      //         "circle-color": "red",
      //         "circle-radius": 6,
      //         "circle-stroke-width": 1,
      //         "circle-stroke-color": "#ffffff",
      //       },
      //     });
      //     const popup = new mapboxgl.Popup({
      //       closeButton: false,
      //       closeOnClick: false,
      //     });

      //     map.current.on("mouseenter", "offline", (e) => {
      //       map.current.getCanvas().style.cursor = "pointer";
      //       const coordinates = e.features[0].geometry.coordinates.slice();
      //       const description = e.features[0].properties.description;

      //       while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      //         coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      //       }

      //       popup.setLngLat(coordinates).setHTML(description).addTo(map.current);
      //     });

      //     map.current.on("mouseleave", "offline", () => {
      //       map.current.getCanvas().style.cursor = "";
      //       popup.remove();
      //     });
      //   });
    }
  }, [asmMonitorData]);
  return (
    <div>
      <div ref={mapContainer} style={{ height: "88vh" }} />
    </div>
  );
};

export default AsmMonitorByShivam;
