import React from "react";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { imgUrl } from "../../url";

import Modal from "@mui/material/Modal";

const ImagePreview = (props) => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "30%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
  };
  return (
    <div>
      <Modal
        open={props.preview}
        onClose={props.handleClosePreview}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                component={"img"}
                src={`${imgUrl}/${props.imageUrl}`}
                sx={{ width: "250px", height: "250px", borderRadius: "50%" }}
              ></Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default ImagePreview;
