import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const SnackbarComponent = ({ setOpen, open, severity, message }) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      {severity === "error" ? (
        <Alert severity="error" sx={{ width: "100%" }}>
          {message}
        </Alert>
      ) : severity === "warning" ? (
        <Alert severity="warning" sx={{ width: "100%" }}>
          {message}
        </Alert>
      ) : severity === "info" ? (
        <Alert severity="info" sx={{ width: "100%" }}>
          {message}
        </Alert>
      ) : severity === "success" ? (
        <Alert severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      ) : (
        <></>
      )}
    </Snackbar>
  );
};
export default SnackbarComponent;
