import { Box, Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { AdminDataContext } from "../../useContext/AdminContext";

import axios from "axios";

import RefferedCustomersDetails from "./RefferedCustomersDetails";
import PayToCustomerForReferral from "./PayToCustomerForReferral";

const CustomerReferredCalculation = () => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [redeemedRewards, setRedeemedRewards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openModalPayment, setOpenModalPayment] = useState(false);
  const [data, setData] = useState([]);
  const [payable, setPayable] = useState([]);
  const [customerId, setCustomerId] = useState(null);
  const handleOpenModal = (data) => {
    console.log("data", data);
    setOpenModal(true);
    setData(data);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  // modal for payment
  const handleOpenModalPayment = (id) => {
    changeDeliveryStatus(id);
    setCustomerId(id);
    setOpenModalPayment(true);
    console.log("data", id);
  };
  const handleCloseModalPayment = () => {
    setOpenModalPayment(false);
  };

  const getRedeemedRewards = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `/api/v1/admin/referred-user-by-customer`
      );
      console.log(response);
      console.log(response);
      setRedeemedRewards(response.data.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const changeDeliveryStatus = async (id) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/referred-customer-available/${id}`
      );
      console.log("referred-customer-available ", res);
      setPayable(res.data.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getRedeemedRewards();
  }, []);
  const columns = [
    {
      field: "name",
      headerName: "Reward Name",
      width: 150,
      renderCell: (params) => {
        return params.row.customer ? params.row.customer.name : "";
      },
    },

    {
      field: "phone",
      headerName: "Customer Phone",
      width: 200,
      renderCell: (params) => {
        return params.row.customer ? params.row.customer.phone : "";
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
      renderCell: (params) => {
        return params.row.customer ? params.row.customer.email : "";
      },
    },

    {
      field: "earn",
      headerName: "Earn",
      width: 120,
    },
    {
      field: "totalEarn",
      headerName: "Total Earn",
      width: 120,
    },

    {
      field: "paidDownloadCustomer",
      headerName: "All Ready Paid For",
      width: 150,
      //   renderCell: (params) => {
      //     return params.row ? params.row.paidDownloadCustomer.length : "";
      //   },
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            onClick={() => handleOpenModal(params.row.paidDownloadCustomer)}
          >
            {params.row.paidDownloadCustomer.length}
          </Button>
        );
      },
    },

    {
      field: "unpaidDownloadCustomer",
      headerName: "Need To Pay For",
      width: 150,
      //   renderCell: (params) => {
      //     return params.row ? params.row.unpaidDownloadCustomer.length : "";
      //   },

      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            onClick={() => handleOpenModal(params.row.unpaidDownloadCustomer)}
          >
            {params.row.unpaidDownloadCustomer.length}
          </Button>
        );
      },
    },

    {
      field: "gender",
      headerName: "Gender",
      width: 150,
      renderCell: (params) => {
        return params.row.customer ? params.row.customer.gender : "";
      },
    },
    {
      field: "dob",
      headerName: "DOB",
      width: 150,
      renderCell: (params) => {
        return params.row.customer ? params.row.customer.dob : "";
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        return params.row.customer ? params.row.customer.status : "";
      },
    },

    {
      field: "paidToCustomer",
      headerName: "Paid to customer",
      width: 150,

      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            onClick={() => handleOpenModalPayment(params.row.customer._id)}
          >
            Pay
          </Button>
        );
      },
    },
  ];

  const addressTxt = {
    width: { xs: "100%", sm: "80%" },
    borderRadius: "10px",
    // background: "rgba(255, 255, 255, 0.31)",
    color: "rgba(255, 255, 255, 0.96)",
    border: "1px solid black",
    "& fieldset": {
      border: "none",
      color: "rgba(255, 255, 255, 0.96);",
    },
  };

  return (
    <>
      <Box>
        <DataGrid
          components={{ Toolbar: GridToolbar }}
          // autoHeight
          rows={redeemedRewards}
          getRowId={(row) => row._id}
          // rowCount={total}
          loading={loading}
          rowsPerPageOptions={[10, 30, 50, 70, 100]}
          pagination
          // page={page - 1}
          // pageSize={limit}
          paginationMode="server"
          sortingMode="server"
          // onSortModelChange={handleSortModelChange}
          filterMode="server"
          columns={columns}
          sx={{
            "& .bold-header": {
              fontWeight: "bold",
              color: "blue",
            },
            height: "600px",
            overflow: "auto",
          }}
        />
      </Box>

      <RefferedCustomersDetails
        handleCloseModal={handleCloseModal}
        openModal={openModal}
        data={data}
      />

      <PayToCustomerForReferral
        handleCloseModalPayment={handleCloseModalPayment}
        openModalPayment={openModalPayment}
        payable={payable}
        customerId={customerId}
      />
    </>
  );
};

export default CustomerReferredCalculation;
