import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  Paper,
  Autocomplete,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Table,
  TableContainer,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { AdminDataContext } from "./../../useContext/AdminContext";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { saveAs } from "file-saver";
import { imgUrl } from "../../url";
import OutletTiming from "./OutletTiming";
import Multiselect from "multiselect-react-dropdown";
import { cuisinesItemList } from "./petPoojaOnboarding/createRestaurantModal/cuisinesItemList";
import {
  dashboardText,
  paperBoxDashboardStyle,
  tableColumnsHeading,
  textHeadingInputFields,
  timeDurationDashboardStyle,
  valueBoxStyle,
} from "../cssStyling/Style";
import FilterByCity from "../../reusableComponent/filterByCity/FilterByCity";
import { capitalize } from "../../reusableComponent/capitalFirstCharacter";
import { DownloadTableExcel } from "react-export-table-to-excel";
import moment from "moment";
var FileSaver = require("file-saver");
function UnVerifiedRestaurant() {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");
  const navigator = useNavigate();
  const { setIsLoading, snackbar, selectCity, filterType, setFilterType } =
    useContext(AdminDataContext);
  const tableRef = useRef(null);
  const [restaurant, setRestaurant] = useState([]);
  const [currentRestaurant, setCurrentRestaurant] = useState(null);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [bankData, setBankData] = useState({});
  const [addressData, setAddressData] = useState({});
  const [bankModel, setBankModel] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [timingModal, setTimingModal] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [editData, setEditData] = useState({});
  const [modalForAttributes, setModalForAttributes] = useState(false);
  const [currentDataForAttributes, setCurrentDataForAttributes] = useState([]);
  const [newDataForAttributes, setNewDataForAttributes] = useState([]);
  const [restaurantList, setRestaurantList] = useState([]);

  const [singleRestaurant, setSingleRestaurant] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [attributeList, setAttributeList] = useState([
    "veg",
    "non-veg",
    "egg",
    "jain",
    "other",
  ]);

  const [city, setCity] = useState("All");
  const [pincode, setPincode] = useState("");

  const modalForAttributesHandleOpen = (data, restaurantId) => {
    setCurrentRestaurant(restaurantId);
    setModalForAttributes(true);
    setCurrentDataForAttributes(data);
    const dd = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      dd.push(element.attribute);
    }
    setNewDataForAttributes(dd);
    console.log(dd);
    var c = dd.concat(attributeList);
    var d = c.filter((item, pos) => c.indexOf(item) === pos);
    setAttributeList(d);
  };
  const modalForAttributesHandleClose = () => {
    setModalForAttributes(false);
    setCurrentDataForAttributes([]);
    setNewDataForAttributes([]);
    setCurrentRestaurant(null);
    setAttributeList(["veg", "non-veg", "egg", "jain", "other"]);
  };
  const [time, setTime] = useState({
    sunday: {
      startTime: { hours: 10, min: 10 },
      endTime: { hours: 12, min: 59 },
    },
    monday: {
      startTime: { hours: 9, min: 0 },
      endTime: { hours: 12, min: 59 },
    },
    tuesday: {
      startTime: { hours: 1, min: 12 },
      endTime: { hours: 1, min: 17 },
    },
    wednesday: {
      startTime: { hours: 9, min: 0 },
      endTime: { hours: 12, min: 59 },
    },
    thursday: {
      startTime: { hours: 9, min: 0 },
      endTime: { hours: 12, min: 59 },
    },
    friday: {
      startTime: { hours: 9, min: 0 },
      endTime: { hours: 12, min: 59 },
    },
    saturday: {
      startTime: { hours: 9, min: 0 },
      endTime: { hours: 12, min: 59 },
    },
  });

  const attributeUpdateHandler = async () => {
    try {
      setLoading(true);
      const response = await axios.patch(
        `/api/v1/admin/restaurant/attribute?restaurantId=${currentRestaurant}`,
        { array: newDataForAttributes }
      );
      modalForAttributesHandleClose();
      snackbar(response.data.message, "success");
      isSelected
        ? getSingleRestaurantForEdit(currentRestaurant)
        : getAllRestaurant(page, limit, "", "");
      setLoading(false);
      setBankModel(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const editHandler = (data) => {
    setEditData({
      ...data,
      note: "",
      cuisines: data.cuisines?.split(","),
      latitude: data.location.coordinates[1],
      longitude: data.location.coordinates[0],
    });
    setEditModel(true);
  };

  const timingHandler = async (data) => {
    try {
      setTime(data.timing);
      setTimingModal(true);
    } catch (error) {}
  };
  const verifiedBankHandler = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `/api/v1/admin/restaurants/bank?bankDetailId=${bankData._id}`
      );

      snackbar(response.data.message, "success");
      isSelected
        ? getSingleRestaurantForEdit(bankData._id)
        : getAllRestaurant(page, limit, "", "");
      setLoading(false);
      setBankModel(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSelect = (selectedList) => {
    setEditData({
      ...editData,
      cuisines: selectedList,
    });
  };

  const handleRemove = (selectedList) => {
    setEditData({
      ...editData,
      cuisines: selectedList,
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "30%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
  };
  const columns = [
    {
      field: "logo",
      headerName: "Logo",
      width: 110,
      renderHeader: () => <strong>{"Logo"}</strong>,

      renderCell: (params) => {
        return params.row.restaurant_logo ? (
          <>
            <Box
              height={"100%"}
              component="img"
              src={
                params.row.restaurant_logo.startsWith("http")
                  ? `${params.row.restaurant_logo}`
                  : `${imgUrl}/${params.row.restaurant_logo}`
              }
            />
          </>
        ) : (
          "No data available"
        );
      },
    },
    {
      field: "brand_display_name",
      headerName: "Name",
      width: 250,
      renderHeader: () => <strong>{"Name"}</strong>,
      renderCell: (params) => capitalize(params.row.brand_display_name),
    },
    {
      field: "owner_name",
      headerName: "Owner Name",
      width: 300,
      renderHeader: () => <strong>{"Owner Name"}</strong>,
      renderCell: (params) => capitalize(params.row.owner_name),
    },

    {
      field: "lastSeen",
      headerName: "Last Seen",
      width: 190,
      renderHeader: () => <strong>{"Last Seen"}</strong>,

      renderCell: (params) => {
        const lastSeen = params.row.status?.updatedAt;
        if (lastSeen) {
          const formattedLastSeen = new Date(lastSeen).toLocaleString();
          return <Typography>{formattedLastSeen}</Typography>;
        }
        return <Typography>N/A</Typography>;
      },
    },
    {
      field: "items",
      headerName: "Menu Items",
      width: 100,
      renderHeader: () => <strong>{"Menu Items"}</strong>,

      renderCell: (params) => {
        return (
          <>
            <Typography>{params.row.items.length}</Typography>
          </>
        );
      },
    },
    {
      field: "outlet_id",
      headerName: "Outlet Id",
      width: 250,
      renderHeader: () => <strong>{"Outlet Id"}</strong>,
    },
    {
      field: "business_contact",
      headerName: "Business Contact",
      width: 250,
      renderHeader: () => <strong>{"Business Contact"}</strong>,
    },

    {
      field: "merchant_number",
      headerName: "Merchant Number",
      width: 250,
      renderHeader: () => <strong>{"Merchant Number"}</strong>,
    },
    {
      field: "address",
      headerName: "Address",
      width: 250,
      renderHeader: () => <strong>{"Address"}</strong>,
    },
    {
      field: "pincode",
      headerName: "Pincode",
      width: 250,
      renderHeader: () => <strong>{"Pin code"}</strong>,
    },
    {
      field: "city",
      headerName: "City",
      width: 250,
      renderHeader: () => <strong>{"City"}</strong>,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      renderHeader: () => <strong>{"Email"}</strong>,
    },
    {
      field: "asm",
      headerName: "ASM Name",
      width: 200,
      renderHeader: () => <strong>{"ASM Name"}</strong>,

      renderCell: (params) => {
        return params.row.asm && params.row.asm.name ? (
          <Typography>{params.row.asm.name}</Typography>
        ) : (
          <Typography>-</Typography>
        );
      },
    },
    {
      field: "menuCard",
      headerName: "Menu Card",
      width: 300,
      renderHeader: () => <strong>{"Menu Card"}</strong>,

      renderCell: (params) => {
        return params.row.menuItemsFile ? (
          <>
            <Typography>{params.row.menuItemsFile}</Typography>
            <CloudDownloadIcon
              onClick={() =>
                FileSaver.saveAs(
                  params.row.menuItemsFile.startsWith("http")
                    ? params.row.menuItemsFile
                    : `${imgUrl}/${params.row.menuItemsFile}`,
                  "document.pdf"
                )
              }
            />
          </>
        ) : (
          "No data available"
        );
      },
    },
    {
      field: "open",
      headerName: "open",
      width: 100,
      renderHeader: () => <strong>{"Open"}</strong>,
    },
    {
      field: "rating",
      headerName: "Rating",
      width: 100,
      renderHeader: () => <strong>{"Rating"}</strong>,
    },
    {
      field: "ratingCount",
      headerName: "ratingCount",
      width: 100,
      renderHeader: () => <strong>{"Rating Count"}</strong>,
    },

    {
      field: "appVisible",
      headerName: "App Visible",
      width: 120,
      renderHeader: () => <strong>{"App Visible"}</strong>,

      renderCell: (params) => {
        const actionSwitchToggle = async (id, appVisible) => {
          try {
            setLoading(true);
            let response = {};
            if (params.row.restaurant_logo) {
              console.log("Restaurant Logo ok");
              response = await axios.post(
                `/api/v1/admin/restaurants/appVisible?restaurantId=${id}`,
                {
                  appVisible,
                }
              );
            } else {
              snackbar("Restaurant image is mandatory", "error");
              setLoading(false);
            }
            console.log(
              "params.row.restaurant_logo",
              params.row.restaurant_logo
            );

            snackbar(response.data.message, "success");
            getAllRestaurant(page, limit, "", "");
            isSelected
              ? getSingleRestaurantForEdit(id)
              : getAllRestaurant(page, limit, "", "");

            setLoading(false);
          } catch (error) {
            snackbar(error.response.data.message, "error");
            setLoading(false);
          }
        };

        return (
          <Switch
            checked={params.row.appVisible ? true : false}
            onChange={(e) =>
              actionSwitchToggle(params.row._id, e.target.checked)
            }
          />
        );
      },
    },
    {
      field: "gstCertificate",
      headerName: "GST",
      width: 250,
      renderHeader: () => <strong>{"GST"}</strong>,

      renderCell: (params) => {
        return params.row.gst_no ? (
          <>
            <Typography>{params.row.gst_no}</Typography>
            <CloudDownloadIcon
              onClick={() =>
                FileSaver.saveAs(
                  params.row.gst_image.startsWith("http")
                    ? `${params.row.gst_image}`
                    : `${imgUrl}/${params.row.gst_image}`,
                  "image.jpg"
                )
              }
            />
          </>
        ) : (
          "No data available"
        );
      },
    },
    {
      field: "aadhar",
      headerName: "Aadhar",
      width: 250,
      renderHeader: () => <strong>{"Aadhar"}</strong>,

      renderCell: (params) => {
        return params.row.aadhar_no ? (
          <>
            <Typography>{params.row.aadhar_no}</Typography>
            <CloudDownloadIcon
              onClick={() =>
                FileSaver.saveAs(
                  params.row.aadhar_image.startsWith("http")
                    ? `${params.row.aadhar_image}`
                    : `${imgUrl}/${params.row.aadhar_image}`,
                  "image.jpg"
                )
              }
            />
          </>
        ) : (
          "No data available"
        );
      },
    },
    {
      field: "pan",
      headerName: "Pan Card",
      width: 250,
      renderHeader: () => <strong>{"Pan Card"}</strong>,

      renderCell: (params) => {
        return params.row.pan_no ? (
          <>
            <Typography>{params.row.pan_no}</Typography>
            <CloudDownloadIcon
              onClick={() =>
                FileSaver.saveAs(
                  params.row.pan_image.startsWith("http")
                    ? `${params.row.pan_image}`
                    : `${imgUrl}/${params.row.pan_image}`,
                  "image.jpg"
                )
              }
            />
          </>
        ) : (
          "No data available"
        );
      },
    },

    {
      field: "fssai_image",
      headerName: "Fssai",
      width: 250,
      renderHeader: () => <strong>{"Fssai"}</strong>,

      renderCell: (params) => {
        return params.row.fssai_no ? (
          <>
            <Typography>{params.row.fssai_no}</Typography>
            <CloudDownloadIcon
              onClick={() =>
                FileSaver.saveAs(
                  params.row.fssai_image.startsWith("http")
                    ? `${params.row.fssai_image}`
                    : `${imgUrl}/${params.row.fssai_image}`,
                  "image.jpg"
                )
              }
            />
          </>
        ) : (
          "No data available"
        );
      },
    },
    {
      field: "attributes",
      headerName: "attributes",
      width: 250,
      renderHeader: () => <strong>{"Attributes"}</strong>,

      renderCell: (params) => {
        let string = "";
        for (let index = 0; index < params.row.attributes.length; index++) {
          const element = params.row.attributes[index];
          if (!string) {
            string = element.attribute;
          } else {
            string = `${string}, ${element.attribute}`;
          }
        }
        return <Typography>{string}</Typography>;
      },
    },
    {
      field: "taxes",
      headerName: "taxes",
      width: 250,
      renderHeader: () => <strong>{"Taxes"}</strong>,

      renderCell: (params) => {
        let string = "";
        for (let index = 0; index < params.row.taxes.length; index++) {
          const element = params.row.taxes[index];
          if (!string) {
            string = `${element.taxname} => ${element.tax}`;
          } else {
            string = `${string}, ${`${element.taxname} => ${element.tax}`}`;
          }
        }
        return <Typography>{string}</Typography>;
      },
    },
    {
      field: "location",
      headerName: "Location",
      width: 120,
      renderHeader: () => <strong>{"Location"}</strong>,

      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              const url = `http://maps.google.com/maps?z=12&t=m&q=loc:${params.row.location.coordinates[1]}+${params.row.location.coordinates[0]}`;
              window.open(url, "_blank");
            }}
          >
            Location
          </Button>
        );
      },
    },

    {
      field: "bank",
      headerName: "bank",
      width: 120,
      renderHeader: () => <strong>{"Bank"}</strong>,

      renderCell: (params) => {
        return params.row.bankDetail ? (
          <Button
            onClick={() => {
              setBankData(params.row.bankDetail);
              setBankModel(true);
            }}
          >
            View
          </Button>
        ) : (
          "No data available"
        );
      },
    },

    {
      field: "EditAttribute",
      headerName: "Edit Attribute",
      width: 150,
      renderHeader: () => <strong>{"Edit Attribute"}</strong>,

      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            onClick={() =>
              modalForAttributesHandleOpen(
                params.row.attributes,
                params.row._id
              )
            }
          >
            Edit Attribute
          </Button>
        );
      },
    },
    {
      field: "Edit",
      headerName: "Edit",
      width: 100,
      renderHeader: () => <strong>{"Edit"}</strong>,

      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              editHandler(params.row);
              // getSearchSingleRestaurant();
            }}
            size="small"
          >
            Edit
          </Button>
        );
      },
    },

    {
      field: "approve",
      headerName: "Approve",
      width: 120,
      renderHeader: () => <strong>{"Approve"}</strong>,

      renderCell: (params) => {
        return params.row.approved ? (
          <Typography>Approved</Typography>
        ) : (
          <Button>Approve</Button>
        );
      },
    },
    {
      field: "note",
      headerName: "note",
      width: 120,
      renderHeader: () => <strong>{"Note"}</strong>,

      renderCell: (params) => {
        return params.row.note ? (
          <Typography>{params.row.note}</Typography>
        ) : (
          <Button>-</Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 120,
      renderHeader: () => <strong>{"Delete"}</strong>,

      renderCell: (params) => {
        return (
          <>
            <Button
              variant="contained"
              onClick={() => deleteRestaurantHandler(params.row._id)}
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];

  const deleteRestaurantHandler = async (id) => {
    console.log(id);

    try {
      setIsLoading(true);
      const res = await axios.delete(
        `/api/v1/admin/restaurant/delete?restaurantId=${id}`
      );

      console.log("this is edit ", res);

      snackbar(res.data.message, "success");

      getAllRestaurant(page, limit, "", "");

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      snackbar(error.response.data.message, "error");
    }
  };

  const editAttributes = async (id) => {};
  useEffect(() => {
    getAllRestaurant(page, limit, "", "");
  }, [selectCity]);

  // const inputChange = (event) => {
  //   setUpgradeData({ ...upgradeData, [event.target.name]: event.target.value });
  // };

  const getAllRestaurant = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      setIsSelected(false);
      setFilterType({ ...filterType, restaurant: false, city: true });

      const res = await axios.get(
        `/api/v1/admin/restaurants?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}&city=${selectCity}&pincode=${pincode}`
      );
      setRestaurant(res.data.restaurant);
      console.log("res.data.restaurant", res.data.restaurant);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getSingleRestaurantForEdit = async (id) => {
    try {
      console.log("getSingleRestaurId", id);
      setLoading(true);
      let row = [];
      const res = await axios.get(
        `/api/v1/admin/restaurants/singleRestaurantEdit?restaurantId=${id}`
      );
      // setRestaurant(res.data.restaurant);
      row.push(res.data.restaurant);
      // setSingleRestaurant(res.data.restaurant);
      setSingleRestaurant(row);

      console.log("res.data.restaurant edit", res.data.restaurant);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getSearchSingleRestaurant = async (e) => {
    try {
      const res = await axios.put(
        `/api/v1/admin/restaurants?query=${e.target.value}`
      );
      console.log("list ", res.data);
      if (!(res.data.data.length === 0)) {
        setRestaurantList(res.data.data);
      } else {
        setRestaurantList([]);
        console.log("none");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSortModelChange = async (item) => {
    getAllRestaurant(page, limit, "", "");
    if (item.length === 0) {
      getAllRestaurant(page, limit, "", "");
    } else {
      getAllRestaurant(page, limit, item[0].sort, item[0].field);
    }
  };
  const onFilterChange = async (e) => {
    console.log(e);
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/admin/restaurants/search`, {
        key: e.items[0].columnField,
        value: e.items[0].value,
      });
      setRestaurant(res.data.Restaurant);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const onChangeEditHandler = (event) => {
    setEditData({ ...editData, [event.target.name]: event.target.value });
  };
  const editSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await axios.post(`/api/v1/admin/restaurant/edit`, editData);

      console.log("this is edit ", editData);

      snackbar(res.data.message, "success");
      // getAllRestaurant(page, limit, "", "");
      isSelected
        ? getSingleRestaurantForEdit(editData._id)
        : getAllRestaurant(page, limit, "", "");
      setEditModel(false);
      setIsLoading(false);
      console.log("this is edit ", editData);
    } catch (error) {
      setIsLoading(false);
      snackbar(error.response.data.message, "error");
    }
  };
  console.log("singleRestaurant for edit", singleRestaurant);

  return (
    <Box>
      <FilterByCity />
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "start", my: 3, px: 2 }}
      >
        <Box sx={{ mx: 3 }}>
          <Typography variant="body1" sx={textHeadingInputFields}>
            Filter By pin code
          </Typography>
        </Box>
        <Box>
          <TextField
            size="small"
            id="outlined-basic"
            sx={{ width: "100%" }}
            label="pincode"
            name="pincode"
            onChange={(e) => setPincode(e.target.value)}
            value={pincode}
            variant="outlined"
          />
        </Box>
        <Box sx={{ mx: 3 }}>
          <Button
            onClick={() => getAllRestaurant(page, limit, "", "")}
            variant="contained"
          >
            Filter
          </Button>
        </Box>
      </Grid>
      <Autocomplete
        sx={{ mb: 2 }}
        size="small"
        disableClearable
        fullWidth
        id="tags-outlined"
        options={restaurantList}
        getOptionLabel={(userData) =>
          `${userData.brand_display_name}  Number : ${userData.business_contact}`
        }
        onInputChange={getSearchSingleRestaurant}
        onChange={(event, value) => {
          let row = [];
          setRestaurantList(value);
          row.push(value);
          console.log("row data is ", row);
          // setSingleRestaurant(row);
          getSingleRestaurantForEdit(value._id);
          setIsSelected(true);
        }}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Restaurant"
            placeholder="Select Restaurant"
          />
        )}
      />
      {isSelected ? (
        <>
          {console.log("inGrid", singleRestaurant)}
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            autoHeight
            rows={singleRestaurant}
            getRowId={(row) => row._id}
            // loading={loading}
            // sortingMode="server"
            pageSize={5}
            rowsPerPageOptions={[5]}
            columns={columns}
          />
        </>
      ) : (
        <DataGrid
          components={{ Toolbar: GridToolbar }}
          // autoHeight
          rows={restaurant}
          getRowId={(row) => row._id}
          rowCount={total}
          loading={loading}
          rowsPerPageOptions={[10, 30, 50, 70, 100]}
          pagination
          page={page - 1}
          pageSize={limit}
          paginationMode="server"
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          filterMode="server"
          // onFilterModelChange={onFilterChange}
          onPageChange={(newPage) => {
            getAllRestaurant(newPage + 1, limit, "", "");
            setPage(newPage + 1);
          }}
          onPageSizeChange={(newPageSize) => {
            getAllRestaurant(page, newPageSize, "", "");
            setLimit(newPageSize);
            //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
          }}
          columns={columns}
          sx={{
            "& .bold-header": {
              fontWeight: "bold",
              color: "blue",
            },
            height: "600px",
            overflow: "auto",
          }}
        />
      )}
      {/* // download in excel */}
      <Stack spacing={2} mt={3} display={"none"}>
        <TableContainer component={Paper}>
          <Table ref={tableRef} aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                }}
              >
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Restaurant
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Owner
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Business Contact
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Merchant Number
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  App Visible
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Approved
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  City
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Pin Code
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Deleted
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Menu Items
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {restaurant?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={tableColumnsHeading}
                  >
                    {row.brand_display_name ? row.brand_display_name : ""}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={tableColumnsHeading}
                  >
                    {row.owner_name ? row.owner_name : ""}
                  </TableCell>

                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={tableColumnsHeading}
                  >
                    {row.business_contact ? row.business_contact : ""}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={tableColumnsHeading}
                  >
                    {row.merchant_number ? row.merchant_number : ""}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={tableColumnsHeading}
                  >
                    {row.appVisible ? "true" : "false"}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={tableColumnsHeading}
                  >
                    {row.approved ? "true" : "false"}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={tableColumnsHeading}
                  >
                    {row.city ? row.city : ""}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={tableColumnsHeading}
                  >
                    {row.pincode ? row.pincode : ""}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={tableColumnsHeading}
                  >
                    {row.deleted ? "true" : "false"}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={tableColumnsHeading}
                  >
                    {row.items?.length}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <DownloadTableExcel
        filename="All Calculation"
        sheet="DeliveryPartner"
        currentTableRef={tableRef.current}
      >
        <Button variant="contained"> Export excel </Button>
      </DownloadTableExcel>{" "}
      {/* //download in excel */}
      <Modal
        open={bankModel}
        onClose={() => setBankModel(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, p: 0 }}>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item xs={12}>
              <Paper sx={{ borderRadius: "10px" }} elevation={3}>
                <Box sx={paperBoxDashboardStyle}>
                  <Typography sx={timeDurationDashboardStyle}>
                    Bank Details
                  </Typography>
                </Box>

                <Box sx={{ padding: "10px" }}>
                  <Box sx={valueBoxStyle}>
                    <Typography sx={dashboardText}>Bank Name :</Typography>{" "}
                    <Typography sx={dashboardText}>
                      {bankData.bankName}
                    </Typography>
                  </Box>

                  <Box sx={valueBoxStyle}>
                    <Typography sx={dashboardText}>
                      {" "}
                      Account Holder Name:
                    </Typography>{" "}
                    <Typography sx={dashboardText}>
                      {bankData.accountHolderName}
                    </Typography>
                  </Box>

                  <Box sx={valueBoxStyle}>
                    <Typography sx={dashboardText}>Branch Name:</Typography>{" "}
                    <Typography sx={dashboardText}>
                      {bankData.branch}
                    </Typography>
                  </Box>

                  <Box sx={valueBoxStyle}>
                    <Typography sx={dashboardText}>Account Number :</Typography>{" "}
                    <Typography sx={dashboardText}>
                      {bankData.accountNumber}
                    </Typography>
                  </Box>

                  <Box sx={valueBoxStyle}>
                    <Typography sx={dashboardText}>IFSC Code:</Typography>{" "}
                    <Typography sx={dashboardText}>
                      {bankData.ifscCode}
                    </Typography>
                  </Box>

                  <Box sx={valueBoxStyle}>
                    <Typography sx={dashboardText}>Verified:</Typography>{" "}
                    <Typography sx={dashboardText}>
                      {bankData.verified ? "True" : "False"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      ...valueBoxStyle,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {!bankData.verified ? (
                      <Button variant="contained" onClick={verifiedBankHandler}>
                        Verify Bank
                      </Button>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={addressModal}
        onClose={() => setAddressModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography>
            Complete Address : {addressData.completeAddress}
          </Typography>
          <Typography>City : {addressData.city}</Typography>
          <Typography>State : {addressData.state}</Typography>
          <Typography>Landmark : {addressData.landmark}</Typography>
        </Box>
      </Modal>
      <Modal
        open={timingModal}
        onClose={() => setTimingModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: "80%" }}>
          <OutletTiming
            time={time}
            setTime={setTime}
            setIsLoading={setIsLoading}
            snackbar={snackbar}
            getAllRestaurant={getAllRestaurant}
            setTimingModal={setTimingModal}
            page={page}
            limit={limit}
          />
        </Box>
      </Modal>
      <Modal
        open={editModel}
        onClose={() => {
          setEditModel(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, overflow: "scroll", height: "95%" }}>
          <Stack
            spacing={2}
            component="form"
            onSubmit={editSubmitHandler}
            // sx={{ overflow: "scroll", height: "95%" }}
          >
            <Box sx={{ ...paperBoxDashboardStyle, borderRadius: "10px" }}>
              <Typography sx={timeDurationDashboardStyle}>
                Edit Restaurant
              </Typography>
            </Box>
            <TextField
              size="small"
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="brand display name"
              name="brand_display_name"
              value={editData.brand_display_name}
              onChange={onChangeEditHandler}
              variant="outlined"
            />
            <TextField
              size="small"
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="outlet name"
              name="outlet_name"
              onChange={onChangeEditHandler}
              value={editData.outlet_name}
              variant="outlined"
            />

            <Multiselect
              options={cuisinesItemList} // Options to display in the dropdown
              selectedValues={editData.cuisines} // Preselected value to persist in dropdown
              onSelect={handleSelect} // Function will trigger on select event
              onRemove={handleRemove} // Function will trigger on remove event
              // displayValue="title" // Property name to display in the dropdown options
              isObject={false}
              placeholder="Select Cuisines"
              style={{
                chips: {
                  background: "#4caf50",
                },
                multiselectContainer: {
                  color: "red",
                },
                searchBox: {
                  // border: "none",
                  // "border-bottom": "1px solid #4caf50",
                  "border-radius": "5px",
                },
              }}
            />
            <TextField
              size="small"
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="address"
              name="address"
              onChange={onChangeEditHandler}
              value={editData.address}
              variant="outlined"
            />
            <TextField
              size="small"
              id="outlined-basic"
              label="landmark"
              onChange={onChangeEditHandler}
              name="landmark"
              sx={{ width: "100%" }}
              value={editData.landmark}
              variant="outlined"
            />
            <TextField
              size="small"
              id="outlined-basic"
              label="area"
              onChange={onChangeEditHandler}
              name="area"
              sx={{ width: "100%" }}
              value={editData.area}
              variant="outlined"
            />
            <TextField
              size="small"
              id="outlined-basic"
              label="state"
              onChange={onChangeEditHandler}
              name="state"
              sx={{ width: "100%" }}
              value={editData.state}
              variant="outlined"
            />
            <TextField
              size="small"
              id="outlined-basic"
              label="pincode"
              onChange={onChangeEditHandler}
              name="pincode"
              sx={{ width: "100%" }}
              value={editData.pincode}
              variant="outlined"
            />
            <TextField
              size="small"
              id="outlined-basic"
              label="city"
              onChange={onChangeEditHandler}
              name="city"
              sx={{ width: "100%" }}
              value={editData.city}
              variant="outlined"
            />
            <TextField
              size="small"
              id="outlined-basic"
              label="business_contact"
              onChange={onChangeEditHandler}
              name="business_contact"
              sx={{ width: "100%" }}
              value={editData.business_contact}
              variant="outlined"
            />
            <TextField
              size="small"
              id="outlined-basic"
              label="merchant_number"
              onChange={onChangeEditHandler}
              name="merchant_number"
              sx={{ width: "100%" }}
              value={editData.merchant_number}
              variant="outlined"
            />
            <TextField
              size="small"
              id="outlined-basic"
              label="email"
              onChange={onChangeEditHandler}
              name="email"
              sx={{ width: "100%" }}
              value={editData.email}
              variant="outlined"
            />
            <TextField
              size="small"
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="Latitude"
              type="number"
              onChange={onChangeEditHandler}
              name="latitude"
              value={editData.latitude}
              variant="outlined"
            />
            <TextField
              size="small"
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="Longitude"
              onChange={onChangeEditHandler}
              name="longitude"
              type="number"
              value={editData.longitude}
              variant="outlined"
            />
            <TextField
              size="small"
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="note"
              onChange={onChangeEditHandler}
              name="note"
              value={editData.note}
              variant="outlined"
            />

            <Button type="submit" variant="contained">
              Update
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Modal
        open={modalForAttributes}
        onClose={modalForAttributesHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <FormControl component="fieldset" sx={{ display: "block" }}>
            <FormGroup
              aria-label="position"
              required
              row
              onChange={(e) => {
                console.log(newDataForAttributes);
                e.target.checked
                  ? setNewDataForAttributes([
                      ...newDataForAttributes,
                      e.target.value,
                    ])
                  : setNewDataForAttributes(
                      newDataForAttributes.filter((a) => a !== e.target.value)
                    );
              }}
            >
              {attributeList.map((item) => {
                console.log();

                return (
                  <FormControlLabel
                    value={item}
                    id={item}
                    control={
                      <Checkbox
                        defaultChecked={newDataForAttributes?.some(
                          (e) => e === item
                        )}
                      />
                    }
                    label={item}
                    labelPlacement="end"
                    disabled={currentDataForAttributes?.some(
                      (e) => e.attribute === item
                    )}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
          <Stack>
            <Button onClick={attributeUpdateHandler} variant="contained">
              Update
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
}

export default UnVerifiedRestaurant;
