import React, { useState, useRef, useContext } from "react";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import Multiselect from "multiselect-react-dropdown";
import { AdminDataContext } from "../../useContext/AdminContext";

import axios from "axios";

const AddNewUser = (props) => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  const name = useRef(null);
  const email = useRef(null);
  const password = useRef(null);
  const passwordConfirm = useRef(null);
  const designation = useRef(null);
  const [selectedOption, setSelectedOption] = useState([]);

  const select = [
    { option: "Dashboard", id: "1" },
    { option: "restaurant", id: "2" },
    { option: "category", id: "3" },
    { option: "customer", id: "4" },
    { option: "cuisine", id: "5" },
    { option: "owner", id: "6" },
    { option: "menu", id: "7" },
    { option: "coupon", id: "8" },
    { option: "complain", id: "9" },
    { option: "delivery", id: "10" },
    { option: "banner", id: "11" },
    { option: "Admin permission", id: "12" },
    { option: "notification", id: "13" },
    { option: "account", id: "14" },
    { option: "version", id: "15" },
  ];

  const handleSubmit = async (e) => {
    try {
      setIsLoading(true);
      e.preventDefault();
      const data = {
        name: name.current.value,
        email: email.current.value,
        password: password.current.value,
        passwordConfirm: passwordConfirm.current.value,
        designation: designation.current.value,
        permissions: {
          dashboard: selectedOption.some((e) => e.option === "Dashboard"),
          restaurant: selectedOption.some((e) => e.option === "restaurant"),
          category: selectedOption.some((e) => e.option === "category"),
          customer: selectedOption.some((e) => e.option === "customer"),
          cuisine: selectedOption.some((e) => e.option === "cuisine"),
          owner: selectedOption.some((e) => e.option === "owner"),
          menu: selectedOption.some((e) => e.option === "menu"),
          coupon: selectedOption.some((e) => e.option === "coupon"),
          complain: selectedOption.some((e) => e.option === "complain"),
          delivery: selectedOption.some((e) => e.option === "delivery"),
          banner: selectedOption.some((e) => e.option === "banner"),
          notification: selectedOption.some((e) => e.option === "notification"),

          account: selectedOption.some((e) => e.option === "account"),
          version: selectedOption.some((e) => e.option === "version"),

          permission: selectedOption.some(
            (e) => e.option === "Admin permission"
          ),
        },
      };
      const res = await axios.post("/api/v1/admin/create/admin", { ...data });
      snackbar(res.data.message, "success");
      props.setRunEffect(true);
      setIsLoading(false);
    } catch (error) {
      snackbar(error.response.data.message, "error");

      setIsLoading(false);
    }
  };

  return (
    <div>
      <Box alignItems="center" justifyContent="center">
        <Typography variant="h4">Add New User</Typography>
      </Box>
      <Grid container spacing={2} component="form" onSubmit={handleSubmit}>
        <Grid item sm={6}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Name"
            variant="outlined"
            placeholder="Name"
            name="name"
            inputRef={name}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Email"
            variant="outlined"
            placeholder="Email"
            name="email"
            inputRef={email}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Designation"
            variant="outlined"
            placeholder="Designation"
            name="designation"
            inputRef={designation}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Password"
            variant="outlined"
            placeholder="Password"
            name="password"
            inputRef={password}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Confirm Password"
            variant="outlined"
            placeholder="Confirm Password"
            name="passwordConfirm"
            inputRef={passwordConfirm}
          />
        </Grid>
        <Grid item sm={12} spacing={2}>
          <Multiselect
            options={select}
            displayValue="option"
            onSelect={(list, item) => setSelectedOption(list)}
            onRemove={(list, item) => setSelectedOption(list)}
            value={select.option}
            name="select"
          />
          <Box display="flex" justifyContent="center" sx={{ margin: "20px" }}>
            <Button type="submit" variant="contained">
              Create Admin
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddNewUser;
