import {
  Box,
  Button,
  Stack,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  Paper,
  Grid,
  Autocomplete,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { AdminDataContext } from "./../../useContext/AdminContext";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { saveAs } from "file-saver";
import { imgUrl } from "../../url";
import OutletTiming from "./OutletTiming";
import {
  dashboardText,
  paperBoxDashboardStyle,
  timeDurationDashboardStyle,
  valueBoxStyle,
} from "../cssStyling/Style";
import FilterByCity from "../../reusableComponent/filterByCity/FilterByCity";
import { capitalize } from "../../reusableComponent/capitalFirstCharacter";
var FileSaver = require("file-saver");
function UnVerifiedRestaurant() {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");
  const navigator = useNavigate();
  const { setIsLoading, snackbar, selectCity, filterType, setFilterType } =
    useContext(AdminDataContext);
  const [restaurant, setRestaurant] = useState([]);
  const [currentRestaurant, setCurrentRestaurant] = useState(null);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [bankData, setBankData] = useState({});
  const [addressData, setAddressData] = useState({});
  const [bankModel, setBankModel] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [timingModal, setTimingModal] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [editData, setEditData] = useState({});
  const [restaurantList, setRestaurantList] = useState([]);

  const [singleRestaurant, setSingleRestaurant] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [city, setCity] = useState("All");
  const [time, setTime] = useState({
    sunday: {
      startTime: { hours: 10, min: 10 },
      endTime: { hours: 12, min: 59 },
    },
    monday: {
      startTime: { hours: 9, min: 0 },
      endTime: { hours: 12, min: 59 },
    },
    tuesday: {
      startTime: { hours: 1, min: 12 },
      endTime: { hours: 1, min: 17 },
    },
    wednesday: {
      startTime: { hours: 9, min: 0 },
      endTime: { hours: 12, min: 59 },
    },
    thursday: {
      startTime: { hours: 9, min: 0 },
      endTime: { hours: 12, min: 59 },
    },
    friday: {
      startTime: { hours: 9, min: 0 },
      endTime: { hours: 12, min: 59 },
    },
    saturday: {
      startTime: { hours: 9, min: 0 },
      endTime: { hours: 12, min: 59 },
    },
  });

  const editHandler = (data) => {
    setEditData({
      ...data,
      note: "",
      latitude: data.location.coordinates[1],
      longitude: data.location.coordinates[0],
    });
    setEditModel(true);
  };

  const verifiedBankHandler = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `/api/v1/admin/restaurants/bank?bankDetailId=${bankData._id}`
      );

      snackbar(response.data.message, "success");
      getAllRestaurant(page, limit, "", "");
      setLoading(false);
      setBankModel(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "30%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
  };
  const columns = [
    {
      field: "logo",
      headerName: "Logo",
      width: 110,
      renderCell: (params) => {
        return params.row.restaurant_logo ? (
          <>
            <Box
              height={"100%"}
              component="img"
              src={
                params.row.restaurant_logo.startsWith("http")
                  ? `${params.row.restaurant_logo}`
                  : `${imgUrl}/${params.row.restaurant_logo}`
              }
            />
          </>
        ) : (
          "No data available"
        );
      },
    },
    {
      field: "brand_display_name",
      headerName: "Name",
      width: 250,
      renderCell: (params) => capitalize(params.row.brand_display_name),
    },
    {
      field: "owner_name",
      headerName: "Owner Name",
      width: 300,
      renderCell: (params) => capitalize(params.row.owner_name),
    },
    {
      field: "outlet_id",
      headerName: "Outlet Id",
      width: 250,
    },
    {
      field: "business_contact",
      headerName: "Business Contact",
      width: 250,
    },

    {
      field: "merchant_number",
      headerName: "Merchant Number",
      width: 250,
    },
    {
      field: "address",
      headerName: "Address",
      width: 250,
    },
    {
      field: "pincode",
      headerName: "Pincode",
      width: 250,
    },
    {
      field: "city",
      headerName: "City",
      width: 250,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
    },
    {
      field: "asm",
      headerName: "ASM Name",
      width: 200,
      renderCell: (params) => {
        return params.row.asm && params.row.asm.name ? (
          <Typography>{params.row.asm.name}</Typography>
        ) : (
          <Typography>-</Typography>
        );
      },
    },
    {
      field: "menuCard",
      headerName: "Menu Card",
      width: 300,
      renderCell: (params) => {
        return params.row.menuItemsFile ? (
          <>
            <Typography>{params.row.menuItemsFile}</Typography>
            <CloudDownloadIcon
              onClick={() =>
                FileSaver.saveAs(
                  params.row.menuItemsFile.startsWith("http")
                    ? params.row.menuItemsFile
                    : `${imgUrl}/${params.row.menuItemsFile}`,
                  "document.pdf"
                )
              }

              // onClick={() =>
              //   FileSaver.saveAs(
              //     params.row.pan_image.startsWith("http")
              //       ? `${params.row.pan_image}`
              //       : `${imgUrl}/${params.row.pan_image}`,
              //     "image.jpg"
              //   )
              // }
            />
          </>
        ) : (
          "No data available"
        );
      },
    },
    {
      field: "open",
      headerName: "open",
      width: 100,
    },
    {
      field: "rating",
      headerName: "Rating",
      width: 100,
    },
    {
      field: "ratingCount",
      headerName: "ratingCount",
      width: 100,
    },

    // {
    //   field: "appVisible",
    //   headerName: "App Visible",
    //   width: 120,
    //   renderCell: (params) => {
    //     const actionSwitchToggle = async (id, appVisible) => {
    //       try {
    //         setLoading(true);
    //         const response = await axios.post(
    //           `/api/v1/admin/restaurants/appVisible?restaurantId=${id}`,
    //           {
    //             appVisible,
    //           }
    //         );

    //         snackbar(response.data.message, "success");
    //         getAllRestaurant(page, limit, "", "");
    //         setLoading(false);
    //       } catch (error) {
    //         snackbar(error.response.data.message, "error");
    //         setLoading(false);
    //       }
    //     };

    //     return (
    //       <Switch
    //         checked={params.row.appVisible ? true : false}
    //         onChange={(e) =>
    //           actionSwitchToggle(params.row._id, e.target.checked)
    //         }
    //       />
    //     );
    //   },
    // },
    {
      field: "gstCertificate",
      headerName: "GST",
      width: 250,
      renderCell: (params) => {
        return params.row.gst_no ? (
          <>
            <Typography>{params.row.gst_no}</Typography>
            <CloudDownloadIcon
              onClick={() =>
                FileSaver.saveAs(
                  params.row.gst_image.startsWith("http")
                    ? `${params.row.gst_image}`
                    : `${imgUrl}/${params.row.gst_image}`,
                  "image.jpg"
                )
              }
            />
          </>
        ) : (
          "No data available"
        );
      },
    },
    {
      field: "aadhar",
      headerName: "Aadhar",
      width: 250,
      renderCell: (params) => {
        return params.row.aadhar_no ? (
          <>
            <Typography>{params.row.aadhar_no}</Typography>
            <CloudDownloadIcon
              onClick={() =>
                FileSaver.saveAs(
                  params.row.aadhar_image.startsWith("http")
                    ? `${params.row.aadhar_image}`
                    : `${imgUrl}/${params.row.aadhar_image}`,
                  "image.jpg"
                )
              }
            />
          </>
        ) : (
          "No data available"
        );
      },
    },
    {
      field: "pan",
      headerName: "Pan Card",
      width: 250,
      renderCell: (params) => {
        return params.row.pan_no ? (
          <>
            <Typography>{params.row.pan_no}</Typography>
            <CloudDownloadIcon
              onClick={() =>
                FileSaver.saveAs(
                  params.row.pan_image.startsWith("http")
                    ? `${params.row.pan_image}`
                    : `${imgUrl}/${params.row.pan_image}`,
                  "image.jpg"
                )
              }
            />
          </>
        ) : (
          "No data available"
        );
      },
    },

    {
      field: "fssai_image",
      headerName: "Fssai",
      width: 250,
      renderCell: (params) => {
        return params.row.fssai_no ? (
          <>
            <Typography>{params.row.fssai_no}</Typography>
            <CloudDownloadIcon
              onClick={() =>
                FileSaver.saveAs(
                  params.row.fssai_image.startsWith("http")
                    ? `${params.row.fssai_image}`
                    : `${imgUrl}/${params.row.fssai_image}`,
                  "image.jpg"
                )
              }
            />
          </>
        ) : (
          "No data available"
        );
      },
    },
    {
      field: "attributes",
      headerName: "attributes",
      width: 250,
      renderCell: (params) => {
        let string = "";
        for (let index = 0; index < params.row.attributes.length; index++) {
          const element = params.row.attributes[index];
          if (!string) {
            string = element.attribute;
          } else {
            string = `${string}, ${element.attribute}`;
          }
        }
        return <Typography>{string}</Typography>;
      },
    },
    {
      field: "location",
      headerName: "Location",
      width: 120,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              const url = `http://maps.google.com/maps?z=12&t=m&q=loc:${params.row.location.coordinates[1]}+${params.row.location.coordinates[0]}`;
              window.open(url, "_blank");
            }}
          >
            Location
          </Button>
        );
      },
    },
    // {
    //   field: "avgCost",
    //   headerName: "Avg. Cost",
    //   width: 80,
    // },
    // {
    //   field: "visitable",
    //   headerName: "App Visible",
    //   width: 120,
    //   renderCell: (params) => {
    //     const actionSwitchToggle = async (id, visitable) => {
    //       try {
    //         setLoading(true);
    //         const response = await axios.post(
    //           `/api/v1/admin/restaurants/visitable?restaurantId=${id}`,
    //           {
    //             visitable,
    //           }
    //         );

    //         snackbar(response.data.message, "success");
    //         getAllRestaurant(page, limit, "", "");
    //         setLoading(false);
    //       } catch (error) {
    //         snackbar(error.response.data.message, "error");
    //         setLoading(false);
    //       }
    //     };

    //     return (
    //       <Switch
    //         checked={params.row.visitable ? true : false}
    //         onChange={(e) =>
    //           actionSwitchToggle(params.row._id, e.target.checked)
    //         }
    //       />
    //     );
    //   },
    // },
    // {
    //   field: "veg",
    //   headerName: "Veg",
    //   width: 100,
    // },
    // {
    //   field: "nonVeg",
    //   headerName: "NonVeg",
    //   width: 100,
    // },

    // {
    //   field: "restaurantType",
    //   headerName: "Type",
    //   width: 100,
    // },
    // {
    //   field: "gstCertificate",
    //   headerName: "gstCertificate",
    //   width: 250,
    //   renderCell: (params) => {
    //     return params.row.gstCertificate ? (
    //       <>
    //         <Typography>{params.row.gstCertificate.gstNumber}</Typography>
    //         <CloudDownloadIcon
    //           onClick={() =>
    //             FileSaver.saveAs(
    //               `${imgUrl}/${params.row.gstCertificate.documentFile}`,
    //               "image.jpg"
    //             )
    //           }
    //         />
    //       </>
    //     ) : (
    //       "No data available"
    //     );
    //   },
    // },

    // {
    //   field: "fssaiCertificate",
    //   headerName: "fssaiCertificate",
    //   width: 200,
    //   renderCell: (params) => {
    //     return params.row.fssaiCertificate ? (
    //       <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
    //         <Typography>{params.row.fssaiCertificate.certificateNo}</Typography>

    //         <CloudDownloadIcon
    //           onClick={() =>
    //             FileSaver.saveAs(
    //               `${imgUrl}/${params.row.fssaiCertificate.documentFile}`,
    //               "image.jpg"
    //             )
    //           }
    //         />
    //       </Box>
    //     ) : (
    //       "No data available"
    //     );
    //   },
    // },
    // {
    //   field: "menuItemFile",
    //   headerName: "Menu Item File",
    //   width: 250,
    //   renderCell: (params) => {
    //     return params.row.menuItemsFile ? (
    //       <>
    //         <Button
    //           onClick={() =>
    //             FileSaver.saveAs(
    //               `${imgUrl}/${params.row.menuItemsFile}`,
    //               "image.jpg"
    //             )
    //           }
    //         >
    //           Download
    //         </Button>
    //       </>
    //     ) : (
    //       "No data available"
    //     );
    //   },
    // },
    // {
    //   field: "menuItems",
    //   headerName: "MenuItem",
    //   width: 120,
    //   renderCell: (params) => {
    //     return !(params.row.menuItems.length === 0) ? (
    //       <Button onClick={() => navigator(`/menu/${params.row._id}`)}>
    //         View
    //       </Button>
    //     ) : (
    //       "No data available"
    //     );
    //   },
    // },
    {
      field: "bank",
      headerName: "bank",
      width: 120,
      renderCell: (params) => {
        return params.row.bankDetail ? (
          <Button
            onClick={() => {
              setBankData(params.row.bankDetail);
              setBankModel(true);
            }}
          >
            View
          </Button>
        ) : (
          "No data available"
        );
      },
    },
    // {
    //   field: "address",
    //   headerName: "Address",
    //   width: 120,
    //   renderCell: (params) => {
    //     return params.row.address ? (
    //       <Button
    //         onClick={() => {
    //           setAddressData(params.row.address);
    //           setAddressModal(true);
    //         }}
    //       >
    //         View
    //       </Button>
    //     ) : (
    //       "No data available"
    //     );
    //   },
    // },
    // {
    //   field: "approve",
    //   headerName: "Approve",
    //   width: 120,
    //   renderCell: (params) => {
    //     return params.row.approved ? (
    //       <Typography>Approved</Typography>
    //     ) : (
    //       <Button>Approve</Button>
    //     );
    //   },
    // },
    {
      field: "Edit",
      headerName: "Edit",
      width: 120,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              editHandler(params.row);
            }}
            size="small"
          >
            Edit
          </Button>
        );
      },
    },
    // {
    //   field: "Timing",
    //   headerName: "Timing",
    //   width: 120,
    //   renderCell: (params) => {
    //     return (
    //       <Button
    //         variant="contained"
    //         onClick={() => {
    //           timingHandler(params.row);
    //         }}
    //         size="small"
    //       >
    //         Timing
    //       </Button>
    //     );
    //   },
    // },
    // {
    //   field: "location",
    //   headerName: "Location",
    //   width: 120,
    //   renderCell: (params) => {
    //     return (
    //       <Button
    //         variant="contained"
    //         size="small"
    //         onClick={() => {
    //           const url = `http://maps.google.com/maps?z=12&t=m&q=loc:${params.row.location.coordinates[1]}+${params.row.location.coordinates[0]}`;
    //           window.open(url, "_blank");
    //         }}
    //       >
    //         Location
    //       </Button>
    //     );
    //   },
    // },
    {
      field: "taxes",
      headerName: "taxes",
      width: 250,
      renderCell: (params) => {
        let string = "";
        for (let index = 0; index < params.row.taxes.length; index++) {
          const element = params.row.taxes[index];
          if (!string) {
            string = `${element.taxname} => ${element.tax}`;
          } else {
            string = `${string}, ${`${element.taxname} => ${element.tax}`}`;
          }
        }
        return <Typography>{string}</Typography>;
      },
    },
    {
      field: "approve",
      headerName: "Approve",
      width: 120,
      renderCell: (params) => {
        return params.row.approved ? (
          <Typography>Approved</Typography>
        ) : (
          <>
            <Button
              onClick={() => approveHandler(params.row._id)}
              disabled={
                !(
                  // params.row.gstCertificate &&
                  (params.row.res_timings.length === 7 &&
                  !(params.row.attributes.length === 0) &&
                  params.row.taxes.length === 4
                    ? true
                    : false)
                )
              }
            >
              Approve
            </Button>
            {/* <Button
              onClick={() => approveHandler(params.row._id)}
              disabled={
                !(
                  // params.row.gstCertificate &&
                  (
                    params.row.res_timings.length === 7 &&
                    !(params.row.attributes.length === 0) &&
                    params.row.taxes.length === 4 &&
                    (params.row.bankDetail
                      ? params.row.bankDetail.verified
                        ? true
                        : false
                      : false)
                  )
                )
              }
            >
              Approve
            </Button> */}
          </>
        );
      },
    },
    {
      field: "note",
      headerName: "note",
      width: 60,
      renderCell: (params) => {
        return params.row.note ? (
          <Typography>{params.row.note}</Typography>
        ) : (
          <Typography>-</Typography>
        );
      },
    },
    {
      field: "Delete",
      headerName: "Delete",
      width: 250,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            size="small"
            onClick={() => deleteHandler(params.row._id)}
          >
            {" "}
            Delete
          </Button>
        );
      },
    },
  ];

  const deleteHandler = async (id) => {
    try {
      setLoading(true);
      const response = await axios.delete(
        `/api/v1/admin/restaurant/delete?restaurantId=${id}`
      );

      snackbar(response.data.message, "success");
      getAllRestaurant(page, limit, "", "");
      setLoading(false);
      setBankModel(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const approveHandler = async (id) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `/api/v1/admin/restaurants/unapproved?restaurantId=${id}`
      );

      snackbar(response.data.message, "success");
      getAllRestaurant(page, limit, "", "");
      setLoading(false);
      setBankModel(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllRestaurant(page, limit, "", "");
  }, [selectCity]);

  // const inputChange = (event) => {
  //   setUpgradeData({ ...upgradeData, [event.target.name]: event.target.value });
  // };

  const getAllRestaurant = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      setFilterType({ ...filterType, restaurant: false, city: true });

      const res = await axios.get(
        `/api/v1/admin/restaurants/unapproved?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}&city=${selectCity}`
      );
      setRestaurant(res.data.restaurant);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSortModelChange = async (item) => {
    getAllRestaurant(page, limit, "", "");
    if (item.length === 0) {
      getAllRestaurant(page, limit, "", "");
    } else {
      getAllRestaurant(page, limit, item[0].sort, item[0].field);
    }
  };
  const onFilterChange = async (e) => {
    console.log(e);
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/admin/get/restaurants/search`, {
        key: e.items[0].columnField,
        value: e.items[0].value,
      });
      setRestaurant(res.data.Restaurant);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const onChangeEditHandler = (event) => {
    if (event.target.name === "nonVeg" || event.target.name === "veg") {
      setEditData({ ...editData, [event.target.name]: event.target.checked });
    } else {
      setEditData({ ...editData, [event.target.name]: event.target.value });
    }
  };
  const editSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await axios.post(`/api/v1/admin/restaurant/edit`, editData);
      snackbar(res.data.message, "success");
      getAllRestaurant(page, limit, "", "");
      setEditModel(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      snackbar(error.response.data.message, "error");
    }
  };
  const getSearchSingleRestaurant = async (e) => {
    try {
      const res = await axios.put(
        `/api/v1/admin/restaurants/unapproved?query=${e.target.value}`
      );
      console.log("list ", res.data);
      if (!(res.data.data.length === 0)) {
        setRestaurantList(res.data.data);
      } else {
        setRestaurantList([]);
        console.log("none");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box>
      <FilterByCity setCity={setCity} />

      <Autocomplete
        sx={{ mb: 2 }}
        size="small"
        disableClearable
        fullWidth
        id="tags-outlined"
        options={restaurantList}
        getOptionLabel={(userData) =>
          `${userData.brand_display_name}  Number : ${userData.business_contact}`
        }
        onInputChange={getSearchSingleRestaurant}
        onChange={(event, value) => {
          let row = [];
          setRestaurantList(value);
          row.push(value);

          setSingleRestaurant(row);
          setIsSelected(true);
        }}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Restaurant"
            placeholder="Select Restaurant"
          />
        )}
      />

      {isSelected ? (
        <>
          {console.log("inGrid", singleRestaurant)}
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            autoHeight
            rows={singleRestaurant}
            getRowId={(row) => row._id}
            // loading={loading}
            // sortingMode="server"
            pageSize={5}
            rowsPerPageOptions={[5]}
            columns={columns}
          />
        </>
      ) : (
        <>
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            // autoHeight
            rows={restaurant}
            getRowId={(row) => row._id}
            rowCount={total}
            loading={loading}
            rowsPerPageOptions={[10, 30, 50, 70, 100]}
            pagination
            page={page - 1}
            pageSize={limit}
            paginationMode="server"
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            filterMode="server"
            onFilterModelChange={onFilterChange}
            onPageChange={(newPage) => {
              getAllRestaurant(newPage + 1, limit, "", "");
              setPage(newPage + 1);
            }}
            onPageSizeChange={(newPageSize) => {
              getAllRestaurant(page, newPageSize, "", "");
              setLimit(newPageSize);
              //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
            }}
            columns={columns}
            sx={{
              "& .bold-header": {
                fontWeight: "bold",
                color: "blue",
              },
              height: "600px",
              overflow: "auto",
            }}
          />
        </>
      )}

      <Modal
        open={bankModel}
        onClose={() => setBankModel(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, p: 0 }}>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item xs={12}>
              <Paper sx={{ borderRadius: "10px" }} elevation={3}>
                <Box sx={paperBoxDashboardStyle}>
                  <Typography sx={timeDurationDashboardStyle}>
                    Bank Details
                  </Typography>
                </Box>

                <Box sx={{ padding: "10px" }}>
                  <Box sx={valueBoxStyle}>
                    <Typography sx={dashboardText}>Bank Name :</Typography>{" "}
                    <Typography sx={dashboardText}>
                      {bankData.bankName}
                    </Typography>
                  </Box>

                  <Box sx={valueBoxStyle}>
                    <Typography sx={dashboardText}>
                      {" "}
                      Account Holder Name:
                    </Typography>{" "}
                    <Typography sx={dashboardText}>
                      {bankData.accountHolderName}
                    </Typography>
                  </Box>

                  <Box sx={valueBoxStyle}>
                    <Typography sx={dashboardText}>Branch Name:</Typography>{" "}
                    <Typography sx={dashboardText}>
                      {bankData.branch}
                    </Typography>
                  </Box>

                  <Box sx={valueBoxStyle}>
                    <Typography sx={dashboardText}>Account Number :</Typography>{" "}
                    <Typography sx={dashboardText}>
                      {bankData.accountNumber}
                    </Typography>
                  </Box>

                  <Box sx={valueBoxStyle}>
                    <Typography sx={dashboardText}>IFSC Code:</Typography>{" "}
                    <Typography sx={dashboardText}>
                      {bankData.ifscCode}
                    </Typography>
                  </Box>

                  <Box sx={valueBoxStyle}>
                    <Typography sx={dashboardText}>Verified:</Typography>{" "}
                    <Typography sx={dashboardText}>
                      {bankData.verified ? "True" : "False"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      ...valueBoxStyle,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {!bankData.verified ? (
                      <Button variant="contained" onClick={verifiedBankHandler}>
                        Verify Bank
                      </Button>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/*  */}
      <Modal
        open={addressModal}
        onClose={() => setAddressModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography>
            Complete Address : {addressData.completeAddress}
          </Typography>
          <Typography>City : {addressData.city}</Typography>
          <Typography>State : {addressData.state}</Typography>
          <Typography>Landmark : {addressData.landmark}</Typography>
        </Box>
      </Modal>

      <Modal
        open={timingModal}
        onClose={() => setTimingModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: "80%" }}>
          <OutletTiming
            time={time}
            setTime={setTime}
            setIsLoading={setIsLoading}
            snackbar={snackbar}
            getAllRestaurant={getAllRestaurant}
            setTimingModal={setTimingModal}
            page={page}
            limit={limit}
          />
        </Box>
      </Modal>

      <Modal
        open={editModel}
        onClose={() => {
          setEditModel(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, overflow: "scroll", height: "95%" }}>
          <Stack
            spacing={2}
            component="form"
            onSubmit={editSubmitHandler}
            // sx={{ overflow: "scroll", height: "95%" }}
          >
            <Box sx={{ ...paperBoxDashboardStyle, borderRadius: "10px" }}>
              <Typography sx={timeDurationDashboardStyle}>
                Edit Restaurant
              </Typography>
            </Box>
            <TextField
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="brand display name"
              name="brand_display_name"
              value={editData.brand_display_name}
              onChange={onChangeEditHandler}
              variant="outlined"
              size="small"
            />
            <TextField
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="outlet name"
              name="outlet_name"
              onChange={onChangeEditHandler}
              value={editData.outlet_name}
              variant="outlined"
              size="small"
            />
            <TextField
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="address"
              name="address"
              onChange={onChangeEditHandler}
              value={editData.address}
              variant="outlined"
              size="small"
            />
            <TextField
              id="outlined-basic"
              label="landmark"
              onChange={onChangeEditHandler}
              name="landmark"
              sx={{ width: "100%" }}
              value={editData.landmark}
              variant="outlined"
              size="small"
            />
            <TextField
              id="outlined-basic"
              label="area"
              onChange={onChangeEditHandler}
              name="area"
              sx={{ width: "100%" }}
              value={editData.area}
              variant="outlined"
              size="small"
            />
            <TextField
              id="outlined-basic"
              label="state"
              onChange={onChangeEditHandler}
              name="state"
              sx={{ width: "100%" }}
              value={editData.state}
              variant="outlined"
              size="small"
            />
            <TextField
              id="outlined-basic"
              label="pincode"
              onChange={onChangeEditHandler}
              name="pincode"
              sx={{ width: "100%" }}
              value={editData.pincode}
              variant="outlined"
              size="small"
            />
            <TextField
              id="outlined-basic"
              label="city"
              onChange={onChangeEditHandler}
              name="city"
              sx={{ width: "100%" }}
              value={editData.city}
              variant="outlined"
              size="small"
            />
            <TextField
              id="outlined-basic"
              label="business_contact"
              onChange={onChangeEditHandler}
              name="business_contact"
              sx={{ width: "100%" }}
              value={editData.business_contact}
              variant="outlined"
              size="small"
            />
            <TextField
              id="outlined-basic"
              label="merchant_number"
              onChange={onChangeEditHandler}
              name="merchant_number"
              sx={{ width: "100%" }}
              value={editData.merchant_number}
              variant="outlined"
              size="small"
            />
            <TextField
              id="outlined-basic"
              label="email"
              onChange={onChangeEditHandler}
              name="email"
              sx={{ width: "100%" }}
              value={editData.email}
              variant="outlined"
              size="small"
            />
            <TextField
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="Latitude"
              type="number"
              onChange={onChangeEditHandler}
              name="latitude"
              value={editData.latitude}
              variant="outlined"
              size="small"
            />
            <TextField
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="Longitude"
              onChange={onChangeEditHandler}
              name="longitude"
              type="number"
              value={editData.longitude}
              variant="outlined"
              size="small"
            />
            <TextField
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="note"
              onChange={onChangeEditHandler}
              name="note"
              multiline
              value={editData.note}
              variant="outlined"
              size="small"
            />

            <Button type="submit" variant="contained">
              Update
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
}

export default UnVerifiedRestaurant;
