import React, { useState, useContext } from "react";
import DeliveryMonitor from "./DeliveryMonitor";
import BhiwandiDeliveryPartnerLocation from "./bhiwandiDelivery/BhiwandiDeliveryPartnerLocation";
import FilterByCity from "../../reusableComponent/filterByCity/FilterByCity";
import { AdminDataContext } from "../../useContext/AdminContext";

const DeliveryBoyLocationTrack = () => {
  const { selectCity, setSelectCity } = useContext(AdminDataContext);
  //   const [selectCity, setSelectCity] = useState({
  //     thane: true,
  //     bhiwandi: false,
  //   });
  console.log("setSelectCity", selectCity);
  return (
    <>
      <FilterByCity />
      {selectCity === "Thane" ? (
        <DeliveryMonitor />
      ) : selectCity === "Bhiwandi" ? (
        <BhiwandiDeliveryPartnerLocation />
      ) : (
        <DeliveryMonitor />
      )}
    </>
  );
};

export default DeliveryBoyLocationTrack;
