import React from "react";
import SelectRestaurantMenuUpload from "../restaurant/petPoojaMenuItems/selectRestaurant/SelectRestaurantMenuUpload";

const ItemsSelect = () => {
  return (
    <div>
      <SelectRestaurantMenuUpload type={"/i/items"} />
    </div>
  );
};

export default ItemsSelect;
