import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import AdminContext from "./useContext/AdminContext";
import palette from "./theme/palette";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AdminContext>
      <ThemeProvider theme={palette}>
        <App />
      </ThemeProvider>
    </AdminContext>
  </BrowserRouter>
  // {/* </React.StrictMode> */}
);
