import { Box, Button, Container, Divider, Grid } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import moment from "moment";
import { capitalize } from "../../reusableComponent/capitalFirstCharacter";

function ActiveCustomer() {
  const [customer, setCustomer] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [changeDetailFormat, setChangeDetailFormat] = useState(true);

  const [expanded, setExpanded] = useState([]);

  const handleExpandClick = (i) => {
    let isTrue = [];
    setExpanded([]);
    for (let index = 0; index < total; index++) {
      if (i === expanded[index]) {
        isTrue[index].push(true);
      } else {
        isTrue[index].push(false);
      }
    }

    expanded.push(isTrue);
    setExpanded(isTrue);
    console.log(expanded);
  };

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 250,
      renderHeader: () => <strong>{"Name"}</strong>,
      renderCell: (params) =>
        params.row.name ? capitalize(params.row.name) : "",
    },

    {
      field: "email",
      headerName: "Email",
      width: 250,
      renderHeader: () => <strong>{"Email"}</strong>,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
      renderHeader: () => <strong>{"Phone"}</strong>,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 100,
      renderHeader: () => <strong>{"Gender"}</strong>,
    },
    {
      field: "orderCount",
      headerName: "orderCount",
      width: 100,
      renderHeader: () => <strong>{"Order Count"}</strong>,
    },
    {
      field: "addressType",
      headerName: "Address Type",
      width: 150,
      renderHeader: () => <strong>{"Address Type"}</strong>,

      renderCell: (params) => {
        return params.row.addresses[0]
          ? params.row.addresses[0].addressType
          : "";
      },
    },
    {
      field: "landmark",
      headerName: "Landmark",
      width: 250,
      renderHeader: () => <strong>{"Landmark"}</strong>,

      renderCell: (params) => {
        return params.row.addresses[0] ? params.row.addresses[0].landmark : "";
      },
    },
    {
      field: "completeAddress",
      headerName: "Complete Address",
      width: 450,
      renderHeader: () => <strong>{"Complete Address"}</strong>,

      renderCell: (params) => {
        return params.row.addresses[0]
          ? `${params.row?.addresses[0].completeAddress} ,
        ${params.row.addresses[0].city},
        ${params.row.addresses[0].state},
        ${params.row.addresses[0].pinCode}`
          : "";
      },
    },

    {
      field: "updatedAt",
      headerName: "Last Use Date",
      width: 250,
      renderHeader: () => <strong>{"Last Use Date"}</strong>,
      // valueFormatter: (params) =>
      // moment(params.row.updatedAt).format("DD/MM/YYYY hh:mm A"),
      renderCell: (params) => {
        return params.row.addresses[0]
          ? moment(params.row.updatedAt).format("DD/MM/YYYY hh:mm A")
          : "";
      },
    },
  ];

  useEffect(() => {
    getAllCustomer();
  }, []);

  const getAllCustomer = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/admin/activeCustomer`);
      setCustomer(res.data.data);
      setTotal(res.data.total);
      let exp = [];
      for (let index = 0; index < res.data.total; index++) {
        exp[index].push(false);
        console.log(expanded);
      }
      expanded.push(exp);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  console.log(expanded);
  return (
    <>
      <Box>
        <Button
          variant="contained"
          sx={{ mb: 2 }}
          onClick={() => setChangeDetailFormat(!changeDetailFormat)}
        >
          Change
        </Button>
        {changeDetailFormat ? (
          <>
            {" "}
            <DataGrid
              components={{ Toolbar: GridToolbar }}
              // autoHeight
              rows={customer}
              getRowId={(row) => row._id}
              rowCount={total}
              loading={loading}
              rowsPerPageOptions={[10, 30, 50, 70, 100]}
              pagination
              page={page - 1}
              pageSize={limit}
              paginationMode="server"
              sortingMode="server"
              // onSortModelChange={handleSortModelChange}
              filterMode="server"
              // onFilterModelChange={onFilterChange}
              onPageChange={(newPage) => {
                getAllCustomer(newPage + 1, limit, "", "");
                setPage(newPage + 1);
              }}
              onPageSizeChange={(newPageSize) => {
                getAllCustomer(page, newPageSize, "", "");
                setLimit(newPageSize);
                //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
              }}
              columns={columns}
              sx={{
                "& .bold-header": {
                  fontWeight: "bold",
                  color: "blue",
                },
                height: "600px",
                overflow: "auto",
              }}
            />
          </>
        ) : (
          <>
            {" "}
            <Container fixed>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "space-between" },
                }}
              >
                {/* expanded={expanded === `panel${index + 1}`} */}
                {/* onChange={handleAccordionChange(`panel${index + 1}`)} */}

                {customer.map((customer, index) => (
                  <Grid item xs={11} sm={6} md={3.8} sx={gridItem}>
                    <Card sx={{ backgroundColor: "#B7E9FF" }}>
                      <CardHeader
                        avatar={
                          <Avatar
                            sx={{ bgcolor: red[400] }}
                            aria-label="recipe"
                          >
                            <AccountCircleIcon />
                          </Avatar>
                        }
                        action={
                          <IconButton
                            aria-label="settings"
                            onClick={() => handleExpandClick(index)}
                          >
                            <ExpandMore
                              expand={expanded[index]}
                              // expand={expanded === `panel${index + 1}`}

                              // onClick={() =>
                              //   // handleAccordionChange(`panel${index + 1}`)
                              // }
                              aria-expanded={expanded}
                              aria-label="show more"
                            >
                              <ExpandMoreIcon />
                            </ExpandMore>
                          </IconButton>
                        }
                        title={customer?.name}
                        subheader={customer?.phone}
                      />

                      <Collapse
                        in={expanded[index]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <CardContent>
                          <Typography paragraph>
                            {" "}
                            <span style={detailsStyle}> Email:</span>{" "}
                            {customer.email}
                          </Typography>
                          <Typography paragraph>
                            {" "}
                            <span style={detailsStyle}> Gender:</span>{" "}
                            {customer.gender}
                          </Typography>
                          <Typography paragraph>
                            {" "}
                            <span style={detailsStyle}>
                              {" "}
                              Last used date:
                            </span>{" "}
                            {customer.updatedAt.slice(0, 10)}
                          </Typography>

                          <Typography paragraph>
                            {" "}
                            <span style={detailsStyle}>
                              {" "}
                              Address Type:
                            </span>{" "}
                            {customer?.addresses[0].addressType}
                          </Typography>
                          <Typography paragraph>
                            {" "}
                            <span style={detailsStyle}>
                              {" "}
                              Complete Address:
                            </span>{" "}
                            {customer?.addresses[0].completeAddress} ,{" "}
                            {customer?.addresses[0].city},{" "}
                            {customer?.addresses[0].state},{" "}
                            {customer?.addresses[0].pinCode}
                          </Typography>
                          <Typography paragraph>
                            {" "}
                            <span style={detailsStyle}> Landmark:</span>{" "}
                            {customer?.addresses[0].landmark}
                          </Typography>
                        </CardContent>
                      </Collapse>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </>
        )}
      </Box>
    </>
  );
}

export default ActiveCustomer;

const gridItem = {
  m: 1,
  // borderRadius: 5,
  // minHeight: "300px",
  // backgroundColor: "#B7E9FF",
};
const nameStyleBox = {
  textAlign: "center",
  // border: "#670000 3px solid",
};
const detailsStyle = {
  fontWeight: "bold",
};
