import { Box, Button, Container, Paper, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import RestaurantLogo from "./restaurantLogo/RestaurantLogo";
import AadharCard from "./aadharCard/AadharCard";
import Fssai from "./fssai/Fssai";
import Gst from "./gst/Gst";
import Timing from "./timing/Timing";
import BankDetails from "./bankDetails/BankDetails";
import { useState } from "react";
import PendingRestaurantScreen from "./pendingApprovalScreen/PendingRestaurantScreen";

import Attributes from "./attributes/Attributes";
import { useParams, useNavigate } from "react-router-dom";
import { AdminDataContext } from "../../../../useContext/AdminContext";
import axios from "axios";
import Pan from "./pan/Pan";
import MenuCard from "./menuCard/MenuCard";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CreateRestaurant = (props) => {
  const { setIsLoading } = useContext(AdminDataContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = React.useState(0);

  const [restaurantDetails, setRestaurantDetails] = useState(null);

  const [resLogo, setResLogo] = useState("");
  const [aadharCard, setAadharCard] = useState({});
  const [fssai, setFssai] = useState({});

  const [panCard, setPanCard] = useState({});

  const [gst, setGst] = useState({});
  const [menuCard, setMenuCard] = useState({});
  const [time, setTime] = useState([]);
  const [bankDetail, setBankDetail] = useState({});
  const [attributes, setAttributes] = useState([]);

  const [pendingScreen, setPendingScreen] = useState(false);
  const handlePendingScreen = () => {
    setPendingScreen(true);
  };
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getRestaurantDetails = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/admin/restaurant?restaurantId=${id}`
      );
      setRestaurantDetails(res.data.restaurant);
      setResLogo(res.data.restaurant.restaurant_logo);
      console.log(res.data);
      setAadharCard(
        res.data.restaurant.hasOwnProperty("aadhar_no")
          ? {
              aadhar_image: res.data.restaurant.aadhar_image,
              aadhar_no: res.data.restaurant.aadhar_no,
            }
          : false
      );
      setFssai(
        res.data.restaurant.hasOwnProperty("fssai_image")
          ? {
              fssai_image: res.data.restaurant.fssai_image,
              fssai_no: res.data.restaurant.fssai_no,
            }
          : false
      );

      setPanCard(
        res.data.restaurant.hasOwnProperty("pan_image")
          ? {
              pan_image: res.data.restaurant.pan_image,
              pan_no: res.data.restaurant.pan_no,
            }
          : false
      );

      setGst(
        res.data.restaurant.hasOwnProperty("gst_image")
          ? {
              gst_image: res.data.restaurant.gst_image,
              gst_no: res.data.restaurant.gst_no,
            }
          : false
      );
      setMenuCard(
        res.data.restaurant.hasOwnProperty("menuItemsFile")
          ? {
              menuItemsFile: res.data.restaurant.menuItemsFile,
              asm: res.data.restaurant.asm,
            }
          : false
      );
      setTime(
        res.data.restaurant.hasOwnProperty("res_timings")
          ? res.data.restaurant.res_timings
          : false
      );
      setBankDetail(
        res.data.restaurant.hasOwnProperty("bankDetail")
          ? res.data.restaurant.bankDetail
          : false
      );
      setAttributes(
        res.data.restaurant.hasOwnProperty("attributes")
          ? res.data.restaurant.attributes
          : false
      );
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getRestaurantDetails();
  }, []);

  const activeCreateBtn = {
    width: "auto",
    backgroundColor: `white.main`,
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
    color: "yellow.secondary",
    paddingY: 0.5,
    paddingX: 2,
    textTransform: "capitalize",
    fontSize: "18px",
    fontWeight: 700,
    // minWidth: { xs: "100px", sm: "140px" },
    textDecoration: "none",
    borderRadius: "6px",
    position: "absolute",
    right: 20,
    "&:hover": {
      backgroundColor: `white.main`,
    },
  };
  console.log("restaurantDetails....", restaurantDetails);
  return (
    <>
      <Container
        fixed
        sx={{
          alignContent: "center",
          minHeight: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{
            width: "100%",
            bgcolor: "bgColor.main",
            borderRadius: "20px",
            minHeight: "80vh",
            position: "relative",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            my: 2,
          }}
          elevation={10}
        >
          {pendingScreen ? (
            <Box
              sx={{
                width: "100%",
              }}
            >
              <PendingRestaurantScreen />
            </Box>
          ) : (
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  width: "100%",
                  height: "60px",
                  background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                  boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                  color: "white.main",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src="/images/createResLogoImg.svg"
                  alt="Preview"
                  width="200"
                  height="60"
                  style={{
                    filter: "drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.45))",
                  }}
                />

                <Typography
                  sx={{
                    fontSize: "18px",
                    color: "white.main",
                    fontWeight: 600,
                    display: { xs: "none", sm: "block" },
                  }}
                >
                  Create Restaurant{" - "}
                  <span style={{ color: "#0C1844" }}>
                    {restaurantDetails?.brand_display_name}
                  </span>
                </Typography>

                <Button
                  sx={activeCreateBtn}
                  variant="contained"
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </Box>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "black.secondary",
                  fontWeight: 600,
                  textAlign: "center",
                  mt: 1,
                  display: { sm: "none" },
                }}
              >
                Create Restaurant{" -"}
                <span style={{ color: "#0C1844" }}>
                  {restaurantDetails?.brand_display_name}
                </span>
              </Typography>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  overflow: "auto",
                  mt: 2,
                }}
              >
                <Tabs
                  value={tabValue}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="basic tabs example"
                >
                  <Tab label="Image" {...a11yProps(0)} />
                  <Tab label="Aadhar Card" {...a11yProps(1)} />

                  <Tab label="Pan Card" {...a11yProps(2)} />
                  <Tab label="FSSAI LICENSE" {...a11yProps(3)} />
                  <Tab label="GST Certificate" {...a11yProps(4)} />
                  <Tab label="Timing" {...a11yProps(5)} />
                  <Tab label="Bank Details" {...a11yProps(6)} />
                  {/* <Tab label="Tax" {...a11yProps(7)} /> */}

                  <Tab label="Menu Card" {...a11yProps(7)} />
                  <Tab label="Attributes" {...a11yProps(8)} />
                </Tabs>
              </Box>
              <TabPanel
                value={tabValue}
                index={0}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  border: "1px solid red",
                }}
              >
                <RestaurantLogo id={id} restaurantLogo={resLogo} />
              </TabPanel>

              <TabPanel
                value={tabValue}
                index={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  border: "1px solid red",
                }}
              >
                <AadharCard
                  id={id}
                  aadharCard={aadharCard}
                  getRestaurantDetails={getRestaurantDetails}
                />
              </TabPanel>

              <TabPanel
                value={tabValue}
                index={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  border: "1px solid red",
                }}
              >
                <Pan
                  id={id}
                  panCard={panCard}
                  getRestaurantDetails={getRestaurantDetails}
                />
              </TabPanel>

              <TabPanel
                value={tabValue}
                index={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  border: "1px solid red",
                }}
              >
                <Fssai
                  id={id}
                  fssai={fssai}
                  getRestaurantDetails={getRestaurantDetails}
                />
              </TabPanel>

              <TabPanel
                value={tabValue}
                index={4}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  border: "1px solid red",
                }}
              >
                <Gst
                  id={id}
                  gst={gst}
                  getRestaurantDetails={getRestaurantDetails}
                />
              </TabPanel>

              <TabPanel
                value={tabValue}
                index={5}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  border: "1px solid red",
                }}
              >
                <Timing
                  id={id}
                  time={time}
                  getRestaurantDetails={getRestaurantDetails}
                />
              </TabPanel>

              <TabPanel
                value={tabValue}
                index={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  border: "1px solid red",
                }}
              >
                <BankDetails
                  id={id}
                  bankDetail={bankDetail}
                  getRestaurantDetails={getRestaurantDetails}
                />
              </TabPanel>

              <TabPanel
                value={tabValue}
                index={7}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  border: "1px solid red",
                }}
              >
                <MenuCard
                  id={id}
                  menuCard={menuCard}
                  getRestaurantDetails={getRestaurantDetails}
                />
              </TabPanel>
              <TabPanel
                value={tabValue}
                index={8}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  border: "1px solid red",
                }}
              >
                <Attributes
                  id={id}
                  attributes={attributes}
                  getRestaurantDetails={getRestaurantDetails}
                  handlePendingScreen={handlePendingScreen}
                  restaurantDetails={restaurantDetails}
                />
              </TabPanel>
            </Box>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default CreateRestaurant;
