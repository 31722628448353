import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import React, { useContext } from "react";
import { AdminDataContext } from "../../../../../useContext/AdminContext";
import { useNavigate } from "react-router-dom";

const PendingRestaurantScreen = () => {
  const { snackbar, setIsLoading, setIsAuthenticated } =
    useContext(AdminDataContext);
  const navigate = useNavigate();

  // const logout = async () => {
  //   setIsLoading(true);

  //   try {
  //     await axios.get("/api/v1.0.1/restaurant/logout");
  //     setIsLoading(false);
  //     setIsAuthenticated(false);
  //     localStorage.removeItem("restaurantId");

  //     return navigate("/login");
  //   } catch (error) {
  //     setIsLoading(false);
  //   }
  // };
  const activeCreateBtn = {
    width: "auto",
    backgroundColor: `white.main`,
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
    color: "yellow.secondary",
    paddingY: 0.5,
    paddingX: 2,
    textTransform: "capitalize",
    fontSize: "18px",
    fontWeight: 700,
    // minWidth: { xs: "100px", sm: "140px" },
    textDecoration: "none",
    borderRadius: "6px",
    position: "absolute",
    right: 20,
    "&:hover": {
      backgroundColor: `white.main`,
    },
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "60px",
          background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
          boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
          color: "white.main",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src="/images/createResLogoImg.svg"
          alt="Preview"
          width="200"
          height="60"
          style={{
            filter: "drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.45))",
          }}
        />

        {/* <Button
          // sx={{ position: "absolute", right: 20 }}
          sx={activeCreateBtn}
          variant="contained"
          onClick={logout}
        >
          Logout
        </Button> */}
        <Button
          // sx={{ position: "absolute", right: 20 }}
          sx={activeCreateBtn}
          variant="contained"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </Box>

      <Box mt={5} textAlign="center">
        <img
          src="/images/pendingImg.svg"
          alt="Preview"
          width="150"
          height="150"
          style={{
            filter: "drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.45))",
          }}
        />
      </Box>
      <Box>
        <Typography
          mt={3}
          sx={{
            fontSize: "30px",
            color: "maroon.secondary",
            fontWeight: 600,
            lineHeight: "119.02%",
            textAlign: "center",
            letterSpacing: "0.12em",
          }}
        >
          Verification <br /> Pending
        </Typography>
        <Typography
          mt={3}
          sx={{
            fontSize: "20px",
            color: "rosyBrown.secondary",
            fontWeight: 500,
            lineHeight: "24px",
            textAlign: "center",
          }}
        >
          You Didn’t Procced before <br /> Verification
        </Typography>
      </Box>

      <Box sx={{ marginTop: "70px" }}>
        <Typography
          mt={3}
          sx={{
            fontSize: "18px",
            color: "grey.primary",
            fontWeight: 500,
            lineHeight: "22px",
            textAlign: "center",
          }}
        >
          A confirmation message will be sent to you once your documents <br />{" "}
          have been verified
        </Typography>
      </Box>
    </>
  );
};

export default PendingRestaurantScreen;
