export const cuisinesItemList = [
  "Asian",
  "American",
  "Barbecue",
  "Beverages",
  "Chinese",
  "Bangladeshi",
  "Bengali",
  "Belgian",
  "Andhra",
  "Fast food",
  "Indian",
  "Maharashtrian",
  "North Indian",
  "Punjabi",
  "Pizza",
  "Afghan",
  "African",
  "Arabian",
  "Argentine",
  "Armenian",
  "Asian Fusion",
  "Assamese",
  "Austrian",
  "Awadhi",
  "Bihari",
  "Biryani",
  "Brazilian",
  "British",
  "Burmese",
  "Cajun & Creole",
  "Cambodian",
  "Cantonese",
  "Caribbean",
  "Chettinad",
  "Chilean",
  "Colombian",
  "Combo",
  "Continental",
  "Coorgi",
  "Creperies",
  "Cuban",
  "Cuisine not available",
  "Czech",
  "Dessert",
  "Dim Sum",
  "Dominican",
  "Ecuadorian",
  "Egyptian",
  "Ethiopian",
  "European",
  "Falafel",
  "Filipino",
  "French",
  "Georgian",
  "German",
  "Goan",
  "Greek",
  "Gujarati",
  "Haitian",
  "Himalayan & Nepalese",
  "Honduran",
  "Hungarian",
  "Indonesian",
  "Indori",
  "Irish",
  "Italian",
  "Japanese",
  "Karaikudi",
  "Kashmiri",
  "Kenyan",
  "Kerala",
  "Konkan",
  "Korean",
  "Laotian",
  "Latin American",
  "Lebanese",
  "Lucknowi",
  "Malabar",
  "Malaysian",
  "Malwani",
  "Mangalorean",
  "Meals",
  "Mediterranean",
  "Mexican",
  "Middle Eastern",
  "Modern European",
  "Mongolian",
  "Moroccan",
  "Mughlai",
  "Nicaraguan",
  "Nigerian",
  "Northeast",
  "Oriya",
  "Persian & Iranian",
  "Peruvian",
  "Polish",
  "Portuguese",
  "Puerto Rican",
  "Rajasthani",
  "Ramen",
  "Russian",
  "Salvadoran",
  "Scandinavian",
  "Scottish",
  "Senegalese",
  "Sindhi",
  "Singaporean",
  "Slovakian",
  "South African",
  "South Indian",
  "Southern",
  "Southwestern & Tex-Mex",
  "Spanish",
  "Sri Lankan",
  "Sushi",
  "Swiss",
  "Szechuan",
  "Taiwanese",
  "Tandoor",
  "Tapas",
  "Thai",
  "Turkish",
  "Ugandan",
  "Ukrainian",
  "Venezuelan",
  "Vietnamese",
  "West African",
  "Ice Cream",
  "Indian sweets",
  "Namkeen",
  "Bakery",
  "Paan",
  "Starters",
  "Snacks",
  "Dinner",
  "Lunch",
  // -------
  "Pulao",
  "Burger",
  "Cafe",
  "Coffee",
  "Grill",
  "Healthy food",
  "Hydrabadi",
  "Jain",
  "Juices",
  "Kebab",
  "Momos",
  "Oriental",
  "Sizzler",
  "Parsi",
  "Pastas",
  "Rolls",
  "Rolls and wraps",
  "Salad",
  "Seafood",
  "Street food",
  "Sushi",
  "Sweet",
  "Thali",
  "Waffle",
  "Shawrama",
  "Kathiyawadi",
  "Portugues",
  "Vegan",
  "Fries",
  "Bao",
  "Misal",
  "Fish",
  "Chicken",
  "Mutton",
  "Curry",
  "Colddrink",
  "Upvas",
  "Fasting food",
  "Soup",
  "Noodles",
  "Lollipop",
  "Chilly",
  "Sandwich",
  "Shakes",
  "Egg",
  "Pure veg",
  "Pavbhaji",
  "Mushroom",
  "Paneer",
  "Falooda",
  "Roti",
  "Bhakri",
  "Chapati",
  "Kulcha",
  "Cake",
  "Chaap",
  "Dal",
  "Bhaji",
  "Breakfast",
  "Doughnut",
  "Pancake",
  "Pakoda",
  "Bhajii",
  "Kachori",
  "Puri",
  "Shira",
  "Pohe",
  "Upama",
  "Chrispy",
  "Manchurian",
  "Chopsuey",
  "Platter",
  "Dumplings",
  "Uttapam",
  "Omelette",
  "Khichdi",
  "Vada",
  "Dhokla",
  "Frankie",
  "Dabeli",
  "Crab",
  "Kolambi",
  "Chole bhature",
  "Chaat",
];
