import { Modal } from "@mui/base";
import { Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState, useRef } from "react";
import io from "socket.io-client";
import CloseIcon from "@mui/icons-material/Close";
import { textHeadingInputFields } from "./menuItem/itemCreate/CssStyle";

const SocketNotification = () => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");

  const [orderData, setOrder] = useState(null);
  const [visible, setVisible] = useState(false);

  const audioRef = useRef(null);

  const handlePlay = async () => {
    await audioRef.current.play();
  };

  const handlePause = () => {
    audioRef.current.pause();
  };
  const handleClose = () => {
    setVisible(false);
    setOrder(null);
  };

  useEffect(() => {
    const socket = io("/", {
      auth: {
        token: "",
      },
    });
    // Handle incoming events from the server
    socket.on("order", async (data) => {
      console.log("Received data from server:", data);

      if (data.alert) {
        setOrder(data);
        setVisible(true);
        handlePlay();
      } else {
        handleClose();
        handlePause();
      }
    });

    // Emit events to the server
    // socket.emit("event-from-client", "Hello from client");

    // Clean up the socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, []);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "30%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Box>
      <audio ref={audioRef}>
        {/* <source src="/R2ZWYCP-notification.mp3" type="audio/mpeg" /> */}
        <source src="/ring.mpeg" type="audio/mpeg" />
      </audio>
      <Modal
        open={visible}
        hideBackdrop={false}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{ position: "absolute", right: 15, top: 15, cursor: "pointer" }}
          >
            <CloseIcon onClick={handleClose} />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "27px",
              color: "rgba(13, 30, 55, 0.67)",
              justifySelf: "start",
            }}
          >
            {orderData?.alertType === "Restaurant" ? (
              <Typography>
                Restaurant has not accepted the order{" "}
                <span style={{ color: "red", fontWeight: 600 }}>
                  {orderData?.orderId}{" "}
                </span>
              </Typography>
            ) : (
              <Typography>
                No Delivery Boy fount for{" "}
                <span style={{ color: "red", fontWeight: 600 }}>
                  {orderData?.orderId}
                </span>
              </Typography>
            )}
          </Typography>

          <Box id="modal-modal-description" sx={{ mt: 2 }}>
            {orderData?.alertType === "Restaurant" ? (
              <>
                <Typography sx={textHeadingInputFields}>
                  Restaurant Name :
                  <span
                    style={{
                      color: "red",
                      fontWeight: 600,
                      marginLeft: "10px",
                    }}
                  >
                    {orderData?.restaurant.outlet_name}
                  </span>
                </Typography>
                <Typography sx={textHeadingInputFields}>
                  Owner Name :
                  <span
                    style={{
                      color: "red",
                      fontWeight: 600,
                      marginLeft: "10px",
                    }}
                  >
                    {orderData?.restaurant.owner_name}
                  </span>
                </Typography>
                <Typography sx={textHeadingInputFields}>
                  Contact No. :
                  <span
                    style={{
                      color: "red",
                      fontWeight: 600,
                      marginLeft: "10px",
                    }}
                  >
                    {orderData?.restaurant.merchant_number}
                  </span>
                </Typography>
              </>
            ) : (
              <Typography sx={textHeadingInputFields}>
                No Delivery Boy found for
                <span
                  style={{
                    color: "red",
                    fontWeight: 600,
                    marginLeft: "10px",
                  }}
                >
                  {orderData?.orderId}
                </span>
              </Typography>
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default SocketNotification;
