import {
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  FormLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import React, { useContext } from "react";
import { cuisinesItemList } from "./../../../components/restaurant/petPoojaOnboarding/createRestaurantModal/cuisinesItemList";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useState } from "react";

import AddVariations from "./AddVariations";
import AddAddOns from "./AddAddOns";
import Multiselect from "multiselect-react-dropdown";
import DragsAndDrop from "../../../reusableComponent/imageUpload/DragsAndDrop";
import axios from "axios";
import { AdminDataContext } from "../../../useContext/AdminContext";
import {
  submitBtn,
  textFieldStyle,
  selectFieldStyle,
  textFieldBox,
  textHeading,
} from "./CssStyle";

const AddItem = ({
  addOns,
  taxes,
  attributes,
  setPage,
  categories,
  restaurantId,
  getData,
  addOn,
  variations,
  restaurantName,
}) => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [variationOrAddOns, setVariationOrAddOns] = useState(0);
  const [image, setImage] = useState("");
  const [data, setData] = useState({
    item_categoryid: "",
    ybitesPackingCharges: 20,
    itemallowaddon: "0", // true  if add is there
    itemaddonbasedon: "0", //Flag addon based on item or variations [values will be boolean]. 0 = Addon group applied on item, 1 = Addon group applied variation wise
    item_favorite: "0",
    variation_groupname: "",
    variation: [],
    cuisine: [],
    itemallowvariation: "0", //true Flag for item allowing variations or not [1 - Item with varaition , 0 - Item without variation]
    minimumpreparationtime: "20",
    item_attributeid: "",
    itemname: "",
    itemdescription: "",
    price: null,
    percentageTax: 5,
    addon: [],
  });
  const summitHandler = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      const res = await axios.post(
        `/api/v1/admin/item?restaurantId=${restaurantId}`,
        {
          ...data,

          price: data.variation.length === 0 ? data.price : "0",
          itemallowaddon: data.addon.length === 0 ? "0" : "1",
          itemallowvariation: data.variation.length === 0 ? "0" : "1",
        }
      );
      console.log("data is ", data);
      if (image) {
        let formData = new FormData();
        formData.append("image", image);
        const res2 = await axios.put(
          `/api/v1/admin/item?itemId=${res.data.data._id}`,
          formData
        );
      }
      snackbar(res.data.message, res.data.status);
      setIsLoading(false);
      setImage("");
      setData({
        item_categoryid: "",
        ybitesPackingCharges: 20,
        itemallowaddon: "0", // true  if add is there
        itemaddonbasedon: "0",
        item_favorite: "0",
        variation_groupname: "",
        variation: [],
        cuisine: [],
        itemallowvariation: "0",
        minimumpreparationtime: "20",
        item_attributeid: "",
        itemname: "",
        itemdescription: "",
        price: null,
        percentageTax: 5,
        addon: [],
      });

      getData();
      setPage(0);
    } catch (error) {
      console.log(error);
      snackbar(error.response.data.message, error.response.data.status);

      setIsLoading(false);
    }
  };

  const handleSelect = (selectedList) => {
    setData({ ...data, cuisine: selectedList });
  };

  const handleRemove = (selectedList) => {
    setData({ ...data, cuisine: selectedList });
  };

  const onChangeHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    console.log(data);
  };

  const onChangeHandlerItemNameHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
        .split(" ")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" "),
    });
    console.log("item name datam    ", data);
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          backgroundColor: "grey.midGrey",
          p: 2,
          borderRadius: "16px",
          mt: 3,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: 600,
            fontSize: "22px",
            color: "white.main",
            justifySelf: "start",
          }}
        >
          Add Menu Item{" "}
          <span style={{ color: "#0C1844" }}>{restaurantName}</span>
        </Typography>
      </Box>

      <Grid
        container
        spacing={2}
        component={"form"}
        onSubmit={summitHandler}
        mt={1}
        sx={{ display: "flex", justifyContent: "start" }}
      >
        <Grid
          item
          sx={{ display: "flex", justifyContent: "center" }}
          xs={12}
          sm={12}
          md={12}
        >
          <Box sx={{ width: 400 }}>
            <DragsAndDrop
              heading="Upload Image"
              inputName="Image"
              aspect={1 / 0.8}
              uploadFile={(x) => {
                setImage(x);
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box sx={textFieldBox}>
            <Typography variant="body1" sx={textHeading}>
              Name*
            </Typography>

            <TextField
              size="small"
              name="itemname"
              type="text"
              required
              placeholder="Name"
              autoFocus
              inputProps={{ style: { textTransform: "capitalize" } }}
              onChange={onChangeHandlerItemNameHandler}
              value={data.itemname}
              sx={textFieldStyle}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Box sx={textFieldBox}>
            <Typography variant="body1" sx={textHeading}>
              Category *
            </Typography>

            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="item_categoryid"
                value={data.item_categoryid}
                size="small"
                onChange={onChangeHandler}
                sx={selectFieldStyle}
                required
                // MenuProps={MenuProps}
              >
                {categories.map((category) => (
                  <MenuItem key={category._id} value={category.categoryid}>
                    {category.categoryname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Box sx={textFieldBox}>
            <Typography variant="body1" sx={textHeading}>
              Price*
            </Typography>

            <TextField
              size="small"
              name="price"
              type="number"
              required
              placeholder="Price"
              value={data.price}
              InputProps={{
                inputProps: {
                  type: "number",
                  min: 0,
                },
              }}
              onChange={onChangeHandler}
              sx={textFieldStyle}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box sx={textFieldBox}>
            <Typography variant="body1" sx={textHeading}>
              Packing Charges
            </Typography>

            <TextField
              size="small"
              name="ybitesPackingCharges"
              type="number"
              // defaultValue={15}
              required
              value={data.ybitesPackingCharges}
              InputProps={{
                inputProps: { type: "number", min: 20, max: 100 },
              }}
              onChange={onChangeHandler}
              placeholder="Packing charge"
              sx={textFieldStyle}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6} px={3}>
          <Typography
            variant="h6"
            fontWeight={500}
            fontSize="16px"
            sx={textHeading}
          >
            Cuisines
          </Typography>

          <Multiselect
            style={{
              multiselectContainer: {
                width: "100%",
                backgroundColor: "#FFD89D",
              },
            }}
            options={cuisinesItemList} // Options to display in the dropdown
            selectedValues={data.cuisine} // Preselected value to persist in dropdown
            onSelect={handleSelect} // Function will trigger on select event
            onRemove={handleRemove} // Function will trigger on remove event
            // displayValue="title" // Property name to display in the dropdown options
            isObject={false}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Box sx={textFieldBox}>
            <Typography variant="body1" sx={textHeading}>
              Minimum Preparation Time
            </Typography>

            <TextField
              size="small"
              name="minimumpreparationtime"
              type="number"
              defaultValue={20}
              required
              placeholder="Minimum Preparation Time"
              InputProps={{
                inputProps: {
                  type: "number",
                  min: 20,
                  max: 60,
                },
              }}
              value={data.minimumpreparationtime}
              onChange={onChangeHandler}
              sx={textFieldStyle}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={textFieldBox}>
            <Typography variant="body1" sx={textHeading}>
              Description
            </Typography>

            <TextField
              multiline
              rows={3}
              size="small"
              name="itemdescription"
              type="text"
              value={data.itemdescription}
              onChange={onChangeHandler}
              placeholder="Description"
              sx={textFieldStyle}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={textFieldBox}>
            <FormControl required>
              <FormLabel id="taxesType" sx={textHeading}>
                Attributes
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="item_attributeid"
                defaultValue={"forward"}
                required
                // onChange={onChange}
                value={data.item_attributeid}
                onChange={onChangeHandler}
              >
                {attributes.map((attribute, index) => (
                  <FormControlLabel
                    key={attribute._id}
                    value={attribute.attributeid}
                    control={<Radio />}
                    defaultChecked={index === 0 ? true : false}
                    label={attribute.attribute}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={textFieldBox}>
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                sx={textHeading}
              >
                Tax percentage
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="percentageTax"
                value={data.percentageTax}
                onChange={onChangeHandler}
              >
                <FormControlLabel value={5} control={<Radio />} label="5%" />
                <FormControlLabel value={18} control={<Radio />} label="18%" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Divider />
          <Box sx={textFieldBox}>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value={1}
                  label={
                    <>
                      <Typography sx={textHeading}>Add Variation</Typography>
                    </>
                  }
                  control={
                    <Radio
                      onChange={(e) => {
                        setVariationOrAddOns(1);
                        setData({ ...data, addon: [], itemaddonbasedon: "0" });
                      }}
                    />
                  }
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={(event) => {
                        setVariationOrAddOns(2);
                        setData({
                          ...data,
                          variation: [],
                          variation_groupname: "",
                          itemaddonbasedon: "0",
                        });
                      }}
                    />
                  }
                  value={2}
                  label={
                    <>
                      <Typography sx={textHeading}>Add Addon</Typography>
                    </>
                  }
                  //   onChange={() => setIsCheckedAddon(!isCheckedAddon)}
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={variationOrAddOns === 0 ? true : false}
                      onChange={(event) => {
                        setVariationOrAddOns(0);
                        setData({
                          ...data,
                          addon: [],
                          variation: [],
                          variation_groupname: "",
                          itemaddonbasedon: "0",
                        });
                      }}
                    />
                  }
                  value={0}
                  label={
                    <>
                      <Typography sx={textHeading}>None</Typography>
                    </>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>

        {variationOrAddOns === 1 ? (
          <Grid
            container
            spacing={2}
            // sx={{ display:  ? "block" : "none" }}
          >
            <Grid item xs={12} mt={2}>
              <AddVariations
                addOns={addOns}
                data={data}
                setData={setData}
                addOn={addOn}
                variations={variations}
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        {variationOrAddOns === 2 ? (
          <Grid
            container
            spacing={2}
            // sx={{ display:  ? "block" : "none" }}
          >
            <Grid item xs={12} mt={2}>
              <AddAddOns
                addOns={addOns}
                data={data}
                setData={setData}
                addOn={addOn}
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        <Grid item xs={12}>
          <Box
            sx={{
              width: "100%",
              mt: 2,
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              variant="contained"
              onClick={() => setPage(0)}
              sx={submitBtn}
            >
              Back
            </Button>
            <Button variant="contained" type="submit" sx={submitBtn}>
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AddItem;
