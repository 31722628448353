import React from "react";
import SelectRestaurantMenuUpload from "../restaurant/petPoojaMenuItems/selectRestaurant/SelectRestaurantMenuUpload";
const CategoriesSelect = () => {
  return (
    <div>
      <SelectRestaurantMenuUpload type={"/i/categories"} />
    </div>
  );
};

export default CategoriesSelect;
