import { Box, Button, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import FilterByCity from "../../reusableComponent/filterByCity/FilterByCity";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Paper,
} from "@mui/material";
import moment from "moment";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { tableColumnsHeading } from "../../components/cssStyling/Style";
function TdsToBePaid() {
  const [orderReport, setOrderReport] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const tableRef = useRef(null);
  const columns = [
    {
      field: "orderId",
      headerName: "Order Id",
      width: 100,
      renderHeader: () => <strong>{"Order Id"}</strong>,
      renderCell: (params) => {
        return params.row.deliveredOrderId
          ? params.row.deliveredOrderId.orderId
          : "";
      },
    },
    {
      field: "brand_display_name",
      headerName: "Restaurant",
      width: 200,
      renderHeader: () => <strong>{"Restaurant"}</strong>,
      renderCell: (params) => {
        return params.row.restaurantId ? (
          <Typography>{params.row.restaurantId.brand_display_name}</Typography>
        ) : (
          <>-</>
        );
      },
    },

    {
      field: "menuItems",
      headerName: "Menu Items",
      width: 300,
      font: 10,
      renderHeader: () => <strong>{"Menu Items"}</strong>,
      renderCell: (params) => {
        return params.row.deliveredOrderId ? (
          <Typography sx={{ fontSize: 13 }}>
            {params.row.deliveredOrderId.orderItems.map((item) => (
              <li>{`${item.itemTitle}=${item.quantity}X${
                item.price / item.quantity
              }=${item.price}, `}</li>
            ))}
          </Typography>
        ) : (
          ""
        );
      },
    },

    {
      field: "quantity",
      headerName: " Total Quantity",
      width: 100,
      renderHeader: () => <strong>{"Total Quantity"}</strong>,
      renderCell: (params) => {
        return params.row.deliveredOrderId ? (
          <Typography sx={{ fontSize: 13 }}>
            {params.row.deliveredOrderId.orderItems.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.quantity,
              0
            )}
          </Typography>
        ) : (
          ""
        );
      },
    },
    {
      field: "totalPrice",
      headerName: "Total Amount Per Order",
      width: 200,
      renderHeader: () => <strong>{"Total Amount Per Order"}</strong>,
      renderCell: (params) => {
        return params.row.deliveredOrderId
          ? params.row.deliveredOrderId.totalPrice
          : "";
      },
    },
    {
      field: "grandTotalPrice",
      headerName: "Amount After Discount",
      width: 200,
      renderHeader: () => <strong>{"Amount After Discount"}</strong>,
      renderCell: (params) => {
        return params.row.deliveredOrderId
          ? params.row.deliveredOrderId.grandTotalPrice
          : "";
      },
    },

    {
      field: "totalTds",
      headerName: "Total TDS",
      width: 170,
      renderHeader: () => <strong>{"Total TDS"}</strong>,
    },
    {
      field: "pan_no",
      headerName: "Pan Card",
      width: 170,
      renderHeader: () => <strong>{"Pan Card"}</strong>,
      renderCell: (params) => {
        return params.row.restaurantId ? params.row.restaurantId.pan_no : "";
      },
    },
  ];

  useEffect(() => {
    getAllOrders(page, limit, "", "");
  }, []);

  const getAllOrders = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      // const res = await axios.get(
      //   `/api/v1/admin/restaurantReports?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}`
      // );
      const res = await axios.get(`/api/v1/admin/restaurantCalculatedReports`);
      // setOrderReport(() =>
      //   res.data.data.filter((data, index) => {
      //     return data.restaurantId.petPooja === false;
      //   })
      // );
      setOrderReport(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Box>
      <FilterByCity />
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        // autoHeight
        rows={orderReport}
        getRowId={(row) => row._id}
        rowCount={total}
        loading={loading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={page - 1}
        pageSize={limit}
        paginationMode="server"
        sortingMode="server"
        // onSortModelChange={handleSortModelChange}
        filterMode="server"
        // onFilterModelChange={onFilterChange}
        onPageChange={(newPage) => {
          getAllOrders(newPage + 1, limit, "", "");
          setPage(newPage + 1);
        }}
        onPageSizeChange={(newPageSize) => {
          getAllOrders(page, newPageSize, "", "");
          setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={columns}
        sx={{
          "& .bold-header": {
            fontWeight: "bold",
            color: "blue",
          },
          height: "520px",
          overflow: "auto",
        }}
      />

      {/* download excel file */}

      <Stack spacing={2} mt={3} display={"none"}>
        <TableContainer component={Paper}>
          <Table ref={tableRef} aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                }}
              >
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Order Id
                </TableCell>

                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Restaurant
                </TableCell>

                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Date
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Menu Items
                </TableCell>

                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Item Quantity
                </TableCell>

                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Total Amount Per Order
                </TableCell>

                {/* ------------------- */}

                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Amount After Discount
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Total TDS
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Pan Card
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderReport?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={tableColumnsHeading}
                  >
                    {row.deliveredOrderId ? row.deliveredOrderId.orderId : ""}
                  </TableCell>

                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row.restaurantId
                      ? row.restaurantId.brand_display_name
                      : ""}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={tableColumnsHeading}
                  >
                    {row.deliveredOrderId
                      ? moment(row.deliveredOrderId.deliveredAt).format(
                          "DD/MM/YYYY hh:mm A"
                        )
                      : ""}
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row.deliveredOrderId
                      ? row.deliveredOrderId.orderItems.map((item) => (
                          <li>
                            {`${item.itemTitle}=${item.quantity}X
                            ${item.price / item.quantity}=${item.price}
                            `}
                            ,{" "}
                          </li>
                        ))
                      : ""}
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row.deliveredOrderId.orderItems.reduce(
                      (accumulator, currentValue) =>
                        accumulator + currentValue.quantity,
                      0
                    )}
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row.deliveredOrderId
                      ? row.deliveredOrderId.totalPrice
                      : ""}
                  </TableCell>
                  {/* // grand total price */}
                  {/* ----------------------- */}
                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row.deliveredOrderId
                      ? row.deliveredOrderId.grandTotalPrice
                      : ""}
                  </TableCell>

                  {/* gst on menu customer */}

                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row.totalTds ? row.totalTds : ""}
                  </TableCell>

                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row?.restaurantId ? row.restaurantId.pan_no : ""}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <DownloadTableExcel
        filename="tds-to-be-paid"
        sheet="DeliveryPartner"
        currentTableRef={tableRef.current}
      >
        <Button variant="contained"> Export excel </Button>
      </DownloadTableExcel>
    </Box>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default TdsToBePaid;
