import React, { useContext, useState } from "react";
import { AdminDataContext } from "../../../../../useContext/AdminContext";
import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { imgUrl } from "../../../../../url";

var FileSaver = require("file-saver");

const StyledContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
  paddingTop: "2rem",
  paddingBottom: "2rem",
  backgroundColor: "#F5F5F5",
  borderRadius: "5px",
});

const StyledForm = styled("form")({
  width: "100%",
});

const StyledInput = styled(TextField)({
  width: "100%",
});

const StyledButton = styled(Button)({
  width: "100%",
});

const Fssai = ({ id, fssai, getRestaurantDetails }) => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  const [fssaiNo, setFssaiNo] = useState({
    fssai_no: fssai.fssai_no ? fssai.fssai_no : null,
  });
  const [fssaiFile, setFssaiFile] = useState("");
  const [isAvailable, setIsAvailable] = useState(
    fssai.hasOwnProperty("fssai_no") ? true : false
  );
  console.log("test fsas ", fssai.hasOwnProperty("fssai_no"));

  const uploadFssaiDocument = (e) => {
    setFssaiFile(e.target.files[0]);
  };

  const onFssaiInputChange = (e) => {
    setFssaiNo({
      ...fssaiNo,
      [e.target.name]: e.target.value,
    });
  };
  // const fssaiRegexp = /^[1-2]{1}[0-9]{2}[0-9]{2}[0-9]{3}[0-9]{6}$/;
  // const fssaiRegexp = /^(?:\d{14}|\d{22})$/;
  const handleFssaiSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      // if (fssaiRegexp.test(fssaiNo.fssai_no)) {
      console.log(true);

      let formData = new FormData();
      formData.append("file", fssaiFile);
      formData.append("fssai_no", fssaiNo.fssai_no);
      formData.append("restaurantId", id);
      const res = await axios.post(`/api/v1/admin/restaurant/fssai`, formData);
      console.log(res);

      // setFssaiNo({
      //   fssai_no: "",
      // });
      getRestaurantDetails();
      snackbar(res.data.message, "success");
      // } else {
      //   console.log(false);
      //   snackbar("Invalid fssai license number", "error");
      // }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      snackbar(error.response.data.message, "error");
    }
  };
  const inputDocuments = {
    borderRadius: "10px",
    // background: "rgba(255, 255, 255, 0.31)",
    color: "rgba(255, 255, 255, 0.96)",
    border: " 1px solid rgba(103, 103, 103, 0.75)",
    "& fieldset": {
      border: "none",
      color: "rgba(255, 255, 255, 0.96);",
    },
  };
  return (
    <>
      <StyledContainer maxWidth="sm">
        <Box>
          <StyledForm onSubmit={(e) => handleFssaiSubmit(e)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "25px",
                    color: "rgba(0, 0, 0, 0.8)",
                  }}
                >
                  {" "}
                  FSSAI LICENSE*{" "}
                  {isAvailable ? (
                    <FormControlLabel
                      mx={1}
                      value="veg"
                      control={<Checkbox />}
                      onChange={() => setIsAvailable(false)}
                      label="Re-Upload document"
                      labelPlacement="end"
                    />
                  ) : (
                    false
                  )}
                </Typography>
                <Typography sx={{ color: "grey.main" }}>
                  {" "}
                  License No*
                </Typography>

                <StyledInput
                  fullWidth
                  variant="outlined"
                  disabled={isAvailable ? true : false}
                  required
                  size="small"
                  name="fssai_no"
                  value={fssaiNo.fssai_no}
                  onChange={(e) => onFssaiInputChange(e)}
                  sx={inputDocuments}
                />
              </Grid>
              {isAvailable ? (
                <>
                  <Grid item xs={12} sx={{ display: "flex" }}>
                    <Typography sx={{ color: "grey.main" }}>
                      {" "}
                      Uploaded File {fssai.fssai_image}
                    </Typography>

                    <CloudDownloadIcon
                      onClick={() =>
                        FileSaver.saveAs(
                          fssai.fssai_image.startsWith("http")
                            ? `${fssai.fssai_image}`
                            : `${imgUrl}/${fssai.fssai_image}`,
                          "image.jpg"
                        )
                      }
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Typography sx={{ color: "grey.main" }}>
                      {" "}
                      File Upload
                    </Typography>

                    <StyledInput
                      fullWidth
                      variant="outlined"
                      disabled={isAvailable ? true : false}
                      required
                      inputProps={{ accept: "image/*,.pdf" }}
                      type="file"
                      size="small"
                      onChange={(e) => uploadFssaiDocument(e)}
                      sx={inputDocuments}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isAvailable ? true : false}
                  sx={{
                    background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                    color: "white.main",
                  }}
                >
                  Submit
                </StyledButton>
              </Grid>
            </Grid>
          </StyledForm>
        </Box>
      </StyledContainer>
    </>
  );
};

export default Fssai;
