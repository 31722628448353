import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Stack,
  Paper,
  Grid,
  TextField,
} from "@mui/material";
import {
  DataGrid,
  GridToolbar,
  // GridToolbarContainer,
  // GridToolbarExport,
} from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";

import { DownloadTableExcel } from "react-export-table-to-excel";
import {
  textHeadingInputFields,
  textFieldBox,
  addBtn,
  tableColumnsHeading,
} from "../../../components/cssStyling/Style";
import DetialModalPetPooja from "./DetialModalPetPooja";
import FilterByCity from "../../../reusableComponent/filterByCity/FilterByCity";
function AllCalculationPetPooja() {
  const [orderReport, setOrderReport] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const tableRef = useRef(null);
  // order details modal
  const [openModal, setOpenModal] = useState(false);
  const [items, setItems] = useState([]);
  const [counter, setCounter] = useState(0);
  const [OrderItemsDetails, setOrderItemsDetails] = useState([]);
  const handleOpenModal = (data) => {
    setOrderItemsDetails(data.deliveredOrderId.orderItems);
    // console.log(data.deliveredOrderId.orderItems);
    setItems([]);

    console.log("OrderItemsDetails", data);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setItems([]);
    setCounter(0);
    setOpenModal(false);
  };

  const columns = [
    {
      field: "orderId",
      headerName: "Order Id",
      width: 100,
      renderCell: (params) => {
        return params.row.deliveredOrderId
          ? params.row.deliveredOrderId.orderId
          : "";
      },
    },
    {
      field: "brand_display_name",
      headerName: "Restaurant",
      width: 200,

      renderCell: (params) => {
        return params.row.restaurantId ? (
          <Typography>{params.row.restaurantId.brand_display_name}</Typography>
        ) : (
          <>-</>
        );
      },
    },

    {
      field: "view-details",
      headerName: "View Details",
      width: 150,
      renderCell: (params) => {
        return (
          <Button onClick={() => handleOpenModal(params.row)}>
            {" "}
            View Details
          </Button>
        );
      },
    },
    {
      field: "menuItems",
      headerName: "Menu Items",
      width: 300,
      font: 10,
      renderCell: (params) => {
        return params.row.deliveredOrderId ? (
          <Typography sx={{ fontSize: 13 }}>
            {params.row.deliveredOrderId.orderItems.map((item) => (
              <li>{`${item.itemTitle}=${item.quantity}X${
                item.price / item.quantity
              }=${item.price}, `}</li>
            ))}
          </Typography>
        ) : (
          ""
        );
      },
    },

    {
      field: "quantity",
      headerName: " Total Quantity",
      width: 100,
      renderCell: (params) => {
        return params.row.deliveredOrderId ? (
          <Typography sx={{ fontSize: 13 }}>
            {params.row.deliveredOrderId.orderItems.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.quantity,
              0
            )}
          </Typography>
        ) : (
          ""
        );
      },
    },

    {
      field: "totalPrice",
      headerName: "Total Amount Per Order",
      width: 200,
      renderCell: (params) => {
        return params.row.deliveredOrderId
          ? params.row.deliveredOrderId.totalPrice
          : "";
      },
    },

    {
      field: "ybiteDiscount",
      headerName: "Ybites Discount",
      width: 200,
      renderCell: (params) => {
        return params.row.couponType?.couponType === "admin"
          ? params.row.deliveredOrderId.discount
          : 0;
      },
    },
    {
      field: "restaurantDiscount",
      headerName: "Restaurant Discount",
      width: 200,
      renderCell: (params) => {
        return params.row.couponType?.couponType === "restaurant"
          ? params.row.deliveredOrderId.discount
          : 0;
      },
    },
    {
      field: "ybitesAfterDiscountPrice",
      headerName: "Ybites Discount Price",
      width: 200,
      renderCell: (params) => {
        return params.row.deliveredOrderId
          ? params.row.deliveredOrderId.ybitesAfterDiscountPrice
          : 0;
      },
    },
    {
      field: "restaurantAfterDiscountPrice",
      headerName: "Restaurant Discount Price",
      width: 200,
      renderCell: (params) => {
        return params.row.deliveredOrderId
          ? params.row.deliveredOrderId.restaurantAfterDiscountPrice
          : 0;
      },
    },

    {
      field: "actualAmountCollectedOnMenu",
      headerName: "Actual Amount Collected On Menu",
      width: 250,
    },
    // ------

    // {
    //   field: "grandTotalPrice",
    //   headerName: "change Actual Amount Collected On Menu",
    //   width: 200,
    //   renderCell: (params) => {
    //     return params.row.deliveredOrderId
    //       ? params.row.deliveredOrderId.grandTotalPrice
    //       : "";
    //   },
    // },

    {
      field: "gstOnMenuCustomer",
      headerName: "GST On Menu Customer",
      width: 200,
      // valueFormatter: (params) =>
      //   moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "YbitesCommission",
      headerName: "Ybites Commission",
      width: 150,
    },
    {
      field: "gstOnCommissionRestaurant",
      headerName: "GST On Commission Restaurant",
      width: 250,
    },
    {
      field: "totalAmountPayable",
      headerName: "Total Amount Payable",
      width: 160,
    },
    // {
    //   field: "totalTds",
    //   headerName: "TDS On Payable Amount",
    //   width: 200,
    // },
    {
      field: "netPayableAmount",
      headerName: "Net Payable Amount",
      width: 150,
    },
    {
      field: "deliveryCharge",
      headerName: "Delivery Charge Customer",
      width: 200,
      renderCell: (params) => {
        return params.row.deliveredOrderId ? (
          <Typography>{params.row.deliveredOrderId.deliveryCharge}</Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "deliveryBoyShare",
      headerName: "Delivery Charge Payable",
      width: 200,
      renderCell: (params) => {
        return params.row.deliveredOrderId ? (
          <Typography>
            {params.row.deliveredOrderId.deliveryBoyShare}
          </Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "gstOnDelivery",
      headerName: "GST On Delivery",
      width: 150,
    },
    {
      field: "packagingCharge",
      headerName: "Packing Charge",
      width: 150,

      renderCell: (params) => {
        return params.row.deliveredOrderId ? (
          <Typography>{params.row.deliveredOrderId.packagingCharge}</Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "gstOnPackingCharge",
      headerName: "GST On Packing Charge",
      width: 200,
    },
    {
      field: "totalGst",
      headerName: "Total GST",
      width: 170,
    },
    {
      field: "totalTds",
      headerName: "Total TDS",
      width: 170,
    },
    {
      field: "ybitesCollection",
      headerName: "Ybites Collection",
      width: 150,
    },
    {
      field: "deduction",
      headerName: "Deduction",
      width: 150,
    },
    {
      field: "profit",
      headerName: "Profit",
      width: 150,
    },
  ];

  useEffect(() => {
    getReportOfYbitesRestaurant(page, limit, "", "");
  }, []);

  const getReportOfYbitesRestaurant = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      // const res = await axios.get(
      //   `/api/v1/admin/restaurantReports?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}`
      // );
      const res = await axios.get(
        `/api/v1/admin/restaurantCalculatedReports?startDate=${startDate}&endDate=${endDate}`
      );
      setOrderReport(() =>
        res.data.data.filter((data, index) => {
          return data.restaurantId.petPooja === true;
        })
      );
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // const handleSortModelChange = async (item) => {
  //   getAllOrders(page, limit, "", "");
  //   if (item.length === 0) {
  //     getAllOrders(page, limit, "", "");
  //   } else {
  //     getAllOrders(page, limit, item[0].sort, item[0].field);
  //   }
  // };

  // const onFilterChange = async (e) => {
  //   console.log(e.items);
  //   try {
  //     setLoading(true);
  //     const res = await axios.post(`/api/v1/admin/order/filter`, {
  //       key: e.items[0].columnField,
  //       value: e.items[0].value,
  //     });
  //     setOrderReport(res.data.data);
  //     setTotal(res.data.total);
  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };
  // onClick={createComplainHandler}
  return (
    <Box>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "space-around", mb: 2 }}
      >
        <Grid
          item
          xs={12}
          sm={3}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box
            sx={{
              ...textFieldBox,
              display: "flex",
              flexDirection: "column",
              justifyContent: { xs: "center", sm: "start" },
            }}
          >
            <FilterByCity />
          </Box>
        </Grid>

        <Grid
          item
          xs={6}
          sm={3}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box
            sx={{
              ...textFieldBox,
              display: "flex",
              flexDirection: "column",
              justifyContent: { xs: "center", sm: "start" },
            }}
          >
            <Typography variant="body1" sx={textHeadingInputFields}>
              Start Date
            </Typography>

            <TextField
              size="small"
              id="outlined-basic"
              type="date"
              variant="outlined"
              name="startDate"
              // onChange={onChangeDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Box>
        </Grid>

        <Grid item xs={6} sm={3} sx={{ display: "flex" }}>
          <Box
            sx={{
              ...textFieldBox,
              display: "flex",
              flexDirection: "column",
              justifyContent: { xs: "center", sm: "start" },
            }}
          >
            <Typography variant="body1" sx={textHeadingInputFields}>
              End Date
            </Typography>

            <TextField
              size="small"
              id="outlined-basic"
              type="date"
              variant="outlined"
              name="endDate"
              // onChange={onChangeDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={6}
          sm={6}
          sx={{
            display: "flex",
            // justifyContent: { xs: "center", sm: "start" },
          }}
        >
          {startDate && endDate ? (
            <>
              <Box
                sx={{
                  ...textFieldBox,
                  display: "flex",
                  // flexDirection: "column",
                  justifyContent: { xs: "center", sm: "start" },
                  mt: 4.6,
                }}
              >
                <Button
                  sx={{
                    ...addBtn,
                    m: 0,
                    height: "40px",
                    width: "max-content",
                  }}
                  onClick={getReportOfYbitesRestaurant}
                  variant="contained"
                >
                  Get Date
                </Button>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>

      <DataGrid
        components={{ Toolbar: GridToolbar }}
        // slots={{ toolbar: GridToolbar }}

        autoHeight
        rows={orderReport}
        getRowId={(row) => row._id}
        rowCount={total}
        loading={loading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={page - 1}
        pageSize={limit}
        paginationMode="server"
        sortingMode="server"
        // onSortModelChange={handleSortModelChange}
        filterMode="server"
        // onFilterModelChange={onFilterChange}
        onPageChange={(newPage) => {
          getReportOfYbitesRestaurant(newPage + 1, limit, "", "");
          setPage(newPage + 1);
        }}
        onPageSizeChange={(newPageSize) => {
          getReportOfYbitesRestaurant(page, newPageSize, "", "");
          setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={columns}
      />

      <Stack spacing={2} mt={3} display={"none"}>
        <TableContainer component={Paper}>
          <Table ref={tableRef} aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                }}
              >
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Order Id
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Restaurant
                </TableCell>

                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Menu Items
                </TableCell>

                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Item Quantity
                </TableCell>

                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Total Amount Per Order
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  GST On Menu Customer
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Ybites Commission
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  GST On Commission Restaurant
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Total Amount Payable
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Net Payable Amount
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Delivery Charge Customer
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Delivery Charge Payable
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  GST On Delivery
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Packing Charge
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  GST On Packing Charge
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Total GST
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Total TDS
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Ybites Collection
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Deduction
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Profit
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderReport.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={tableColumnsHeading}
                  >
                    {row.deliveredOrderId ? row.deliveredOrderId.orderId : ""}
                  </TableCell>

                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row.restaurantId
                      ? row.restaurantId.brand_display_name
                      : ""}
                  </TableCell>

                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row.deliveredOrderId
                      ? row.deliveredOrderId.orderItems.map((item) => (
                          <li>
                            {`${item.itemTitle}=${item.quantity}X
                            ${item.price / item.quantity}=${item.price}
                            `}
                            ,{" "}
                          </li>
                        ))
                      : ""}
                  </TableCell>

                  <TableCell align="center" sx={tableColumnsHeading}>
                    {/* {row.deliveredOrderId
                      ? row.deliveredOrderId.orderItems.length
                      : ""} */}

                    {row.deliveredOrderId.orderItems.reduce(
                      (accumulator, currentValue) =>
                        accumulator + currentValue.quantity,
                      0
                    )}
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row.deliveredOrderId
                      ? row.deliveredOrderId.grandTotalPrice
                      : ""}
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row.gstOnMenuCustomer ? row.gstOnMenuCustomer : ""}
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row.YbitesCommission ? row.YbitesCommission : ""}
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row.gstOnCommissionRestaurant
                      ? row.gstOnCommissionRestaurant
                      : ""}
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row.totalAmountPayable ? row.totalAmountPayable : ""}
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row.netPayableAmount ? row.netPayableAmount : ""}
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row.deliveredOrderId
                      ? row.deliveredOrderId.deliveryCharge
                      : ""}
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row.deliveredOrderId
                      ? row.deliveredOrderId.deliveryBoyShare
                      : ""}
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row.gstOnDelivery ? row.gstOnDelivery : ""}
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row.deliveredOrderId
                      ? row.deliveredOrderId.packagingCharge
                      : ""}
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row.gstOnPackingCharge ? row.gstOnPackingCharge : "0"}
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row.totalGst ? row.totalGst : ""}
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row.totalTds ? row.totalTds : ""}
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row.ybitesCollection ? row.ybitesCollection : ""}
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row.deduction ? row.deduction : ""}
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row.profit ? row.profit : ""}
                  </TableCell>

                  <TableCell align="center"> </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <DownloadTableExcel
        filename="All Calculation"
        sheet="DeliveryPartner"
        currentTableRef={tableRef.current}
      >
        <Button variant="contained"> Export excel </Button>
      </DownloadTableExcel>
      <DetialModalPetPooja
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        OrderItemsDetails={OrderItemsDetails}
        // items={items}
        // setItems={setItems}
        // counter={counter}
        // setCounter={setCounter}
      />
    </Box>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default AllCalculationPetPooja;
