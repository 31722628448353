import React, { useEffect, useRef, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl"; // or "const mapboxgl = require('mapbox-gl');"
import axios from "axios";
import { Button } from "@mui/material";

const AsmMonitor = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [lng, setLng] = useState(72.978146);
  const [lat, setLat] = useState(19.217302);
  const [zoom, setZoom] = useState(12.5);
  const [wait, setWait] = useState(false);
  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      setWait(false);
      const res = await axios.get("/api/v1/admin/asm/location");
      setData(res.data.data[7]);

      // console.log(data);
      setWait(true);
    } catch (error) {}
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    {
      console.log("locati", data ? data : "");
    }

    // console.log(busyPartnersData, availablePartnersData);
    if (wait) {
      if (map.current) return; // initialize map only once
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v12",
        center: [lng, lat],
        zoom: zoom,
        accessToken:
          "pk.eyJ1IjoieWJpdGVzIiwiYSI6ImNsZmdveXJ2YTBsamkzeW5wanY1bjBkMnkifQ.nQdGB1L5C8Vd-qLV3vKfSA",
      });
      map.current.on("load", () => {
        map.current.addSource("available", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: data,
          },
        });
        // Add a layer showing the available.
        map.current.addLayer({
          id: "available",
          type: "circle",
          source: "available",
          paint: {
            "circle-color": "green",
            "circle-radius": 6,
            "circle-stroke-width": 1,
            "circle-stroke-color": "#ffffff",
          },
        });
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        });

        map.current.on("mouseenter", "available", (e) => {
          console.log("fetures : ", e);
          map.current.getCanvas().style.cursor = "pointer";
          const coordinates = e.features[0].geometry.coordinates.slice();
          const description = e.features[0].properties.description;

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            console.log("locasss ", coordinates[0]);
          }

          popup.setLngLat(coordinates).setHTML(description).addTo(map.current);
        });

        map.current.on("mouseleave", "available", () => {
          map.current.getCanvas().style.cursor = "";
          popup.remove();
        });
      });
    }
  }, [data]);
  return (
    <div>
      <div ref={mapContainer} style={{ height: "88vh" }} />
    </div>
  );
};

export default AsmMonitor;
