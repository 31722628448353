import React, { useContext, useState } from "react";
import { AdminDataContext } from "../../../../../useContext/AdminContext";
import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { imgUrl } from "../../../../../url";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
var FileSaver = require("file-saver");

const StyledContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
  paddingTop: "2rem",
  paddingBottom: "2rem",
  backgroundColor: "#F5F5F5",
  borderRadius: "5px",
});

const StyledForm = styled("form")({
  width: "100%",
});

const StyledInput = styled(TextField)({
  width: "100%",
});

const StyledButton = styled(Button)({
  width: "100%",
});

const AadharCard = (props) => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const { id, aadharCard } = props;
  const [aadharNo, setAadharNo] = useState({
    aadhar_no: aadharCard.aadhar_no ? aadharCard.aadhar_no : null,
  });
  const [aadharFile, setAadharFile] = useState("");
  const [isAvailable, setIsAvailable] = useState(
    aadharCard.hasOwnProperty("aadhar_no") ? true : false
  );
  console.log("test ", aadharCard.hasOwnProperty("aadhar_no"));
  const uploadDocument = (e) => {
    setAadharFile(e.target.files[0]);
  };

  const onChange = (e) => {
    setAadharNo({
      ...aadharNo,
      [e.target.name]: e.target.value,
    });
  };
  console.log("aadharCard ", aadharCard.aadhar_image);
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const regexp = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;

      const aadhar = aadharNo.aadhar_no;

      if (regexp.test(aadhar)) {
        let formData = new FormData();
        formData.append("file", aadharFile);
        formData.append("aadhar_no", aadharNo.aadhar_no);
        formData.append("restaurantId", id);
        console.log("form Data ", formData);
        const res = await axios.post(
          `/api/v1/admin/restaurant/aadhar`,
          formData
        );
        snackbar(res.data.message, "success");
        console.log(res);
        // setAadharFile("");
        // setAadharNo({
        //   aadhar_no: "",
        // });
        props.getRestaurantDetails();
      } else {
        setIsLoading(false);
        snackbar("Invalid aadhar number", "error");
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      snackbar(error.response.data.message, "error");
    }
  };
  const inputDocuments = {
    borderRadius: "10px",
    // background: "rgba(255, 255, 255, 0.31)",
    color: "rgba(255, 255, 255, 0.96)",
    border: " 1px solid rgba(103, 103, 103, 0.75)",
    "& fieldset": {
      border: "none",
      color: "rgba(255, 255, 255, 0.96);",
    },
  };
  return (
    <>
      <StyledContainer maxWidth="sm">
        <Box>
          <StyledForm onSubmit={(e) => handleSubmit(e)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "25px",
                    color: "rgba(0, 0, 0, 0.8)",
                  }}
                >
                  {" "}
                  Aadhar Card*{" "}
                  {isAvailable ? (
                    <FormControlLabel
                      mx={1}
                      value="veg"
                      control={<Checkbox />}
                      onChange={() => setIsAvailable(false)}
                      label="Re-Upload document"
                      labelPlacement="end"
                    />
                  ) : (
                    false
                  )}
                </Typography>
                <Typography sx={{ color: "grey.main" }}> Aadhar No*</Typography>

                <StyledInput
                  fullWidth
                  variant="outlined"
                  disabled={isAvailable ? true : false}
                  required
                  size="small"
                  name="aadhar_no"
                  value={aadharNo.aadhar_no}
                  onChange={(e) => onChange(e)}
                  sx={inputDocuments}
                />
              </Grid>
              {isAvailable ? (
                <>
                  <Grid item xs={12} sx={{ display: "flex" }}>
                    <Typography sx={{ color: "grey.main" }}>
                      {" "}
                      Uploaded File {aadharCard.aadhar_image}
                    </Typography>

                    <CloudDownloadIcon
                      onClick={() =>
                        FileSaver.saveAs(
                          aadharCard.aadhar_image.startsWith("http")
                            ? `${aadharCard.aadhar_image}`
                            : `${imgUrl}/${aadharCard.aadhar_image}`,
                          "image.jpg"
                        )
                      }
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Typography sx={{ color: "grey.main" }}>
                      {" "}
                      File Upload{" "}
                    </Typography>

                    <StyledInput
                      fullWidth
                      variant="outlined"
                      // required={fileData ? false : true}
                      disabled={isAvailable ? true : false}
                      required
                      inputProps={{ accept: "image/*,.pdf" }}
                      type="file"
                      size="small"
                      onChange={(e) => uploadDocument(e)}
                      sx={inputDocuments}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isAvailable ? true : false}
                  sx={{
                    background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                    color: "white.main",
                  }}
                >
                  Submit
                </StyledButton>
              </Grid>
            </Grid>
          </StyledForm>
        </Box>
      </StyledContainer>
    </>
  );
};

export default AadharCard;
