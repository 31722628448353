import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext } from "react";
import { useState } from "react";
import { AdminDataContext } from "../../../../../useContext/AdminContext";

const Attributes = ({
  id,
  handlePendingScreen,
  attributes,
  getRestaurantDetails,
  restaurantDetails,
}) => {
  // const [data, setData] = useState([]);

  // const onChangeHandler = (e) => {
  //   let array = [...data];

  //   if (e.target.checked) {
  //     array = [...array, e.target.value];

  //     console.log(array);
  //   } else {
  //     array.splice(data.indexOf(e.target.value), 1);
  //     console.log(array);
  //   }
  //   setData(array);
  // };
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  // const [array, setArray] = useState(
  //   attributes.length > 0 ? [...attributes] : []
  // );
  const [array, setArray] = useState([]);
  console.log("attributessssss", attributes);
  const [isAvailable, setIsAvailable] = useState(
    attributes.length > 0 ? true : false
  );

  const onChangeHandler = (e) => {
    let attribute = [...array];

    if (e.target.checked) {
      attribute = [...attribute, { attribute: e.target.value }];

      console.log(attribute);
    } else {
      attribute.splice(array.indexOf(e.target.value), 1);
      console.log(attribute);
    }
    setArray(attribute);
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const res = await axios.post(
        `/api/v1/admin/restaurant/attribute/?restaurantId=${id}`,
        { array }
      );

      setIsLoading(false);
      snackbar(res.data.message, "success");

      console.log(res);
      handlePendingScreen();
    } catch (error) {
      snackbar(error.response.data.message, "error");

      console.log(error);
    }
  };
  console.log("array issss", array);
  const textFieldBox = {
    width: "100%",
    mt: 2,
  };

  const textHeading = {
    fontWeight: 600,
    fontSize: "18px",

    color: "black.primary",
    justifySelf: "start",
  };

  return (
    <>
      <Grid item xs={12} md={5}>
        <Box sx={textFieldBox} component={"form"} onSubmit={onSubmit}>
          <Typography variant="body1" sx={textHeading}>
            Choice*{" "}
            {isAvailable ? (
              <FormControlLabel
                mx={1}
                value="veg"
                control={<Checkbox />}
                onChange={() => setIsAvailable(false)}
                label="Update Choice"
                labelPlacement="end"
              />
            ) : (
              false
            )}
          </Typography>

          <FormControl component="fieldset" sx={{ display: "block" }}>
            <FormGroup
              aria-label="position"
              required
              row
              onChange={onChangeHandler}
            >
              <FormControlLabel
                value="veg"
                control={<Checkbox />}
                label="Veg"
                labelPlacement="end"
                disabled={isAvailable ? true : false}
              />

              <FormControlLabel
                value="non-veg"
                control={<Checkbox />}
                label="Non-veg"
                labelPlacement="end"
                disabled={isAvailable ? true : false}
              />
              <FormControlLabel
                value="egg"
                control={<Checkbox />}
                label="Egg"
                labelPlacement="end"
                disabled={isAvailable ? true : false}
              />
              <FormControlLabel
                value="jain"
                control={<Checkbox />}
                label="Jain"
                labelPlacement="end"
                disabled={isAvailable ? true : false}
              />
              <FormControlLabel
                value="other"
                control={<Checkbox />}
                label="Other"
                labelPlacement="end"
                disabled={isAvailable ? true : false}
              />
            </FormGroup>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            disabled={restaurantDetails.approved}
            type="submit"
            sx={{
              background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
              boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
              color: "white.main",
            }}
          >
            Submit
          </Button>
        </Box>
      </Grid>
    </>
  );
};

export default Attributes;
