import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableColumnsHeading } from "../cssStyling/Style";
import moment from "moment";

function SingleDeliveryReportDataGrid({ data }) {
  console.log("data is", data);

  const getTimeDifference = (startTime, endTime) => {
    const start = moment(startTime);
    const end = moment(endTime);
    const duration = moment.duration(end.diff(start));

    // Format time difference
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    return `${hours} hours and ${minutes} minutes`;
  };

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#FFCE78" }}>
              <TableCell align="center" sx={tableColumnsHeading}>
                S.No
              </TableCell>
              <TableCell align="center" sx={tableColumnsHeading}>
                Start Time
              </TableCell>
              <TableCell align="center" sx={tableColumnsHeading}>
                End Time
              </TableCell>
              <TableCell align="center" sx={tableColumnsHeading}>
                Total login time
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  sx={tableColumnsHeading}
                >
                  {index + 1}
                </TableCell>

                <TableCell align="center">
                  {moment(row.startTime).format("DD/MM/YYYY hh:mm A")}
                </TableCell>
                <TableCell align="center">
                  {" "}
                  {moment(row.endTime).format("DD/MM/YYYY hh:mm A")}
                </TableCell>
                <TableCell align="center">
                  {getTimeDifference(row.startTime, row.endTime)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default SingleDeliveryReportDataGrid;
