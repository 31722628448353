import {
  Box,
  IconButton,
  Modal,
  Stack,
  useMediaQuery,
  Grid,
  Button,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import Checkbox from "@mui/material/Checkbox";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { AdminDataContext } from "../../useContext/AdminContext";
import axios from "axios";
const PayToCustomerForReferral = (props) => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");

  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  //   const handleChange = (event, id) => {
  //     setChecked(event.target.checked);
  //     console.log("Checked", id);
  //     console.log("event.target.checked", event.target.checked);
  //   };

  const [checkedItems, setCheckedItems] = useState({});

  const handleChange = (e, id) => {
    const { checked } = e.target;
    setCheckedItems((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
    console.log("checkedItems", checkedItems);
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      const customers = Object.entries(checkedItems)
        .filter(([id, isChecked]) => isChecked === true)
        .map(([id]) => id);

      console.log(customers);
      if (customers.length > 0) {
        console.log("submit pay", customers);
        // api/v1/admin/paying-for-customers
        const res = await axios.patch(
          `/api/v1/admin/paying-for-customers/${props.customerId}`,
          customers
        );
        snackbar(res.data.message, "success");
      } else {
        console.log("can not submit empty array", customers);
        snackbar("Please select available customers", "error");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "550px",
    width: matches ? "40%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 2,
    overflow: "auto",
  };

  return (
    <>
      <Modal
        open={props.openModalPayment}
        onClose={props.handleCloseModalPayment}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
            gap={1}
            sx={{ position: "relative" }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "27px",
                color: "rgba(13, 30, 55, 0.67)",
                justifySelf: "start",
              }}
            >
              Referred Customers
            </Typography>

            <IconButton
              onClick={props.handleCloseModalPayment}
              aria-label="delete"
            >
              <CancelIcon />
            </IconButton>
          </Stack>
          <Grid component={"form"} onSubmit={onSubmit}>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 300 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Pay</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Order Count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.payable.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        {/* <Checkbox
                          checked={checked}
                          onChange={(e) => handleChange(e, row._id)}
                          inputProps={{ "aria-label": "controlled" }}
                        /> */}
                        <Checkbox
                          checked={checkedItems[row._id] || false}
                          onChange={(e) => handleChange(e, row._id)}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell>{row.phone}</TableCell>
                      <TableCell>{row.orderCount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                py: 1,
              }}
            >
              <Button variant="contained" type="submit">
                Done
              </Button>
            </Box>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default PayToCustomerForReferral;
