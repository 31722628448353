import {
  Box,
  Button,
  Typography,
  Stack,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  useMediaQuery,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { AdminDataContext } from "./../useContext/AdminContext";
import {
  selectFieldStyle,
  textFieldHeadingStyleForModal,
  textFieldStyleForModal,
} from "./cssStyling/Style";
import FilterByCity from "../reusableComponent/filterByCity/FilterByCity";
import { capitalize } from "../reusableComponent/capitalFirstCharacter";
import { textFieldBox } from "./menuItem/itemCreate/CssStyle";

function Owner() {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");
  const [owner, setOwner] = useState([]);
  const [data, setData] = useState({});
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [asm, setAsm] = useState([]);
  const [selectedASM, setSelectedASM] = useState("");
  const [searchOwner, setSearchOwner] = useState([]);

  const [singleOwner, setSingleOwner] = useState({});

  const [selectedOwner, setSelectOwner] = useState(false);

  const [createOwner, setCreateOwner] = useState(false);
  const handleOpen = () => {
    setData({});
    setEdit(false);
    setCreateOwner(true);
  };
  const handleClose = () => setCreateOwner(false);
  const onChangeHandler = (e) => {
    setSelectedASM(e.target.value);
    console.log(selectedASM);
  };
  const getAllASM = async () => {
    try {
      const res = await axios.get(`/api/v1/admin/all-asm`);
      setAsm(res.data.data);
      console.log("res.data.asm", res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "30%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 300,
      renderHeader: () => <strong>{"Name"}</strong>,
      // renderCell: (params) => params.row.name,
      renderCell: (params) => capitalize(params.row.name),
    },

    {
      field: "email",
      headerName: "Email",
      width: 250,
      renderHeader: () => <strong>{"Email"}</strong>,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 180,
      renderHeader: () => <strong>{"Phone"}</strong>,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 120,
      renderHeader: () => <strong>{"Gender"}</strong>,
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 150,
      renderHeader: () => <strong>{"Edit"}</strong>,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="contained"
              onClick={() => {
                setEdit(true);
                setData({ ...params.row, phone: params.row.phone.slice(-10) });

                setCreateOwner(true);
                // console.log(params.row);
              }}
            >
              Edit
            </Button>
          </>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 150,
      renderHeader: () => <strong>{"Delete"}</strong>,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="contained"
              onClick={() => deleteOwnerHandler(params.row._id)}
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];

  const deleteOwnerHandler = async (id) => {
    try {
      setLoading(true);
      const res = await axios.delete(`/api/v1/admin/owner?ownerId=${id}`);
      console.log("owner deleted ", res);
      snackbar(res.data.message, "success");

      getAllOwner(page, limit, "", "");
      setLoading(false);
    } catch (error) {
      console.log(error);
      snackbar(error.response.data.message, "error");
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllOwner(page, limit, "", "");
    getAllASM();
  }, []);

  // const inputChange = (event) => {
  //   setUpgradeData({ ...upgradeData, [event.target.name]: event.target.value });
  // };

  const getAllOwner = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/owner?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}`
      );
      setOwner(res.data.owner);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getSearchOwner = async (e) => {
    try {
      const res = await axios.get(
        `/api/v1/admin/owner/search/?query=${e.target.value}`
      );
      if (!(res.data.data.length === 0)) {
        setSearchOwner(res.data.data);
      } else {
        setSearchOwner([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createOwnerHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      let res = "";
      if (edit === true) {
        console.log("edit data is ", data);
        console.log("data._id", data._id);
        res = await axios.patch(`/api/v1/admin/owner?ownerId=${data._id}`, {
          ...data,

          phone: `+91${data.phone}`,
        });
        setEdit(false);
      } else {
        res = await axios.post(`/api/v1/admin/owner`, {
          ...data,
          phone: `+91${data.phone}`,
        });
      }
      setData({});
      snackbar(res.data.message, "success");
      getAllOwner(page, limit, "", "");
      setIsLoading(false);
      handleClose();
    } catch (error) {
      console.log(error);
      snackbar(error.response.data.message, "error");

      setIsLoading(false);
    }
  };
  const handleSortModelChange = async (item) => {
    getAllOwner(page, limit, "", "");
    if (item.length === 0) {
      getAllOwner(page, limit, "", "");
    } else {
      getAllOwner(page, limit, item[0].sort, item[0].field);
    }
  };
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
    console.log(data);
  };
  const onFilterChange = async (e) => {
    console.log(e);
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/admin/get/owners/search`, {
        key: e.items[0].columnField,
        value: e.items[0].value,
      });
      setOwner(res.data.owner);
      setTotal(res.data.totalowner);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  // onClick={createOwnerHandler}
  return (
    <>
      <Box>
        <FilterByCity />

        <Grid
          container
          spacing={2}
          mt={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Grid xs={12}>
            <Autocomplete
              fullWidth={true}
              disableClearable
              id="combo-box-demo"
              size="small"
              options={searchOwner}
              getOptionLabel={(userData) =>
                `${userData.name} Phone : ${userData.phone}`
              }
              onInputChange={getSearchOwner}
              onChange={(event, value) => {
                // console.log(event.target.value);
                // console.log("owner is ", value);
                let row = [];
                row.push(value);
                console.log(value);
                setSearchOwner(value);
                setSingleOwner(row);
                setSelectOwner(true);
              }}
              filterSelectedOptions
              //   sx={{ width: 250 }}
              renderInput={(params) => (
                <TextField {...params} label="Select Owner" />
              )}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "end", my: 2 }}>
          <Button onClick={handleOpen} variant="contained">
            Add Owner
          </Button>
        </Box>

        {selectedOwner ? (
          <>
            <DataGrid
              components={{ Toolbar: GridToolbar }}
              autoHeight
              rows={singleOwner}
              getRowId={(row) => row._id}
              // loading={loading}
              // sortingMode="server"
              pageSize={5}
              rowsPerPageOptions={[5]}
              columns={columns}
            />
          </>
        ) : (
          <>
            <DataGrid
              components={{ Toolbar: GridToolbar }}
              // autoHeight
              rows={owner}
              getRowId={(row) => row._id}
              rowCount={total}
              loading={loading}
              rowsPerPageOptions={[10, 30, 50, 70, 100]}
              pagination
              page={page - 1}
              pageSize={limit}
              paginationMode="server"
              sortingMode="server"
              onSortModelChange={handleSortModelChange}
              filterMode="server"
              onFilterModelChange={onFilterChange}
              onPageChange={(newPage) => {
                getAllOwner(newPage + 1, limit, "", "");
                setPage(newPage + 1);
              }}
              onPageSizeChange={(newPageSize) => {
                getAllOwner(page, newPageSize, "", "");
                setLimit(newPageSize);
                //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
              }}
              columns={columns}
              sx={{
                "& .bold-header": {
                  fontWeight: "bold",
                  color: "blue",
                },
                height: "600px",
                overflow: "auto",
              }}
            />
          </>
        )}

        <Modal
          open={createOwner}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container>
              <Grid item xs={12} component="form" onSubmit={createOwnerHandler}>
                <Typography sx={{ textFieldHeadingStyleForModal, my: 1 }}>
                  Name
                </Typography>
                <TextField
                  size="small"
                  sx={textFieldStyleForModal}
                  id="outlined-basic"
                  fullWidth
                  name="name"
                  value={data.name}
                  required
                  variant="outlined"
                  onChange={handleChange}
                />

                <Typography sx={{ textFieldHeadingStyleForModal, my: 1 }}>
                  Email
                </Typography>

                <TextField
                  size="small"
                  sx={textFieldStyleForModal}
                  fullWidth
                  id="outlined-basic"
                  name="email"
                  value={data.email}
                  required
                  variant="outlined"
                  onChange={handleChange}
                />

                <Typography sx={{ textFieldHeadingStyleForModal, my: 1 }}>
                  Gender
                </Typography>

                <FormControl fullWidth>
                  <Select
                    size="small"
                    sx={{
                      ...textFieldStyleForModal,
                      color: "black",
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data.gender}
                    required
                    name="gender"
                    onChange={handleChange}
                  >
                    {/* "Male", "Female", "Other" */}
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>
                </FormControl>

                <Typography sx={{ textFieldHeadingStyleForModal, my: 1 }}>
                  Mobile Number
                </Typography>
                <TextField
                  sx={textFieldStyleForModal}
                  size="small"
                  name="phone"
                  type="text"
                  required
                  placeholder="Mobile Number"
                  // sx={textFieldStyle}
                  onChange={handleChange}
                  // value={props.data.contactPersonPhone ? props.data.contactPersonPhone.slice(-10) : ""}
                  // value={edit ? data.phone.slice(-10) : data.phone}
                  value={data.phone}
                  inputProps={{
                    pattern: "[0-9]{10}",
                    maxLength: 10,
                    autoComplete: "tel",
                  }}
                />

                <Box sx={textFieldBox}>
                  <Typography sx={{ color: "grey.main" }}>
                    {" "}
                    Select ASM
                  </Typography>

                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="asm"
                      value={asm._id ? asm._id : data.asm}
                      size="small"
                      onChange={handleChange}
                      sx={selectFieldStyle}
                      disabled={data.asm ? true : false}
                      required
                      // MenuProps={MenuProps}
                    >
                      {asm.map((data) => (
                        <MenuItem key={data._id} value={data._id}>
                          <span style={{ fontWeight: "bold" }}>
                            {data.name}
                          </span>{" "}
                          {data.phone}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    mt: 2,
                  }}
                >
                  <Button variant="contained" type="submit">
                    {edit ? "Edit" : " Create"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Box>
    </>
  );
}

export default Owner;
