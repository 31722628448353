import React from "react";
import SelectRestaurantMenuUpload from "../restaurant/petPoojaMenuItems/selectRestaurant/SelectRestaurantMenuUpload";
const TaxesSelect = () => {
  return (
    <div>
      <SelectRestaurantMenuUpload type={"/i/taxes"} />
    </div>
  );
};

export default TaxesSelect;
