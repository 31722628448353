import React, { useState } from "react";

import {
  Box,
  Grid,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Paper from "@mui/material/Paper";
const DetialModalPetPooja = (props) => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");

  const [itemCount, setItemCount] = useState([]);

  //   if (props.openModal === true && props.counter === 0) {
  //     let orderItemsArr = props.OrderItemsDetails.orderItems.map(
  //       (value, index) => {
  //         return value.itemTitle;
  //       }
  //     );
  //     console.log("orderItemsArr", orderItemsArr);

  //     const counts = {};
  //     orderItemsArr.forEach((x) => {
  //       counts[x] = (counts[x] || 0) + 1;
  //     });

  //     console.log(Object.keys(counts));
  //     console.log(Object.values(counts));
  //     // setItemCount(Object.values(counts));
  //     itemCount.push(Object.values(counts));

  //     console.log("itemCount", itemCount);

  //     let orderItems = orderItemsArr.filter((value, index) => {
  //       return orderItemsArr.indexOf(value) === index;
  //     });
  //     props.setCounter(props.counter + 1);
  //     props.items.push(orderItems);
  //   }

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
  ];

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "550px",
    width: matches ? "40%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 2,
    overflow: "auto",
  };

  return (
    <>
      <Modal
        open={props.openModal}
        onClose={props.handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
            gap={1}
            sx={{ position: "relative" }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "27px",
                color: "rgba(13, 30, 55, 0.67)",
                justifySelf: "start",
              }}
            >
              Item Details
            </Typography>

            <IconButton onClick={props.handleCloseModal} aria-label="delete">
              <CancelIcon />
            </IconButton>
          </Stack>

          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            {/* ---------------------- */}
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 350 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Order Item</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Total Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.OrderItemsDetails?.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.itemTitle ? row.itemTitle : ""}
                      </TableCell>
                      <TableCell align="right">
                        {row.quantity ? row.quantity : ""}
                      </TableCell>
                      <TableCell align="right">
                        {row.quantity ? row.price / row.quantity : ""}
                      </TableCell>
                      <TableCell align="right">
                        {row.price ? row.price : ""}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* ------------------ */}

            {/* <Grid item xs={6}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Customer Name
              </Typography>
            </Grid>

            <Grid item xs={6} textAlign={"right"}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                {props.OrderItemsDetails.customer
                  ? props.OrderItemsDetails.customer.name
                  : "no"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Mobile Number
              </Typography>
            </Grid>

            <Grid item xs={6} textAlign={"right"}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                {props.OrderItemsDetails.customer
                  ? props.OrderItemsDetails.customer.phone
                  : "no"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Status
              </Typography>
            </Grid>

            <Grid item xs={6} textAlign={"right"}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                {props.OrderItemsDetails
                  ? props.OrderItemsDetails.status
                  : "no"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Order Quantity
              </Typography>
            </Grid>

            <Grid item xs={6} textAlign={"right"}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                {props.OrderItemsDetails.orderItems
                  ? props.OrderItemsDetails.orderItems.length
                  : "no"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Order OTP
              </Typography>
            </Grid>

            <Grid item xs={6} textAlign={"right"}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                {props.OrderItemsDetails ? props.OrderItemsDetails.otp : "no"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Items
              </Typography>
            </Grid>

            <Grid item xs={6} textAlign={"right"}>
              {props.items.map((row, index) => (
                <>
                  <Typography
                    key={index}
                    variant="h6"
                    fontWeight={500}
                    fontSize="16px"
                    sx={{ color: "grey.main" }}
                  >
                    {row ? `${row},  ` : "no"}
                 
                  </Typography>
                </>
              ))}
            </Grid>

            <Grid item xs={6}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Restaurant Name
              </Typography>
            </Grid>

            <Grid item xs={6} textAlign={"right"}>
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                {props.OrderItemsDetails.restaurant
                  ? props.OrderItemsDetails.restaurant.brand_display_name
                  : "no"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Merchant Number
              </Typography>
            </Grid>

            <Grid item xs={6} textAlign={"right"}>
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                {props.OrderItemsDetails.restaurant
                  ? props.OrderItemsDetails.restaurant.merchant_number
                  : "no"}
              </Typography>
            </Grid> */}
            {/* <Grid item xs={6}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Order Created At
              </Typography>
            </Grid> */}
            {/* <Grid item xs={6} textAlign={"right"}>
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                {props.OrderItemsDetails
                  ? moment(props.OrderItemsDetails.updatedAt?.value).format(
                      "DD/MM/YYYY hh:mm A"
                    )
                  : "no"}
              </Typography>
            </Grid> */}
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default DetialModalPetPooja;
