import {
  Box,
  Button,
  Input,
  Modal,
  Paper,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AdminDataContext } from "./../useContext/AdminContext";
import moment from "moment/moment";
import { imgUrl } from "../url";
import OrderItemsDetailsModal from "./OrderItemsDetailsModal";

function CouponUsedOrders() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setLog([]);
  };

  const [openModal, setOpenModal] = useState(false);
  const [items, setItems] = useState([]);
  const [counter, setCounter] = useState(0);
  const [OrderItemsDetails, setOrderItemsDetails] = useState({});
  const handleOpenModal = (data) => {
    setOrderItemsDetails(data);
    setItems([]);

    console.log("OrderItemsDetails", data);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setItems([]);
    setCounter(0);
    setOpenModal(false);
  };

  const [openReject, setOpenReject] = useState(false);
  const [rejectionReject, setRejectionReject] = useState({
    rejectionReject: "",
    orderId: "",
  });
  const handleOpenReject = (id) => {
    setOpenReject(true);
    setRejectionReject({ ...rejectionReject, orderId: id });
  };
  const handleCloseReject = () => {
    setOpenReject(false);
    setRejectionReject({ rejectionReject: "", orderId: "" });
  };

  const { snackbar, setIsLoading } = useContext(AdminDataContext);
  const [complain, setComplain] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [log, setLog] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTimeDeference = (first, second) => {
    var a = moment(first);
    var b = moment(second);

    return a.diff(b, "minutes");
  };
  const acceptOrder = async (id) => {
    try {
      setLoading(true);
      const res = await axios.post("/api/v1/admin/order/accept", {
        orderId: id,
      });
      setLoading(false);
      getAllOrders(page, limit, "", "");
      snackbar(res.data.message, "success");
    } catch (error) {
      setLoading(false);
      snackbar(error.response.data.message, "error");
    }
  };
  const readyOrder = async (id) => {
    try {
      setLoading(true);
      const res = await axios.post("/api/v1/admin/order/ready", {
        orderId: id,
      });
      setLoading(false);
      getAllOrders(page, limit, "", "");
      snackbar(res.data.message, "success");
    } catch (error) {
      setLoading(false);
      snackbar(error.response.data.message, "error");
    }
  };

  const rejectOrder = async (orderId, rejectionReject) => {
    try {
      setLoading(true);
      const res = await axios.post("/api/v1/admin/order/reject", {
        orderId,
        rejectionReject,
      });
      setLoading(false);
      getAllOrders(page, limit, "", "");
      snackbar(res.data.message, "success");
      handleCloseReject();
    } catch (error) {
      setLoading(false);
      snackbar(error.response.data.message, "error");
    }
  };

  const columns = [
    {
      field: "orderId",
      headerName: "Order Id",
      width: 100,
    },
    {
      field: "Platform",
      headerName: "Platform",
      width: 100,

      renderCell: (params) => {
        return params.row.restaurant.petPooja ? (
          <Typography>petPooja</Typography>
        ) : (
          <>Ybites</>
        );
      },
    },

    {
      field: "view-details",
      headerName: "View Details",
      width: 150,
      renderCell: (params) => {
        return (
          <Button onClick={() => handleOpenModal(params.row)}>
            {" "}
            View Details
          </Button>
        );
      },
    },

    {
      field: "customer",
      headerName: "customer",
      width: 150,

      renderCell: (params) => {
        return params.row.customer ? (
          <Typography>{params.row.customer.name}</Typography>
        ) : (
          <>-</>
        );
      },
    },

    {
      field: "Item Value",
      headerName: "Item Value",
      width: 150,

      renderCell: (params) => {
        return params.row.totalPrice ? (
          <Typography>{params.row.totalPrice}</Typography>
        ) : (
          <>-</>
        );
      },
    },

    {
      field: "Coupon Used",
      headerName: "Coupon Used",
      width: 150,

      renderCell: (params) => {
        return params.row.coupon ? (
          <Typography>{params.row.coupon.code}</Typography>
        ) : (
          <>-</>
        );
      },
    },

    {
      field: "Amount After Discount On Item",
      headerName: "Amount After Discount On Item",
      width: 220,

      renderCell: (params) => {
        return params.row.grandTotalPrice ? (
          <Typography>{params.row.grandTotalPrice}</Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "Delivery Charge",
      headerName: "Delivery Charge",
      width: 150,

      renderCell: (params) => {
        return params.row.deliveryCharge ? (
          <Typography>{params.row.deliveryCharge}</Typography>
        ) : (
          <>-</>
        );
      },
    },

    //   ------------
    {
      field: "GST",
      headerName: "GST",
      width: 100,

      renderCell: (params) => {
        return params.row.grandTotalTaxes ? (
          <Typography>{params.row.grandTotalTaxes}</Typography>
        ) : (
          <>-</>
        );
      },
    },

    {
      field: "Packing",
      headerName: "Packing",
      width: 150,

      renderCell: (params) => {
        return params.row.packagingCharge ? (
          <Typography>{params.row.packagingCharge}</Typography>
        ) : (
          <>-</>
        );
      },
    },

    {
      field: "Total Amount Collected from Customer",
      headerName: "Total Amount Collected from Customer",
      width: 260,

      renderCell: (params) => {
        return params.row.paymentAmount ? (
          <Typography>{params.row.paymentAmount}</Typography>
        ) : (
          <>-</>
        );
      },
    },

    {
      field: "Actual value",
      headerName: "Actual value",
      width: 150,

      renderCell: (params) => {
        return params.row.paymentAmount ? (
          <Typography>
            {params.row.paymentAmount + params.row.discount}
          </Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "Amount Beared By the Ybites",
      headerName: "Amount Beared By the Ybites",
      width: 200,

      renderCell: (params) => {
        return params.row.discount ? (
          <Typography>{params.row.discount}</Typography>
        ) : (
          <>-</>
        );
      },
    },

    {
      field: "createdAt",
      headerName: "Order Created At",
      width: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },

    {
      field: "Restaurant",
      headerName: "Restaurant",
      width: 300,

      renderCell: (params) => {
        return params.row.restaurant ? (
          <Typography>{params.row.restaurant.brand_display_name}</Typography>
        ) : (
          <>-</>
        );
      },
    },

    // {
    //   field: "paymentAmount",
    //   headerName: "Payment Amount",
    //   width: 150,

    //   renderCell: (params) => {
    //     return params.row.paymentAmount ? (
    //       <Typography>{params.row.paymentAmount}</Typography>
    //     ) : (
    //       <>-</>
    //     );
    //   },
    // },

    // {
    //   field: "orderItems",
    //   headerName: "Items ",
    //   width: 250,

    //   renderCell: (params) => {
    //     return !(params.row.orderItems?.length === 0) ? (
    //       <Typography>
    //         {params.row.orderItems[0].itemTitle}
    //         {params.row.orderItems.length >= 2
    //           ? `, +${params.row.orderItems.length - 1} items`
    //           : ""}
    //       </Typography>
    //     ) : (
    //       <>-</>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    getAllOrders(page, limit, "", "");
  }, []);

  const getAllOrders = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/orders?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}`
      );

      let couponOrder = res.data.data.filter((order) => {
        return order.coupon != null;
      });
      console.log("couponOrder", couponOrder);
      setComplain(couponOrder);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSortModelChange = async (item) => {
    getAllOrders(page, limit, "", "");
    if (item.length === 0) {
      getAllOrders(page, limit, "", "");
    } else {
      getAllOrders(page, limit, item[0].sort, item[0].field);
    }
  };

  const onFilterChange = async (e) => {
    console.log(e.items);
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/admin/order/filter`, {
        key: e.items[0].columnField,
        value: e.items[0].value,
      });
      setComplain(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  console.log("coupons order", complain);
  // onClick={createComplainHandler}
  return (
    <Box>
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        autoHeight
        rows={complain}
        getRowId={(row) => row._id}
        rowCount={total}
        loading={loading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={page - 1}
        pageSize={limit}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        onPageChange={(newPage) => {
          getAllOrders(newPage + 1, limit, "", "");
          setPage(newPage + 1);
        }}
        onPageSizeChange={(newPageSize) => {
          getAllOrders(page, newPageSize, "", "");
          setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={columns}
      />
      <OrderItemsDetailsModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        OrderItemsDetails={OrderItemsDetails}
        items={items}
        setItems={setItems}
        counter={counter}
        setCounter={setCounter}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Amount</TableCell>
                  <TableCell align="center">Date And Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {log.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {index + 1}
                    </TableCell>

                    <TableCell align="center">{row.amountSubmitted}</TableCell>
                    <TableCell align="center">
                      {new Date(row.date).toLocaleString("en-GB", {
                        timeZone: "IST",
                      })}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
      <Modal
        open={openReject}
        onClose={handleCloseReject}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reject Rejection
          </Typography>
          <TextField
            id="outlined-basic"
            label="Outlined"
            onClick={(e) => {
              setRejectionReject({
                ...rejectionReject,
                rejectionReject: e.target.value,
              });
            }}
            variant="outlined"
          />
          <Button
            onClick={() =>
              rejectOrder(
                rejectionReject.orderId,
                rejectionReject.rejectionReject
              )
            }
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default CouponUsedOrders;
