import {
  Box,
  Button,
  Grid,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { textFieldStyleForModal } from "../cssStyling/Style";
import DragsAndDrop from "../../reusableComponent/imageUpload/DragsAndDrop";

const NotificationSingleModal = (props) => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");
  //   const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const handleChange = (event) => {
    props.setData({ ...props.data, [event.target.name]: event.target.value });
    console.log(props.data);
  };

  const handleSendNotification = async (event) => {
    props.sendNotification(event, props.data, props.image);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "30%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
  };
  return (
    <div>
      <Modal
        open={props.openModal}
        onClose={props.handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid
              item
              xs={12}
              component="form"
              onSubmit={handleSendNotification}
            >
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Title *
              </Typography>
              <TextField
                // id="outlined-basic"
                fullWidth
                size="small"
                value={props.data.title}
                name="title"
                required
                variant="outlined"
                onChange={handleChange}
                sx={textFieldStyleForModal}
              />
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Message *
              </Typography>
              <TextField
                // id="outlined-basic"
                fullWidth
                multiline
                rows={4}
                required
                size="small"
                value={props.data.message}
                name="message"
                variant="outlined"
                onChange={handleChange}
                sx={textFieldStyleForModal}
              />
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{ color: "grey.main" }}
                >
                  Image
                </Typography>
                <DragsAndDrop
                  heading="Upload Image"
                  inputName="Image"
                  aspect={1.8 / 1}
                  uploadFile={(x) => {
                    props.setImage(x);
                  }}
                />
              </Grid>

              <Grid container spacing={2} px={3} mt={3}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",

                    justifyContent: { xs: "center" },
                    alignItems: "end",
                  }}
                >
                  <Button type="submit" variant="contained">
                    Send Notification
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default NotificationSingleModal;
