import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Input,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useState, useContext } from "react";
import Papa from "papaparse";
import { AdminDataContext } from "./../../useContext/AdminContext";

const MenuItemUpload = () => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [restaurants, setRestaurants] = useState([]);
  const [restaurant, setRestaurant] = useState();
  const [menuJson, setMenuJson] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const createMenuItemFromCSVHandler = async () => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `/api/v1/admin/restaurant/menuItem/csv?restaurantId=${restaurant._id}`,
        { menuJson }
      );
      snackbar(res.data.message, "success");
      handleClose();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      snackbar(error.response.data.message, "error");
    }
  };
  const handleClose = () => {
    setOpen(false);
    setRestaurants([]);
    setRestaurant();
    setMenuJson([]);
  };
  const getRestaurantData = async (e) => {
    try {
      const res = await axios.get(
        `/api/v1/admin/restaurants/search?query=${e.target.value}`
      );

      if (!(res.data.data.length === 0)) {
        setRestaurants(res.data.data);
      } else {
        setRestaurants([]);
      }
    } catch (error) {}
  };
  const convertCSVtoObjectHandler = (e) => {
    const files = e.target.files;
    const data = [];
    if (files) {
      console.log(files[0]);
      Papa.parse(files[0], {
        complete: function (results) {
          console.log("Finished:", results.data);
          for (let index = 1; index < results.data.length - 1; index++) {
            const element = results.data[index];
            const title = results.data[0];
            if (element.length === 8) {
              if (
                element[2] === "Veg" ||
                element[2] === "Non-Veg" ||
                element[2] === "Jain"
              ) {
                if (
                  !(
                    element[0] === "" ||
                    element[2] === "" ||
                    element[3] === "" ||
                    element[4] === "" ||
                    element[5] === "" ||
                    element[6] === ""
                  )
                ) {
                  data.push({
                    [title[0]]: element[0],
                    [title[1]]: element[1] === "" ? NaN : element[1],
                    [title[2]]: element[2],
                    [title[3]]: element[3],
                    [title[4]]: element[4],
                    [title[5]]: element[5],
                    [title[6]]: element[6],
                    [title[7]]: element[7] === "" ? NaN : element[7],
                  });
                } else {
                  snackbar("Data is missing in file", "error");
                  break;
                }
              } else {
                snackbar("Invalid Menu Item Type", "error");
                break;
              }
            } else {
              snackbar("Invalid file", "error");
              break;
            }
          }
          setMenuJson(data);
          handleOpen(true);
        },
      });
    }
  };
  return (
    <Box>
      <Autocomplete
        // multiple
        aria-required
        fullWidth
        id="tags-outlined"
        options={restaurants}
        getOptionLabel={(userData) =>
          `${userData.name} Address : ${userData.address.completeAddress}`
        }
        onInputChange={getRestaurantData}
        onChange={(event, value) => {
          console.log(value);
          setRestaurant(value);
        }}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Restaurant"
            placeholder="Select Restaurant"
          />
        )}
      />
      {restaurant ? (
        <Box>
          <input
            type="file"
            accept=".csv"
            onChange={convertCSVtoObjectHandler}
          />
        </Box>
      ) : (
        <></>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {!(menuJson.length === 0) ? (
            <TableContainer
              component={Paper}
              sx={{ overflow: "scroll", height: "95%" }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell align="center">Title</TableCell>
                    <TableCell align="center">Description</TableCell>
                    <TableCell align="center">Menu Item Type</TableCell>
                    <TableCell align="center">Price</TableCell>
                    <TableCell align="center">Unit Price</TableCell>
                    <TableCell align="center">Preparation Time</TableCell>
                    <TableCell align="center">Menu Category</TableCell>
                    <TableCell align="center">Menu Sub Category</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {menuJson.map((row, index) => (
                    <TableRow
                      key={row.index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center" component="th" scope="row">
                        {index + 1}
                      </TableCell>

                      <TableCell align="center">{row.title}</TableCell>
                      <TableCell align="center">{row.description}</TableCell>
                      <TableCell align="center">{row.menuItemType}</TableCell>
                      <TableCell align="center">{row.price}</TableCell>
                      <TableCell align="center">{row.unitPrice}</TableCell>
                      <TableCell align="center">
                        {row.preparationTime}
                      </TableCell>
                      <TableCell align="center">{row.menuCategory}</TableCell>
                      <TableCell align="center">
                        {row.menuSubCategory ? row.menuSubCategory : "none"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <>No data found in File</>
          )}
          <Stack spacing={2}>
            <Button onClick={createMenuItemFromCSVHandler} variant="contained">
              Submit
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};
const style = {
  height: "80%",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default MenuItemUpload;
