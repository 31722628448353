import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import React, { useContext } from "react";
import { Grid, Divider, Box } from "@mui/material";

import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { TextField } from "@mui/material";

import axios from "axios";
// import { AdminDataContext } from "./../../useContext/AdminContext";

import moment from "moment";

const weeks = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const OutletTiming = (props) => {
  // const { setIsLoading, snackbar } = useContext(AdminDataContext);

  const submitTimeHandler = async (e) => {
    try {
      props.setIsLoading(true);

      const res = await axios.post(
        `/api/v1/admin/restaurant/time/?restaurantId=${props.time.restaurant}`,
        { ...props.time }
      );
      props.getAllRestaurant(props.page, props.limit, "", "");
      props.setTimingModal(false);
      props.setIsLoading(false);
      props.snackbar(res.data.message, "success");
    } catch (error) {
      props.snackbar(error.response.data.message, "error");
      props.setIsLoading(false);
      console.log(error);
    }
  };
  const createDateValue = (hh, mm) => {
    return moment(`2018-01-01 ${hh}:${mm}`);
  };
  const timeChangeHandler = (newTimeValue, item, slot, otherSlot) => {
    props.setTime({
      ...props.time,
      [item]: {
        ...otherSlot,
        [slot]: {
          // ...[slot],
          hours: newTimeValue.hours(),
          min: newTimeValue.minute(),
        },
      },
    });
  };

  return (
    <Box
      component="form"
      //  onSubmit={(e) => onTimeSubmit(e)}
    >
      <Grid
        container
        spacing={2}
        mb={1}
        sx={{ display: "flex", justifyContent: "center", mb: 2, mt: 2 }}
      >
        <Grid
          item
          xs={4}
          sm={3}
          md={3}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            sx={{
              color: "color: rgba(34, 34, 34, 0.79);",
              fontWeight: 500,
              fontSize: "20px",
            }}
          >
            Days
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={3}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "color: rgba(34, 34, 34, 0.79);",
              fontWeight: 500,
              fontSize: "20px",
            }}
          >
            Start Time
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={3}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "color: rgba(34, 34, 34, 0.79);",
              fontWeight: 500,
              fontSize: "20px",
            }}
          >
            End Time
          </Typography>
        </Grid>
      </Grid>

      {weeks.map((item, index) => (
        <>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid
              item
              xs={4}
              sm={3}
              md={3}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                sx={{
                  color: "#FF8A00",
                  fontWeight: 700,
                  fontSize: "20px",
                  textTransform: "uppercase",
                }}
              >
                {item[0].toUpperCase() + item.substring(1)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sm={4}
              md={3}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <LocalizationProvider border={2} dateAdapter={AdapterMoment}>
                <MobileTimePicker
                  // label="Select Time"
                  sx={{ width: "15ch" }}
                  minutesStep={1}
                  value={createDateValue(
                    props.time[item].startTime.hours,
                    props.time[item].startTime.min
                  )}
                  onChange={(newValue) => {
                    timeChangeHandler(newValue, item, "startTime", {
                      endTime: props.time[item].endTime,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              {/* <Typography
                    sx={{
                      color: "rgba(13, 30, 55, 0.63)",
                      fontWeight: 400,
                      fontSize: "16px",
                    }}
                  >
                    to
                  </Typography> */}
            </Grid>

            <Grid
              item
              xs={4}
              sm={4}
              md={3}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileTimePicker
                  // label="Select Time"

                  sx={{ width: "15ch" }}
                  minutesStep={1}
                  value={createDateValue(
                    props.time[item].endTime.hours,
                    props.time[item].endTime.min
                  )}
                  onChange={(newValue) => {
                    timeChangeHandler(newValue, item, "endTime", {
                      startTime: props.time[item].startTime,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>

          {item[0].toUpperCase() + item.substring(1) === "Saturday" ? (
            <></>
          ) : (
            <Divider sx={{ mt: 1, mb: 1 }} />
          )}
        </>
      ))}

      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "end" },
        }}
      >
        <Button
          id="sign-in-button"
          sx={{
            my: 3,
            background:
              "linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)",
          }}
          variant="contained"
          // type="submit"
          size="large"
          onClick={submitTimeHandler}
        >
          Update Time
        </Button>
      </Grid>
    </Box>
  );
};

export default OutletTiming;
