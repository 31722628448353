import React, { useState, useContext, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";

import { AdminDataContext } from "./../../useContext/AdminContext";
import axios from "axios";

import NotificationSingleModal from "./NotificationSingleModal";
import { DataGrid } from "@mui/x-data-grid";
import { imgUrl } from "../../url";

const RestaurantNotification = () => {
  const [data, setData] = useState({
    title: "",
    message: "",
  });
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [image, setImage] = useState("");

  const getNotification = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/notification/restaurant?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}`
      );
      setNotification(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getNotification(page, limit, "", "");
  }, []);

  const handleSortModelChange = async (item) => {
    getNotification(page, limit, "", "");
    if (item.length === 0) {
      getNotification(page, limit, "", "");
    } else {
      getNotification(page, limit, item[0].sort, item[0].field);
    }
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 150,
    },

    {
      field: "description",
      headerName: "Description",
      width: 250,
    },

    {
      field: "Image",
      headerName: "Image",
      width: 300,
      renderCell: (params) => {
        return (
          <Box
            height={"100%"}
            component="img"
            src={`${imgUrl}/${params.row.image}`}
            alt=""
          />
        );
      },
    },
  ];

  const sendNotification = async (event, data, image) => {
    event.preventDefault();
    try {
      if (image) {
        setIsLoading(true);
        let formData = new FormData();
        formData.append("image", image);
        formData.append("title", data.title);
        formData.append("message", data.message);

        const res = await axios.post(
          `/api/v1/admin/notification/restaurant`,
          formData
        );
        setData({ title: "", message: "" });
        setImage("");
        snackbar(res.data.message, "success");
        setIsLoading(false);
        handleCloseModal();
      } else {
        setIsLoading(true);

        const res = await axios.post(`/api/v1/admin/notification/restaurant`, {
          ...data,
        });
        setImage("");
        setData({ title: "", message: "" });
        snackbar(res.data.message, "success");
        setIsLoading(false);
        handleCloseModal();
      }
    } catch (error) {
      snackbar(error.response.data.message, "error");

      setIsLoading(false);
    }
  };
  return (
    <Box>
      <Button onClick={handleOpenModal} variant="contained">
        Send Notification for Restaurants
      </Button>

      <Box p={2}>
        <DataGrid
          //   components={{ Toolbar: GridToolbar }}
          autoHeight
          rows={notification}
          getRowId={(row) => row._id}
          rowCount={total}
          loading={loading}
          rowsPerPageOptions={[10, 30, 50, 70, 100]}
          pagination
          page={page - 1}
          pageSize={limit}
          paginationMode="server"
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          filterMode="server"
          // onFilterModelChange={onFilterChange}
          onPageChange={(newPage) => {
            getNotification(newPage + 1, limit, "", "");
            setPage(newPage + 1);
          }}
          onPageSizeChange={(newPageSize) => {
            getNotification(page, newPageSize, "", "");
            setLimit(newPageSize);
            //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
          }}
          columns={columns}
        />
      </Box>

      <NotificationSingleModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        data={data}
        setImage={setImage}
        image={image}
        setData={setData}
        sendNotification={sendNotification}
      />
    </Box>
  );
};

export default RestaurantNotification;
