import {
  Box,
  Button,
  Input,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AdminDataContext } from "./../../useContext/AdminContext";

function RestaurantTicket() {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [solution, setSolution] = useState("");

  const [complain, setComplain] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      field: "ticketId",
      headerName: "Ticket Id",
      width: 150,
    },
    {
      field: "title",
      headerName: "Title",
      width: 250,
    },
    {
      field: "description",
      headerName: "Description",
      width: 250,
    },

    {
      field: "brand_display_name",
      headerName: "Restaurant",
      width: 300,
      renderCell: (params) => {
        return (
          <Typography>
            {params.row.restaurant
              ? params.row.restaurant.brand_display_name
              : ""}
          </Typography>
        );
      },
    },

    {
      field: "Solution",
      headerName: "Solution",
      width: 450,
      renderCell: (params) => {
        const solutionHandler = async (e) => {
          e.preventDefault();
          try {
            setLoading(true);
            const response = await axios.post(
              `/api/v1/admin/restaurant/ticket?restaurantTicketId=${params.row._id}`,
              { solution }
            );

            snackbar(response.data.message, "success");
            getAllComplain(page, limit, "", "");
            setSolution("");
            setLoading(false);
          } catch (error) {
            snackbar(error.response.data.message, "error");
            setLoading(false);
          }
        };

        return params.row.solution ? (
          <Typography>{params.row.solution}</Typography>
        ) : (
          <Box component={"form"} onSubmit={solutionHandler}>
            <TextField
              id="outlined-basic"
              label="Solution"
              type="text"
              variant="outlined"
              size="small"
              onKeyDown={(event) => {
                console.log("Key down: ", event.key);
                event.stopPropagation();
              }}
              onChange={(e) => setSolution(e.target.value)}
              required
            />
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </Box>
        );
      },
    },
    {
      field: "satisfaction",
      headerName: "Satisfaction",
      width: 250,
    },
    {
      field: "Rating",
      headerName: "Rating",
      width: 150,
      renderCell: (params) => {
        return <Rating name="read-only" value={params.row.rating} readOnly />;
      },
    },
  ];

  useEffect(() => {
    getAllComplain(page, limit, "", "");
  }, []);

  const getAllComplain = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/restaurant/ticket?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}`
      );
      setComplain(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSortModelChange = async (item) => {
    getAllComplain(page, limit, "", "");
    if (item.length === 0) {
      getAllComplain(page, limit, "", "");
    } else {
      getAllComplain(page, limit, item[0].sort, item[0].field);
    }
  };

  const onFilterChange = async (e) => {
    console.log(e);
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/admin/get/complains/search`, {
        key: e.items[0].columnField,
        value: e.items[0].value,
      });
      setComplain(res.data.complain);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  // onClick={createComplainHandler}
  return (
    <Box>
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        autoHeight
        rows={complain}
        getRowId={(row) => row._id}
        rowCount={total}
        loading={loading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={page - 1}
        pageSize={limit}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        onPageChange={(newPage) => {
          getAllComplain(newPage + 1, limit, "", "");
          setPage(newPage + 1);
        }}
        onPageSizeChange={(newPageSize) => {
          getAllComplain(page, newPageSize, "", "");
          setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={columns}
      />
    </Box>
  );
}

export default RestaurantTicket;
