import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { AdminDataContext } from "./../../useContext/AdminContext";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { saveAs } from "file-saver";
import { imgUrl } from "../../url";
import OutletTiming from "./OutletTiming";
import DragsAndDrop from "./../../reusableComponent/imageUpload/DragsAndDrop";
import {
  textFieldHeadingStyleForModal,
  topHeading,
  textFieldStyleForModal,
} from "../cssStyling/Style";
var FileSaver = require("file-saver");
function PetPoojaRestaurant() {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");
  const navigator = useNavigate();
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [restaurant, setRestaurant] = useState([]);
  const [currentRestaurant, setCurrentRestaurant] = useState(null);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [bankData, setBankData] = useState({});
  const [addressData, setAddressData] = useState({});
  const [bankModel, setBankModel] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [timingModal, setTimingModal] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [editData, setEditData] = useState({});
  const [image, setImage] = useState(null);
  const [imageKey, setImageKey] = useState("");

  const [time, setTime] = useState({
    sunday: {
      startTime: { hours: 10, min: 10 },
      endTime: { hours: 12, min: 59 },
    },
    monday: {
      startTime: { hours: 9, min: 0 },
      endTime: { hours: 12, min: 59 },
    },
    tuesday: {
      startTime: { hours: 1, min: 12 },
      endTime: { hours: 1, min: 17 },
    },
    wednesday: {
      startTime: { hours: 9, min: 0 },
      endTime: { hours: 12, min: 59 },
    },
    thursday: {
      startTime: { hours: 9, min: 0 },
      endTime: { hours: 12, min: 59 },
    },
    friday: {
      startTime: { hours: 9, min: 0 },
      endTime: { hours: 12, min: 59 },
    },
    saturday: {
      startTime: { hours: 9, min: 0 },
      endTime: { hours: 12, min: 59 },
    },
  });

  const editHandler = (data) => {
    setEditData({
      latitude: data.location.coordinates[1],
      longitude: data.location.coordinates[0],
      _id: data._id,
    });
    setImageKey(data.restaurant_logo);
    setEditModel(true);
  };

  const timingHandler = async (data) => {
    try {
      setTime(data.timing);
      setTimingModal(true);
    } catch (error) {}
  };
  const verifiedBankHandler = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `/api/v1/admin/restaurants/bank?bankDetailId=${bankData._id}`
      );

      snackbar(response.data.message, "success");
      getAllRestaurant(page, limit, "", "");
      setLoading(false);
      setBankModel(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "30%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
  };
  const columns = [
    {
      field: "logo",
      headerName: "Logo",
      width: 110,
      renderCell: (params) => {
        return params.row.restaurant_logo ? (
          <>
            <Box
              height={"100%"}
              component="img"
              src={
                params.row.restaurant_logo.startsWith("http")
                  ? `${params.row.restaurant_logo}`
                  : `${imgUrl}/${params.row.restaurant_logo}`
              }
            />
          </>
        ) : (
          "No data available"
        );
      },
    },
    {
      field: "brand_display_name",
      headerName: "Name",
      width: 250,
    },
    {
      field: "owner_name",
      headerName: "Owner Name",
      width: 250,
    },
    {
      field: "outlet_id",
      headerName: "Outlet Id",
      width: 250,
    },
    {
      field: "business_contact",
      headerName: "Business Contact",
      width: 250,
    },

    {
      field: "merchant_number",
      headerName: "Merchant Number",
      width: 250,
    },
    {
      field: "address",
      headerName: "Address",
      width: 250,
    },
    {
      field: "pincode",
      headerName: "Pincode",
      width: 250,
    },
    {
      field: "city",
      headerName: "City",
      width: 250,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
    },
    {
      field: "open",
      headerName: "open",
      width: 100,
    },
    {
      field: "rating",
      headerName: "Rating",
      width: 100,
    },
    {
      field: "ratingCount",
      headerName: "ratingCount",
      width: 100,
    },

    {
      field: "appVisible",
      headerName: "App Visible",
      width: 120,
      renderCell: (params) => {
        const actionSwitchToggle = async (id, appVisible) => {
          try {
            setLoading(true);
            const response = await axios.post(
              `/api/v1/admin/restaurants/appVisible?restaurantId=${id}`,
              {
                appVisible,
              }
            );

            snackbar(response.data.message, "success");
            getAllRestaurant(page, limit, "", "");
            setLoading(false);
          } catch (error) {
            snackbar(error.response.data.message, "error");
            setLoading(false);
          }
        };

        return (
          <Switch
            checked={params.row.appVisible ? true : false}
            onChange={(e) =>
              actionSwitchToggle(params.row._id, e.target.checked)
            }
          />
        );
      },
    },
    {
      field: "gstCertificate",
      headerName: "GST",
      width: 250,
      renderCell: (params) => {
        return params.row.gst_no ? (
          <>
            <Typography>{params.row.gst_no}</Typography>
            <CloudDownloadIcon
              onClick={() =>
                FileSaver.saveAs(
                  params.row.gst_image.startsWith("http")
                    ? `${params.row.gst_image}`
                    : `${imgUrl}/${params.row.gst_image}`,
                  "image.jpg"
                )
              }
            />
          </>
        ) : (
          "No data available"
        );
      },
    },
    {
      field: "aadhar",
      headerName: "Aadhar",
      width: 250,
      renderCell: (params) => {
        return params.row.aadhar_no ? (
          <>
            <Typography>{params.row.aadhar_no}</Typography>
            <CloudDownloadIcon
              onClick={() =>
                FileSaver.saveAs(
                  params.row.aadhar_image.startsWith("http")
                    ? `${params.row.aadhar_image}`
                    : `${imgUrl}/${params.row.aadhar_image}`,
                  "image.jpg"
                )
              }
            />
          </>
        ) : (
          "No data available"
        );
      },
    },
    {
      field: "pan",
      headerName: "Pan Card",
      width: 250,
      renderCell: (params) => {
        return params.row.pan_no ? (
          <>
            <Typography>{params.row.pan_no}</Typography>
            <CloudDownloadIcon
              onClick={() =>
                FileSaver.saveAs(
                  params.row.pan_image.startsWith("http")
                    ? `${params.row.pan_image}`
                    : `${imgUrl}/${params.row.pan_image}`,
                  "image.jpg"
                )
              }
            />
          </>
        ) : (
          "No data available"
        );
      },
    },

    {
      field: "fssai_image",
      headerName: "Fssai",
      width: 250,
      renderCell: (params) => {
        return params.row.fssai_no ? (
          <>
            <Typography>{params.row.fssai_no}</Typography>
            <CloudDownloadIcon
              onClick={() =>
                FileSaver.saveAs(
                  params.row.fssai_image.startsWith("http")
                    ? `${params.row.fssai_image}`
                    : `${imgUrl}/${params.row.fssai_image}`,
                  "image.jpg"
                )
              }
            />
          </>
        ) : (
          "No data available"
        );
      },
    },
    {
      field: "attributes",
      headerName: "attributes",
      width: 250,
      renderCell: (params) => {
        let string = "";
        for (let index = 0; index < params.row.attributes.length; index++) {
          const element = params.row.attributes[index];
          if (!string) {
            string = element.attribute;
          } else {
            string = `${string}, ${element.attribute}`;
          }
        }
        return <Typography>{string}</Typography>;
      },
    },
    {
      field: "location",
      headerName: "Location",
      width: 120,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              const url = `http://maps.google.com/maps?z=12&t=m&q=loc:${params.row.location.coordinates[1]}+${params.row.location.coordinates[0]}`;
              window.open(url, "_blank");
            }}
          >
            Location
          </Button>
        );
      },
    },
    {
      field: "taxes",
      headerName: "taxes",
      width: 250,
      renderCell: (params) => {
        let string = "";
        for (let index = 0; index < params.row.taxes.length; index++) {
          const element = params.row.taxes[index];
          if (!string) {
            string = `${element.taxname} => ${element.tax}`;
          } else {
            string = `${string}, ${`${element.taxname} => ${element.tax}`}`;
          }
        }
        return <Typography>{string}</Typography>;
      },
    },
    // {
    //   field: "avgCost",
    //   headerName: "Avg. Cost",
    //   width: 80,
    // },
    // {
    //   field: "visitable",
    //   headerName: "App Visible",
    //   width: 120,
    //   renderCell: (params) => {
    //     const actionSwitchToggle = async (id, visitable) => {
    //       try {
    //         setLoading(true);
    //         const response = await axios.post(
    //           `/api/v1/admin/restaurants/visitable?restaurantId=${id}`,
    //           {
    //             visitable,
    //           }
    //         );

    //         snackbar(response.data.message, "success");
    //         getAllRestaurant(page, limit, "", "");
    //         setLoading(false);
    //       } catch (error) {
    //         snackbar(error.response.data.message, "error");
    //         setLoading(false);
    //       }
    //     };

    //     return (
    //       <Switch
    //         checked={params.row.visitable ? true : false}
    //         onChange={(e) =>
    //           actionSwitchToggle(params.row._id, e.target.checked)
    //         }
    //       />
    //     );
    //   },
    // },
    // {
    //   field: "veg",
    //   headerName: "Veg",
    //   width: 100,
    // },
    // {
    //   field: "nonVeg",
    //   headerName: "NonVeg",
    //   width: 100,
    // },

    // {
    //   field: "restaurantType",
    //   headerName: "Type",
    //   width: 100,
    // },
    // {
    //   field: "gstCertificate",
    //   headerName: "gstCertificate",
    //   width: 250,
    //   renderCell: (params) => {
    //     return params.row.gstCertificate ? (
    //       <>
    //         <Typography>{params.row.gstCertificate.gstNumber}</Typography>
    //         <CloudDownloadIcon
    //           onClick={() =>
    //             FileSaver.saveAs(
    //               `${imgUrl}/${params.row.gstCertificate.documentFile}`,
    //               "image.jpg"
    //             )
    //           }
    //         />
    //       </>
    //     ) : (
    //       "No data available"
    //     );
    //   },
    // },

    // {
    //   field: "fssaiCertificate",
    //   headerName: "fssaiCertificate",
    //   width: 200,
    //   renderCell: (params) => {
    //     return params.row.fssaiCertificate ? (
    //       <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
    //         <Typography>{params.row.fssaiCertificate.certificateNo}</Typography>

    //         <CloudDownloadIcon
    //           onClick={() =>
    //             FileSaver.saveAs(
    //               `${imgUrl}/${params.row.fssaiCertificate.documentFile}`,
    //               "image.jpg"
    //             )
    //           }
    //         />
    //       </Box>
    //     ) : (
    //       "No data available"
    //     );
    //   },
    // },
    // {
    //   field: "menuItemFile",
    //   headerName: "Menu Item File",
    //   width: 250,
    //   renderCell: (params) => {
    //     return params.row.menuItemsFile ? (
    //       <>
    //         <Button
    //           onClick={() =>
    //             FileSaver.saveAs(
    //               `${imgUrl}/${params.row.menuItemsFile}`,
    //               "image.jpg"
    //             )
    //           }
    //         >
    //           Download
    //         </Button>
    //       </>
    //     ) : (
    //       "No data available"
    //     );
    //   },
    // },
    // {
    //   field: "menuItems",
    //   headerName: "MenuItem",
    //   width: 120,
    //   renderCell: (params) => {
    //     return !(params.row.menuItems.length === 0) ? (
    //       <Button onClick={() => navigator(`/menu/${params.row._id}`)}>
    //         View
    //       </Button>
    //     ) : (
    //       "No data available"
    //     );
    //   },
    // },
    // {
    //   field: "bank",
    //   headerName: "bank",
    //   width: 120,
    //   renderCell: (params) => {
    //     return params.row.bankDetail ? (
    //       <Button
    //         onClick={() => {
    //           setBankData(params.row.bankDetail);
    //           setBankModel(true);
    //         }}
    //       >
    //         View
    //       </Button>
    //     ) : (
    //       "No data available"
    //     );
    //   },
    // },
    // {
    //   field: "address",
    //   headerName: "Address",
    //   width: 120,
    //   renderCell: (params) => {
    //     return params.row.address ? (
    //       <Button
    //         onClick={() => {
    //           setAddressData(params.row.address);
    //           setAddressModal(true);
    //         }}
    //       >
    //         View
    //       </Button>
    //     ) : (
    //       "No data available"
    //     );
    //   },
    // },
    // {
    //   field: "approve",
    //   headerName: "Approve",
    //   width: 120,
    //   renderCell: (params) => {
    //     return params.row.approved ? (
    //       <Typography>Approved</Typography>
    //     ) : (
    //       <Button>Approve</Button>
    //     );
    //   },
    // },
    {
      field: "Edit",
      headerName: "Edit",
      width: 120,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              editHandler(params.row);
            }}
            size="small"
          >
            Edit
          </Button>
        );
      },
    },
    // {
    //   field: "Timing",
    //   headerName: "Timing",
    //   width: 120,
    //   renderCell: (params) => {
    //     return (
    //       <Button
    //         variant="contained"
    //         onClick={() => {
    //           timingHandler(params.row);
    //         }}
    //         size="small"
    //       >
    //         Timing
    //       </Button>
    //     );
    //   },
    // },
    // {
    //   field: "location",
    //   headerName: "Location",
    //   width: 120,
    //   renderCell: (params) => {
    //     return (
    //       <Button
    //         variant="contained"
    //         size="small"
    //         onClick={() => {
    //           const url = `http://maps.google.com/maps?z=12&t=m&q=loc:${params.row.location.coordinates[1]}+${params.row.location.coordinates[0]}`;
    //           window.open(url, "_blank");
    //         }}
    //       >
    //         Location
    //       </Button>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    getAllRestaurant(page, limit, "", "");
  }, []);

  // const inputChange = (event) => {
  //   setUpgradeData({ ...upgradeData, [event.target.name]: event.target.value });
  // };

  const getAllRestaurant = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/restaurants/pet_pooja?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}`
      );
      setRestaurant(res.data.restaurant);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSortModelChange = async (item) => {
    getAllRestaurant(page, limit, "", "");
    if (item.length === 0) {
      getAllRestaurant(page, limit, "", "");
    } else {
      getAllRestaurant(page, limit, item[0].sort, item[0].field);
    }
  };
  const onFilterChange = async (e) => {
    console.log(e);
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/admin/get/restaurants/search`, {
        key: e.items[0].columnField,
        value: e.items[0].value,
      });
      setRestaurant(res.data.Restaurant);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const onChangeEditHandler = (event) => {
    setEditData({ ...editData, [event.target.name]: event.target.value });
  };
  const editSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await axios.post(
        `/api/v1/admin/restaurant/edit/location`,
        editData
      );
      snackbar(res.data.message, "success");
      if (image) {
        let formData = new FormData();
        formData.append("image", image);
        console.log(image);
        const res = await axios.patch(
          `/api/v1/admin/restaurant/create?restaurantId=${editData._id}`,
          formData
        );
      }
      getAllRestaurant(page, limit, "", "");
      setEditModel(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      snackbar(error.response.data.message, "error");
    }
  };

  return (
    <Box>
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        autoHeight
        rows={restaurant}
        getRowId={(row) => row._id}
        rowCount={total}
        loading={loading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={page - 1}
        pageSize={limit}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        onPageChange={(newPage) => {
          getAllRestaurant(newPage + 1, limit, "", "");
          setPage(newPage + 1);
        }}
        onPageSizeChange={(newPageSize) => {
          getAllRestaurant(page, newPageSize, "", "");
          setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={columns}
      />
      <Modal
        open={bankModel}
        onClose={() => setBankModel(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography>Bank Name : {bankData.bankName}</Typography>
          <Typography>
            Account Holder Name : {bankData.accountHolderName}
          </Typography>
          <Typography>Branch Name : {bankData.branch}</Typography>
          <Typography>Account Number : {bankData.accountNumber}</Typography>
          <Typography>IFSC Code : {bankData.ifscCode}</Typography>
          <Typography>
            Verified : {bankData.verified ? "True" : "False"}
          </Typography>
          {!bankData.verified ? (
            <Button variant="contained" onClick={verifiedBankHandler}>
              Verify Bank
            </Button>
          ) : (
            ""
          )}
        </Box>
      </Modal>

      <Modal
        open={addressModal}
        onClose={() => setAddressModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography>
            Complete Address : {addressData.completeAddress}
          </Typography>
          <Typography>City : {addressData.city}</Typography>
          <Typography>State : {addressData.state}</Typography>
          <Typography>Landmark : {addressData.landmark}</Typography>
        </Box>
      </Modal>

      <Modal
        open={timingModal}
        onClose={() => setTimingModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: "80%" }}>
          <OutletTiming
            time={time}
            setTime={setTime}
            setIsLoading={setIsLoading}
            snackbar={snackbar}
            getAllRestaurant={getAllRestaurant}
            setTimingModal={setTimingModal}
            page={page}
            limit={limit}
          />
        </Box>
      </Modal>

      <Modal
        open={editModel}
        onClose={() => {
          setEditModel(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style }}>
          <Grid item sx={12} component="form" onSubmit={editSubmitHandler}>
            <Typography sx={{ ...topHeading, color: "black" }}>
              Edit Restaurant
            </Typography>
            {/* <Typography sx={{ ...textHeadingInputFields, mb: 1 }}>
              Change Image
            </Typography> */}
            <Typography variant="h6" sx={textFieldHeadingStyleForModal}>
              Change Image
            </Typography>

            <DragsAndDrop
              heading="Upload Image"
              inputName="Image"
              aspect={1.8 / 1}
              uploadFile={(x) => {
                setImage(x);
              }}
              imgKey={imageKey}
            />
            <Typography variant="h6" mt={1} sx={textFieldHeadingStyleForModal}>
              Latitude
            </Typography>
            <TextField
              id="outlined-basic1"
              size="small"
              sx={textFieldStyleForModal}
              // label="Latitude"
              type="number"
              onChange={onChangeEditHandler}
              name="latitude"
              value={editData.latitude}
              variant="outlined"
            />
            <Typography variant="h6" sx={textFieldHeadingStyleForModal}>
              Longitude
            </Typography>
            <TextField
              size="small"
              id="outlined-basic"
              sx={textFieldStyleForModal}
              // label="Longitude"
              onChange={onChangeEditHandler}
              name="longitude"
              type="number"
              value={editData.longitude}
              variant="outlined"
            />
            <Grid container spacing={2} px={3} mt={3}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",

                  justifyContent: { xs: "center" },
                  alignItems: "end",
                }}
              >
                <Button type="submit" variant="contained">
                  Update
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
}

export default PetPoojaRestaurant;
