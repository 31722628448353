import React, { useState } from "react";

import {
  Box,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

const OrderItemsDetailsModal = (props) => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");

  const [itemCount, setItemCount] = useState([]);
  const [totalPricePay, setTotalPricePay] = useState(0);
  if (props.openModal === true && props.counter === 0) {
    // total price on single order

    let orderItemsArr = props.OrderItemsDetails.orderItems.map(
      (value, index) => {
        return value.itemTitle;
      }
    );
    // console.log("orderItemsArr", orderItemsArr);

    const counts = {};
    orderItemsArr.forEach((x) => {
      counts[x] = (counts[x] || 0) + 1;
    });

    console.log(Object.keys(counts));
    console.log(Object.values(counts));
    // setItemCount(Object.values(counts));
    itemCount.push(Object.values(counts));

    console.log("itemCount", itemCount);

    let orderItems = orderItemsArr.filter((value, index) => {
      return orderItemsArr.indexOf(value) === index;
    });
    props.setCounter(props.counter + 1);
    props.items.push(orderItems);
  }

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "550px",
    width: matches ? "40%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 2,
    overflow: "auto",
  };

  return (
    <>
      <Modal
        open={props.openModal}
        onClose={props.handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
            gap={1}
            sx={{ position: "relative" }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "27px",
                color: "rgba(13, 30, 55, 0.67)",
                justifySelf: "start",
              }}
            >
              Order Details
            </Typography>

            <IconButton onClick={props.handleCloseModal} aria-label="delete">
              <CancelIcon />
            </IconButton>
          </Stack>

          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item xs={6}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Customer Name
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign={"right"}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={900}
                fontSize="16px"
                sx={{ color: "black.main" }}
              >
                {props.OrderItemsDetails.customer
                  ? props.OrderItemsDetails.customer.name
                  : "no"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Customer Number
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign={"right"}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={900}
                fontSize="16px"
                sx={{ color: "black.main" }}
              >
                {props?.OrderItemsDetails?.customer?.phone}
              </Typography>
            </Grid>
            {/* coupon if used */}
            <Grid item xs={6}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Coupon
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign={"right"}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={900}
                fontSize="16px"
                sx={{ color: "black.main" }}
              >
                {props?.OrderItemsDetails?.coupon
                  ? props?.OrderItemsDetails?.coupon.code
                  : "Not Available"}
              </Typography>
            </Grid>

            {/* // customer Price */}
            <Grid item xs={6}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Payment By Customer
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign={"right"}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={900}
                fontSize="16px"
                sx={{ color: "black.main" }}
              >
                {props?.OrderItemsDetails?.paymentAmount}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Mobile Number
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign={"right"}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={700}
                fontSize="16px"
                sx={{ color: "black.main" }}
              >
                {props.OrderItemsDetails.customer
                  ? props.OrderItemsDetails.customer.phone
                  : "no"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Status
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign={"right"}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "black.main" }}
              >
                {props.OrderItemsDetails
                  ? props.OrderItemsDetails.status
                  : "no"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Order Quantity
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign={"right"}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "black.main" }}
              >
                {props.OrderItemsDetails.orderItems &&
                props.OrderItemsDetails.orderItems.length > 0 ? (
                  <React.Fragment>
                    <Typography sx={{ color: "grey.main" }}>
                      {/* Total Quantity:{" "} */}
                      {props.OrderItemsDetails.orderItems.reduce(
                        (total, item) => total + item.quantity,
                        0
                      )}
                    </Typography>
                  </React.Fragment>
                ) : (
                  <Typography>-</Typography>
                )}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Order OTP
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign={"right"}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={900}
                fontSize="16px"
                sx={{ color: "black.main" }}
              >
                {props.OrderItemsDetails ? props.OrderItemsDetails.otp : "no"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Items
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign={"right"}>
              {props.OrderItemsDetails.orderItems &&
              props.OrderItemsDetails.orderItems.length > 0 ? (
                props.OrderItemsDetails.orderItems.map((item, i) => (
                  <Typography key={i}>
                    {" "}
                    {item.variationTitle ? item.variationTitle : ""}{" "}
                    {item.itemTitle} X {item.quantity}
                  </Typography>
                ))
              ) : (
                <Typography>-</Typography>
              )}
            </Grid>

            <Grid item xs={6}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Restaurant Name
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign={"right"}>
              <Typography
                variant="h6"
                fontWeight={700}
                fontSize="16px"
                sx={{ color: "black.main" }}
              >
                {props.OrderItemsDetails.restaurant
                  ? props.OrderItemsDetails.restaurant.brand_display_name
                  : "no"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Merchant Number
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign={"right"}>
              <Typography
                variant="h6"
                fontWeight={700}
                fontSize="16px"
                sx={{ color: "black.main" }}
              >
                {props.OrderItemsDetails.restaurant
                  ? props.OrderItemsDetails.restaurant.merchant_number
                  : "no"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Restaurant Payable Amount
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign={"right"}>
              {" "}
              <Typography
                variant="h6"
                fontWeight={900}
                fontSize="16px"
                sx={{ color: "black.main" }}
              >
                {props?.OrderItemsDetails?.orderItems?.reduce(
                  (ac, curr) => ac + curr.totalPrice,
                  totalPricePay
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default OrderItemsDetailsModal;
