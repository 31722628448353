import React, { useState, useEffect, useContext } from "react";

import { Button, Grid, Typography } from "@mui/material";

import { Box } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";

import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import AddCategory from "./AddCategory";
import EditCategory from "./EditCategory";
import axios from "axios";
import { imgUrl } from "../../../../../url";
import { submitBtn } from "../../../../menuItem/itemCreate/CssStyle";
import { AdminDataContext } from "../../../../../useContext/AdminContext";

const Category = () => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const { restaurantId } = useParams();
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState({});
  const [restaurantName, setRestaurantName] = useState("");
  const [pageSize, setPageSize] = useState(10);

  const getDate = async () => {
    try {
      const res = await axios.get(
        `/api/v1/admin/restaurant?restaurantId=${restaurantId}`
      );
      setCategories(res.data.restaurant.categories);
      setRestaurantName(res.data.restaurant.brand_display_name);
      console.log(res.data);
    } catch (error) {}
  };

  const deleteCategory = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.delete(
        `/api/v1/admin/item/category?categoryId=${id}`
      );

      console.log(id);
      console.log(res);
      getDate();
      snackbar(res.data.message, "success");
      setIsLoading(false);
    } catch (error) {
      snackbar(error.response.data.message, "error");
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDate();
  }, []);

  const [childCategory, setChildCategory] = useState({
    0: true,
    1: false,
    2: false,
  });

  const handleChildCategory = (table, add, edit) => {
    setChildCategory({
      0: table,
      1: add,
      2: edit,
    });
  };

  const handleEdit = (data) => {
    console.log(`Edit clicked for ID: ${data._id}`);
    setCategory(data);
    // console.log(data);
    handleChildCategory(false, false, true);
  };

  const columns = [
    {
      field: "categoryid",
      headerName: "ID",
      width: 150,
    },

    {
      field: "image",
      width: 100,
      headerName: "Image",
      renderCell: (params) => {
        return params.row.category_image_url ? (
          <Box
            component="img"
            width="100%"
            src={`${imgUrl}/${params.row.category_image_url}`}
          ></Box>
        ) : (
          <></>
        );
      },
    },
    { field: "categoryname", headerName: "category name", minWidth: 180 },
    { field: "categoryrank", headerName: "category rank", minWidth: 100 },
    {
      field: "active",
      headerName: "active",
      minWidth: 80,
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          startIcon={<EditIcon />}
          onClick={() => handleEdit(params.row)}
        ></Button>
      ),
    },

    {
      field: "delete",
      headerName: "Delete",
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          startIcon={<DeleteIcon />}
          onClick={() => deleteCategory(params.row._id)}
        ></Button>
      ),
    },
  ];

  return (
    <>
      <Box sx={{ display: childCategory[0] ? "block" : "none" }}>
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Grid item xs={10} sm={5}>
            <Button
              variant="contained"
              onClick={() => handleChildCategory(false, true, false)}
              sx={submitBtn}
            >
              Add Category{" "}
            </Button>
            <Typography
              sx={{ color: "#0C1844", fontWeight: "bold", mt: 2 }}
            >{`${restaurantName}`}</Typography>
          </Grid>
        </Grid>
      </Box>

      <div
        style={{
          height: 500,
          width: "100%",
          marginTop: "20px",
          display: childCategory[0] ? "block" : "none",
        }}
      >
        <DataGrid
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "dataGrid.header",
              color: "black.main",
              fontSize: 16,
            },
          }}
          columns={columns}
          getRowId={(row) => row._id}
          rows={categories}
          rowsPerPageOptions={[10, 25, 100]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          // pageSizeOptions={[5, 10]}
          // rowsPerPageOptions={[10, 25, 100]}
        />
      </div>

      {childCategory[1] ? (
        <Box
        // sx={{ display: childCategory[1] ? "block" : "none" }}
        >
          <AddCategory
            handleChildCategory={handleChildCategory}
            restaurantId={restaurantId}
            getDate={getDate}
            restaurantName={restaurantName}
          />
        </Box>
      ) : (
        <></>
      )}

      {childCategory[2] ? (
        <Box
        //  sx={{ display: childCategory[2] ? "block" : "none" }}
        >
          <EditCategory
            handleChildCategory={handleChildCategory}
            restaurantId={restaurantId}
            category={category}
            getDate={getDate}
            restaurantName={restaurantName}
          />
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default Category;
