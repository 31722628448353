import React, { useEffect, useRef, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl"; // or "const mapboxgl = require('mapbox-gl');"
import axios from "axios";
import { useLocation } from "react-router-dom";

import { useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
function ResLocationDeliveryBoy(props) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");
  const [lng, setLng] = useState(72.978146);
  const [lat, setLat] = useState(19.217302);

  // const [zoom, setZoom] = useState(12.3);
  const [zoom, setZoom] = useState(12.5);
  const [wait, setWait] = useState(false);
  const [busyPartnersData, setBusyPartnersData] = useState([]);
  const [availablePartnersData, setAvailablePartnersData] = useState([]);
  const [offlinePartnersData, setOfflinePartnersData] = useState([]);
  const [complain, setComplain] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [restaurantLocation, setRestaurantLocation] = useState([]);
  const { data } = useParams();
  // const dataReceived = JSON.parse(decodeURIComponent(data));

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dataString = queryParams.get("data");
  const restaurantName = queryParams.get("restaurant");
  const orderId = queryParams.get("orderId");
  const dataReceived = JSON.parse(decodeURIComponent(dataString));

  let coordinates = [];
  // let a = props.restaurantLocationIs.coordinates[0];
  // let b = props.restaurantLocationIs.coordinates[1];
  // const [lngs, setLngs] = useState(a);
  // const [lats, setLats] = useState(b);
  coordinates.push(dataReceived.coordinates[0]);
  coordinates.push(dataReceived.coordinates[1]);
  const [singleRestaurant, setSingleRestaurant] = useState([
    {
      type: "Feature",
      properties: {
        description: `<strong>${restaurantName}</strong><p>${orderId}</p>`,
      },
      geometry: {
        type: "Point",
        coordinates: coordinates,
      },
    },
  ]);

  const getData = async () => {
    try {
      setWait(false);
      const res = await axios.get("/api/v1/admin/delivery/location");
      setBusyPartnersData(res.data.busyPartnersData);
      setAvailablePartnersData(res.data.availablePartnersData);

      setOfflinePartnersData(res.data.offlinePartnersData);

      console.log("delivery Monitor ", res);
      setWait(true);
    } catch (error) {}
  };

  const getAllOrders = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/orders?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}`
      );
      setRestaurantLocation(res.data.data);
      setComplain(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    getAllOrders(page, limit, "", "");
  }, []);

  useEffect(() => {
    if (wait) {
      if (map.current) return; // initialize map only once
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v12",
        center: [lng, lat],
        zoom: zoom,

        accessToken:
          "pk.eyJ1IjoieWJpdGVzIiwiYSI6ImNsZmdveXJ2YTBsamkzeW5wanY1bjBkMnkifQ.nQdGB1L5C8Vd-qLV3vKfSA",
      });
      //exmple to resstaurat

      map.current.on("load", () => {
        map.current.addSource("restaurant", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: singleRestaurant,
          },
        });

        map.current.addLayer({
          id: "restaurant",
          type: "circle",
          source: "restaurant",
          paint: {
            "circle-color": "blue",
            "circle-radius": 6,
            "circle-stroke-width": 1,
            "circle-stroke-color": "#ffffff",
          },
        });
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        });

        map.current.on("mouseenter", "restaurant", (e) => {
          map.current.getCanvas().style.cursor = "pointer";
          const coordinates = e.features[0].geometry.coordinates.slice();
          const description = e.features[0].properties.description;

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          popup.setLngLat(coordinates).setHTML(description).addTo(map.current);
        });

        map.current.on("mouseleave", "restaurant", () => {
          map.current.getCanvas().style.cursor = "";
          popup.remove();
        });
      });

      //exmple to resstaurat

      map.current.on("load", () => {
        map.current.addSource("available", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: availablePartnersData,
          },
        });
        // Add a layer showing the available.
        map.current.addLayer({
          id: "available",
          type: "circle",
          source: "available",
          paint: {
            "circle-color": "green",
            "circle-radius": 6,
            "circle-stroke-width": 1,
            "circle-stroke-color": "#ffffff",
          },
        });
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        });

        map.current.on("mouseenter", "available", (e) => {
          map.current.getCanvas().style.cursor = "pointer";
          const coordinates = e.features[0].geometry.coordinates.slice();
          const description = e.features[0].properties.description;

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          popup.setLngLat(coordinates).setHTML(description).addTo(map.current);
        });

        map.current.on("mouseleave", "available", () => {
          map.current.getCanvas().style.cursor = "";
          popup.remove();
        });
      });

      map.current.on("load", () => {
        map.current.addSource("busy", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: busyPartnersData,
          },
        });
        // Add a layer showing the busy.
        map.current.addLayer({
          id: "busy",
          type: "circle",
          source: "busy",
          paint: {
            "circle-color": "orange",
            "circle-radius": 6,
            "circle-stroke-width": 1,
            "circle-stroke-color": "#ffffff",
          },
        });
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        });

        map.current.on("mouseenter", "busy", (e) => {
          map.current.getCanvas().style.cursor = "pointer";
          const coordinates = e.features[0].geometry.coordinates.slice();
          const description = e.features[0].properties.description;

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          popup.setLngLat(coordinates).setHTML(description).addTo(map.current);
        });

        map.current.on("mouseleave", "busy", () => {
          map.current.getCanvas().style.cursor = "";
          popup.remove();
        });
      });

      // ll
      map.current.on("load", () => {
        map.current.addSource("offline", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: offlinePartnersData,
          },
        });
        // Add a layer showing the offline.
        map.current.addLayer({
          id: "offline",
          type: "circle",
          source: "offline",
          paint: {
            "circle-color": "red",
            "circle-radius": 6,
            "circle-stroke-width": 1,
            "circle-stroke-color": "#ffffff",
          },
        });
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        });

        map.current.on("mouseenter", "offline", (e) => {
          map.current.getCanvas().style.cursor = "pointer";
          const coordinates = e.features[0].geometry.coordinates.slice();
          const description = e.features[0].properties.description;

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          popup.setLngLat(coordinates).setHTML(description).addTo(map.current);
        });

        map.current.on("mouseleave", "offline", () => {
          map.current.getCanvas().style.cursor = "";
          popup.remove();
        });
      });
      //restaurant live location here
    }
  }, [availablePartnersData, busyPartnersData]);
  return (
    <div>
      <div ref={mapContainer} style={{ height: "88vh" }} />
    </div>
  );
}

export default ResLocationDeliveryBoy;
