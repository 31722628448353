import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Paper,
  useMediaQuery,
  Box,
  Typography,
  Grid,
  Autocomplete,
  TextField,
} from "@mui/material";
import { AdminDataContext } from "../../../../useContext/AdminContext";
import { imgUrl } from "../../../../url";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  containerStyle,
  imageBox,
  paperBox,
  paperStyle,
  restaurantDetailsGrid,
} from "../../../menuItem/itemCreate/CssStyle";

const SearchRestaurant = () => {
  const [value, setValue] = React.useState(0);
  const { snackbar, setIsLoading } = useContext(AdminDataContext);
  const [restaurantList, setRestaurantList] = useState([]);

  const [owner, setOwner] = useState([]);
  const [searchOwner, setSearchOwner] = useState([]);
  const [ownerId, setOwnerId] = useState("");
  const [singleOwner, setSingleOwner] = useState({});
  const [singleRestaurant, setSingleRestaurant] = useState({});
  const [isSearchRestaurant, setIsSearchRestaurant] = useState(true);
  const [selectedOwner, setSelectOwner] = useState(false);

  const navigate = useNavigate();

  const getAllOwner = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`/api/v1/admin/owner`);
      setOwner(res.data.owner);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getSearchSingleRestaurant = async (e) => {
    try {
      const res = await axios.put(
        `/api/v1/admin/restaurants?query=${e.target.value}`
      );
      console.log("list ", res.data.data);
      if (!(res.data.data.length === 0)) {
        setRestaurantList(res.data.data);
      } else {
        setRestaurantList([]);
        console.log("none");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const restaurantBasicDetails = (id) => {
    navigate(`/restaurant/create-restaurant-details/${id}`);
  };
  //   const getSearchOwner = async (e) => {
  //     try {
  //       const res = await axios.get(
  //         `/api/v1/admin/owner/search/?query=${e.target.value}`
  //       );
  //       if (!(res.data.data.length === 0)) {
  //         setSearchOwner(res.data.data);
  //       } else {
  //         setSearchOwner([]);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  useEffect(() => {
    getAllOwner();
    // getSearchOwner();
  }, []);

  console.log("setSearchOwner is ", searchOwner);
  // console.log(setSingleOwner);

  return (
    <>
      <Box>
        {ownerId ? (
          <></>
        ) : (
          <Container fixed sx={containerStyle}>
            <Paper sx={paperStyle} elevation={10}>
              <Box sx={paperBox}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "200px",
                  }}
                  component="img"
                  alt="logo"
                  src="/images/logoCreateRest.svg"
                ></Box>
                <Typography
                  sx={{ color: "grey.tertiary", fontWeight: 600, mt: 1 }}
                >
                  SELECT RESTAURANT
                </Typography>
              </Box>
              <Grid
                container
                spacing={2}
                mt={2}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Grid xs={12} md={6}>
                  <Autocomplete
                    fullWidth={true}
                    disableClearable
                    id="combo-box-demo"
                    size="small"
                    options={restaurantList}
                    getOptionLabel={(userData) =>
                      `${userData.brand_display_name} Phone : ${userData.business_contact}`
                    }
                    onInputChange={getSearchSingleRestaurant}
                    onChange={(event, value) => {
                      // console.log(event.target.value);
                      console.log("restaurant is ", value);
                      console.log(value);
                      setRestaurantList(value);

                      setSingleRestaurant(value);
                      setSelectOwner(true);
                    }}
                    filterSelectedOptions
                    //   sx={{ width: 250 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Search Restaurant" />
                    )}
                  />
                </Grid>
              </Grid>

              {selectedOwner ? (
                <Grid
                  container
                  //   onClick={() => {
                  //     setOwnerId(singleRestaurant._id);
                  //     navigate(
                  //       `/restaurant/restaurant-list/${singleRestaurant._id}`
                  //     );
                  //     console.log(singleRestaurant._id);
                  //   }}
                  onClick={() => restaurantBasicDetails(singleRestaurant._id)}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 4,
                    borderRadius: "100px",
                  }}
                >
                  <Grid item xs={8} sm={3} md={1}>
                    <Box
                      sx={imageBox}
                      component="img"
                      alt="logo"
                      src={
                        singleRestaurant.restaurant_logo
                          ? `${imgUrl}/${singleRestaurant.restaurant_logo}`
                          : "/images/defaultRes.svg"
                      }
                    ></Box>
                  </Grid>
                  <Grid item xs={10} sm={6} md={4} sx={restaurantDetailsGrid}>
                    <Typography
                      sx={{ color: "grey.main", fontWeight: 600, mt: 1 }}
                    >
                      {singleRestaurant.brand_display_name}
                    </Typography>
                    <Typography
                      sx={{ color: "grey.main", fontWeight: 600, mt: 1 }}
                    >
                      {singleRestaurant.email}
                    </Typography>

                    <Typography
                      variant="h6"
                      fontWeight={500}
                      fontSize="15px"
                      lineHeight="20px"
                      sx={{ color: "rgba(13, 30, 55, 0.67)", mt: 1 }}
                    >
                      {singleRestaurant.business_contact}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
            </Paper>
          </Container>
        )}
      </Box>
    </>
  );
};

export default SearchRestaurant;
