import { Box, Button, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AdminDataContext } from "./../../useContext/AdminContext";
import { imgUrl } from "../../url";

function Complain() {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  const [complain, setComplain] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      field: "complainNo",
      headerName: "Complain No.",
      width: 150,
    },
    {
      field: "customerTitle",
      headerName: "Title",
      width: 250,
    },
    {
      field: "complainStatus",
      headerName: "Status",
      width: 100,
    },

    {
      field: "customerDesc",
      headerName: "Description",
      width: 300,
      renderCell: (params) => {
        return <Typography>{params.row.customerDescription}</Typography>;
      },
    },
    {
      field: "brand_display_name",
      headerName: "Restaurant",
      width: 300,
      renderCell: (params) => {
        return (
          <Typography>{params.row.restaurant.brand_display_name}</Typography>
        );
      },
    },
    {
      field: "restaurantReply",
      headerName: "Restaurant reply",
      width: 300,
      renderCell: (params) => {
        return params.row.restaurant || params.row.paymentAccept ? (
          <Typography>
            {params.row.paymentAccept
              ? "Refund Payment"
              : params.row.restaurantDescription}
          </Typography>
        ) : (
          <Typography>No Reply</Typography>
        );
      },
    },
    {
      field: "image",
      headerName: "Image",
      width: 120,
      renderCell: (params) => {
        return (
          <Box
            height={"100%"}
            component="img"
            src={`${imgUrl}/${params.row.image}`}
            alt=""
          />
        );
      },
    },

    {
      field: "refundStatus",
      headerName: "Refund Status",
      width: 120,
      renderCell: (params) => {
        const refundHandler = async (id) => {
          try {
            setLoading(true);
            const response = await axios.post(
              `/api/v1/admin/complain?complainId=${id}`
            );

            snackbar(response.data.message, "success");
            getAllComplain(page, limit, "", "");
            setLoading(false);
          } catch (error) {
            snackbar(error.response.data.message, "error");
            setLoading(false);
          }
        };

        return params.row.refundStatus ? (
          <Typography>{params.row.refundStatus}</Typography>
        ) : (
          <Button
            onClick={() => refundHandler(params.row._id)}
            variant="contained"
          >
            Refund
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    getAllComplain(page, limit, "", "");
  }, []);

  const getAllComplain = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/complain?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}`
      );
      setComplain(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSortModelChange = async (item) => {
    getAllComplain(page, limit, "", "");
    if (item.length === 0) {
      getAllComplain(page, limit, "", "");
    } else {
      getAllComplain(page, limit, item[0].sort, item[0].field);
    }
  };

  const onFilterChange = async (e) => {
    console.log(e);
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/admin/get/complains/search`, {
        key: e.items[0].columnField,
        value: e.items[0].value,
      });
      setComplain(res.data.complain);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  // onClick={createComplainHandler}
  return (
    <Box>
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        autoHeight
        rows={complain}
        getRowId={(row) => row._id}
        rowCount={total}
        loading={loading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={page - 1}
        pageSize={limit}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        onPageChange={(newPage) => {
          getAllComplain(newPage + 1, limit, "", "");
          setPage(newPage + 1);
        }}
        onPageSizeChange={(newPageSize) => {
          getAllComplain(page, newPageSize, "", "");
          setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={columns}
      />
    </Box>
  );
}

export default Complain;
