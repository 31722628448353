import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Edit as EditIcon } from "@mui/icons-material";
import AddItem from "./AddItem";
import { tableColumnsHeading, textHeading } from "./CssStyle";
import { AdminDataContext } from "./../../../useContext/AdminContext";

import EditItem from "./EditItem";
import { imgUrl } from "../../../url";
function NestedRow(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row?.name}
        </TableCell>
        <TableCell align="center">{row?.groupname}</TableCell>
        <TableCell align="center">{row?.price}</TableCell>
        <TableCell align="center">
          {row?.variationallowaddon === 0 ? "False" : "True"}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {row?.addon.length !== 0 ? (
              <Box sx={{ margin: 1 }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  sx={textHeading}
                >
                  AddOns
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" sx={tableColumnsHeading}>
                        Addon Name
                      </TableCell>
                      <TableCell align="center" sx={tableColumnsHeading}>
                        Addon Item Selection Max
                      </TableCell>
                      <TableCell align="center" sx={tableColumnsHeading}>
                        Addon Item Selection Min
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.addon.map((addon) => (
                      <TableRow key={addon._id}>
                        <TableCell align="center">
                          {props.addOn[addon.addon_group_id]}
                        </TableCell>
                        <TableCell align="center">
                          {addon.addon_item_selection_max}
                        </TableCell>
                        <TableCell align="center">
                          {addon.addon_item_selection_min}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            ) : (
              <></>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
function Row(props) {
  const { item, editHandler, deleteHandler, actionSwitchToggle } = props;
  const [open, setOpen] = React.useState(false);
  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {/* {item.itemname} */}
          <Box
            height={"50px"}
            width={"50px"}
            component="img"
            src={`${imgUrl}/${item.item_image_url}`}
            alt=""
          />
        </TableCell>
        <TableCell component="th" scope="row">
          {item.itemname}
        </TableCell>
        <TableCell component="th" scope="row">
          <Switch
            checked={item.itemVisible ? true : false}
            onChange={(e) => actionSwitchToggle(item.itemid, e.target.checked)}
          />
        </TableCell>
        <TableCell align="center">
          {item.cuisine?.length === 0 ? "" : item.cuisine.toString()}
        </TableCell>
        <TableCell align="center">{item.itemdescription}</TableCell>
        <TableCell align="center">{item.price}</TableCell>
        <TableCell align="center">
          {props.category[item.item_categoryid]}
        </TableCell>
        <TableCell align="center">{item.minimumpreparationtime}</TableCell>
        <TableCell align="center">
          {props.attributes[item.item_attributeid]}
        </TableCell>
        <TableCell align="center">{item.ybitesPackingCharges}</TableCell>
        <TableCell align="center">{item.itemallowaddon}</TableCell>
        <TableCell align="center">{item.variation_groupname}</TableCell>
        <TableCell align="center">
          {item.itemallowvariation === "0" ? "false" : "true"}
        </TableCell>
        <TableCell align="center">
          {item.item_tax
            .split(",")
            .map((tax) => props.tax[tax])
            .toString()}
        </TableCell>
        <TableCell align="center">
          <Button onClick={() => editHandler(item)} variant="contained">
            Edit
          </Button>
        </TableCell>
        <TableCell align="center">
          <Button onClick={() => deleteHandler(item._id)} variant="contained">
            Delete
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {item.addon.length !== 0 ? (
              <Box sx={{ margin: 1 }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  sx={textHeading}
                >
                  AddOns
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" sx={tableColumnsHeading}>
                        Addon Name
                      </TableCell>
                      <TableCell align="center" sx={tableColumnsHeading}>
                        Addon Item Selection Max
                      </TableCell>
                      <TableCell align="center" sx={tableColumnsHeading}>
                        Addon Item Selection Min
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.addon.map((addon) => (
                      <TableRow key={addon._id}>
                        <TableCell align="center">
                          {props.addOn[addon.addon_group_id]}
                        </TableCell>
                        <TableCell align="center">
                          {addon.addon_item_selection_max}
                        </TableCell>
                        <TableCell align="center">
                          {addon.addon_item_selection_min}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            ) : (
              <></>
            )}
            {item.variation.length !== 0 ? (
              <Box sx={{ margin: 1 }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  sx={textHeading}
                >
                  Variations
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell align="center" sx={tableColumnsHeading}>
                        Variation Name
                      </TableCell>
                      <TableCell align="center" sx={tableColumnsHeading}>
                        Groupname
                      </TableCell>
                      <TableCell align="center" sx={tableColumnsHeading}>
                        Price
                      </TableCell>
                      <TableCell align="center" sx={tableColumnsHeading}>
                        Variation Allow Addon
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.variation.map((item) => (
                      <NestedRow
                        key={item._id}
                        row={item}
                        attributes={props.attributes}
                        tax={props.tax}
                        addOn={props.addOn}
                      />
                    ))}
                  </TableBody>
                  {/* <TableBody>
                    {item.variation.map((variation) => (
                      <TableRow key={variation._id}>
                        <TableCell align="center">{variation.name}</TableCell>
                        <TableCell align="center">
                          {variation.groupname}
                        </TableCell>
                        <TableCell align="center">{variation.price}</TableCell>
                        <TableCell align="center">
                          {variation.variationallowaddon === 0
                            ? "False"
                            : "True"}
                        </TableCell>
                        <TableCell align="center">
                          {variation.variationallowaddon === 0
                            ? "False"
                            : "True"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody> */}
                </Table>
              </Box>
            ) : (
              <></>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const MenuItems = () => {
  const [itemsData, setItemsData] = useState([]);
  const [attributes, setAttributes] = useState();
  const [tax, setTax] = useState();
  const [page, setPage] = useState(0);
  const [attributesArray, setAttributesArray] = useState([]);
  const [taxes, setTaxes] = useState([]);

  const [addOn, setAddOn] = useState();
  const [addOnsArray, setAddOnsArray] = useState([]);

  const [category, setCategory] = useState();
  const [categoriesArray, setCategoriesArray] = useState([]);
  const [variationsArray, setVariationsArray] = useState([]);
  const [currentItem, setCurrentItem] = useState(NaN);
  const [isItemVisible, setIsItemVisible] = useState(true);
  const [restaurantName, setRestaurantName] = useState("");
  console.log(isItemVisible);
  const { restaurantId } = useParams();
  const editHandler = (item) => {
    setCurrentItem(item);
    setPage(2);
  };
  const { setIsLoading, snackbar, setLoading } = useContext(AdminDataContext);

  const actionSwitchToggle = async (itemId, itemVisible) => {
    try {
      setIsLoading(true);
      const response = await axios.patch(`/api/v1/admin/item/${itemId}`, {
        itemVisible,
      });

      console.log("actionSwitchToggle", response);
      snackbar(response.data.message, "success");
      // getAllCoupon(page, limit, "", "");
      getData();
      setIsLoading(false);
    } catch (error) {
      snackbar(error.response.data.message, "error");
      setIsLoading(false);
    }
  };

  const deleteHandler = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.delete(`/api/v1/admin/item?itemId=${id}`);

      snackbar(res.data.message, res.data.status);
      setIsLoading(false);

      getData();
    } catch (error) {
      console.log(error);
      snackbar(error.response.data.message, error.response.data.status);

      setIsLoading(false);
    }
  };
  const getData = async () => {
    try {
      const res = await axios.get(
        `/api/v1/admin/restaurant?restaurantId=${restaurantId}`
      );
      setRestaurantName(res.data.restaurant.brand_display_name);
      setItemsData(() =>
        res.data.restaurant.items.filter(
          (item) => item.itemVisible === isItemVisible
        )
      );
      const attributesObj = {};
      for (
        let index = 0;
        index < res.data.restaurant.attributes.length;
        index++
      ) {
        const element = res.data.restaurant.attributes[index];
        attributesObj[element.attributeid] = element.attribute;
      }

      const taxObj = {};
      for (let index = 0; index < res.data.restaurant.taxes.length; index++) {
        const element = res.data.restaurant.taxes[index];
        taxObj[element.taxid] = `${element.taxname} => ${element.tax}`;
      }
      setTax(taxObj);

      const addonObj = {};
      for (
        let index = 0;
        index < res.data.restaurant.addongroups.length;
        index++
      ) {
        const element = res.data.restaurant.addongroups[index];
        addonObj[element.addongroupid] = `${element.addongroup_name}`;
      }
      setAddOn(addonObj);

      const categoryObj = {};
      for (
        let index = 0;
        index < res.data.restaurant.categories.length;
        index++
      ) {
        const element = res.data.restaurant.categories[index];
        categoryObj[element.categoryid] = `${element.categoryname}`;
      }
      setCategory(categoryObj);

      setAttributesArray(res.data.restaurant.attributes);
      setAddOnsArray(res.data.restaurant.addongroups);
      setTaxes(res.data.restaurant.taxes);
      setAttributes(attributesObj);
      setCategoriesArray(res.data.restaurant.categories);
      setVariationsArray(res.data.restaurant.variations);
    } catch (error) {}
  };

  useEffect(() => {
    getData();
  }, [isItemVisible]);
  return (
    <div>
      {page === 0 ? (
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", justifyContent: "center", b: 2 }}
        >
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Typography
                variant="h6"
                gutterBottom
                // component="div"
                sx={textHeading}
              >
                Menu Item List -{" "}
                <span style={{ color: "#0C1844" }}>{restaurantName}</span>
              </Typography>
            </Box>

            <Button
              variant="contained"
              onClick={() => setIsItemVisible(!isItemVisible)}
            >
              {isItemVisible
                ? "Click here to see Invisible Items"
                : "Click here to see Visible Items"}
            </Button>
            <Button
              sx={{ mx: 1 }}
              variant="contained"
              onClick={() => setPage(1)}
            >
              Add Item
            </Button>
          </Grid>
          {/* <Box width={"100%"}>
            <Typography
              variant="h6"
              gutterBottom
              // component="div"
              sx={textHeading}
            >
              Menu Item List
            </Typography>
          </Box> */}
          {/* <Box width={"100%"}>
            <Button
              variant="contained"
              onClick={() => setIsItemVisible(!isItemVisible)}
            >
              {isItemVisible
                ? "Click here to see Visible Items"
                : "Click here to see Invisible Items"}
            </Button>
          </Box> */}

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell align="center" sx={tableColumnsHeading}>
                      Item Image
                    </TableCell>
                    <TableCell align="center" sx={tableColumnsHeading}>
                      Name
                    </TableCell>

                    <TableCell align="center" sx={tableColumnsHeading}>
                      Item Visibility
                    </TableCell>

                    <TableCell align="center" sx={tableColumnsHeading}>
                      Cuisine
                    </TableCell>
                    <TableCell align="center" sx={tableColumnsHeading}>
                      Description
                    </TableCell>
                    <TableCell align="center" sx={tableColumnsHeading}>
                      Price
                    </TableCell>
                    <TableCell align="center" sx={tableColumnsHeading}>
                      Category
                    </TableCell>
                    <TableCell align="center" sx={tableColumnsHeading}>
                      Preparation Time
                    </TableCell>
                    <TableCell align="center" sx={tableColumnsHeading}>
                      Attribute
                    </TableCell>
                    <TableCell align="center" sx={tableColumnsHeading}>
                      Packing Charge
                    </TableCell>
                    <TableCell align="center" sx={tableColumnsHeading}>
                      Item Allow Addon
                    </TableCell>
                    <TableCell align="center" sx={tableColumnsHeading}>
                      Variation Groupname
                    </TableCell>
                    <TableCell align="center" sx={tableColumnsHeading}>
                      Item Allow Variation
                    </TableCell>
                    <TableCell align="center" sx={tableColumnsHeading}>
                      Tax
                    </TableCell>
                    <TableCell align="center" sx={tableColumnsHeading}>
                      Edit
                    </TableCell>
                    <TableCell align="center" sx={tableColumnsHeading}>
                      Delete
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itemsData.map((item) => (
                    <Row
                      deleteHandler={deleteHandler}
                      key={item._id}
                      item={item}
                      attributes={attributes}
                      tax={tax}
                      addOn={addOn}
                      category={category}
                      editHandler={editHandler}
                      actionSwitchToggle={actionSwitchToggle}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      ) : page === 1 ? (
        <AddItem
          categories={categoriesArray}
          addOns={addOnsArray}
          taxes={taxes}
          attributes={attributesArray}
          setPage={setPage}
          getData={getData}
          restaurantId={restaurantId}
          addOn={addOn}
          variations={variationsArray}
          restaurantName={restaurantName}
        />
      ) : (
        <EditItem
          categories={categoriesArray}
          addOns={addOnsArray}
          taxes={taxes}
          tax={tax}
          attributes={attributesArray}
          setPage={setPage}
          getData={getData}
          restaurantId={restaurantId}
          addOn={addOn}
          variations={variationsArray}
          currentItem={currentItem}
          restaurantName={restaurantName}
        />
      )}
    </div>
  );
};

export default MenuItems;
