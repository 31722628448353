import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  textFieldStyle,
  textHeadingInputFields,
} from "../../components/cssStyling/Style";

const OrderFilter = (props) => {
  const cities = ["All", "Bhiwandi", "Thane"];
  const platform = ["All", "ybites", "petPooja"];
  const status = [
    "All",
    "Pending",
    "Preparing",
    "Reject",
    "Ready",
    "Picked-up",
    "Cancelled",
    "Delivered",
  ];
  const deliveryStatus = [
    "All",
    "Searching",
    "Accepted",
    "Reject",
    "ReachedRestaurant",
    "ReachedCustomer",
    "Picked-up",
    "Delivered",
  ];
  const paymentStatus = [
    "All",
    "Failed",
    "Pending",
    "Paid",
    "COD",
    "Refunded-In-Progress",
    "Refunded",
  ];
  const paymentMode = ["All", "kotak", "COD"];
  const couponTypes = ["All", "admin", "restaurant"];

  const onInputChange = (e) => {
    props.setFilterOptions({
      ...props.filterOptions,
      [e.target.name]: e.target.value,
    });
    console.log("filterOptions", props.filterOptions);
  };

  return (
    <>
      <Grid container spacing={2} mb={0} mt={-4.5}>
        <Grid item xs={6} sm={3} md={1}>
          <Typography
            variant="body1"
            sx={{ ...textHeadingInputFields, fontSize: "10px" }}
          >
            Filter By City
          </Typography>
          <TextField
            id="outlined-basic"
            select
            size="small"
            type="text"
            name="city"
            required
            value={props.filterOptions.city}
            onChange={onInputChange}
            sx={textFieldStyle}
          >
            {/* <MenuItem selected>All</MenuItem> */}
            {cities.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} sm={3} md={1}>
          <Typography
            variant="body1"
            sx={{ ...textHeadingInputFields, fontSize: "10px" }}
          >
            Platform
          </Typography>
          <TextField
            id="outlined-basic"
            select
            size="small"
            type="text"
            name="platform"
            required
            value={props.filterOptions.platform}
            onChange={onInputChange}
            sx={textFieldStyle}
          >
            {/* <MenuItem selected>All</MenuItem> */}
            {platform.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Typography variant="body1" sx={textHeadingInputFields}>
            Status
          </Typography>
          <TextField
            id="outlined-basic"
            select
            size="small"
            type="text"
            name="status"
            required
            value={props.filterOptions.status}
            onChange={onInputChange}
            sx={textFieldStyle}
          >
            {/* <MenuItem selected>All</MenuItem> */}
            {status.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Typography variant="body1" sx={textHeadingInputFields}>
            Delivery Status
          </Typography>
          <TextField
            id="outlined-basic"
            select
            size="small"
            type="text"
            name="deliveryStatus"
            required
            value={props.filterOptions.deliveryStatus}
            onChange={onInputChange}
            sx={textFieldStyle}
          >
            {/* <MenuItem selected>All</MenuItem> */}
            {deliveryStatus.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Typography variant="body1" sx={textHeadingInputFields}>
            Payment Mode
          </Typography>
          <TextField
            id="outlined-basic"
            select
            size="small"
            type="text"
            name="paymentMode"
            required
            value={props.filterOptions.paymentMode}
            onChange={onInputChange}
            sx={textFieldStyle}
          >
            {/* <MenuItem selected>All</MenuItem> */}
            {paymentMode.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Typography variant="body1" sx={textHeadingInputFields}>
            Payment Status
          </Typography>
          <TextField
            id="outlined-basic"
            select
            size="small"
            type="text"
            name="paymentStatus"
            required
            value={props.filterOptions.paymentStatus}
            onChange={onInputChange}
            sx={textFieldStyle}
          >
            {/* <MenuItem selected>All</MenuItem> */}
            {paymentStatus.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Typography variant="body1" sx={textHeadingInputFields}>
            Coupon Type
          </Typography>
          <TextField
            id="outlined-basic"
            select
            size="small"
            type="text"
            name="couponType"
            required
            value={props.filterOptions.couponType}
            onChange={onInputChange}
            sx={textFieldStyle}
          >
            {/* <MenuItem selected>All</MenuItem> */}
            {couponTypes.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </>
  );
};

export default OrderFilter;
