import React, { useEffect, useRef, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl"; // or "const mapboxgl = require('mapbox-gl');"
import axios from "axios";

const BhiwandiDeliveryPartnerLocation = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [lng, setLng] = useState(73.0499696461665);
  const [lat, setLat] = useState(19.28323291633152);

  // const [zoom, setZoom] = useState(12.3);
  const [zoom, setZoom] = useState(12.5);
  const [wait, setWait] = useState(false);
  const [busyPartnersData, setBusyPartnersData] = useState([]);
  const [availablePartnersData, setAvailablePartnersData] = useState([]);
  const [offlinePartnersData, setOfflinePartnersData] = useState([]);

  const getData = async () => {
    try {
      setWait(false);
      const res = await axios.get("/api/v1/admin/delivery/location");
      setBusyPartnersData(res.data.busyPartnersData);
      setAvailablePartnersData(res.data.availablePartnersData);
      setOfflinePartnersData(res.data.offlinePartnersData);

      console.log("delivery Monitor ", res);
      setWait(true);
    } catch (error) {}
  };

  // const ref = () => {
  //   window.location.reload();
  // };

  useEffect(() => {
    getData();

    // const intervalId = setInterval(ref, 30000);

    // Clean up the interval when the component unmounts
    // return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // console.log(busyPartnersData, availablePartnersData);
    console.log("colling");
    console.log("availablePartnersData", availablePartnersData);
    if (wait) {
      if (map.current) return; // initialize map only once
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v12",
        center: [lng, lat],
        zoom: zoom,

        accessToken:
          "pk.eyJ1IjoieWJpdGVzIiwiYSI6ImNsZmdveXJ2YTBsamkzeW5wanY1bjBkMnkifQ.nQdGB1L5C8Vd-qLV3vKfSA",
      });

      map.current.on("load", () => {
        map.current.addSource("available", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: availablePartnersData,
          },
        });
        // Add a layer showing the available.
        map.current.addLayer({
          id: "available",
          type: "circle",
          source: "available",
          paint: {
            "circle-color": "green",
            "circle-radius": 6,
            "circle-stroke-width": 1,
            "circle-stroke-color": "#ffffff",
          },
        });
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        });

        map.current.on("mouseenter", "available", (e) => {
          map.current.getCanvas().style.cursor = "pointer";
          const coordinates = e.features[0].geometry.coordinates.slice();
          const description = e.features[0].properties.description;

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          popup.setLngLat(coordinates).setHTML(description).addTo(map.current);
        });

        map.current.on("mouseleave", "available", () => {
          map.current.getCanvas().style.cursor = "";
          popup.remove();
        });
      });

      map.current.on("load", () => {
        map.current.addSource("busy", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: busyPartnersData,
          },
        });
        // Add a layer showing the busy.
        map.current.addLayer({
          id: "busy",
          type: "circle",
          source: "busy",
          paint: {
            "circle-color": "orange",
            "circle-radius": 6,
            "circle-stroke-width": 1,
            "circle-stroke-color": "#ffffff",
          },
        });
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        });

        map.current.on("mouseenter", "busy", (e) => {
          map.current.getCanvas().style.cursor = "pointer";
          const coordinates = e.features[0].geometry.coordinates.slice();
          const description = e.features[0].properties.description;

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          popup.setLngLat(coordinates).setHTML(description).addTo(map.current);
        });

        map.current.on("mouseleave", "busy", () => {
          map.current.getCanvas().style.cursor = "";
          popup.remove();
        });
      });

      // ll
      map.current.on("load", () => {
        map.current.addSource("offline", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: offlinePartnersData,
          },
        });
        // Add a layer showing the offline.
        map.current.addLayer({
          id: "offline",
          type: "circle",
          source: "offline",
          paint: {
            "circle-color": "red",
            "circle-radius": 6,
            "circle-stroke-width": 1,
            "circle-stroke-color": "#ffffff",
          },
        });
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        });

        map.current.on("mouseenter", "offline", (e) => {
          map.current.getCanvas().style.cursor = "pointer";
          const coordinates = e.features[0].geometry.coordinates.slice();
          const description = e.features[0].properties.description;

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          popup.setLngLat(coordinates).setHTML(description).addTo(map.current);
        });

        map.current.on("mouseleave", "offline", () => {
          map.current.getCanvas().style.cursor = "";
          popup.remove();
        });
      });
    }
    console.log("availablePartnersDataNicheh", availablePartnersData);
  }, [availablePartnersData, busyPartnersData]);
  return (
    <div>
      <div ref={mapContainer} style={{ height: "88vh" }} />
    </div>
  );
};

export default BhiwandiDeliveryPartnerLocation;
