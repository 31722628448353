import {
  Box,
  Button,
  Input,
  Modal,
  Paper,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AdminDataContext } from "./../useContext/AdminContext";
import moment from "moment/moment";
import { imgUrl } from "../url";
import OrderItemsDetailsModal from "./OrderItemsDetailsModal";
import OrderFilter from "./filterForAllOrder/OrderFilter";

// import ResLocationDeliveryBoy from "./restaurantLocationWithDeliveryBoys/ResLocationDeliveryBoy";

function AllOrders() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setLog([]);
  };

  const [openModal, setOpenModal] = useState(false);
  const [items, setItems] = useState([]);
  const [counter, setCounter] = useState(0);
  const [OrderItemsDetails, setOrderItemsDetails] = useState({});
  const handleOpenModal = (data) => {
    console.log("modal data", data);
    setOrderItemsDetails(data);
    setItems([]);

    console.log("OrderItemsDetails", data);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setItems([]);
    setCounter(0);
    setOpenModal(false);
  };

  const [openReject, setOpenReject] = useState(false);
  const [rejectionReject, setRejectionReject] = useState({
    rejectionReject: "",
    orderId: "",
  });
  const handleOpenReject = (id) => {
    setOpenReject(true);
    setRejectionReject({ ...rejectionReject, orderId: id });
  };
  const handleCloseReject = () => {
    setOpenReject(false);
    setRejectionReject({ rejectionReject: "", orderId: "" });
  };

  const { snackbar, setIsLoading } = useContext(AdminDataContext);
  const [complain, setComplain] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [log, setLog] = useState([]);
  const [loading, setLoading] = useState(true);

  // filter options

  const [filterOptions, setFilterOptions] = useState({
    city: "All",
    platform: "All",
    status: "All",
    deliveryStatus: "All",
    paymentMode: "All",
    paymentStatus: "All",
    couponType: "All",
  });

  const getTimeDeference = (first, second) => {
    var a = moment(first);
    var b = moment(second);

    return a.diff(b, "minutes");
  };
  const acceptOrder = async (id) => {
    try {
      setLoading(true);
      const res = await axios.post("/api/v1/admin/order/accept", {
        orderId: id,
      });
      setLoading(false);
      getAllOrders(page, limit, "", "");
      snackbar(res.data.message, "success");
    } catch (error) {
      setLoading(false);
      snackbar(error.response.data.message, "error");
    }
  };
  const readyOrder = async (id) => {
    try {
      setLoading(true);
      const res = await axios.post("/api/v1/admin/order/ready", {
        orderId: id,
      });
      setLoading(false);
      getAllOrders(page, limit, "", "");
      snackbar(res.data.message, "success");
    } catch (error) {
      setLoading(false);
      snackbar(error.response.data.message, "error");
    }
  };

  const rejectOrder = async (orderId, rejectionReject) => {
    try {
      setLoading(true);
      const res = await axios.post("/api/v1/admin/order/reject", {
        orderId,
        rejectionReject,
      });
      setLoading(false);
      getAllOrders(page, limit, "", "");
      snackbar(res.data.message, "success");
      handleCloseReject();
    } catch (error) {
      setLoading(false);
      snackbar(error.response.data.message, "error");
    }
  };

  const columns = [
    {
      field: "orderId",
      headerName: "Order Id",
      width: 100,
      renderHeader: () => <strong>{"Order Id"}</strong>,
    },
    {
      field: "Platform",
      headerName: "Platform",
      width: 100,
      renderHeader: () => <strong>{"Platform"}</strong>,

      renderCell: (params) => {
        return params.row.restaurant?.petPooja ? (
          <Typography>petPooja</Typography>
        ) : (
          <>Ybites</>
        );
      },
    },

    {
      field: "view-details",
      headerName: "View Details",
      renderHeader: () => <strong>{"View Details"}</strong>,
      width: 150,
      renderCell: (params) => {
        return (
          <Button onClick={() => handleOpenModal(params.row)}>
            {" "}
            View Details
          </Button>
        );
      },
    },

    {
      field: "paymentMode",
      headerName: "Mode",
      width: 100,
      renderHeader: () => <strong>{"Mode"}</strong>,
    },

    //sanaya
    {
      field: "actualmenuPriceperitem",
      headerName: "Menu Price",
      width: 200,
      renderHeader: () => <strong>{"Menu Price"}</strong>,
      renderCell: (params) => {
        if (params.row.orderItems && params.row.orderItems.length > 0) {
          const itemPrices = params.row.orderItems.map((item) => (
            <div key={item._id}>
              {`${item.variationTitle ? item.variationTitle : ""} ${
                item.itemTitle
              } = ${parseFloat(item.totalPrice / item.quantity).toFixed(2)}`}
            </div>
          ));

          return <div>{itemPrices}</div>;
        } else {
          return <Typography>-</Typography>;
        }
      },
    },
    {
      field: "menuanditsquantity",
      headerName: "Menu And Its Quantity",
      width: 250,
      renderHeader: () => <strong>{"Menu And Its Quantity"}</strong>,
      renderCell: (params) => {
        if (params.row.orderItems && params.row.orderItems.length > 0) {
          const items = params.row.orderItems.map((item) => {
            return `${item.variationTitle ? item.variationTitle : ""} ${
              item.itemTitle
            } x ${item.quantity}`;
          });

          return (
            <div>
              {items.map((menuItem, index) => (
                <Typography key={index}>{menuItem}</Typography>
              ))}
            </div>
          );
        } else {
          return <Typography>-</Typography>;
        }
      },
    },
    {
      field: "menuPriceperitem",
      headerName: "Menu Price per items",
      width: 200,
      renderHeader: () => <strong>{"Menu Price per items"}</strong>,
      renderCell: (params) => {
        if (params.row.orderItems && params.row.orderItems.length > 0) {
          const itemPrices = params.row.orderItems.map((item) => (
            <div key={item._id}>
              {`${item.variationTitle ? item.variationTitle : ""} ${
                item.itemTitle
              }  = ${parseFloat(item.totalPrice).toFixed(2)}`}
            </div>
          ));

          return <div>{itemPrices}</div>;
        } else {
          return <Typography>-</Typography>;
        }
      },
    },
    //sanaya
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderHeader: () => <strong>{"Status"}</strong>,
    },
    {
      field: "isCustomerRejected",
      headerName: "Rejected By Customer",
      width: 150,
      renderHeader: () => <strong>{"Rejected By Customer"}</strong>,
    },
    {
      field: "deliveryStatus",
      headerName: "Delivery Status",
      width: 150,
      renderHeader: () => <strong>{"Delivery Status"}</strong>,
    },

    {
      field: "createdAt",
      headerName: "Order Created At",
      width: 200,
      renderHeader: () => <strong>{"Order Created At"}</strong>,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "pickUpImage",
      headerName: "Pick-Up Image",
      width: 100,
      renderHeader: () => <strong>{"Pick-Up Image"}</strong>,

      renderCell: (params) => {
        return params.row.pickUpImage ? (
          <Box
            component="img"
            width="70px"
            height="70px"
            src={`${imgUrl}/${params.row.pickUpImage}`}
          ></Box>
        ) : (
          <></>
        );
      },
    },
    {
      field: "accepted",
      headerName: "Accepted",
      width: 100,
      renderHeader: () => <strong>{"Accepted"}</strong>,
      renderCell: (params) => {
        return params.row.acceptedAt ? (
          <Typography>
            {getTimeDeference(params.row.acceptedAt, params.row.createdAt)}
          </Typography>
        ) : (
          <></>
        );
      },
    },
    {
      field: "ready",
      headerName: "ready",
      width: 100,
      renderHeader: () => <strong>{"Ready"}</strong>,
      renderCell: (params) => {
        return params.row.readyAt ? (
          <Typography>
            {getTimeDeference(params.row.readyAt, params.row.createdAt)}
          </Typography>
        ) : (
          <></>
        );
      },
    },
    {
      field: "delivered",
      headerName: "delivered",
      width: 100,
      renderHeader: () => <strong>{"Delivered"}</strong>,
      renderCell: (params) => {
        return params.row.deliveredAt ? (
          <Typography>
            {getTimeDeference(params.row.deliveredAt, params.row.createdAt)}
          </Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "picked Up",
      headerName: "picked Up",
      width: 100,
      renderHeader: () => <strong>{"Picked Up"}</strong>,
      renderCell: (params) => {
        return params.row.pickedUpAt ? (
          <Typography>
            {getTimeDeference(params.row.pickedUpAt, params.row.createdAt)}
          </Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "C-location",
      headerName: "C-Location",
      width: 120,
      renderHeader: () => <strong>{"C-Location"}</strong>,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              const url = `http://maps.google.com/maps?z=12&t=m&q=loc:${params.row.customerAddress.location.coordinates[1]}+${params.row.customerAddress.location.coordinates[0]}`;
              window.open(url, "_blank");
            }}
          >
            Location
          </Button>
        );
      },
    },
    {
      field: "D-location",
      headerName: "D-Location",
      width: 120,
      renderHeader: () => <strong>{"D-Location"}</strong>,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              const url = `http://maps.google.com/maps?z=12&t=m&q=loc:${params.row.deliveryPartner.deliveryPartnerCurrentLocation.location.coordinates[1]}+${params.row.deliveryPartner.deliveryPartnerCurrentLocation.location.coordinates[0]}`;
              window.open(url, "_blank");
            }}
          >
            Location
          </Button>
        );
      },
    },
    {
      field: "R-location",
      headerName: "R-Location",
      width: 120,
      renderHeader: () => <strong>{"R-Location"}</strong>,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              const url = `http://maps.google.com/maps?z=12&t=m&q=loc:${params.row.restaurant.location.coordinates[1]}+${params.row.restaurant.location.coordinates[0]}`;
              window.open(url, "_blank");
            }}
          >
            Location
          </Button>
        );
      },
    },
    {
      field: "Restaurant",
      headerName: "Restaurant",
      width: 300,
      renderHeader: () => <strong>{"Restaurant"}</strong>,
      renderCell: (params) => {
        return params.row.restaurant ? (
          <Typography>{params.row.restaurant.brand_display_name}</Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "RestaurantContactPersonPhone",
      headerName: "R-Phone",
      width: 150,
      renderHeader: () => <strong>{"R-Phone"}</strong>,
      renderCell: (params) => {
        return params.row.restaurant ? (
          <Typography>{params.row.restaurant.merchant_number}</Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "customer",
      headerName: "customer",
      width: 150,
      renderHeader: () => <strong>{"Customer"}</strong>,
      renderCell: (params) => {
        return params.row.customer ? (
          <Typography>{params.row.customer.name}</Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "customerContactPersonPhone",
      headerName: "C-Phone",
      width: 150,
      renderHeader: () => <strong>{"C-Phone"}</strong>,
      renderCell: (params) => {
        return params.row.customer ? (
          <Typography>{params.row.customer.phone}</Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "paymentAmount",
      headerName: "Payment By Customer",
      width: 170,
      renderHeader: () => <strong>{"Payment By Customer"}</strong>,
      renderCell: (params) => {
        return params.row.paymentAmount ? (
          <Typography>{params.row.paymentAmount}</Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "deliveryPartner",
      headerName: "deliveryPartner",
      width: 150,
      renderHeader: () => <strong>{"DeliveryPartner"}</strong>,
      renderCell: (params) => {
        return params.row.deliveryPartner ? (
          <Typography>{params.row.deliveryPartner.name}</Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "Accept",
      headerName: "Accept",
      width: 150,
      renderHeader: () => <strong>{"Accept"}</strong>,
      renderCell: (params) => {
        return params.row.status === "Pending" ? (
          <Button onClick={() => acceptOrder(params.row._id)}>Accept</Button>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "Reject",
      headerName: "Reject",
      width: 150,
      renderHeader: () => <strong>{"Reject"}</strong>,
      renderCell: (params) => {
        return params.row.status === "Pending" ? (
          <Button
            onClick={() => {
              handleOpenReject(params.row._id);
            }}
          >
            Reject
          </Button>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "Ready",
      headerName: "Ready",
      width: 150,
      renderHeader: () => <strong>{"Ready"}</strong>,
      renderCell: (params) => {
        return params.row.status === "Preparing" ? (
          <Button onClick={() => readyOrder(params.row._id)}>Ready</Button>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "deliveryPartnerContactPersonPhone",
      headerName: "D-Phone",
      width: 150,
      renderHeader: () => <strong>{"D-Phone"}</strong>,
      renderCell: (params) => {
        return params.row.deliveryPartner ? (
          <Typography>{params.row.deliveryPartner.phone}</Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "orderItems",
      headerName: "Items ",
      width: 250,
      renderHeader: () => <strong>{"Items"}</strong>,
      renderCell: (params) => {
        return !(params.row.orderItems?.length === 0) ? (
          <Typography>
            {params.row.orderItems[0].itemTitle}
            {params.row.orderItems.length >= 2
              ? `, +${params.row.orderItems.length - 1} items`
              : ""}
          </Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "otp",
      headerName: "OTP",
      width: 80,
      renderHeader: () => <strong>{"OTP"}</strong>,
    },
  ];

  useEffect(() => {
    getAllOrders(page, limit, "", "", filterOptions);
  }, [filterOptions, limit]);

  const getAllOrders = async (pageNo, limitNo, sort, field, filterOptions) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/orders?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}&city=${
          filterOptions.city === "All" ? "" : filterOptions.city
        }&platform=${
          filterOptions.platform === "All" ? "" : filterOptions.platform
        }&status=${
          filterOptions.status === "All" ? "" : filterOptions.status
        }&deliveryStatus=${
          filterOptions.deliveryStatus === "All"
            ? ""
            : filterOptions.deliveryStatus
        }&paymentMode=${
          filterOptions.paymentMode === "All" ? "" : filterOptions.paymentMode
        }&paymentStatus=${
          filterOptions.paymentStatus === "All"
            ? ""
            : filterOptions.paymentStatus
        }&couponType=${
          filterOptions.couponType === "All" ? "" : filterOptions.couponType
        }`
      );
      setComplain(res.data.data);

      console.log("res.data.total", res.data.total);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSortModelChange = async (item) => {
    getAllOrders(page, limit, "", "", filterOptions);
    if (item.length === 0) {
      getAllOrders(page, limit, "", "", filterOptions);
    } else {
      getAllOrders(page, limit, item[0].sort, item[0].field);
    }
  };

  const onFilterChange = async (e) => {
    console.log(e.items);
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/admin/order/filter`, {
        key: e.items[0].columnField,
        value: e.items[0].value,
      });
      setComplain(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <Box>
      <OrderFilter
        setFilterOptions={setFilterOptions}
        filterOptions={filterOptions}
      />

      <DataGrid
        components={{ Toolbar: GridToolbar }}
        // autoHeight
        // height={600}
        getRowHeight={() => "auto"}
        rows={complain}
        getRowId={(row) => row._id}
        rowCount={total}
        loading={loading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={page - 1}
        pageSize={limit}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        onPageChange={(newPage) => {
          getAllOrders(newPage + 1, limit, "", "", filterOptions);
          setPage(newPage + 1);
        }}
        onPageSizeChange={(newPageSize) => {
          getAllOrders(page, newPageSize, "", "");
          setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={columns}
        sx={{
          "& .bold-header": {
            fontWeight: "bold",
            color: "blue",
          },
          height: "550px",
          overflow: "auto",
        }}
      />
      <OrderItemsDetailsModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        OrderItemsDetails={OrderItemsDetails}
        items={items}
        setItems={setItems}
        counter={counter}
        setCounter={setCounter}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Amount</TableCell>
                  <TableCell align="center">Date And Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {log.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {index + 1}
                    </TableCell>

                    <TableCell align="center">{row.amountSubmitted}</TableCell>
                    <TableCell align="center">
                      {new Date(row.date).toLocaleString("en-GB", {
                        timeZone: "IST",
                      })}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
      <Modal
        open={openReject}
        onClose={handleCloseReject}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reject Rejection
          </Typography>
          {/* <TextField
            id="outlined-basic"
            label="Outlined"
            onClick={(e) => {
              setRejectionReject({
                ...rejectionReject,
                rejectionReject: e.target.value,
              });
            }}
            variant="outlined"
          /> */}
          <TextField
            id="outlined-basic"
            label="Outlined"
            onChange={(e) => {
              setRejectionReject({
                ...rejectionReject,
                rejectionReject: e.target.value,
              });
            }}
            variant="outlined"
          />
          <Button
            onClick={() =>
              rejectOrder(
                rejectionReject.orderId,
                rejectionReject.rejectionReject
              )
            }
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default AllOrders;
