import React, { useState, useEffect, useContext } from "react";

import { Button, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import { AdminDataContext } from "../../../../useContext/AdminContext";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddAndEditVariantsModal from "./AddAndEditVariantsModal";
import axios from "axios";
import { submitBtn } from "../../../menuItem/itemCreate/CssStyle";

const Variants = () => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const { restaurantId } = useParams();
  const [variants, setVariants] = useState([]);
  const [variant, setVariant] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [rowId, setRowId] = useState(null);
  const [restaurantName, setRestaurantName] = useState("");
  const getDate = async () => {
    try {
      const res = await axios.get(
        `/api/v1/admin/restaurant?restaurantId=${restaurantId}`
      );
      setVariants(res.data.restaurant.variations);
      setRestaurantName(res.data.restaurant.brand_display_name);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDate();
  }, []);

  const [addEditVariant, setAddEditVariant] = useState(false);

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    let res;
    try {
      setIsLoading(true);
      if (rowId) {
        res = await axios.patch(
          `/api/v1/admin/variation?variationId=${rowId}`,
          variant
        );
        setRowId(null);
      } else {
        res = await axios.post(
          `/api/v1/admin/variation?restaurantId=${restaurantId}`,
          variant
        );
      }

      snackbar(res.data.message, res.data.status);
      setVariant({});
      handleOpenAddEditVariantModal();
      getDate();
      setIsLoading(false);
    } catch (error) {
      snackbar(error.response.data.message, error.response.data.status);
      setIsLoading(false);
    }
  };

  const handleOpenAddEditVariantModal = () => {
    setAddEditVariant(!addEditVariant);
    setRowId(null);
    setVariant({});
  };

  const handleEdit = (row) => {
    handleOpenAddEditVariantModal();
    console.log(`Edit clicked for ID: ${row._id}`);
    setVariant(row);
    setRowId(row._id);
  };

  const handleDelete = async (variationId) => {
    try {
      setIsLoading(true);
      const res = await axios.delete(`/api/v1/admin/variation/${variationId}`);
      snackbar(res.data.message, res.data.status);

      getDate();
      setIsLoading(false);
    } catch (error) {
      snackbar(error.response.data.message, error.response.data.status);
      setIsLoading(false);
    }
  };

  const columns = [
    { field: "variationid", headerName: "ID", width: 150 },

    {
      field: "name",
      headerName: " Item Name",
      minWidth: 180,
      editable: true,
    },
    {
      field: "groupname",
      headerName: "Group Name",
      minWidth: 180,
      editable: true,
    },

    {
      field: "status",
      headerName: "Status",
      minWidth: 80,
    },

    // {
    //   field: "Actions",
    //   headerName: "Action",
    //   type: "action",
    //   width: 100,
    //   renderCell: (params) => <Actions {...{ params, rowId, setRowId }} />,
    // },
    {
      field: "edit",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          startIcon={<EditIcon />}
          onClick={() => handleEdit(params.row)}
        ></Button>
      ),
    },

    {
      field: "delete",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          endIcon={<DeleteIcon />}
          onClick={() => handleDelete(params.row._id)}
        ></Button>
      ),
    },
  ];

  return (
    <>
      <Box>
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Grid item xs={10} sm={5}>
            <Button
              variant="contained"
              onClick={() => handleOpenAddEditVariantModal()}
              sx={submitBtn}
            >
              Add Variation
            </Button>
            <Typography
              sx={{ color: "#0C1844", fontWeight: "bold", mt: 2 }}
            >{`${restaurantName}`}</Typography>
          </Grid>
        </Grid>
      </Box>
      <div
        style={{
          height: 500,
          width: "100%",
          marginTop: "20px",
        }}
      >
        <DataGrid
          columns={columns}
          getRowId={(row) => row._id}
          rows={variants}
          rowsPerPageOptions={[10, 25, 100]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          // onCellEditCommit={(params) => setRowId(params.id)}
          onCellClick={(params) => setRowId(params.id)}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "dataGrid.header",
              color: "black.main",
              fontSize: 16,
            },
          }}
        />
      </div>
      <AddAndEditVariantsModal
        handleSubmitForm={handleSubmitForm}
        variant={variant}
        setVariant={setVariant}
        addEditVariant={addEditVariant}
        handleOpenAddEditVariantModal={handleOpenAddEditVariantModal}
        rowId={rowId}
      />
    </>
  );
};

export default Variants;
