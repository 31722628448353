import React from "react";
import SelectRestaurantMenuUpload from "../restaurant/petPoojaMenuItems/selectRestaurant/SelectRestaurantMenuUpload";
const AddonsSelect = () => {
  return (
    <div>
      <SelectRestaurantMenuUpload type={"/i/addons"} />
    </div>
  );
};

export default AddonsSelect;
