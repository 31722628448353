import { Box, Button, Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import DragsAndDrop from "../../../../../reusableComponent/imageUpload/DragsAndDrop";
import axios from "axios";
import { AdminDataContext } from "../../../../../useContext/AdminContext";
import { imgUrl } from "../../../../../url";

const RestaurantLogo = (props) => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [image, setImage] = useState("");

  const imageSubmitHandler = async () => {
    try {
      console.log("Image id", props.id);
      setIsLoading(true);
      let formData = new FormData();
      formData.append("image", image);
      console.log(image);
      const res = await axios.patch(
        `/api/v1/admin/restaurant/create?restaurantId=${props.id}`,
        formData
      );
      console.log("props.id ", props.id);

      snackbar(res.data.message, "success");
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      snackbar("Some error", "error");
      setIsLoading(false);
    }
  };
  console.log("loho", props.restaurantLogo);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        spacing={2}
      >
        <Grid
          container
          spacing={4}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item xs={8} sm={6} md={5}>
            <DragsAndDrop
              heading="Upload Image"
              inputName="Image"
              imgKey={props.restaurantLogo}
              aspect={2 / 1.2}
              uploadFile={(x) => {
                setImage(x);
              }}
            />
          </Grid>
        </Grid>

        <Button
          sx={{ mt: { xs: 2, sm: 2, md: 5 } }}
          disabled={image ? false : true}
          variant="contained"
          onClick={imageSubmitHandler}
        >
          Upload Images
        </Button>
      </Box>
    </>
  );
};

export default RestaurantLogo;
