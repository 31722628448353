import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Paper,
  useMediaQuery,
  Box,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { AdminDataContext } from "../../../../useContext/AdminContext";

import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import CreateRestaurantModal from "../createRestaurantModal/CreateRestaurantModal";
import {
  containerStyle,
  imageBox,
  paperBox,
  paperStyle,
  restaurantDetailsGrid,
  submitBtn,
} from "../../../menuItem/itemCreate/CssStyle";

const RestaurantList = (props) => {
  const matches = useMediaQuery("(min-width:1100px)");

  const { snackbar, setIsLoading } = useContext(AdminDataContext);
  const { id } = useParams();
  const [openModal, setOpenModal] = React.useState(false);
  const [index, setIndex] = React.useState(false);

  const handleModal = () => setOpenModal(!openModal);

  const [restaurant, setRestaurant] = React.useState(props.restaurantList);
  const navigate = useNavigate();

  const restaurantBasicDetails = (id) => {
    navigate(`/restaurant/create-restaurant-details/${id}`);
  };
  console.log("id isssss", id);
  const getRestaurant = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`/api/v1/admin/owner/single?ownerId=${id}`);
      setRestaurant(res.data.owner.restaurant);
      setIsLoading(false);

      console.log(res.data.owner.restaurant);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRestaurant();
  }, []);

  // const changeRestaurant = (id) => {
  //   localStorage.setItem("restaurantId", id);
  //   navigate("/");
  //   console.log("clicked");
  // };
  console.log("restaurants dd", restaurant);

  return (
    <>
      <Box>
        <Container fixed sx={containerStyle}>
          <Paper sx={paperStyle} elevation={10}>
            <Box sx={paperBox}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "200px",
                }}
                component="img"
                alt="logo"
                src="/images/logoCreateRest.svg"
              ></Box>

              <Box
                sx={{
                  display: "flex",
                  // flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "evenly",
                }}
              >
                <Typography sx={{ color: "grey.tertiary", fontWeight: 600 }}>
                  SELECT YOUR RESTAURANT
                </Typography>

                <Button
                  variant="contained"
                  sx={{ mx: 2 }}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </Box>
            </Box>

            {restaurant &&
              restaurant.map((data, i) => {
                return (
                  <>
                    <Grid
                      container
                      key={data._id}
                      // onClick={() =>
                      //   data.approved === false
                      //     ? restaurantBasicDetails(data._id)
                      //     : changeRestaurant(data._id)
                      // }
                      onClick={() => restaurantBasicDetails(data._id)}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: 4,
                        borderRadius: "100px",
                      }}
                    >
                      <Grid item xs={8} sm={3} md={1}>
                        <Box
                          sx={imageBox}
                          component="img"
                          alt="logo"
                          src={"/images/defaultRes.svg"}
                        ></Box>
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        sm={6}
                        md={4}
                        sx={restaurantDetailsGrid}
                      >
                        <Typography
                          sx={{ color: "grey.main", fontWeight: 600, mt: 1 }}
                        >
                          {data.brand_display_name}
                        </Typography>
                        <Typography
                          sx={{ color: "grey.main", fontWeight: 600, mt: 1 }}
                        >
                          {data.state}, {data.city}, {data.landmark}
                        </Typography>

                        <Typography
                          variant="h6"
                          fontWeight={500}
                          fontSize="15px"
                          lineHeight="20px"
                          sx={{ color: "rgba(13, 30, 55, 0.67)", mt: 1 }}
                        >
                          {data.latitude} {data.longitude}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                );
              })}

            <Button
              id="sign-in-button"
              onClick={handleModal}
              sx={submitBtn}
              variant="contained"
              size="large"
            >
              Create New Restaurant
            </Button>
          </Paper>
        </Container>
      </Box>

      <CreateRestaurantModal
        handleModal={handleModal}
        openModal={openModal}
        ownerId={props.ownerId}
        getRestaurant={getRestaurant}
      />
    </>
  );
};

export default RestaurantList;
