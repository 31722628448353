import { Box, Button, Typography, Grid, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";

import moment from "moment";
import {
  textHeadingInputFields,
  textFieldBox,
  addBtn,
} from "../../cssStyling/Style";
import { capitalize } from "../../../reusableComponent/capitalFirstCharacter";

const ActiveRestaurants = () => {
  const [restaurant, setRestaurant] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState("");
  const getAllRestaurant = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);

      const res = await axios.get(
        `/api/v1/admin/restaurant/active-restaurant?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}&date=${date}`
      );
      setRestaurant(res.data.restaurant);
      console.log("res.data.restaurantId", res.data.restaurant);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllRestaurant(page, limit, "", "");
  }, []);
  // renderCell: (params) => capitalize(params.row.owner_name),
  const columns = [
    {
      field: "brand_display_name",
      headerName: "Name",
      width: 250,
      renderHeader: () => <strong>{"Name"}</strong>,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {capitalize(params.row.restaurantId.brand_display_name)}
            </Typography>
          </>
        );
      },
    },
    {
      field: "owner_name",
      headerName: "Owner Name",
      width: 300,
      renderHeader: () => <strong>{"Owner Name"}</strong>,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {capitalize(params.row.restaurantId.owner_name)}
            </Typography>
          </>
        );
      },
    },
    {
      field: "lastOpenTime",
      headerName: "Last Open Time",
      width: 250,
      renderHeader: () => <strong>{"Last Open Time"}</strong>,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {moment(params.row.lastOpenTime).format("DD-MM-YYYY  hh:mm A")}
            </Typography>
          </>
        );
      },
    },
    {
      field: "items",
      headerName: "Menu Items",
      width: 100,
      renderHeader: () => <strong>{"Menu Items"}</strong>,
      renderCell: (params) => {
        return (
          <>
            <Typography>{params.row.restaurantId.items.length}</Typography>
          </>
        );
      },
    },
    {
      field: "outlet_id",
      headerName: "Outlet Id",
      width: 250,
      renderHeader: () => <strong>{"Outlet Id"}</strong>,
      renderCell: (params) => {
        return (
          <>
            <Typography>{params.row.restaurantId.outlet_id}</Typography>
          </>
        );
      },
    },
    {
      field: "business_contact",
      headerName: "Business Contact",
      width: 250,
      renderHeader: () => <strong>{"Business Contact"}</strong>,
      renderCell: (params) => {
        return (
          <>
            <Typography>{params.row.restaurantId.business_contact}</Typography>
          </>
        );
      },
    },

    {
      field: "merchant_number",
      headerName: "Merchant Number",
      width: 250,
      renderHeader: () => <strong>{"Merchant Number"}</strong>,
      renderCell: (params) => {
        return (
          <>
            <Typography>{params.row.restaurantId.merchant_number}</Typography>
          </>
        );
      },
    },
    {
      field: "address",
      headerName: "Address",
      width: 250,
      renderHeader: () => <strong>{"Address"}</strong>,
      renderCell: (params) => {
        return (
          <>
            <Typography>{params.row.restaurantId.address}</Typography>
          </>
        );
      },
    },
    {
      field: "pincode",
      headerName: "Pincode",
      width: 250,
      renderHeader: () => <strong>{"Pin Code"}</strong>,
      renderCell: (params) => {
        return (
          <>
            <Typography>{params.row.restaurantId.pincode}</Typography>
          </>
        );
      },
    },
    {
      field: "city",
      headerName: "City",
      width: 250,
      renderHeader: () => <strong>{"City"}</strong>,
      renderCell: (params) => {
        return (
          <>
            <Typography>{params.row.restaurantId.city}</Typography>
          </>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      renderHeader: () => <strong>{"Email"}</strong>,
      renderCell: (params) => {
        return (
          <>
            <Typography>{params.row.restaurantId.email}</Typography>
          </>
        );
      },
    },

    {
      field: "open",
      headerName: "open",
      width: 100,
      renderHeader: () => <strong>{"Open"}</strong>,
      renderCell: (params) => {
        return (
          <>
            <Typography>{params.row.restaurantId.open}</Typography>
          </>
        );
      },
    },
    {
      field: "rating",
      headerName: "Rating",
      width: 100,
      renderHeader: () => <strong>{"Rating"}</strong>,
      renderCell: (params) => {
        return (
          <>
            <Typography>{params.row.restaurantId.rating}</Typography>
          </>
        );
      },
    },
    {
      field: "ratingCount",
      headerName: "ratingCount",
      width: 100,
      renderHeader: () => <strong>{"Rating Count"}</strong>,
      renderCell: (params) => {
        return (
          <>
            <Typography>{params.row.restaurantId.ratingCount}</Typography>
          </>
        );
      },
    },

    {
      field: "note",
      headerName: "note",
      width: 120,
      renderHeader: () => <strong>{"Note"}</strong>,
      renderCell: (params) => {
        return params.row.note ? (
          <Typography>{params.row.restaurantId.note}</Typography>
        ) : (
          <Button>-</Button>
        );
      },
    },
  ];

  return (
    <div>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "space-around", mb: 2 }}
      >
        <Grid
          item
          xs={12}
          sm={3}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box
            sx={{
              ...textFieldBox,
              display: "flex",
              flexDirection: "column",
              justifyContent: { xs: "center", sm: "start" },
            }}
          ></Box>
        </Grid>

        <Grid
          item
          xs={6}
          sm={3}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box
            sx={{
              ...textFieldBox,
              display: "flex",
              flexDirection: "column",
              justifyContent: { xs: "center", sm: "start" },
            }}
          >
            <Typography variant="body1" sx={textHeadingInputFields}>
              Restaurant open from : {date ? date : "All Time"}
            </Typography>

            <TextField
              size="small"
              id="outlined-basic"
              type="date"
              variant="outlined"
              name="date"
              // onChange={onChangeDate}
              onChange={(e) => setDate(e.target.value)}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={6}
          sm={6}
          sx={{
            display: "flex",
            // justifyContent: { xs: "center", sm: "start" },
          }}
        >
          {date ? (
            <>
              <Box
                sx={{
                  ...textFieldBox,
                  display: "flex",
                  // flexDirection: "column",
                  justifyContent: { xs: "center", sm: "start" },
                  mt: 4.6,
                }}
              >
                <Button
                  sx={{
                    ...addBtn,
                    m: 0,
                    height: "40px",
                    width: "max-content",
                  }}
                  onClick={() => getAllRestaurant(page, limit, "", "")}
                  variant="contained"
                >
                  Get Date
                </Button>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>{" "}
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        // autoHeight
        rows={restaurant}
        getRowId={(row) => row._id}
        rowCount={total}
        loading={loading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={page - 1}
        pageSize={limit}
        paginationMode="server"
        sortingMode="server"
        // onSortModelChange={handleSortModelChange}
        filterMode="server"
        // onFilterModelChange={onFilterChange}
        onPageChange={(newPage) => {
          getAllRestaurant(newPage + 1, limit, "", "");
          setPage(newPage + 1);
        }}
        onPageSizeChange={(newPageSize) => {
          getAllRestaurant(page, newPageSize, "", "");
          setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={columns}
        sx={{
          "& .bold-header": {
            fontWeight: "bold",
            color: "blue",
          },
          height: "600px",
          overflow: "auto",
        }}
      />
    </div>
  );
};

export default ActiveRestaurants;
