import { Box, Button, TextField, Autocomplete } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";

import { AdminDataContext } from "./../../../useContext/AdminContext";

import { imgUrl } from "../../../url";

import MenuItemModal from "./MenuItemModal";

function BhiwandiRestaurant() {
  const { selectCity, filterType, setFilterType } =
    useContext(AdminDataContext);

  const [restaurant, setRestaurant] = useState([]);
  //   const [currentRestaurant, setCurrentRestaurant] = useState(null);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);

  const [restaurantList, setRestaurantList] = useState([]);

  const [singleRestaurant, setSingleRestaurant] = useState([]);
  const [restaurantName, setRestaurantName] = useState("");
  const [id, setId] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (id, resName) => {
    setId(id);
    setRestaurantName(resName);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setId(null);
    setRestaurantName("");
    setOpenModal(false);
  };

  const columns = [
    {
      field: "logo",
      headerName: "Logo",
      width: 110,
      renderCell: (params) => {
        return params.row.restaurant_logo ? (
          <>
            <Box
              height={"100%"}
              component="img"
              src={
                params.row.restaurant_logo.startsWith("http")
                  ? `${params.row.restaurant_logo}`
                  : `${imgUrl}/${params.row.restaurant_logo}`
              }
            />
          </>
        ) : (
          "No data available"
        );
      },
    },
    {
      field: "brand_display_name",
      headerName: "Name",
      width: 250,
    },
    {
      field: "owner_name",
      headerName: "Owner Name",
      width: 150,
    },

    {
      field: "business_contact",
      headerName: "Business Contact",
      width: 250,
    },

    {
      field: "restaurant",
      headerName: "Restaurant",
      width: 120,
      renderCell: (params) => {
        return params.row ? (
          <Button
            variant="contained"
            onClick={() =>
              handleOpenModal(params.row._id, params.row.brand_display_name)
            }
          >
            Menu Items
          </Button>
        ) : (
          "No data available"
        );
      },
    },
  ];

  useEffect(() => {
    getAllRestaurant(page, limit, "", "");
  }, [selectCity]);

  const getAllRestaurant = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      setIsSelected(false);
      setFilterType({ ...filterType, restaurant: false, city: true });

      const res = await axios.get(
        `/api/v1/admin/getAllRestaurant?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}&city=${selectCity}`
      );
      setRestaurant(res.data.data);
      console.log("res.data.restaurant", res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getSingleRestaurantForEdit = async (id) => {
    try {
      console.log("getSingleRestaurId", id);
      setLoading(true);
      let row = [];
      const res = await axios.get(
        `/api/v1/admin/restaurants/singleRestaurantEdit?restaurantId=${id}`
      );
      // setRestaurant(res.data.restaurant);
      row.push(res.data.restaurant);
      // setSingleRestaurant(res.data.restaurant);
      setSingleRestaurant(row);

      console.log("res.data.restaurant edit", res.data.restaurant);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getSearchSingleRestaurant = async (e) => {
    try {
      const res = await axios.put(
        `/api/v1/admin/restaurants?query=${e.target.value}`
      );
      console.log("list ", res.data);
      if (!(res.data.data.length === 0)) {
        setRestaurantList(res.data.data);
      } else {
        setRestaurantList([]);
        console.log("none");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSortModelChange = async (item) => {
    getAllRestaurant(page, limit, "", "");
    if (item.length === 0) {
      getAllRestaurant(page, limit, "", "");
    } else {
      getAllRestaurant(page, limit, item[0].sort, item[0].field);
    }
  };
  const onFilterChange = async (e) => {
    console.log(e);
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/admin/get/restaurants/search`, {
        key: e.items[0].columnField,
        value: e.items[0].value,
      });
      setRestaurant(res.data.Restaurant);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Box>
      {/* <Autocomplete
        sx={{ mb: 2 }}
        size="small"
        disableClearable
        fullWidth
        id="tags-outlined"
        options={restaurantList}
        getOptionLabel={(userData) =>
          `${userData.brand_display_name}  Number : ${userData.business_contact}`
        }
        onInputChange={getSearchSingleRestaurant}
        onChange={(event, value) => {
          let row = [];
          setRestaurantList(value);
          row.push(value);
          console.log("row data is ", row);
          // setSingleRestaurant(row);
          getSingleRestaurantForEdit(value._id);
          setIsSelected(true);
        }}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Restaurant"
            placeholder="Select Restaurant"
          />
        )}
      /> */}
      {isSelected ? (
        <>
          {console.log("inGrid", singleRestaurant)}
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            autoHeight
            rows={singleRestaurant}
            getRowId={(row) => row._id}
            // loading={loading}
            // sortingMode="server"
            pageSize={5}
            rowsPerPageOptions={[5]}
            columns={columns}
          />
        </>
      ) : id ? (
        <MenuItemModal
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          id={id}
          restaurantName={restaurantName}
          getAllRestaurant={getAllRestaurant}
        />
      ) : (
        <DataGrid
          components={{ Toolbar: GridToolbar }}
          autoHeight
          rows={restaurant}
          getRowId={(row) => row._id}
          rowCount={total}
          loading={loading}
          rowsPerPageOptions={[10, 30, 50, 70, 100]}
          pagination
          page={page - 1}
          pageSize={limit}
          paginationMode="server"
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          filterMode="server"
          onFilterModelChange={onFilterChange}
          onPageChange={(newPage) => {
            getAllRestaurant(newPage + 1, limit, "", "");
            setPage(newPage + 1);
          }}
          onPageSizeChange={(newPageSize) => {
            getAllRestaurant(page, newPageSize, "", "");
            setLimit(newPageSize);
            //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
          }}
          columns={columns}
        />
      )}

      {/* {id ? (
        <MenuItemModal
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          id={id}
          getAllRestaurant={getAllRestaurant}
        />
      ) : (
        <></>
      )} */}
    </Box>
  );
}

export default BhiwandiRestaurant;
