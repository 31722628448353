import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useContext } from "react";
import DragsAndDrop from "../../../../../reusableComponent/imageUpload/DragsAndDrop";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { AdminDataContext } from "../../../../../useContext/AdminContext";
import {
  topHeading,
  topBox,
  textHeadingInputFields,
  textFieldStyle,
  submitBtn,
  addBtn,
  ScheduleBtn,
  textFieldBox,
} from "../../../../menuItem/itemCreate/CssStyle";

const EditCategory = ({
  handleChildCategory,
  restaurantId,
  getDate,
  category,
  restaurantName,
}) => {
  const { snackbar, setIsLoading } = useContext(AdminDataContext);
  const timeArray = [
    { label: "00:00" },
    { label: "00:30" },
    { label: "01:00" },
    { label: "01:30" },
    { label: "02:00" },
    { label: "02:30" },
    { label: "03:00" },
    { label: "03:30" },
    { label: "04:00" },
    { label: "04:30" },
    { label: "05:00" },
    { label: "05:30" },
    { label: "04:00" },
    { label: "04:30" },
    { label: "05:00" },
    { label: "05:30" },
    { label: "06:00" },
    { label: "06:30" },
    { label: "07:00" },
    { label: "07:30" },
    { label: "08:00" },
    { label: "08:30" },
    { label: "09:00" },
    { label: "09:30" },
    { label: "10:00" },
    { label: "10:30" },
    { label: "11:00" },
    { label: "11:30" },
    { label: "12:00" },
    { label: "12:30" },
    { label: "13:00" },
    { label: "13:30" },
    { label: "14:00" },
    { label: "14:30" },
    { label: "15:00" },
    { label: "15:30" },
    { label: "14:00" },
    { label: "14:30" },
    { label: "15:00" },
    { label: "15:30" },
    { label: "16:00" },
    { label: "16:30" },
    { label: "17:00" },
    { label: "17:30" },
    { label: "18:00" },
    { label: "18:30" },
    { label: "19:00" },
    { label: "19:30" },
    { label: "20:00" },
    { label: "20:30" },
    { label: "21:00" },
    { label: "21:30" },
    { label: "22:00" },
    { label: "22:30" },
    { label: "23:00" },
    { label: "23:30" },
  ];

  console.log("category", category);
  const [image, setImage] = useState("");
  const [categoryname, setCategoryName] = useState(
    category ? category.categoryname : ""
  );
  const dummy = {
    schedule_name: "Timing",
    schedule_day: "All",
    schedule_time_slots: [
      {
        start_time: "04:00",
        end_time: "08:00",
      },
    ],
  };

  let time = JSON.parse(category.categorytimings);

  console.log("final", time);

  console.log("only TIming", JSON.parse(category.categorytimings));
  const [categorytimings, setCategoryTimings] = useState("");
  // {...categorytimings,  category ? category.categorytimings : dummy}
  const dayChangeHandler = (checked, day) => {
    const currentArray = categorytimings.schedule_day.split(",");
    if (checked) {
      currentArray.push(day);
      setCategoryTimings({
        ...categorytimings,
        schedule_day: currentArray.join(","),
      });
    } else {
      setCategoryTimings({
        ...categorytimings,
        schedule_day: currentArray.filter((word) => !(word === day)).join(","),
      });
    }
  };

  const summitHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await axios.patch(
        `/api/v1/admin/item/category?categoryId=${category.categoryid}`,
        {
          categoryname,
          categorytimings,
        }
      );
      if (image) {
        let formData = new FormData();
        formData.append("image", image);
        const res2 = await axios.put(
          `/api/v1/admin/item/category?categoryId=${res.data.data._id}`,
          formData
        );
      }
      handleChildCategory(true, false, false);
      snackbar(res.data.message, res.data.status);
      setIsLoading(false);
      setImage("");
      setCategoryTimings("");
      setCategoryName("");
      getDate();
    } catch (error) {
      snackbar(error.response.data.message, error.response.data.status);

      setIsLoading(false);
    }
  };
  return (
    <div>
      <>
        <Box
          sx={{
            position: "relative",
            backgroundColor: "grey.midGrey",
            p: 2,
            borderRadius: "16px",
            mt: 3,
          }}
        >
          <Typography variant="body1" sx={topHeading}>
            Edit Category -{" "}
            <span style={{ color: "#0C1844" }}>{restaurantName} </span>
          </Typography>
        </Box>

        <Grid
          container
          spacing={2}
          component={"form"}
          onSubmit={summitHandler}
          mt={1}
          sx={{ display: "flex", justifyContent: "start" }}
        >
          <Grid item xs={12} sm={12} md={5}>
            <Box sx={textFieldBox}>
              <Typography variant="body1" sx={textHeadingInputFields}>
                Category Name*
              </Typography>

              <TextField
                size="small"
                name="categoryname"
                type="text"
                required
                placeholder="Name"
                autoFocus
                sx={textFieldStyle}
                onChange={(e) => setCategoryName(e.target.value)}
                value={categoryname}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={5}>
            <Box sx={textFieldBox}>
              <Typography variant="body1" sx={textHeadingInputFields}>
                Image
              </Typography>

              <Box sx={{ width: 250 }}>
                <DragsAndDrop
                  heading="Upload Image"
                  inputName="Image"
                  imgKey={category.category_image_url}
                  aspect={1.8 / 1}
                  img
                  uploadFile={(x) => {
                    setImage(x);
                  }}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={5}>
            <Box sx={ScheduleBtn}>
              <Typography variant="body1" sx={textHeadingInputFields}>
                Category Schedule
              </Typography>
              {categorytimings ? (
                <Button
                  variant="contained"
                  sx={addBtn}
                  onClick={() => setCategoryTimings("")}
                >
                  Remove Schedule
                </Button>
              ) : (
                <Button
                  variant="contained"
                  // onClick={() => setCategoryTimings(dummy)}
                  onClick={() => setCategoryTimings(time)}
                  sx={addBtn}
                >
                  {console.log("see", category.categorytimings)}
                  Add Schedule
                </Button>
              )}
            </Box>
          </Grid>

          {categorytimings ? (
            <Box
              sx={{
                display: categorytimings ? "block" : "none",
                width: "100%",
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{ display: "flex", justifyContent: "start" }}
              >
                <Grid item xs={12} sm={12} md={5}>
                  <Box sx={textFieldBox}>
                    <Typography variant="body1" sx={textHeadingInputFields}>
                      Name
                    </Typography>

                    <TextField
                      size="small"
                      name="schedule_name"
                      type="text"
                      disabled
                      required
                      placeholder="Schedule Name"
                      onChange={(e) =>
                        setCategoryTimings({
                          ...categorytimings,
                          schedule_name: e.target.value,
                        })
                      }
                      value={categorytimings.schedule_name}
                      sx={textFieldStyle}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={5}>
                  <Box sx={textFieldBox}>
                    <Typography variant="body1" sx={textHeadingInputFields}>
                      Days *
                    </Typography>

                    <Box>
                      <FormControlLabel
                        checked={
                          categorytimings.schedule_day === "All" ? true : false
                        }
                        value="All"
                        name="schedule_day"
                        control={<Checkbox />}
                        label="All Days"
                        labelPlacement="end"
                        onChange={(e) => {
                          setCategoryTimings({
                            ...categorytimings,
                            schedule_day: !e.target.checked ? "" : "All",
                          });
                        }}
                      />
                    </Box>
                    {categorytimings &&
                    !(categorytimings.schedule_day === "All") ? (
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            checked={
                              categorytimings.schedule_day
                                .split(",")
                                .includes("Sun")
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              dayChangeHandler(e.target.checked, "Sun");
                            }}
                            value="home delivery"
                            control={<Checkbox />}
                            label="Sun"
                            labelPlacement="end"
                          />

                          <FormControlLabel
                            checked={
                              categorytimings.schedule_day
                                .split(",")
                                .includes("Mon")
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              dayChangeHandler(e.target.checked, "Mon");
                            }}
                            value="Pick Up"
                            control={<Checkbox />}
                            label="Mon"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            checked={
                              categorytimings.schedule_day
                                .split(",")
                                .includes("Tue")
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              dayChangeHandler(e.target.checked, "Tue");
                            }}
                            value="Dine In"
                            control={<Checkbox />}
                            label="Tue"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            checked={
                              categorytimings.schedule_day
                                .split(",")
                                .includes("Wed")
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              dayChangeHandler(e.target.checked, "Wed");
                            }}
                            value="Dine In"
                            control={<Checkbox />}
                            label="Wed"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            checked={
                              categorytimings.schedule_day
                                .split(",")
                                .includes("Thu")
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              dayChangeHandler(e.target.checked, "Thu");
                            }}
                            value="Dine In"
                            control={<Checkbox />}
                            label="Thu"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            checked={
                              categorytimings.schedule_day
                                .split(",")
                                .includes("Fri")
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              dayChangeHandler(e.target.checked, "Fri");
                            }}
                            value="Dine In"
                            control={<Checkbox />}
                            label="Fri"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            checked={
                              categorytimings.schedule_day
                                .split(",")
                                .includes("Sat")
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              dayChangeHandler(e.target.checked, "Sat");
                            }}
                            value="Dine In"
                            control={<Checkbox />}
                            label="Sat"
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </FormControl>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={5}>
                <Box sx={ScheduleBtn}>
                  <Typography variant="body1" sx={textHeadingInputFields}>
                    Timings
                  </Typography>

                  <Button
                    variant="contained"
                    onClick={() => {
                      const array = categorytimings.schedule_time_slots;
                      array.push({
                        start_time: "04:00",
                        end_time: "08:00",
                      });
                      setCategoryTimings({
                        ...categorytimings,
                        schedule_time_slots: array,
                      });
                    }}
                    sx={addBtn}
                  >
                    Add Timing
                  </Button>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      From *
                    </Grid>
                    <Grid item xs={6}>
                      To*
                    </Grid>
                  </Grid>

                  {categorytimings &&
                    categorytimings.schedule_time_slots.map((time, index) => (
                      <Grid container spacing={2} mt={1} key={index}>
                        <Grid item xs={5} key={index}>
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="start_time"
                              value={time.start_time}
                              onChange={(e) => {
                                const timeArray =
                                  categorytimings.schedule_time_slots;
                                timeArray[index].start_time = e.target.value;
                                setCategoryTimings({
                                  ...categorytimings,
                                  schedule_time_slots: timeArray,
                                });
                              }}
                              size="small"
                              // MenuProps={MenuProps}
                            >
                              {timeArray.map((time3, index3) => (
                                <MenuItem key={index3} value={time3.label}>
                                  {time3.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="end_time"
                              value={time.end_time}
                              onChange={(e) => {
                                const timeArray =
                                  categorytimings.schedule_time_slots;
                                timeArray[index].end_time = e.target.value;
                                setCategoryTimings({
                                  ...categorytimings,
                                  schedule_time_slots: timeArray,
                                });
                              }}
                              size="small"
                              // MenuProps={MenuProps}
                            >
                              {timeArray.map((time3, index3) => (
                                <MenuItem key={index3} value={time3.label}>
                                  {time3.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            onClick={() => {
                              const array = categorytimings.schedule_time_slots;
                              if (!(array.length === 1)) {
                                setCategoryTimings({
                                  ...categorytimings,
                                  schedule_time_slots: array.filter(
                                    (_, index1) => !(index1 === index)
                                  ),
                                });
                              }
                            }}
                          >
                            <DeleteIcon />
                          </Button>
                        </Grid>
                      </Grid>
                    ))}
                </Box>
              </Grid>
            </Box>
          ) : (
            <></>
          )}

          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                mt: 2,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                onClick={() => handleChildCategory(true, false, false)}
                sx={submitBtn}
              >
                Back
              </Button>
              <Button type="submit" variant="contained" sx={submitBtn}>
                Update
              </Button>
            </Box>
          </Grid>
        </Grid>
      </>
    </div>
  );
};

export default EditCategory;
