import { createTheme } from "@mui/material/styles";

const palette = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#F75B28",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    // secondary: {
    //   // light: "#0066ff",
    //   // dark: will be calculated from palette.secondary.main,
    //   // contrastText: "#ffcc00",
    // },
    // Provide every color token (light, main, dark, and contrastText) when using
    // custom colors for props in Material UI's components.
    // Then you will be able to use it like this: `<Button color="custom">`
    // (For TypeScript, you need to add module augmentation for the `custom` value)
    // custom: {
    //   light: "#ffa726",
    //   main: "#f57c00",
    //   dark: "#ef6c00",
    //   contrastText: "rgba(0, 0, 0, 0.87)",
    // },

    drawerColor: {
      main: "#0D1E37",
    },
    white: {
      main: "#ffffff",
    },

    bgColor: {
      main: "#FFF6F6",
    },
    blue: {
      main: "#0085FF",
    },
    yellow: {
      main: "#A19B00",
      secondary: "#FF9415",
      tertiary: "#FF9B25",
    },
    purple: {
      main: "#5D59FA",
    },
    green: {
      main: "#00710B",
    },
    // shivam #FF9B25

    addMenuItemColor: {
      light: "rgba(217, 217, 217, 0.44)",
      light2: "rgba(210, 210, 210, 0.33)",
      light3: "rgba(248, 215, 177, 0.89)",
    },
    dataGrid: {
      header: "#FDE5EC",
    },
    grey: {
      main: "#7B7B7B",
      primary: "#8B8B8B",
      secondary: "#F5F5F5",
      tertiary: "#393939",
      light: "rgba(97, 97, 97, 0.11)",
      light2: "rgba(97, 97, 97, 0.29)",
      cloudyGrey: "#686868",
      midGrey: "#616161",
      lightGrey: "#DFDFDF",
    },
    black: {
      main: "00000",
    },

    // shivam

    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

export default palette;
