import React, { useContext, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import axios from "axios";
import moment from "moment";
import { Box } from "@mui/system";
import { imgUrl } from "../../url";
import TodaysDeliveryLogReport from "../TodaysDeliveryLogReport";

const TodayDeliveryReport = () => {
  const [data, setData] = useState([]);
  const [randomSelfies, setRandomSelfies] = useState([]);

  const getData = async () => {
    try {
      const response = await axios.get(`/api/v1/admin/delivery/live`);
      setData(response.data.data);
    } catch (error) {}
  };
  const getRandomSelfies = async () => {
    try {
      const response = await axios.get(
        "/api/v1/admin/upload/deliveryBoySelfie"
      );
      setRandomSelfies(response.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    getRandomSelfies();
    getData();
  }, []);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 700 }}>S.No</TableCell>

              <TableCell sx={{ fontWeight: 700 }}>Name</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Login Time</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Selfie</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow
                key={item._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{index + 1}</TableCell>

                <TableCell>{item?.deliveryPartner?.name}</TableCell>

                <TableCell>
                  {moment(item?.deliveryPartnerLogCheck?.createdAt).format(
                    "hh:mm A"
                  )}
                </TableCell>

                <TableCell>
                  {randomSelfies.length > 0 ? (
                    randomSelfies[0].image.map((image, index) => (
                      <img
                        key={index}
                        src={`${imgUrl}/${image}`}
                        alt={`Selfie ${index}`}
                        width={80}
                        height={80}
                      />
                    ))
                  ) : (
                    <></>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TodaysDeliveryLogReport />
        </Grid>
      </Grid>
    </>
  );
};

export default TodayDeliveryReport;
