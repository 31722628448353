import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import React, { useState, useContext } from "react";
import {
  textFieldStyle,
  textHeadingInputFields,
} from "../../components/cssStyling/Style";
import { AdminDataContext } from "../../useContext/AdminContext";

const cities = ["Bhiwandi", "Thane"];
const restaurantTypes = ["admin", "restaurant"];

const FilterByCity = (props) => {
  const { setSelectCity, filterType } = useContext(AdminDataContext);

  const [city, setCity] = useState("");

  const onInputChange = (e) => {
    setCity(e.target.value);
    // props.setCity(e.target.value); //ye change krna h kyuki esko set nhi kr rha hu kahi kahi pe
    setSelectCity(e.target.value);
  };
  const options = filterType.restaurant ? restaurantTypes : cities;

  return (
    <>
      <Grid container spacing={2} mt={-2}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="body1" sx={textHeadingInputFields}>
            Filter By City
          </Typography>
          <TextField
            id="outlined-basic"
            select
            size="small"
            type="text"
            name="city"
            required
            value={city}
            onChange={onInputChange}
            sx={textFieldStyle}
          >
            {/* <MenuItem selected>All</MenuItem> */}
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </>
  );
};

export default FilterByCity;
