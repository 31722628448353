import React, { useState } from "react";
import {
  Container,
  Paper,
  Box,
  Typography,
  Grid,
  Autocomplete,
  TextField,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  containerStyle,
  paperStyle,
  imageBox,
  restaurantDetailsGrid,
  paperBox,
} from "../../../menuItem/itemCreate/CssStyle";

const SelectRestaurantMenuUpload = ({ type }) => {
  const [searchRestaurant, setSearchRestaurant] = useState([]);
  const [ownerId, setOwnerId] = useState("");
  const [singleRestaurant, setSingleRestaurant] = useState({});

  const [selectRestaurant, setSelectRestaurant] = useState(false);

  const navigate = useNavigate();

  const getSearchOwner = async (e) => {
    try {
      const res = await axios.get(
        `/api/v1/admin/restaurants/search?query=${e.target.value}`
      );
      if (!(res.data.data.length === 0)) {
        setSearchRestaurant(res.data.data);
      } else {
        setSearchRestaurant([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box>
        {ownerId ? (
          <></>
        ) : (
          <Container fixed sx={containerStyle}>
            <Paper sx={paperStyle} elevation={10}>
              <Box sx={paperBox}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "200px",
                  }}
                  component="img"
                  alt="logo"
                  src="/images/logoCreateRest.svg"
                ></Box>
                <Typography
                  sx={{ color: "grey.tertiary", fontWeight: 600, mt: 1 }}
                >
                  SELECT RESTAURANT
                </Typography>
              </Box>
              <Grid
                container
                spacing={2}
                mt={2}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Grid xs={12} md={6}>
                  <Autocomplete
                    fullWidth={true}
                    disableClearable
                    id="combo-box-demo"
                    size="small"
                    options={searchRestaurant}
                    getOptionLabel={(userData) =>
                      `${userData.brand_display_name} Phone : ${userData.business_contact}`
                    }
                    onInputChange={getSearchOwner}
                    onChange={(event, value) => {
                      console.log(event.target.value);
                      console.log(value);
                      setSearchRestaurant(value);
                      setSingleRestaurant(value);
                      setSelectRestaurant(true);
                    }}
                    filterSelectedOptions
                    //   sx={{ width: 250 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Restaurant" />
                    )}
                  />
                </Grid>
              </Grid>

              {selectRestaurant ? (
                <Grid
                  container
                  onClick={() => {
                    setOwnerId(singleRestaurant._id);
                    navigate(`${type}/${singleRestaurant._id}`);
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 4,
                    borderRadius: "100px",
                  }}
                >
                  <Grid item xs={8} sm={3} md={1}>
                    <Box
                      sx={imageBox}
                      component="img"
                      alt="logo"
                      src={"/images/defaultRes.svg"}
                    ></Box>
                  </Grid>
                  <Grid item xs={10} sm={6} md={4} sx={restaurantDetailsGrid}>
                    <Typography
                      sx={{ color: "grey.main", fontWeight: 600, mt: 1 }}
                    >
                      {singleRestaurant.brand_display_name}
                    </Typography>
                    <Typography
                      sx={{ color: "grey.main", fontWeight: 600, mt: 1 }}
                    >
                      {singleRestaurant.email}
                    </Typography>

                    <Typography
                      variant="h6"
                      fontWeight={500}
                      fontSize="15px"
                      lineHeight="20px"
                      sx={{ color: "rgba(13, 30, 55, 0.67)", mt: 1 }}
                    >
                      {singleRestaurant.business_contact}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
            </Paper>
          </Container>
        )}
      </Box>
    </>
  );
};

export default SelectRestaurantMenuUpload;
