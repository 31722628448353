import React, { useState, useContext } from "react";
import {
  useMediaQuery,
  Box,
  Typography,
  Grid,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { AdminDataContext } from "../../../../useContext/AdminContext";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Multiselect } from "multiselect-react-dropdown";

import axios from "axios";

import { cuisinesItemList } from "./cuisinesItemList";
import { useParams } from "react-router-dom";
import {
  submitBtn,
  textFieldStyle,
} from "../../../menuItem/itemCreate/CssStyle";
import cities from "./restaurantCities";

const CreateRestaurantModal = (props) => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");
  const { snackbar, setIsLoading } = useContext(AdminDataContext);
  const { id } = useParams();

  const [cuisineItems, setCuisineItems] = useState([]);

  // setItems(cuisinesItemList)

  const [createRestaurant, setCreateRestaurant] = useState({
    brand_display_name: "",
    outlet_name: "",
    address: "",
    landmark: "",
    area: "",
    state: "",
    pincode: "",
    city: "",
    business_contact: "",
    merchant_number: "",
    email: "",
    cuisines: "",
    longitude: 50,
    latitude: 50,
  });

  const onInputChange = (e) => {
    setCreateRestaurant({
      ...createRestaurant,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect = (selectedList) => {
    setCuisineItems(selectedList);
    console.log(cuisineItems);
  };

  const handleRemove = (selectedList) => {
    setCuisineItems(selectedList);
  };
  let cuisineItemsIs = "";
  let ownerId = props.ownerId;
  console.log(id);

  const getAddress = async () => {
    try {
      navigator.geolocation.getCurrentPosition((coordinate) => {
        const coordinates = coordinate.coords;
        const { latitude, longitude } = coordinates;
        console.log("get coordinates: ", latitude, longitude);
        const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
        const add = fetch(url)
          .then((res) => res.json())
          .then((data) => {
            setCreateRestaurant({
              ...createRestaurant,
              state: data.address.state,
              city: data.address.city,
              landmark: data.address.neighbourhood,
              latitude: latitude,
              longitude: longitude,
            });
            console.log("full address ", data.address);
          });
      });
    } catch (error) {
      console.log("Error while getting address", error);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      console.log(createRestaurant);
      for (let index = 0; index < cuisineItems.length; index++) {
        const element = cuisineItems[index];
        // cuisineItemsIs = cuisineItemsIs + element + ",";
        if (index < cuisineItems.length - 1) {
          cuisineItemsIs = cuisineItemsIs + element + ",";
        } else {
          cuisineItemsIs = cuisineItemsIs + element;
        }
      }

      console.log("cuisineItemsIs ", cuisineItemsIs);

      if (cuisineItemsIs.length > 0) {
        const res = await axios.post(`/api/v1/admin/restaurant/create`, {
          ...createRestaurant,
          ownerId: id,
          cuisines: cuisineItemsIs,
        });
        console.log("cuisineItemsIs", cuisineItemsIs);
        console.log(res);
        props.getRestaurant();
        props.handleModal();
        setIsLoading(false);
        snackbar("Restaurant created successfully", "success");
        props.getRestaurant();
      } else {
        snackbar("Please select cuisines", "error");
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "50%" : matchesDown ? "60%" : "80%",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
    maxHeight: "80%",
    overflow: "auto",
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.openModal}
        onClose={props.handleModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.openModal}>
          <Box
            component="form"
            // noValidate
            onSubmit={(e) => onSubmit(e)}
            sx={style}
          >
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                id="transition-modal-title"
                variant="h4"
                component="h2"
                sx={{
                  display: "inline-block",
                  background: `#fd9459`,
                  color: "white.main",
                  fontWeight: 600,
                  fontSize: { xs: "22px", md: "30px" },
                  px: 4,
                  py: 1,
                  borderRadius: "10px",
                }}
              >
                ADD YOUR RESTAURANT
              </Typography>

              <Button
                onClick={getAddress}
                sx={{ width: "max-content", mt: 2 }}
                id="create-restaurant"
                variant="contained"
                size="large"
              >
                Add Current Address
              </Button>
            </Box>

            <Grid
              container
              spacing={2}
              sx={{ display: "flex", justifyContent: "center", my: 1 }}
            >
              <Grid item xs={12} sm={12} md={6} px={3}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{}}
                >
                  Brand Display Name
                </Typography>

                <TextField
                  id="outlined-basic"
                  size="small"
                  type="text"
                  name="brand_display_name"
                  required
                  value={createRestaurant.brand_display_name}
                  onChange={(e) => onInputChange(e)}
                  placeholder="Enter name"
                  sx={textFieldStyle}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} px={3}>
                <Typography variant="h6" fontWeight={500} fontSize="16px">
                  Outlet Name
                </Typography>
                <TextField
                  id="outlined-basic"
                  size="small"
                  type="text"
                  name="outlet_name"
                  required
                  value={createRestaurant.outlet_name}
                  onChange={(e) => onInputChange(e)}
                  placeholder="Enter name"
                  sx={textFieldStyle}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} px={3}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{}}
                >
                  Address
                </Typography>

                <TextField
                  id="outlined-basic"
                  size="small"
                  type="text"
                  name="address"
                  required
                  value={createRestaurant.address}
                  onChange={(e) => onInputChange(e)}
                  placeholder="Enter name"
                  sx={textFieldStyle}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} px={3}>
                <Typography variant="h6" fontWeight={500} fontSize="16px">
                  Landmark
                </Typography>
                <TextField
                  id="outlined-basic"
                  size="small"
                  type="text"
                  name="landmark"
                  required
                  value={createRestaurant.landmark}
                  onChange={(e) => onInputChange(e)}
                  placeholder="Enter name"
                  sx={textFieldStyle}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} px={3}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{}}
                >
                  Area
                </Typography>

                <TextField
                  id="outlined-basic"
                  size="small"
                  type="text"
                  name="area"
                  required
                  value={createRestaurant.area}
                  onChange={(e) => onInputChange(e)}
                  placeholder="Enter name"
                  sx={textFieldStyle}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} px={3}>
                <Typography variant="h6" fontWeight={500} fontSize="16px">
                  State
                </Typography>
                <TextField
                  id="outlined-basic"
                  size="small"
                  type="text"
                  name="state"
                  required
                  value={createRestaurant.state}
                  onChange={(e) => onInputChange(e)}
                  placeholder="Enter name"
                  sx={textFieldStyle}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} px={3}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{}}
                >
                  Pin Code
                </Typography>

                <TextField
                  id="outlined-basic"
                  size="small"
                  type="text"
                  name="pincode"
                  required
                  value={createRestaurant.pincode}
                  onChange={(e) => onInputChange(e)}
                  placeholder="Enter name"
                  sx={textFieldStyle}
                />
              </Grid>

              {/* <Grid item xs={12} sm={12} md={6} px={3}>
                <Typography variant="h6" fontWeight={500} fontSize="16px">
                  City
                </Typography>
                <TextField
                  id="outlined-basic"
                  size="small"
                  type="text"
                  name="city"
                  required
                  value={createRestaurant.city}
                  onChange={(e) => onInputChange(e)}
                  placeholder="Enter name"
                  sx={textFieldStyle}
                />
              </Grid> */}

              <Grid item xs={12} sm={12} md={6} px={3}>
                <Typography variant="h6" fontWeight={500} fontSize="16px">
                  City
                </Typography>
                <TextField
                  id="outlined-basic"
                  select
                  size="small"
                  type="text"
                  name="city"
                  required
                  value={createRestaurant.city}
                  onChange={onInputChange}
                  placeholder="Select city"
                  sx={textFieldStyle}
                >
                  {cities.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={12} md={6} px={3}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{}}
                >
                  Business Contact
                </Typography>

                <TextField
                  id="outlined-basic"
                  size="small"
                  type="text"
                  name="business_contact"
                  required
                  value={createRestaurant.business_contact}
                  inputProps={{
                    pattern: "[0-9]{10}",
                    maxLength: 10,
                    autoComplete: "tel",
                  }}
                  onChange={(e) => onInputChange(e)}
                  placeholder="Enter name"
                  sx={textFieldStyle}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} px={3}>
                <Typography variant="h6" fontWeight={500} fontSize="16px">
                  Merchant Number
                </Typography>

                <TextField
                  id="outlined-basic"
                  size="small"
                  type="text"
                  name="merchant_number"
                  required
                  value={createRestaurant.merchant_number}
                  inputProps={{
                    pattern: "[0-9]{10}",
                    maxLength: 10,
                    autoComplete: "tel",
                  }}
                  onChange={(e) => onInputChange(e)}
                  placeholder="Enter name"
                  sx={textFieldStyle}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} px={3}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{}}
                >
                  Email
                </Typography>

                <TextField
                  id="outlined-basic"
                  size="small"
                  type="email"
                  name="email"
                  required
                  value={createRestaurant.email}
                  onChange={(e) => onInputChange(e)}
                  placeholder="Enter name"
                  sx={textFieldStyle}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} px={3}>
                <Typography variant="h6" fontWeight={500} fontSize="16px">
                  Cuisines
                </Typography>

                <Multiselect
                  style={{
                    multiselectContainer: {
                      width: "100%",
                      backgroundColor: "#FFD89D",
                    },
                  }}
                  options={cuisinesItemList} // Options to display in the dropdown
                  // selectedValues={cuisineItems} // Preselected value to persist in dropdown
                  onSelect={handleSelect} // Function will trigger on select event
                  onRemove={handleRemove} // Function will trigger on remove event
                  // displayValue="title" // Property name to display in the dropdown options
                  isObject={false}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} px={3}>
                <Typography variant="h6" fontWeight={500} fontSize="16px">
                  Latitude
                </Typography>
                <TextField
                  id="outlined-basic"
                  size="small"
                  type="text"
                  name="latitude"
                  required
                  value={createRestaurant.latitude}
                  onChange={(e) => onInputChange(e)}
                  placeholder="Enter name"
                  sx={textFieldStyle}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} px={3}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{}}
                >
                  Longitude
                </Typography>

                <TextField
                  id="outlined-basic"
                  size="small"
                  type="text"
                  name="longitude"
                  required
                  value={createRestaurant.longitude}
                  onChange={(e) => onInputChange(e)}
                  placeholder="Enter name"
                  sx={textFieldStyle}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{ mt: 5, display: "flex", justifyContent: "center" }}
              >
                <Button
                  id="create-restaurant"
                  type="submit"
                  sx={submitBtn}
                  variant="contained"
                  size="large"
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default CreateRestaurantModal;
