import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useState } from "react";
import AddAddOns from "./AddAddOns";
import {
  textFieldStyle,
  selectFieldStyle,
  tableColumnsHeading,
  textHeading,
} from "./CssStyle";
function NestedRow(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row?.name}
        </TableCell>
        <TableCell align="center">{row?.groupname}</TableCell>
        <TableCell align="center">{row?.price}</TableCell>
        <TableCell align="center">
          {row?.variationallowaddon === 0 ? "False" : "True"}
        </TableCell>
        <TableCell align="center">
          <Button onClick={() => props.removeVariation(row)}>Remove</Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {row?.addon.length !== 0 ? (
              <Box sx={{ margin: 1 }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  sx={textHeading}
                >
                  AddOns
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" sx={tableColumnsHeading}>
                        Addon Name
                      </TableCell>
                      <TableCell align="center" sx={tableColumnsHeading}>
                        Addon Item Selection Max
                      </TableCell>
                      <TableCell align="center" sx={tableColumnsHeading}>
                        Addon Item Selection Min
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.addon.map((addon) => (
                      <TableRow key={addon._id}>
                        <TableCell align="center">
                          {props.addOn[addon.addon_group_id]}
                        </TableCell>
                        <TableCell align="center">
                          {addon.addon_item_selection_max}
                        </TableCell>
                        <TableCell align="center">
                          {addon.addon_item_selection_min}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            ) : (
              <></>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
NestedRow.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};
const AddVariations = ({ addOns, data, setData, addOn, variations }) => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");

  const [groupnames, setGroupname] = useState(
    variations.reduce((accumulator, currentValue) => {
      !accumulator.includes(currentValue.groupname)
        ? accumulator.push(currentValue.groupname)
        : console.log("first");
      return accumulator;
    }, [])
  );
  const [groupNameVariationList, setGroupNameVariationList] = useState([]);
  const selectGroupNameHandler = (e) => {
    setGroupNameVariationList([]);
    setData({ ...data, variation_groupname: e.target.value });

    setGroupNameVariationList(
      variations.reduce((accumulator, currentValue) => {
        currentValue.groupname === e.target.value
          ? accumulator.push(currentValue)
          : console.log("first");
        return accumulator;
      }, [])
    );
  };
  const removeVariation = (item) => {
    setData({
      ...data,
      variation: data.variation.filter((e) => !(e.name === item.name)),
    });
    for (let index = 0; index < data.variation.length; index++) {
      const element = data.variation[index];

      if (!(data.itemaddonbasedon === "1")) {
        if (element.variationallowaddon === "1") {
          setData({ ...data, itemaddonbasedon: "1" });
        }
      }
    }
  };
  const [openVariationModal, setOpenVariationModal] = React.useState(false);
  const handleOpenVariationModal = () => {
    setOpenVariationModal(true);
    setVariationModalData({
      ...variationModalData,
      groupname: data.variation_groupname,
    });
  };
  const handleCloseVariationModal = () => {
    setOpenVariationModal(false);
    setVariationModalData({
      groupname: "",
      name: "",
      price: 0,
      variationallowaddon: 0,
      addon: [],
      active: "1",
      variationrank: "0",
    });
  };
  const [variationModalData, setVariationModalData] = React.useState({
    groupname: "",
    name: "",
    price: 0,
    variationallowaddon: 0,
    addon: [],
    active: "1",
    variationrank: "0",
  });
  const onChangeHandlerForVariation = (event) => {
    setVariationModalData({
      ...variationModalData,
      [event.target.name]: event.target.value,
    });
  };
  const onSubmitHandler = () => {
    setData({ ...data, variation: [...data.variation, variationModalData] });
    for (let index = 0; index < data.variation.length; index++) {
      const element = data.variation[index];

      if (!(data.itemaddonbasedon === "1")) {
        if (element.variationallowaddon === "1") {
          setData({ ...data, itemaddonbasedon: "1" });
        }
      }
    }
    handleCloseVariationModal();
  };

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "550px",
    width: matches ? "40%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 2,
    overflow: "auto",
  };

  return (
    <div>
      <Stack spacing={2} mx={2}>
        <Typography variant="body1" sx={textHeading}>
          Select variation group name
        </Typography>

        <FormControl fullWidth>
          <Select
            sx={selectFieldStyle}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="addon_group_id"
            value={data.variation_groupname}
            size="small"
            required
            onChange={selectGroupNameHandler}
          >
            {groupnames.map((groupname) => (
              <MenuItem key={groupname} value={groupname}>
                {groupname}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Grid>
          <Box sx={{ margin: 1 }}>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              sx={textHeading}
            >
              Variations
            </Typography>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align="center" sx={tableColumnsHeading}>
                    Variation Name
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    Groupname
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    Price
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    Variation allow addon
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.variation.map((item) => (
                  <NestedRow
                    key={item._id}
                    row={item}
                    removeVariation={removeVariation}
                    // attributes={attributes}
                    // tax={tax}
                    addOn={addOn}
                  />
                ))}
              </TableBody>
            </Table>
          </Box>
        </Grid>
        <Box>
          <Button
            variant="contained"
            disabled={!data.variation_groupname}
            onClick={handleOpenVariationModal}
          >
            Add variations
          </Button>
        </Box>
      </Stack>

      <Modal
        open={openVariationModal}
        onClose={handleCloseVariationModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
            gap={1}
            sx={{ position: "relative" }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "27px",
                color: "rgba(13, 30, 55, 0.67)",
                justifySelf: "start",
              }}
            >
              Add Variation
            </Typography>

            <IconButton onClick={handleCloseVariationModal} aria-label="delete">
              <CancelIcon />
            </IconButton>
          </Stack>

          <Stack spacing={2}>
            <Typography
              variant="h6"
              fontWeight={500}
              fontSize="16px"
              sx={{ color: "grey.main" }}
            >
              Group Name
            </Typography>
            <TextField
              sx={textFieldStyle}
              size="small"
              id="outlined-basic"
              variant="outlined"
              required
              name="groupname"
              disabled
              value={variationModalData.groupname}
              defaultValue={5}
            />

            <Typography
              variant="h6"
              fontWeight={500}
              fontSize="16px"
              sx={{ color: "grey.main" }}
            >
              Variation Name
            </Typography>

            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="name"
                value={variationModalData.name}
                size="small"
                onChange={onChangeHandlerForVariation}
                required
                sx={selectFieldStyle}
              >
                {groupNameVariationList.map((item2) => (
                  <MenuItem key={item2._id} value={item2.name}>
                    {item2.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography
              variant="h6"
              fontWeight={500}
              fontSize="16px"
              sx={{ color: "grey.main" }}
            >
              Price
            </Typography>
            <TextField
              sx={textFieldStyle}
              id="outlined-basic"
              size="small"
              variant="outlined"
              type="number"
              required
              value={variationModalData.price}
              onChange={onChangeHandlerForVariation}
              // value={modalData.addon_item_selection_min}
              defaultValue={0}
              name="price"
            />

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={variationModalData.variationallowaddon === 1}
                    onChange={(e) =>
                      setVariationModalData({
                        ...variationModalData,
                        variationallowaddon: e.target.checked ? 1 : 0,
                        addon: e.target.checked ? variationModalData.addon : [],
                      })
                    }
                  />
                }
                label="Allow AddOns"
              />
            </FormGroup>
            {variationModalData.variationallowaddon === 1 ? (
              <AddAddOns
                addOns={addOns}
                data={variationModalData}
                setData={setVariationModalData}
                addOn={addOn}
              />
            ) : (
              <></>
            )}

            <Button onClick={onSubmitHandler}>Submit</Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default AddVariations;
