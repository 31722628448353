import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import Typography from "@mui/material/Typography";

import axios from "axios";
import React, { useState, useRef } from "react";
import SingleDeliveryReportDataGrid from "./SingleDeliveryReportDataGrid";
import { DownloadTableExcel } from "react-export-table-to-excel";

import {
  textHeadingInputFields,
  textFieldBox,
  addBtn,
  tableColumnsHeading,
} from "../cssStyling/Style";
import { Autocomplete } from "@mui/material";
import moment from "moment";

const DeliveryReportByPartner = () => {
  const [dates, setDates] = useState({});
  const [logData, setLogData] = useState([]);

  const [deliveryPartners, setDeliveryPartners] = useState([]);
  const [deliveryPartner, setDeliveryPartner] = useState({});
  //   const [logData, setLogData] = useState([]);
  const [deliveryPartnersName, setDeliveryPartnerName] = useState("");
  const tableRef = useRef(null);
  const getDeliveryPartnerLogData = async (e) => {
    try {
      const res = await axios.post(
        `/api/v1/admin/delivery/get-delivery-partner-log-time`,

        {
          ...dates,
          deliveryPartner_id: deliveryPartner._id,
        }
      );
      console.log("res", res.data.data);
      setDeliveryPartnerName(res.data.data[0].deliveryPartner[0].name);
      setLogData(res.data.data);
    } catch (error) {}
  };
  const onChangeDate = (event) => {
    setDates({ ...dates, [event.target.name]: event.target.value });
    console.log(dates);
  };
  const getDeliveryPartnerData = async (e) => {
    try {
      const res = await axios.get(
        `/api/v1/admin/delivery/partner/search?query=${e.target.value}`
      );

      if (!(res.data.data.length === 0)) {
        setDeliveryPartners(res.data.data);
      } else {
        setDeliveryPartners([]);
      }
    } catch (error) {}
  };
  const onChangeDeliveryPartner = (event, value) => {
    setDeliveryPartner(value);
    if (value && value._id) {
      getDeliveryPartnerLogData();
    } else {
      setLogData([]);
    }
  };
  return (
    <div>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "space-around" }}
      >
        <Grid
          item
          xs={6}
          sm={3}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box
            sx={{
              ...textFieldBox,
              display: "flex",
              flexDirection: "column",
              justifyContent: { xs: "center", sm: "start" },
            }}
          >
            <Typography variant="body1" sx={textHeadingInputFields}>
              Select Delivery Partner
            </Typography>

            <Autocomplete
              // multiple
              size="small"
              aria-required
              fullWidth
              id="tags-outlined"
              options={deliveryPartners}
              getOptionLabel={(userData) =>
                `${userData.name} Phone : ${userData.phone}`
              }
              onInputChange={getDeliveryPartnerData}
              onChange={onChangeDeliveryPartner}
              filterSelectedOptions
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={6}
          sm={3}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box
            sx={{
              ...textFieldBox,
              display: "flex",
              flexDirection: "column",
              justifyContent: { xs: "center", sm: "start" },
            }}
          >
            <Typography variant="body1" sx={textHeadingInputFields}>
              Start Date
            </Typography>

            <TextField
              size="small"
              id="outlined-basic"
              type="date"
              value={dates.startDate}
              variant="outlined"
              name="startDate"
              onChange={onChangeDate}
            />
          </Box>
        </Grid>

        <Grid item xs={6} sm={3} sx={{ display: "flex" }}>
          <Box
            sx={{
              ...textFieldBox,
              display: "flex",
              flexDirection: "column",
              justifyContent: { xs: "center", sm: "start" },
            }}
          >
            <Typography variant="body1" sx={textHeadingInputFields}>
              End Date
            </Typography>

            <TextField
              size="small"
              id="outlined-basic"
              type="date"
              value={dates.endDate}
              variant="outlined"
              name="endDate"
              onChange={onChangeDate}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={6}
          sm={3}
          sx={{
            display: "flex",
            // justifyContent: { xs: "center", sm: "start" },
          }}
        >
          {dates.startDate && dates.endDate && deliveryPartner._id ? (
            <>
              <Box
                sx={{
                  ...textFieldBox,
                  display: "flex",
                  // flexDirection: "column",
                  justifyContent: { xs: "center", sm: "start" },
                  mt: 4.6,
                }}
              >
                <Button
                  sx={{
                    ...addBtn,
                    m: 0,
                    height: "40px",
                    width: "max-content",
                  }}
                  onClick={getDeliveryPartnerLogData}
                  variant="contained"
                >
                  Get Date
                </Button>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>

      {/* ----------------- */}
      <Box>
        {dates.startDate && dates.endDate && deliveryPartner._id ? (
          <>
            <Stack spacing={2} mt={3}>
              <TableContainer component={Paper}>
                <Table ref={tableRef} aria-label="simple table">
                  <TableHead>
                    <TableRow
                      sx={{
                        background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                      }}
                    >
                      <TableCell
                        align="center"
                        sx={{ ...tableColumnsHeading, color: "white.main" }}
                      >
                        S.No
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ ...tableColumnsHeading, color: "white.main" }}
                      >
                        Date
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ ...tableColumnsHeading, color: "white.main" }}
                      >
                        Delivery Boy -{" "}
                        <span style={{ color: "black" }}>
                          {deliveryPartnersName}
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {logData
                      .filter((row, index1) => row.deliveryPartner.length > 0)
                      .map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell
                            align="center"
                            component="th"
                            scope="row"
                            sx={tableColumnsHeading}
                          >
                            {index + 1}
                          </TableCell>

                          <TableCell align="center" sx={tableColumnsHeading}>
                            {moment(row.loginDate).format("DD/MM/YYYY hh:mm A")}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            <SingleDeliveryReportDataGrid
                              data={row.loginData}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>

            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "end" },
                mt: 2,
              }}
            >
              <DownloadTableExcel
                filename="DeliveryPartner Log"
                sheet="DeliveryPartner"
                currentTableRef={tableRef.current}
              >
                <Button variant="contained"> Export excel </Button>
              </DownloadTableExcel>
            </Box>
          </>
        ) : (
          <></>
        )}
      </Box>
    </div>
  );
};

export default DeliveryReportByPartner;
