import React from "react";

import SelectRestaurantMenuUpload from "../restaurant/petPoojaMenuItems/selectRestaurant/SelectRestaurantMenuUpload";
const VariantsSelect = () => {
  return (
    <div>
      <SelectRestaurantMenuUpload type={"/i/variants"} />
    </div>
  );
};

export default VariantsSelect;
