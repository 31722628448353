import { Box, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { imgUrl } from "../../url";
import RewardModal from "./RewardModal";
import { AdminDataContext } from "../../useContext/AdminContext";

function AllCustomerRewards() {
  const [customerReward, setCustomerReward] = useState([]);

  const [image, setImage] = useState("");

  const [data, setData] = useState({
    points: null,
    name: "",
    description: "",
  });

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    console.log(data);
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      let res = {};

      if (data._id) {
        res = await axios.patch(`/api/v1/admin/editReward/${data._id}`, {
          ...data,
        });

        console.log("edit kro re");
        setData({
          points: "",
          name: "",
          description: "",
        });
      } else {
        if (image) {
          console.log("new data h re");
          res = await axios.post(`/api/v1/admin/createRedeemRwards`, {
            ...data,
          });

          setData({
            points: "",
            name: "",
            description: "",
          });
        } else {
          snackbar("Please select image", "error");
        }
      }
      if (image) {
        let formData = new FormData();
        formData.append("image", image);
        const res2 = await axios.put(
          `/api/v1/admin/createRedeemRwards?rewardId=${res.data.data._id}`,
          formData
        );
      }

      console.log(res);
      getAllCustomer(page, limit, "", "");
      snackbar(res.data.message, "success");
      setIsLoading(false);
    } catch (error) {
      console.log(error);

      setIsLoading(false);
    }
  };

  const editHandler = (row) => {
    handleOpen();
    setData(row);
  };

  const deleteHandler = async (id) => {
    try {
      const res = await axios.delete(`/api/v1/admin/deleteReward/${id}`);
      console.log(res);
      snackbar(res.data.message, "success");
      getAllCustomer(page, limit, "", "");
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const columns = [
    {
      field: "image",
      headerName: "Image",
      width: 150,
      renderHeader: () => <strong>{"Image"}</strong>,

      renderCell: (params) => {
        return params.row.image ? (
          <>
            <Box
              height={"100%"}
              component="img"
              src={
                params.row.image.startsWith("http")
                  ? `${params.row.image}`
                  : `${imgUrl}/${params.row.image}`
              }
            />
          </>
        ) : (
          "No data available"
        );
      },
    },

    {
      field: "points",
      headerName: "Points",
      width: 100,
      renderHeader: () => <strong>{"Points"}</strong>,
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      renderHeader: () => <strong>{"Name"}</strong>,
    },
    {
      field: "rewardCount",
      headerName: "Reward Count",
      width: 150,
      renderHeader: () => <strong>{"Reward Count"}</strong>,
    },
    {
      field: "description",
      headerName: "Description",
      width: 450,
      renderHeader: () => <strong>{"Description"}</strong>,
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 120,
      renderHeader: () => <strong>{"Edit"}</strong>,

      renderCell: (params) => {
        return (
          <>
            <Button variant="contained" onClick={() => editHandler(params.row)}>
              Edit
            </Button>
          </>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 120,
      renderHeader: () => <strong>{"Delete"}</strong>,

      renderCell: (params) => {
        return (
          <>
            <Button
              variant="contained"
              onClick={() => deleteHandler(params.row._id)}
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getAllCustomer(page, limit, "", "");
  }, []);

  const getAllCustomer = async (pageNo, limitNo, sort, field) => {
    try {
      // setLoading(true);
      const res = await axios.get(`/api/v1/admin/allRewards`);
      setCustomerReward(res.data.allRedeemRwards);
      setTotal(res.data.total);
      // setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Box>
      <Button variant="contained" onClick={() => handleOpen()}>
        Create Reward
      </Button>

      <DataGrid
        components={{ Toolbar: GridToolbar }}
        autoHeight
        rows={customerReward}
        getRowId={(row) => row._id}
        rowCount={total}
        // loading={loading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={page - 1}
        pageSize={limit}
        paginationMode="server"
        sortingMode="server"
        // onSortModelChange={handleSortModelChange}
        filterMode="server"
        // onFilterModelChange={onFilterChange}
        // onPageChange={(newPage) => {
        //   getAllCustomer(newPage + 1, limit, "", "");
        //   setPage(newPage + 1);
        // }}
        onPageSizeChange={(newPageSize) => {
          getAllCustomer(page, newPageSize, "", "");
          setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={columns}
      />

      <RewardModal
        openModal={openModal}
        handleClose={handleClose}
        data={data}
        setData={setData}
        onChange={onChange}
        onSubmit={onSubmit}
        setImage={setImage}
      />
    </Box>
  );
}

export default AllCustomerRewards;
