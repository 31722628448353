import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

import axios from "axios";
import React, { useState, useRef } from "react";
import SingleDeliveryReportDataGrid from "./SingleDeliveryReportDataGrid";
import { DownloadTableExcel } from "react-export-table-to-excel";
import TodayDeliveryReport from "./TodayDeliveryReport";
import {
  textHeadingInputFields,
  textFieldBox,
  addBtn,
  tableColumnsHeading,
} from "../cssStyling/Style";
import DeliveryReportByPartner from "./DeliveryReportByPartner";
const AllDeliveryReport = () => {
  const [dates, setDates] = useState({});
  const [logData, setLogData] = useState([]);
  const tableRef = useRef(null);
  const getDeliveryPartnerLogData = async (e) => {
    try {
      const res = await axios.post(`/api/v1/admin/delivery/log`, dates);
      setLogData(res.data.data);
    } catch (error) {}
  };
  const onChangeDate = (event) => {
    setDates({ ...dates, [event.target.name]: event.target.value });
    console.log(dates);
  };

  // -----------------------------tab

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  // ---------------------tab
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Today Active Delivery Report" {...a11yProps(0)} />
            <Tab label="All Delivery Report" {...a11yProps(1)} />
            <Tab label="Delivery boy report" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <TodayDeliveryReport />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "space-around" }}
          >
            <Grid
              item
              xs={6}
              sm={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  ...textFieldBox,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: { xs: "center", sm: "start" },
                }}
              >
                <Typography variant="body1" sx={textHeadingInputFields}>
                  Start Date
                </Typography>

                <TextField
                  size="small"
                  id="outlined-basic"
                  type="date"
                  value={dates.startDate}
                  variant="outlined"
                  name="startDate"
                  onChange={onChangeDate}
                />
              </Box>
            </Grid>

            <Grid item xs={6} sm={3} sx={{ display: "flex" }}>
              <Box
                sx={{
                  ...textFieldBox,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: { xs: "center", sm: "start" },
                }}
              >
                <Typography variant="body1" sx={textHeadingInputFields}>
                  End Date
                </Typography>

                <TextField
                  size="small"
                  id="outlined-basic"
                  type="date"
                  value={dates.endDate}
                  variant="outlined"
                  name="endDate"
                  onChange={onChangeDate}
                />
              </Box>
            </Grid>

            <Grid
              item
              xs={6}
              sm={6}
              sx={{
                display: "flex",
                // justifyContent: { xs: "center", sm: "start" },
              }}
            >
              {dates.startDate && dates.endDate ? (
                <>
                  <Box
                    sx={{
                      ...textFieldBox,
                      display: "flex",
                      // flexDirection: "column",
                      justifyContent: { xs: "center", sm: "start" },
                      mt: 4.6,
                    }}
                  >
                    <Button
                      sx={{
                        ...addBtn,
                        m: 0,
                        height: "40px",
                        width: "max-content",
                      }}
                      onClick={getDeliveryPartnerLogData}
                      variant="contained"
                    >
                      Get Date
                    </Button>
                  </Box>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>

          {/* ----------------- */}
          <Box>
            {dates.startDate && dates.endDate ? (
              <>
                <Stack spacing={2} mt={3}>
                  <TableContainer component={Paper}>
                    <Table ref={tableRef} aria-label="simple table">
                      <TableHead>
                        <TableRow
                          sx={{
                            background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={{ ...tableColumnsHeading, color: "white.main" }}
                          >
                            S.No
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ ...tableColumnsHeading, color: "white.main" }}
                          >
                            Date
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ ...tableColumnsHeading, color: "white.main" }}
                          >
                            Delivery Boy
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {logData
                          .filter(
                            (row, index1) => row.deliveryPartner.length > 0
                          )
                          .map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                                sx={tableColumnsHeading}
                              >
                                {index + 1}
                              </TableCell>

                              <TableCell
                                align="center"
                                sx={tableColumnsHeading}
                              >
                                {row.date}
                              </TableCell>
                              <TableCell align="center">
                                {" "}
                                <SingleDeliveryReportDataGrid
                                  data={row.deliveryPartner}
                                />
                              </TableCell>
                            </TableRow>
                          ))}

                        {/* if you want to have all date data then use this commented code */}

                        {/* {logData.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              {index + 1}
                            </TableCell>

                            <TableCell align="center">{row.date}</TableCell>
                            <TableCell align="center">
                              {" "}
                              <SingleDeliveryReportDataGrid
                                data={row.deliveryPartner}
                              />
                            </TableCell>
                          </TableRow>
                        ))} */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", sm: "end" },
                    mt: 2,
                  }}
                >
                  <DownloadTableExcel
                    filename="DeliveryPartner Log"
                    sheet="DeliveryPartner"
                    currentTableRef={tableRef.current}
                  >
                    <Button variant="contained"> Export excel </Button>
                  </DownloadTableExcel>
                </Box>
              </>
            ) : (
              <></>
            )}
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <DeliveryReportByPartner />
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default AllDeliveryReport;
