import React, { useContext, useEffect, useState } from "react";
import { AdminDataContext } from "../../../../../useContext/AdminContext";
import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { imgUrl } from "../../../../../url";
import {
  selectFieldStyle,
  textFieldBox,
} from "../../../../menuItem/itemCreate/CssStyle";

var FileSaver = require("file-saver");
const StyledContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
  paddingTop: "2rem",
  paddingBottom: "2rem",
  backgroundColor: "#F5F5F5",
  borderRadius: "5px",
});

const StyledForm = styled("form")({
  width: "100%",
});

const StyledInput = styled(TextField)({
  width: "100%",
});

const StyledButton = styled(Button)({
  width: "100%",
});

const MenuCard = ({ id, menuCard, getRestaurantDetails }) => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  console.log("menuCard", menuCard);
  //   const [gstNo, setGstNo] = useState({
  //     gst_no: gst.gst_no ? gst.gst_no : null,
  //   });
  //   const [gstFile, setGstFile] = useState("");
  const [menuItemsFile, setMenuItemsFile] = useState("");
  const [asm, setAsm] = useState([]);
  const [selectedASM, setSelectedASM] = useState("");
  const [isAvailable, setIsAvailable] = useState(
    menuCard.hasOwnProperty("menuItemsFile") ? true : false
  );

  const uploadDocument = (e) => {
    setMenuItemsFile(e.target.files[0]);
  };

  const getAllASM = async () => {
    try {
      const res = await axios.get(`/api/v1/admin/all-asm`);
      setAsm(res.data.data);
      console.log("res.data.asm", res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const onChangeHandler = (e) => {
    setSelectedASM(e.target.value);
    console.log(selectedASM);
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);

      console.log("restaurantId", id);
      let formData = new FormData();
      formData.append("document", menuItemsFile);
      formData.append("asm_id", selectedASM);
      formData.append("restaurantId", id);
      const res = await axios.post(
        `/api/v1/admin/restaurant/menu/item/file?restaurantId=${id}`,
        formData
      );
      snackbar(res.data.message, "success");
      console.log(res);
      // setGstFile("");
      // setGstNo({
      //   gst_no: "",
      // });
      getRestaurantDetails();

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      snackbar(error.response.data.message, "error");
      setIsLoading(false);
    }
  };
  const inputDocuments = {
    borderRadius: "10px",
    // background: "rgba(255, 255, 255, 0.31)",
    color: "rgba(255, 255, 255, 0.96)",
    border: " 1px solid rgba(103, 103, 103, 0.75)",
    "& fieldset": {
      border: "none",
      color: "rgba(255, 255, 255, 0.96);",
    },
  };
  useEffect(() => {
    getAllASM();
  }, []);
  return (
    <>
      <StyledContainer maxWidth="sm">
        <Box>
          <StyledForm onSubmit={(e) => handleSubmit(e)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "25px",
                    color: "rgba(0, 0, 0, 0.8)",
                  }}
                >
                  {" "}
                  Menu Card*{" "}
                  {isAvailable ? (
                    <FormControlLabel
                      mx={1}
                      value="veg"
                      control={<Checkbox />}
                      onChange={() => setIsAvailable(false)}
                      label="Re-Upload document"
                      labelPlacement="end"
                    />
                  ) : (
                    false
                  )}
                </Typography>
              </Grid>
              {isAvailable ? (
                <>
                  <Grid item xs={12} sx={{ display: "flex" }}>
                    <Typography sx={{ color: "grey.main" }}>
                      {" "}
                      Uploaded File {menuCard.menuItemsFile}
                    </Typography>

                    <CloudDownloadIcon
                      onClick={() =>
                        FileSaver.saveAs(
                          menuCard.menuItemsFile.startsWith("http")
                            ? `${menuCard.menuItemsFile}`
                            : `${imgUrl}/${menuCard.menuItemsFile}`,
                          "image.jpg"
                        )
                      }
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Box sx={textFieldBox}>
                      <Typography sx={{ color: "grey.main" }}>
                        {" "}
                        Select ASM
                      </Typography>

                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="selectedASM"
                          value={asm._id ? asm._id : menuCard.asm}
                          size="small"
                          onChange={onChangeHandler}
                          sx={selectFieldStyle}
                          disabled={menuCard.asm ? true : false}
                          required
                          // MenuProps={MenuProps}
                        >
                          {asm.map((data) => (
                            <MenuItem key={data._id} value={data._id}>
                              <span style={{ fontWeight: "bold" }}>
                                {data.name}
                              </span>{" "}
                              {data.phone}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>

                    <Typography sx={{ color: "grey.main" }}>
                      {" "}
                      File Upload
                    </Typography>

                    <StyledInput
                      fullWidth
                      variant="outlined"
                      required
                      inputProps={{ accept: "image/*,.pdf" }}
                      type="file"
                      size="small"
                      disabled={isAvailable ? true : false}
                      onChange={(e) => uploadDocument(e)}
                      sx={inputDocuments}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isAvailable ? true : false}
                  sx={{
                    background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                    color: "white.main",
                  }}
                >
                  Submit
                </StyledButton>
              </Grid>
            </Grid>
          </StyledForm>
        </Box>
      </StyledContainer>
    </>
  );
};

export default MenuCard;
