import React, { useContext, useEffect, useState } from "react";

import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { imgUrl } from "../../../url";
import { AdminDataContext } from "../../../useContext/AdminContext";
import { textFieldStyle } from "../../cssStyling/Style";

const MenuItemModal = (props) => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");
  const [price, setPrice] = useState(null);
  const [restaurant, setRestaurant] = useState([]);

  const [amount, setAmount] = useState([{ _id: "", amount: 0 }]);
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [log, setLog] = useState([]);
  const [loading, setLoading] = useState(true);

  const getAllRestaurant = async () => {
    try {
      setLoading(true);

      const res = await axios.get(`/api/v1/admin/getAllItems/${props.id}`);
      console.log("id is", props.id);
      setRestaurant(res.data.data);
      console.log("res.data.restaurant", res.data.data);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllRestaurant();
  }, []);

  const columns = [
    {
      field: "logo",
      headerName: "Logo",
      width: 100,
      renderCell: (params) => {
        return params.row.item_image_url ? (
          <>
            <Box
              height={"100%"}
              component="img"
              src={
                params.row.item_image_url.startsWith("http")
                  ? `${params.row.item_image_url}`
                  : `${imgUrl}/${params.row.item_image_url}`
              }
            />
          </>
        ) : (
          "No data available"
        );
      },
    },
    {
      field: "itemname",
      headerName: "Item Name",
      width: 180,
    },
    {
      field: "price",
      headerName: "Price",
      width: 70,
    },
    {
      field: "bhiwandiItemPrice",
      headerName: "Bhiwandi Price",
      width: 150,
      renderCell: (params) => {
        return params.row.bhiwandiItemPrice
          ? `${params.row.bhiwandiItemPrice}`
          : "N/A";
      },
    },
    {
      field: "Submitted",
      headerName: "Edit Bhiwandi Price",
      width: 400,
      renderCell: (params) => {
        const amountValue = amount.find(
          (element) => element._id === params.row._id
        );
        const amountHandler = async (e) => {
          e.preventDefault();
          try {
            setLoading(true);
            const response = await axios.patch(
              `/api/v1/admin/restaurants/price/${params.row._id}`,
              { bhiwandiItemPrice: amountValue.amount }
            );
            console.log("submited value is " + amountValue.amount);
            snackbar(response.data.message, "success");
            props.getAllRestaurant(page, limit, "", "");
            setAmount([{ _id: "", amount: 0 }]);
            setLoading(false);
          } catch (error) {
            snackbar(error.response.data.message, "error");
            setLoading(false);
          }
        };

        return (
          <Box component={"form"} onSubmit={amountHandler}>
            <TextField
              required
              sx={{
                ...textFieldStyle,
                width: "100px",
                border: "1px solid black",
                mx: 2,
              }}
              id="outlined-basic"
              //   label="amount"
              type="number"
              variant="outlined"
              size="small"
              onKeyDown={(event) => {
                event.stopPropagation();
              }}
              value={amountValue ? amountValue.amount : 0}
              //   value={params.row.price}
              onChange={(e) =>
                setAmount([
                  {
                    _id: params.row._id,
                    amount: e.target.value,
                  },
                ])
              }
            />
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </Box>
        );
      },
    },
  ];

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "550px",
    width: matches ? "40%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 2,
    overflow: "auto",
  };
  console.log(
    "props.restaurantData props.restaurantData",
    props.restaurantData
  );
  return (
    <>
      {/* <Modal
        open={props.openModal}
        onClose={props.handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      > */}
      <Box
      //  sx={styleModal}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
          gap={1}
          sx={{ position: "relative" }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "27px",
              color: "rgba(13, 30, 55, 0.67)",
              justifySelf: "start",
            }}
          >
            Available Menu Items in {props.restaurantName} restaurant
          </Typography>

          <IconButton onClick={props.handleCloseModal} aria-label="delete">
            <CancelIcon />
          </IconButton>
        </Stack>

        <DataGrid
          // components={{ Toolbar: GridToolbar }}
          autoHeight
          rows={restaurant}
          getRowId={(row) => row._id}
          // loading={loading}
          // sortingMode="server"
          pageSize={5}
          rowsPerPageOptions={[5]}
          columns={columns}
        />
      </Box>
      {/* </Modal> */}
    </>
  );
};

export default MenuItemModal;
