import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import CreateCustomerRewards from "./RedeemedCustomerRewards";
import AllCustomerRewards from "./AllCustomerRewards";
import { Box } from "@mui/material";
import RedeemedCustomerRewards from "./RedeemedCustomerRewards";
import CustomerReferredCalculation from "./CustomerReferredCalculation";

const CustomerReward = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          scrollButtons="auto"
          variant="scrollable"
        >
          <Tab label="Create Customer Rewards" {...a11yProps(0)} />
          <Tab label="Redeemed Rewards" {...a11yProps(1)} />
          <Tab label="Customer Referral Records" {...a11yProps(2)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <AllCustomerRewards />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <RedeemedCustomerRewards />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        <CustomerReferredCalculation />
      </CustomTabPanel>
    </>
  );
};

export default CustomerReward;
