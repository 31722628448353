import {
  Box,
  Button,
  TextField,
  Grid,
  useMediaQuery,
  Typography,
} from "@mui/material";

import Modal from "@mui/material/Modal";

import { imgUrl } from "../../url";
import DragsAndDrop from "../../reusableComponent/imageUpload/DragsAndDrop";
import { textFieldStyleForModal } from "../../components/cssStyling/Style";

const RewardModal = (props) => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "30%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Modal
        open={props.openModal}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container sx={{ maxHeight: "90vh", overflow: "auto" }}>
            <Grid item sx={12} component="form" onSubmit={props.onSubmit}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{ color: "grey.main" }}
                >
                  Image*
                </Typography>
                <DragsAndDrop
                  heading="Upload Image"
                  inputName="Image"
                  imgKey={props.data.image}
                  aspect={3.3 / 4}
                  uploadFile={(x) => {
                    props.setImage(x);
                  }}
                />
              </Grid>

              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main", mt: 2 }}
              >
                Points*
              </Typography>

              <TextField
                name="points"
                type="number"
                onChange={(e) => props.onChange(e)}
                value={props.data.points}
                size="small"
                required
                // sx={{ ...addressTxt, width: "100%" }}
                sx={textFieldStyleForModal}
              />

              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main", mt: 2 }}
              >
                Name*
              </Typography>

              <TextField
                name="name"
                type="text"
                onChange={(e) => props.onChange(e)}
                value={props.data.name}
                size="small"
                required
                // sx={{ ...addressTxt, width: "100%" }}
                sx={textFieldStyleForModal}
              />

              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main", mt: 2 }}
              >
                Description
              </Typography>

              <TextField
                multiline
                rows={2}
                // maxRows={3}
                inputProps={{ maxLength: 150 }}
                name="description"
                onChange={(e) => props.onChange(e)}
                value={props.data.description}
                size="small"
                required
                // sx={{ ...addressTxt, width: "100%" }}
                sx={textFieldStyleForModal}
              />

              <Grid container spacing={2} px={3} mt={3}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",

                    justifyContent: { xs: "center" },
                    alignItems: "end",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                      boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                      color: "white.main",
                      me: 5,
                    }}
                  >
                    Submit
                  </Button>

                  {/* <Button variant="contained" type="submit">
                    Create
                  </Button> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default RewardModal;
