import React, { useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AdminDataContext } from "../useContext/AdminContext";

const LogOut = () => {
  const { setIsLoading, setIsAuthenticated } = useContext(AdminDataContext);
  const navigate = useNavigate();
  const logout = async () => {
    setIsLoading(true);
    await axios.get("/api/v1/admin/logout");
    setIsLoading(false);
    setIsAuthenticated(false);

    return navigate("/login");
  };
  useEffect(() => {
    logout();
  }, []);
};

export default LogOut;
