import React, { useContext, useState } from "react";
import { AdminDataContext } from "../../../../../useContext/AdminContext";
import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { imgUrl } from "../../../../../url";

var FileSaver = require("file-saver");

const StyledContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
  paddingTop: "2rem",
  paddingBottom: "2rem",
  backgroundColor: "#F5F5F5",
  borderRadius: "5px",
});

const StyledForm = styled("form")({
  width: "100%",
});

const StyledInput = styled(TextField)({
  width: "100%",
});

const StyledButton = styled(Button)({
  width: "100%",
});

const Pan = ({ id, panCard, getRestaurantDetails }) => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  const [panNo, setPanNo] = useState({
    pan_no: panCard.pan_no,
  });
  const [panFile, setPanFile] = useState("");
  const [isAvailable, setIsAvailable] = useState(
    panCard.hasOwnProperty("pan_no") ? true : false
  );
  console.log("pan test", panCard.hasOwnProperty("pan_image"));
  const uploadPanDocument = (e) => {
    setPanFile(e.target.files[0]);
  };

  const onPanInputChange = (e) => {
    setPanNo({
      ...panNo,

      [e.target.name]: e.target.value.toUpperCase(),
    });
    console.log(panCard.pan_image);
  };
  // const fssaiRegexp = /^[1-2]{1}[0-9]{2}[0-9]{2}[0-9]{3}[0-9]{6}$/;
  const regex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  const handlePanSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      if (regex.test(panNo.pan_no)) {
        console.log(true);

        let formData = new FormData();
        formData.append("file", panFile);
        formData.append("pan_no", panNo.pan_no);
        formData.append("restaurantId", id);
        const res = await axios.post(`/api/v1/admin/restaurant/pan`, formData);
        console.log(res);

        // setPanNo({
        //   pan_no: "",
        // });
        getRestaurantDetails();
        snackbar(res.data.message, "success");
      } else {
        console.log(false);
        snackbar("Invalid pan number", "error");
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      snackbar(error.response.data.message, "error");
    }
  };
  const inputDocuments = {
    borderRadius: "10px",
    // background: "rgba(255, 255, 255, 0.31)",
    color: "rgba(255, 255, 255, 0.96)",
    border: " 1px solid rgba(103, 103, 103, 0.75)",
    "& fieldset": {
      border: "none",
      color: "rgba(255, 255, 255, 0.96);",
    },
  };
  return (
    <>
      <StyledContainer maxWidth="sm">
        <Box>
          <StyledForm onSubmit={(e) => handlePanSubmit(e)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "25px",
                    color: "rgba(0, 0, 0, 0.8)",
                  }}
                >
                  {" "}
                  Pan Card*{" "}
                  {isAvailable ? (
                    <FormControlLabel
                      mx={1}
                      value="veg"
                      control={<Checkbox />}
                      onChange={() => setIsAvailable(false)}
                      label="Re-Upload document"
                      labelPlacement="end"
                    />
                  ) : (
                    false
                  )}
                </Typography>
                <Typography sx={{ color: "grey.main" }}> Pan No*</Typography>

                <StyledInput
                  fullWidth
                  variant="outlined"
                  required
                  size="small"
                  name="pan_no"
                  disabled={isAvailable ? true : false}
                  value={panNo.pan_no}
                  onChange={(e) => onPanInputChange(e)}
                  sx={inputDocuments}
                />
              </Grid>

              {isAvailable ? (
                <>
                  <Grid item xs={12} sx={{ display: "flex" }}>
                    <Typography sx={{ color: "grey.main" }}>
                      {" "}
                      Uploaded File {panCard.pan_image}
                    </Typography>

                    <CloudDownloadIcon
                      onClick={() =>
                        FileSaver.saveAs(
                          panCard.pan_image.startsWith("http")
                            ? `${panCard.pan_image}`
                            : `${imgUrl}/${panCard.pan_image}`,
                          "image.jpg"
                        )
                      }
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Typography sx={{ color: "grey.main" }}>
                      {" "}
                      File Upload
                    </Typography>

                    <StyledInput
                      fullWidth
                      variant="outlined"
                      disabled={isAvailable ? true : false}
                      required
                      inputProps={{ accept: "image/*,.pdf" }}
                      type="file"
                      size="small"
                      onChange={(e) => uploadPanDocument(e)}
                      sx={inputDocuments}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isAvailable ? true : false}
                  sx={{
                    background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                    color: "white.main",
                  }}
                >
                  Submit
                </StyledButton>
              </Grid>
            </Grid>
          </StyledForm>
        </Box>
      </StyledContainer>
    </>
  );
};

export default Pan;
