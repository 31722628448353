import { Button, Stack, TextField } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AdminDataContext } from "../useContext/AdminContext";
const Version = () => {
  const { snackbar, setIsLoading } = useContext(AdminDataContext);
  const [data, setData] = useState({
    customerAndroidVersion: "",
    customerIosVersion: "",
    rpAndroidVersion: "",
    rpIosVersion: "",
    dpAndroidVersion: "",
    dpIosVersion: "",
  });
  const getData = async () => {
    try {
      const res = await axios.get("/api/v1/admin/version");
      setData(res.data.data);
    } catch (error) {}
  };

  const updateData = async () => {
    try {
      setIsLoading(true);
      const res = await axios.post("/api/v1/admin/version", { ...data });
      getData();
      setIsLoading(false);
      snackbar(res.data.message, "success");
    } catch (error) {
      setIsLoading(false);
      snackbar(error.response.data.message, "error");
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <Stack spacing={5}>
      <TextField
        sx={{ width: "300px" }}
        id="outlined-basic"
        label="Customer Android Version"
        name="customerAndroidVersion"
        value={data?.customerAndroidVersion}
        variant="outlined"
        onChange={onChange}
      />
      <TextField
        sx={{ width: "300px" }}
        id="outlined-basic"
        label="Customer Ios Version"
        name="customerIosVersion"
        value={data?.customerIosVersion}
        variant="outlined"
        onChange={onChange}
      />
      <TextField
        sx={{ width: "300px" }}
        id="outlined-basic"
        label="RP Android Version"
        name="rpAndroidVersion"
        value={data?.rpAndroidVersion}
        variant="outlined"
        onChange={onChange}
      />
      <TextField
        sx={{ width: "300px" }}
        id="outlined-basic"
        label="Rp Ios Version"
        name="rpIosVersion"
        value={data?.rpIosVersion}
        variant="outlined"
        onChange={onChange}
      />
      <TextField
        sx={{ width: "300px" }}
        id="outlined-basic"
        label="DP Android Version"
        name="dpAndroidVersion"
        value={data?.dpAndroidVersion}
        variant="outlined"
        onChange={onChange}
      />
      <TextField
        sx={{ width: "300px" }}
        id="outlined-basic"
        label="DP Ios Version"
        name="dpIosVersion"
        value={data?.dpIosVersion}
        variant="outlined"
        onChange={onChange}
      />

      <Button onClick={updateData} sx={{ width: "300px" }} variant="contained">
        Update
      </Button>
    </Stack>
  );
};

export default Version;
