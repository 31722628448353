import {
  Box,
  Button,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import { capitalize } from "../../reusableComponent/capitalFirstCharacter";
import { tableColumnsHeading } from "../cssStyling/Style";
import { DownloadTableExcel } from "react-export-table-to-excel";

function Customer() {
  const [customer, setCustomer] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [orderedCustomers, setOrderedCustomers] = useState(false);
  const tableRef = useRef(null);
  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 250,
      renderHeader: () => <strong>{"Name"}</strong>,
      renderCell: (params) =>
        params.row.name ? capitalize(params.row.name) : "",
    },

    {
      field: "email",
      headerName: "Email",
      width: 250,
      renderHeader: () => <strong>{"Email"}</strong>,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
      renderHeader: () => <strong>{"Phone"}</strong>,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 100,
      renderHeader: () => <strong>{"Gender"}</strong>,
    },
    {
      field: "orderCount",
      headerName: "orderCount",
      width: 100,
      renderHeader: () => <strong>{"Order Count"}</strong>,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      renderHeader: () => <strong>{"Created At"}</strong>,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
  ];

  useEffect(() => {
    getAllCustomer(page, limit, "", "");
  }, [orderedCustomers]);

  const getAllCustomer = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/customer?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}&orderedCustomers=${orderedCustomers}`
      );
      console.log("res.data.customer", res.data.customer);
      setCustomer(res.data.customer);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSortModelChange = async (item) => {
    getAllCustomer(page, limit, "", "", orderedCustomers);
    if (item.length === 0) {
      getAllCustomer(page, limit, "", "", orderedCustomers);
    } else {
      getAllCustomer(page, limit, item[0].sort, item[0].field);
    }
  };

  const onFilterChange = async (e) => {
    console.log(e);
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/admin/get/customer/search`, {
        key: e.items[0].columnField,
        value: e.items[0].value,
      });
      setCustomer(res.data.customer);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <Box>
      <Button
        variant="contained"
        sx={{ mb: 2 }}
        onClick={() => setOrderedCustomers(!orderedCustomers)}
      >
        {!orderedCustomers ? "Ordered Customers" : "All Customers"}
      </Button>
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        // autoHeight
        rows={customer}
        getRowId={(row) => row._id}
        rowCount={total}
        loading={loading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={page - 1}
        pageSize={limit}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        onPageChange={(newPage) => {
          getAllCustomer(newPage + 1, limit, "", "");
          setPage(newPage + 1);
        }}
        onPageSizeChange={(newPageSize) => {
          getAllCustomer(page, newPageSize, "", "");
          setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={columns}
        sx={{
          "& .bold-header": {
            fontWeight: "bold",
            color: "blue",
          },
          height: "600px",
          overflow: "auto",
        }}
      />
      {/* // download customers data */}
      <Stack spacing={2} mt={3} display={"none"}>
        <TableContainer component={Paper}>
          <Table ref={tableRef} aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                }}
              >
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  phone
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  email
                </TableCell>

                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  gender
                </TableCell>

                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  status
                </TableCell>

                {/* ------------------- */}

                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  orderCount
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableColumnsHeading, color: "white.main" }}
                >
                  Created At
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customer?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={tableColumnsHeading}
                  >
                    {row?.name}
                  </TableCell>

                  <TableCell align="center" sx={tableColumnsHeading}>
                    {"MN" + row?.phone}
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row?.email}
                  </TableCell>

                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row?.gender}
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row?.status}
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    {row?.orderCount}
                  </TableCell>
                  <TableCell align="center" sx={tableColumnsHeading}>
                    {moment(row.createdAt).format("DD/MM/YYYY hh:mm A")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <DownloadTableExcel
        filename="profit-loss"
        sheet="DeliveryPartner"
        currentTableRef={tableRef.current}
      >
        <Button variant="contained"> Export excel </Button>
      </DownloadTableExcel>
    </Box>
  );
}

export default Customer;
