import { Button, Grid } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import { imgUrl } from "../../url";
import { AdminDataContext } from "../../useContext/AdminContext";
const RestaurantMenuItem = () => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const { restaurantId } = useParams();
  const [menuItems, setMenuItems] = useState([]);
  const getMenuItems = async () => {
    const res = await axios.get(
      `/api/v1/admin/menuItems?restaurantId=${restaurantId}`
    );
    setMenuItems(res.data.data);
  };
  useEffect(() => {
    getMenuItems();
  }, []);

  const approveMenuItem = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `/api/v1/admin/restaurants/menuItem?menuItemId=${id}`
      );
      snackbar(res.data.message, "success");
      setIsLoading(false);
      getMenuItems();
    } catch (error) {
      snackbar(error.response.data.message, "success");

      setIsLoading(false);
    }
  };

  return (
    <Grid container flexDirection="column">
      <Grid item>
        <Typography sx={{ margin: "10px" }}>Menu Item Category</Typography>
      </Grid>
      {menuItems.map((category, index) => (
        <Grid item sx={{ margin: "10px" }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}a-content`}
              id={`panel${index + 1}a-header`}
            >
              <Typography>{category.title}</Typography>
            </AccordionSummary>
            <Grid item>
              <Typography sx={{ margin: "10px" }}>
                Menu Item Sub Category
              </Typography>
            </Grid>
            <AccordionDetails>
              <Grid>
                {category.menuItems.map((menuItem, index) => (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index + 1}b-content`}
                      id={`panel${index + 1}b-header`}
                    >
                      <Typography>{menuItem.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        <Grid item>
                          <Box
                            component="img"
                            width="400px"
                            src={`${imgUrl}/${menuItem.image}`}
                          ></Box>
                        </Grid>
                        <Grid margin={"10px"} item>
                          <Typography>
                            Unit Price: &#8377; {menuItem.unitPrice}
                          </Typography>
                          <Typography>
                            Final Price: &#8377; {menuItem.price}
                          </Typography>
                          <Typography>
                            Discount: {menuItem.discount}%
                          </Typography>

                          <Typography>
                            Preparation Time: {menuItem.preparationTime} min
                          </Typography>
                          {menuItem.approved ? (
                            <Typography>Approved</Typography>
                          ) : (
                            <Button
                              variant="contained"
                              onClick={() => approveMenuItem(menuItem._id)}
                            >
                              Approved
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
              {category.menuSubCategories.map((subCategory) => (
                <Grid>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index + 1}b-content`}
                      id={`panel${index + 1}b-header`}
                    >
                      <Typography>{subCategory.title}</Typography>
                    </AccordionSummary>
                    <Typography sx={{ margin: "10px" }}>Menu Item</Typography>
                    <AccordionDetails>
                      <Grid>
                        {subCategory.menuItems.map((menuItem, index) => (
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`panel${index + 1}b-content`}
                              id={`panel${index + 1}b-header`}
                            >
                              <Typography>{menuItem.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container>
                                <Grid item>
                                  <Box
                                    component="img"
                                    width="400px"
                                    src={`${imgUrl}/${menuItem.image}`}
                                  ></Box>
                                </Grid>
                                <Grid margin={"10px"} item>
                                  <Typography>
                                    Unit Price: &#8377; {menuItem.unitPrice}
                                  </Typography>
                                  <Typography>
                                    Final Price: &#8377; {menuItem.price}
                                  </Typography>
                                  <Typography>
                                    Discount: {menuItem.discount}%
                                  </Typography>

                                  <Typography>
                                    Preparation Time: {menuItem.preparationTime}{" "}
                                    min
                                  </Typography>
                                  {menuItem.approved ? (
                                    <Typography>Approved</Typography>
                                  ) : (
                                    <Button
                                      variant="contained"
                                      onClick={() =>
                                        approveMenuItem(menuItem._id)
                                      }
                                    >
                                      Approved
                                    </Button>
                                  )}
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
              <Typography></Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </Grid>
  );
};

export default RestaurantMenuItem;
