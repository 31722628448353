function capitalize(strr) {
  // console.log(strr)
  const a = strr.split(" ");
  console.log(a);
  let word = "";
  for (let i = 0; i < a.length; i++) {
    console.log(a[i].charAt(0).toUpperCase() + a[i].slice(1));
    word += a[i].charAt(0).toUpperCase() + a[i].slice(1).toLowerCase() + " ";
  }
  return word;
}

export { capitalize };
