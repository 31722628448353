import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DragsAndDrop from "../../reusableComponent/imageUpload/DragsAndDrop";
import axios from "axios";
import { AdminDataContext } from "../../useContext/AdminContext";
import { imgUrl } from "../../url";

const RedeemedCustomerRewards = () => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [redeemedRewards, setRedeemedRewards] = useState([]);
  const [loading, setLoading] = useState(true);

  const getRedeemedRewards = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/api/v1/admin/customerRedeemedRewards`);
      console.log(response);
      console.log(response);
      setRedeemedRewards(response.data.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const changeDeliveryStatus = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.patch(`/api/v1/admin/updateDeliveryStatus/${id}`);
      setIsLoading(false);
      console.log("change status ", res);
      snackbar(res.data.message, "success");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRedeemedRewards();
  }, []);
  const columns = [
    {
      field: "image",
      headerName: "Image",
      width: 150,
      renderHeader: () => <strong>{"Image"}</strong>,

      renderCell: (params) => {
        return params.row.redeemedReward.image ? (
          <>
            <Box
              height={"100%"}
              component="img"
              src={
                params.row.redeemedReward.image.startsWith("http")
                  ? `${params.row.redeemedReward.image}`
                  : `${imgUrl}/${params.row.redeemedReward.image}`
              }
            />
          </>
        ) : (
          "No data available"
        );
      },
    },

    {
      field: "name",
      headerName: "Reward Name",
      width: 150,
      renderHeader: () => <strong>{"Reward Name"}</strong>,

      renderCell: (params) => {
        return params.row.redeemedReward ? params.row.redeemedReward.name : "";
      },
    },

    {
      field: "customerName",
      headerName: "Customer Name",
      width: 200,
      renderHeader: () => <strong>{"Customer Name"}</strong>,
    },
    {
      field: "customerPhone",
      headerName: "Customer Phone",
      width: 150,
      renderHeader: () => <strong>{"Customer Phone"}</strong>,
    },
    {
      field: "deliveryStatus",
      headerName: "Delivery Status",
      width: 150,
      renderHeader: () => <strong>{"Delivery Status"}</strong>,

      renderCell: (params) => {
        return params.row?.deliveryStatus === "Pending" ? (
          <Button
            variant="contained"
            onClick={() => changeDeliveryStatus(params.row._id)}
          >
            Delivered
          </Button>
        ) : (
          "Delivered"
        );
      },
    },

    {
      field: "referralCode",
      headerName: "Referral Code",
      width: 150,
      renderHeader: () => <strong>{"Referral Code"}</strong>,

      renderCell: (params) => {
        return params.row.rewardedCustomer
          ? params.row.rewardedCustomer.referralCode
          : "";
      },
    },
    {
      field: "points",
      headerName: "Available Points",
      width: 150,
      renderHeader: () => <strong>{"Available Points"}</strong>,

      renderCell: (params) => {
        return params.row.rewardedCustomer
          ? params.row.rewardedCustomer.points
          : "";
      },
    },
    {
      field: "referredUsers",
      headerName: "Referred Users",
      width: 120,
      renderHeader: () => <strong>{"Referred Users"}</strong>,

      renderCell: (params) => {
        return params.row.rewardedCustomer
          ? params.row.rewardedCustomer.referredUsers.length
          : "";
      },
    },
    {
      field: "isUsedReferralCode",
      headerName: "Is Used Referral Code",
      width: 180,
      renderHeader: () => <strong>{"Is Used Referral Code"}</strong>,

      renderCell: (params) => {
        return params.row.rewardedCustomer
          ? params.row.rewardedCustomer.isUsedReferralCode
            ? "Used"
            : "Not Used"
          : "";
      },
    },
  ];

  return (
    <>
      <Box>
        <DataGrid
          components={{ Toolbar: GridToolbar }}
          autoHeight
          rows={redeemedRewards}
          getRowId={(row) => row._id}
          // rowCount={total}
          loading={loading}
          rowsPerPageOptions={[10, 30, 50, 70, 100]}
          pagination
          // page={page - 1}
          // pageSize={limit}
          paginationMode="server"
          sortingMode="server"
          // onSortModelChange={handleSortModelChange}
          filterMode="server"
          // onFilterModelChange={onFilterChange}
          // onPageChange={(newPage) => {
          //   getAllCustomer(newPage + 1, limit, "", "");
          //   setPage(newPage + 1);
          // }}
          // onPageSizeChange={(newPageSize) => {
          //   getAllCustomer(page, newPageSize, "", "");
          //   setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
          // }}
          columns={columns}
        />
      </Box>
    </>
  );
};

export default RedeemedCustomerRewards;
