export const dashboardText = {
  color: "rgba(13, 30, 55, 0.67)",
  fontWeight: 600,
};
export const valueBoxStyle = {
  display: "flex",
  justifyContent: "space-between",
};
export const paperBoxDashboardStyle = {
  textAlign: "center",
  backgroundColor: "yellow.tertiary",

  padding: 1.5,
  borderRadius: "10px 10px 0px 0px",
};

export const timeDurationDashboardStyle = {
  textAlign: "center",
  color: "white.main",
  fontSize: "25px",
  fontWeight: 600,
};
export const submitBtn = {
  width: "auto",
  // height: "50px",
  background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
  boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
  color: "white",
  p: 1.4,
  py: 0,
  textTransform: "capitalize",
  fontSize: "18px",
  fontWeight: 500,
  mt: 2,
  textDecoration: "none",
  borderRadius: "11px",
  mx: 2,
};

export const active = {
  width: "auto",

  background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
  boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
  color: "white.main",

  paddingY: 1,
  paddingX: 2,
  minWidth: "140px",
  textTransform: "capitalize",
  fontSize: "18px",
  fontWeight: 500,

  textDecoration: "none",
  borderRadius: "6px",
};

export const addBtn = {
  textTransform: "capitalize",
  color: "white.main",
  fontWeight: 600,
  fontSize: "15px",
  backgroundColor: "blue.primary",
  px: 2,
  marginRight: 10,
  "&:hover": {
    backgroundColor: "blue.primary",
  },
};

export const textFieldStyle = {
  width: "90%",
  borderRadius: "10px",
  backgroundColor: "addMenuItemColor.light",
  color: "rgba(255, 255, 255, 0.96)",

  "& fieldset": {
    border: "none",
    color: "rgba(255, 255, 255, 0.96);",
  },
};

export const selectFieldStyle = {
  width: "90%",
  borderRadius: "10px",
  backgroundColor: "addMenuItemColor.light",
  color: "back.main",

  "& fieldset": {
    border: "none",
    color: "back.main",
  },
};

export const textFieldBox = {
  width: "100%",
  mt: 2,
};

export const textHeading = {
  fontWeight: 600,
  fontSize: "18px",

  color: "black.primary",
  justifySelf: "start",
};

export const tableColumnsHeading = {
  fontWeight: 600,
  fontSize: "16px",

  color: "black.primary",
  justifySelf: "start",
};

// woner and restaurant select

export const containerStyle = {
  alignContent: "center",
  minHeight: "80vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const paperStyle = {
  width: "100%",
  bgcolor: "bgColor.main",
  borderRadius: "20px",
  minHeight: "70vh",
  position: "relative",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  p: 2,
};

export const paperBox = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

export const imageBox = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  borderRadius: 2,
};

export const restaurantDetailsGrid = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  p: 1,
  backgroundColor: "whisper.main",
  mt: { xs: 2, sm: 0 },
  borderRadius: 2,
};

// add menu items category, variants, addon,

export const topHeading = {
  fontWeight: 600,
  fontSize: "22px",
  color: "white.main",
  justifySelf: "start",
};
export const topBox = {
  position: "relative",
  backgroundColor: "grey.midGrey",
  p: 2,
  borderRadius: "16px",
  mt: 3,
};

export const textHeadingInputFields = {
  fontWeight: 600,
  fontSize: "15px",

  color: "black.primary",
  // justifySelf: "start",
};

export const ScheduleBtn = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  mt: 2,
};

export const textFieldStyleForModal = {
  width: { xs: "100%" },
  borderRadius: "10px",
  // background: "rgba(255, 255, 255, 0.31)",
  color: "rgba(255, 255, 255, 0.96)",
  border: "1px solid black",
  "& fieldset": {
    border: "none",
    color: "rgba(255, 255, 255, 0.96);",
  },
};

export const textFieldHeadingStyleForModal = {
  fontWeight: 500,
  fontSize: "16px",
  color: "grey.main",
};
export const accordianHeading = {
  fontWeight: 600,
  fontSize: "18px",
  mx: 1,
  color: "white.main",
};
