import {
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  Modal,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
// import io from "socket.io-client";
import CloseIcon from "@mui/icons-material/Close";
import { textHeadingInputFields } from "../menuItem/itemCreate/CssStyle";

const ModalForPaymentConfirmation = (props) => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "30%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
  };
  return (
    <div>
      <Modal
        open={props.visible}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
            gap={1}
            sx={{ position: "relative" }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "27px",
                color: "rgba(13, 30, 55, 0.67)",
                justifySelf: "start",
              }}
            >
              Payment Status
            </Typography>

            <IconButton onClick={() => props.handleClose()} aria-label="delete">
              <CloseIcon />
            </IconButton>
          </Stack>

          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item xs={12}>
              Are you sure you want to change the restaurant payment status ?
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" fontWeight={500} fontSize="16px">
                Message
              </Typography>

              <TextField
                id="outlined-basic"
                size="small"
                type="text"
                name="massage"
                required
                value={props.message}
                onChange={(e) => props.setMessage(e.target.value)}
                placeholder="Enter name"
                sx={textFieldStyle}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button variant="contained" onClick={() => props.handleClose()}>
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => props.updateRestaurantStatus(props.oid)}
              >
                Yes
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
const textFieldStyle = {
  width: "90%",
  borderRadius: "10px",
  backgroundColor: "addMenuItemColor.light",
  color: "rgba(255, 255, 255, 0.96)",

  "& fieldset": {
    border: "none",
    color: "rgba(255, 255, 255, 0.96);",
  },
};
export default ModalForPaymentConfirmation;
