import DashboardIcon from "@mui/icons-material/Dashboard";
import CategoryIcon from "@mui/icons-material/Category";
import LogoutIcon from "@mui/icons-material/Logout";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import FoodBankIcon from "@mui/icons-material/FoodBank";
import { useContext } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { AdminDataContext } from "../../../useContext/AdminContext";
import PersonIcon from "@mui/icons-material/Person";
import DiscountIcon from "@mui/icons-material/Discount";
import Person4Icon from "@mui/icons-material/Person4";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import NotificationsIcon from "@mui/icons-material/Notifications";
import StoreIcon from "@mui/icons-material/Store";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
const useContent = () => {
  const { adminData } = useContext(AdminDataContext);

  const content = [
    {
      icon: DashboardIcon,
      text: "Dashboard",
      slug: "",
    },
  ];
  if (adminData.permissions.restaurant) {
    content.push({
      icon: RestaurantIcon,
      text: "Manage Restaurants",
      slug: "restaurant",
      items: [
        {
          text: "Onboarding",
          slug: "onboarding",
        },
        // {
        //   text: "Add Menu Item",
        //   slug: "select-restaurant",
        // },

        {
          text: "Pet Pooja",
          slug: "pet_pooja",
        },
        {
          text: "UnVerified Pet Pooja",
          slug: "unverified_pet_pooja",
        },
        {
          text: "Verified",
          slug: "verified",
        },
        {
          text: "Active Restaurant",
          slug: "active-restaurant",
        },
        {
          text: "Unverified",
          slug: "unverified",
        },
        {
          text: "Bhiwandi Restaurant",
          slug: "bhiwandi-restaurant",
        },
        {
          text: "Owner",
          slug: "owner",
        },
        {
          text: "AppReview Restaurant",
          slug: "appReview",
        },
        {
          text: "Online",
          slug: "online",
        },
      ],
      // restaurant/online
    });
  }

  if (adminData.permissions.restaurant) {
    content.push({
      icon: RestaurantIcon,
      text: "Menu Items",
      slug: "i",
      items: [
        {
          text: "Categories",
          slug: "categories",
        },
        {
          text: "Variants",
          slug: "variants",
        },
        {
          text: "Addons",
          slug: "addons",
        },
        {
          text: "Items",
          slug: "items",
        },
      ],
      // restaurant/online
    });
  }

  if (adminData.permissions.restaurant) {
    content.push({
      icon: RestaurantIcon,
      text: "Manage Order",
      slug: "order",
      items: [
        {
          text: "Assign Order",
          slug: "deliveryBoyNotAvailable",
        },
        {
          text: "All Orders",
          slug: "allOrders",
        },

        {
          text: "Discounted Orders",
          slug: "discountedOrders",
        },
        {
          text: "Restaurant not accepted",
          slug: "restaurantNotAccepted",
        },
        // {
        //   text: "Restaurant Orders",
        //   slug: "restaurantOrder",
        // },
      ],
    });
  }

  if (adminData.permissions.account) {
    content.push({
      icon: PersonIcon,
      text: "Ybites Accounts",
      slug: "accounts",
      items: [
        {
          text: "All Calculations",
          slug: "",
        },
        {
          text: "Bhiwandi All Calculations",
          slug: "bhiwandi-all-calculations",
        },
        {
          text: "GST To Be Paid",
          slug: "gst-to-be-paid",
        },
        {
          text: "TDS To Be Paid",
          slug: "tds-to-be-paid",
        },
        {
          text: "Payment To Restaurant",
          slug: "payment-to-restaurant",
        },
        {
          text: "Profit N Loss",
          slug: "profit-loss",
        },
        {
          text: "Delivery Charge Payable",
          slug: "delivery-charge-payable",
        },
      ],
    });
    content.push({
      icon: PersonIcon,
      text: "Petpooja Accounts",
      slug: "accounts-petpooja",
      items: [
        {
          text: "All Calculations",
          slug: "",
        },
        {
          text: "GST To Be Paid",
          slug: "gst-to-be-paid-petpooja",
        },
        {
          text: "TDS To Be Paid",
          slug: "tds-to-be-paid-petpooja",
        },
        {
          text: "Payment To Restaurant",
          slug: "payment-to-restaurant-petpooja",
        },
        {
          text: "Profit N Loss",
          slug: "profit-loss-petpooja",
        },
        {
          text: "Delivery Charge Payable",
          slug: "delivery-charge-payable-petpooja",
        },
      ],
    });
  }

  if (adminData.permissions.category) {
    content.push({
      icon: CategoryIcon,
      text: "Category",
      slug: "category",
    });
  }
  // if (adminData.permissions.category) {
  //   content.push({
  //     icon: CategoryIcon,
  //     text: "Report",
  //     slug: "report",
  //   });
  // }
  if (adminData.permissions.category) {
    content.push({
      icon: StoreIcon,
      text: "Brand",
      slug: "brand",
    });
  }
  if (adminData.permissions.version) {
    content.push({
      icon: ManageHistoryIcon,
      text: "App Version",
      slug: "version",
    });
  }

  if (adminData.permissions.complain) {
    content.push({
      icon: HelpOutlineIcon,
      text: "Complain",
      slug: "complain",
    });
  }
  // if (adminData.permissions.complain) {
  //   content.push({
  //     icon: HelpOutlineIcon,
  //     text: "AppReview Restaurant",
  //     slug: "appReview/r",
  //   });
  // }

  if (adminData.permissions.complain) {
    content.push({
      icon: HelpOutlineIcon,
      text: "Ticket",
      slug: "ticket",
      items: [
        {
          text: "Restaurant Ticket",
          slug: "ticket/r",
        },
        {
          text: "Delivery Ticket",
          slug: "ticket/d",
        },
      ],
    });
  }

  // if (adminData.permissions.complain) {
  //   content.push({
  //     icon: HelpOutlineIcon,
  //     text: "Restaurant Ticket",
  //     slug: "ticket/r",
  //   });
  // }
  // if (adminData.permissions.complain) {
  //   content.push({
  //     icon: HelpOutlineIcon,
  //     text: "Delivery Ticket",
  //     slug: "ticket/d",
  //   });
  // }

  if (adminData.permissions.coupon) {
    content.push({
      icon: DiscountIcon,
      text: "Ybites Coupon",
      slug: "coupon",
    });
  }

  if (adminData.permissions.delivery) {
    content.push({
      icon: DeliveryDiningIcon,
      text: "Delivery",
      slug: "delivery",
      items: [
        {
          text: "Delivery Boys",
          slug: "delivery",
        },
        {
          text: "Blocked Delivery Boys",
          slug: "blocked-delivery",
        },
        {
          text: "Delivery Report",
          slug: "deliveryReport",
        },
        {
          text: "All Delivery Report",
          slug: "allDeliveryReport",
        },
        // {
        //   text: "Delivery Monitor",
        //   slug: "deliveryMonitor",
        // },
        {
          text: "Delivery Monitor",
          slug: "delivery-boy-location",
        },

        {
          text: "Delivery Cash",
          slug: "deliveryCash",
        },
        {
          text: "Unverified Delivery Boys",
          slug: "deliveryUnverified",
        },
      ],
    });
  }

  // if (adminData.permissions.delivery) {
  //   content.push({
  //     icon: DeliveryDiningIcon,
  //     text: "Delivery Boys",
  //     slug: "delivery",
  //   });
  // }

  // if (adminData.permissions.delivery) {
  //   content.push({
  //     icon: DeliveryDiningIcon,
  //     text: "Delivery Report",
  //     slug: "deliveryReport",
  //   });
  // }

  // if (adminData.permissions.delivery) {
  //   content.push({
  //     icon: DeliveryDiningIcon,
  //     text: "All Delivery Report",
  //     slug: "allDeliveryReport",
  //   });
  // }

  // if (adminData.permissions.delivery) {
  //   content.push({
  //     icon: DeliveryDiningIcon,
  //     text: "Delivery Monitor",
  //     slug: "deliveryMonitor",
  //   });
  // }
  // if (adminData.permissions.delivery) {
  //   content.push({
  //     icon: DeliveryDiningIcon,
  //     text: "Delivery Cash",
  //     slug: "deliveryCash",
  //   });
  // }
  if (adminData.permissions.notification) {
    content.push({
      icon: NotificationsIcon,
      text: "Notification",
      slug: "notification",
      items: [
        {
          text: "Restaurant Notification",
          slug: "restaurantNotification",
        },
        {
          text: "Customer Notification",
          slug: "customerNotification",
        },
        {
          text: "Delivery Notification",
          slug: "deliveryNotification",
        },
      ],
    });
  }

  // if (adminData.permissions.delivery) {
  //   content.push({
  //     icon: DeliveryDiningIcon,
  //     text: "Unverified Delivery Boys",
  //     slug: "deliveryUnverified",
  //   });
  // }
  // if (adminData.permissions.notification) {
  //   content.push({
  //     icon: NotificationsIcon,
  //     text: "Restaurant Notification",
  //     slug: "restaurantNotification",
  //   });
  // }
  // if (adminData.permissions.notification) {
  //   content.push({
  //     icon: NotificationsIcon,
  //     text: "Customer Notification",
  //     slug: "customerNotification",
  //   });
  // }
  // if (adminData.permissions.notification) {
  //   content.push({
  //     icon: NotificationsIcon,
  //     text: "Delivery Notification",
  //     slug: "deliveryNotification",
  //   });
  // }

  if (adminData.permissions.banner) {
    content.push({
      icon: ViewCarouselIcon,
      text: "Banner",
      slug: "banner",
    });
  }

  // if (adminData.permissions.owner) {
  //   content.push({
  //     icon: CategoryIcon,
  //     text: "Owner",
  //     slug: "owner",
  //   });
  // }
  if (adminData.permissions.owner) {
    content.push({
      icon: CategoryIcon,
      text: "ASM",
      slug: "asm",
      items: [
        {
          text: "ASM",
          slug: "",
        },
        {
          text: "ASM Monitor",
          slug: "asm-monitor",
        },
      ],
    });
  }

  if (adminData.permissions.customer) {
    content.push({
      icon: Person4Icon,
      text: "Customers",
      slug: "customer",
      items: [
        { icon: Person4Icon, text: "All Customers", slug: "allCustomers" },
        {
          icon: Person4Icon,
          text: "Active Customers",
          slug: "activeCustomers",
        },
        {
          icon: Person4Icon,
          text: "Customer Reward",
          slug: "reward",
        },
      ],
    });
  }
  if (adminData.permissions.permission) {
    content.push({
      icon: PersonIcon,
      text: "Admin Permission",
      slug: "permission",
    });
  }
  content.push({
    icon: LogoutIcon,
    text: "Logout",
    slug: "logout",
  });
  return content;
};
export default useContent;
