import React, { useContext, useState } from "react";
import { AdminDataContext } from "../../../../../useContext/AdminContext";
import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

const BankDetails = ({
  id,

  bankDetail,
  getRestaurantDetails,
}) => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [bankDetails, setBankDetails] = useState(
    bankDetail
      ? bankDetail
      : {
          bankName: "",
          accountNumber: null,
          ifscCode: "",
          branch: "",
          accountHolderName: "",
        }
  );
  const [isAvailable, setIsAvailable] = useState(
    bankDetail.hasOwnProperty("ifscCode") ? true : false
  );
  const onBankDetailChange = (e) => {
    setBankDetails({ ...bankDetails, [e.target.name]: e.target.value });
    console.log(bankDetails);
  };

  const handleBankDetailSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const accountNumberPattern = /^\d{9,18}$/;
      const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
      const ifsc = bankDetails.ifscCode.toUpperCase();
      if (
        accountNumberPattern.test(bankDetails.accountNumber) &&
        bankDetails.accountNumber > 0 &&
        ifscRegex.test(ifsc)
      ) {
        const res = await axios.post(
          `/api/v1/admin/restaurant/bank/?restaurantId=${id}`,
          {
            ...bankDetails,
            accountNumber: bankDetails.accountNumber * 1,
            ifscCode: ifsc,
          }
        );
        console.log("finally", bankDetails);
        snackbar(res.data.message, "success");
        getRestaurantDetails();
      } else {
        console.log("finalyy", bankDetails);

        snackbar("Invalid account number or IFSC code", "error");
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const addressTxt = {
    width: { xs: "100%", sm: "80%" },

    borderRadius: "10px",
    // background: "rgba(255, 255, 255, 0.31)",
    color: "rgba(255, 255, 255, 0.96)",
    border: "1px solid black",
    "& fieldset": {
      border: "none",
      color: "rgba(255, 255, 255, 0.96);",
    },
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <form
          style={{ width: "100%" }}
          onSubmit={(e) => handleBankDetailSubmit(e)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              {isAvailable ? (
                <FormControlLabel
                  mx={1}
                  value="veg"
                  control={<Checkbox />}
                  onChange={() => setIsAvailable(false)}
                  label="Update Bank Details"
                  labelPlacement="end"
                />
              ) : (
                false
              )}
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Bank Name*
              </Typography>

              <TextField
                name="bankName"
                value={bankDetails.bankName}
                //  defaultValue={restaurantDetails.bankDetail.bankName}
                disabled={isAvailable ? true : false}
                onChange={(e) => onBankDetailChange(e)}
                size="small"
                required
                sx={addressTxt}
              />

              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main", mt: 2 }}
              >
                Account Holder Name*
              </Typography>

              <TextField
                name="accountHolderName"
                disabled={isAvailable ? true : false}
                value={bankDetails.accountHolderName}
                onChange={(e) => onBankDetailChange(e)}
                size="small"
                required
                sx={addressTxt}
              />

              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main", mt: 2 }}
              >
                Branch*
              </Typography>

              <TextField
                name="branch"
                value={bankDetails.branch}
                disabled={isAvailable ? true : false}
                // defaultValue={restaurantDetails.bankDetail.branch}
                onChange={(e) => onBankDetailChange(e)}
                size="small"
                type="text"
                required
                sx={addressTxt}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                IFSC Code*
              </Typography>

              <TextField
                name="ifscCode"
                value={bankDetails.ifscCode.toUpperCase()}
                disabled={isAvailable ? true : false}
                // defaultValue={restaurantDetails.bankDetail.ifscCode}
                onChange={(e) => onBankDetailChange(e)}
                size="small"
                required
                sx={addressTxt}
              />

              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main", mt: 2 }}
              >
                Account Number*
              </Typography>

              <TextField
                name="accountNumber"
                value={
                  bankDetails.accountNumber ? bankDetails.accountNumber : ""
                }
                disabled={isAvailable ? true : false}
                // defaultValue={restaurantDetails.bankDetail.accountNumber}
                onChange={(e) => onBankDetailChange(e)}
                size="small"
                type="number"
                required
                sx={addressTxt}
              />
            </Grid>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: { xs: "center", sm: "end" },
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isAvailable ? true : false}
                sx={{
                  mt: 4,
                  background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                  boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                  color: "white.main",
                  me: 5,
                }}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default BankDetails;
