import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import DragsAndDrop from "../../reusableComponent/imageUpload/DragsAndDrop";
import { AdminDataContext } from "./../../useContext/AdminContext";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { imgUrl } from "../../url";
import NotificationSingleModal from "./NotificationSingleModal";

const DeliveryNotification = () => {
  const [data, setData] = useState({
    title: "",
    message: "",
  });

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [image, setImage] = useState("");
  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  const [complain, setComplain] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 150,
    },

    {
      field: "description",
      headerName: "Description",
      width: 250,
    },

    {
      field: "Image",
      headerName: "Image",
      width: 300,
      renderCell: (params) => {
        return (
          <Box
            height={"100%"}
            component="img"
            src={`${imgUrl}/${params.row.image}`}
            alt=""
          />
        );
      },
    },

    {
      field: "show",
      headerName: "show",
      width: 150,
      renderCell: (params) => {
        const solutionHandler = async (e) => {
          try {
            setLoading(true);
            const response = await axios.patch(
              `/api/v1/admin/notification/delivery?notificationId=${params.row._id}`,
              { show: e.target.checked }
            );

            snackbar(response.data.message, "success");
            getNotification(page, limit, "", "");

            setLoading(false);
          } catch (error) {
            snackbar(error.response.data.message, "error");
            setLoading(false);
          }
        };

        return <Switch onChange={solutionHandler} checked={params.row.show} />;
      },
    },
  ];

  useEffect(() => {
    getNotification(page, limit, "", "");
  }, []);

  const getNotification = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/notification/delivery?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}`
      );
      setComplain(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSortModelChange = async (item) => {
    getNotification(page, limit, "", "");
    if (item.length === 0) {
      getNotification(page, limit, "", "");
    } else {
      getNotification(page, limit, item[0].sort, item[0].field);
    }
  };

  const onFilterChange = async (e) => {
    console.log(e);
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/admin/get/complains/search`, {
        key: e.items[0].columnField,
        value: e.items[0].value,
      });
      setComplain(res.data.complain);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const sendNotification = async (event, data, image) => {
    event.preventDefault();
    try {
      if (image) {
        setIsLoading(true);
        let formData = new FormData();
        formData.append("image", image);
        formData.append("title", data.title);
        formData.append("message", data.message);

        const res = await axios.post(
          `/api/v1/admin/notification/delivery`,
          formData
        );
        setData({ title: "", message: "" });
        setImage("");
        snackbar(res.data.message, "success");
        setIsLoading(false);
        getNotification(page, limit, "", "");
        handleCloseModal();
      } else {
        snackbar("Image Is Required", "error");
        setIsLoading(false);
      }
    } catch (error) {
      snackbar(error.response.data.message, "error");

      setIsLoading(false);
    }
  };
  return (
    <Box>
      <Button onClick={handleOpenModal} variant="contained">
        Send Notification for Delivery Boy
      </Button>

      <NotificationSingleModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        data={data}
        setImage={setImage}
        image={image}
        setData={setData}
        sendNotification={sendNotification}
      />

      <Box p={2}>
        <DataGrid
          //   components={{ Toolbar: GridToolbar }}
          autoHeight
          rows={complain}
          getRowId={(row) => row._id}
          rowCount={total}
          loading={loading}
          rowsPerPageOptions={[10, 30, 50, 70, 100]}
          pagination
          page={page - 1}
          pageSize={limit}
          paginationMode="server"
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          filterMode="server"
          onFilterModelChange={onFilterChange}
          onPageChange={(newPage) => {
            getNotification(newPage + 1, limit, "", "");
            setPage(newPage + 1);
          }}
          onPageSizeChange={(newPageSize) => {
            getNotification(page, newPageSize, "", "");
            setLimit(newPageSize);
            //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
          }}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default DeliveryNotification;
