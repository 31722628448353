import { Box, Button, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import FilterByCity from "../../../reusableComponent/filterByCity/FilterByCity";

function TdsToBePaidPetPooja() {
  const [orderReport, setOrderReport] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      field: "orderId",
      headerName: "Order Id",
      width: 100,
      renderCell: (params) => {
        return params.row.deliveredOrderId
          ? params.row.deliveredOrderId.orderId
          : "";
      },
    },
    {
      field: "brand_display_name",
      headerName: "Restaurant",
      width: 200,

      renderCell: (params) => {
        return params.row.restaurantId ? (
          <Typography>{params.row.restaurantId.brand_display_name}</Typography>
        ) : (
          <>-</>
        );
      },
    },

    {
      field: "menuItems",
      headerName: "Menu Items",
      width: 300,
      font: 10,
      renderCell: (params) => {
        return params.row.deliveredOrderId ? (
          <Typography sx={{ fontSize: 13 }}>
            {params.row.deliveredOrderId.orderItems.map((item) => (
              <li>{`${item.itemTitle}=${item.quantity}X${
                item.price / item.quantity
              }=${item.price}, `}</li>
            ))}
          </Typography>
        ) : (
          ""
        );
      },
    },

    {
      field: "quantity",
      headerName: " Total Quantity",
      width: 100,
      renderCell: (params) => {
        return params.row.deliveredOrderId ? (
          <Typography sx={{ fontSize: 13 }}>
            {params.row.deliveredOrderId.orderItems.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.quantity,
              0
            )}
          </Typography>
        ) : (
          ""
        );
      },
    },

    {
      field: "grandTotalPrice",
      headerName: "Total Amount Per Order",
      width: 200,
      renderCell: (params) => {
        return params.row.deliveredOrderId
          ? params.row.deliveredOrderId.grandTotalPrice
          : "";
      },
    },

    {
      field: "totalTds",
      headerName: "Total TDS",
      width: 170,
    },
    {
      field: "pan_no",
      headerName: "Pan Card",
      width: 170,
      renderCell: (params) => {
        return params.row.restaurantId ? params.row.restaurantId.pan_no : "";
      },
    },
  ];

  useEffect(() => {
    getAllOrders(page, limit, "", "");
  }, []);

  const getAllOrders = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      // const res = await axios.get(
      //   `/api/v1/admin/restaurantReports?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}`
      // );
      const res = await axios.get(`/api/v1/admin/restaurantCalculatedReports`);
      setOrderReport(() =>
        res.data.data.filter((data, index) => {
          return data.restaurantId.petPooja === true;
        })
      );
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // const handleSortModelChange = async (item) => {
  //   getAllOrders(page, limit, "", "");
  //   if (item.length === 0) {
  //     getAllOrders(page, limit, "", "");
  //   } else {
  //     getAllOrders(page, limit, item[0].sort, item[0].field);
  //   }
  // };

  // const onFilterChange = async (e) => {
  //   console.log(e.items);
  //   try {
  //     setLoading(true);
  //     const res = await axios.post(`/api/v1/admin/order/filter`, {
  //       key: e.items[0].columnField,
  //       value: e.items[0].value,
  //     });
  //     setOrderReport(res.data.data);
  //     setTotal(res.data.total);
  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };
  // onClick={createComplainHandler}
  return (
    <Box>
      <FilterByCity />
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        autoHeight
        rows={orderReport}
        getRowId={(row) => row._id}
        rowCount={total}
        loading={loading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={page - 1}
        pageSize={limit}
        paginationMode="server"
        sortingMode="server"
        // onSortModelChange={handleSortModelChange}
        filterMode="server"
        // onFilterModelChange={onFilterChange}
        onPageChange={(newPage) => {
          getAllOrders(newPage + 1, limit, "", "");
          setPage(newPage + 1);
        }}
        onPageSizeChange={(newPageSize) => {
          getAllOrders(page, newPageSize, "", "");
          setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={columns}
      />
    </Box>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default TdsToBePaidPetPooja;
