import React, { useState } from "react";

import {
  Box,
  Divider,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

const AsmRestaurantDetailsModal = (props) => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");

  const [itemCount, setItemCount] = useState([]);

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "550px",
    width: matches ? "40%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 2,
    overflow: "auto",
  };
  console.log(
    "props.restaurantData props.restaurantData",
    props.restaurantData
  );
  return (
    <>
      <Modal
        open={props.openModal}
        onClose={props.handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
            gap={1}
            sx={{ position: "relative" }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "27px",
                color: "rgba(13, 30, 55, 0.67)",
                justifySelf: "start",
              }}
            >
              {props.type.restaurant === true
                ? "ASM Restaurant Details"
                : "ASM Owner Details"}
            </Typography>

            <IconButton onClick={props.handleCloseModal} aria-label="delete">
              <CancelIcon />
            </IconButton>
          </Stack>

          {props.type.restaurant === true ? (
            <>
              {" "}
              {props.restaurantData.map((data, index) => (
                <Grid
                  key={index}
                  container
                  spacing={2}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Grid item xs={6}>
                    {" "}
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      fontSize="16px"
                      sx={{ color: "grey.main" }}
                    >
                      <span style={{ fontWeight: "bold" }}> {index} - </span>{" "}
                      Owner Name
                    </Typography>
                  </Grid>

                  <Grid item xs={6} textAlign={"right"}>
                    {" "}
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      fontSize="16px"
                      sx={{ color: "grey.main" }}
                    >
                      {data.owner_name}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    {" "}
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      fontSize="16px"
                      sx={{ color: "grey.main" }}
                    >
                      Restaurant Name
                    </Typography>
                  </Grid>

                  <Grid item xs={6} textAlign={"right"}>
                    {" "}
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      fontSize="16px"
                      sx={{ color: "grey.main" }}
                    >
                      {data.brand_display_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {" "}
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      fontSize="16px"
                      sx={{ color: "grey.main" }}
                    >
                      Mobile Number
                    </Typography>
                  </Grid>

                  <Grid item xs={6} textAlign={"right"}>
                    {" "}
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      fontSize="16px"
                      sx={{ color: "grey.main" }}
                    >
                      {data.business_contact}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {" "}
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      fontSize="16px"
                      sx={{ color: "grey.main" }}
                    >
                      Email
                    </Typography>
                  </Grid>

                  <Grid item xs={6} textAlign={"right"}>
                    {" "}
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      fontSize="16px"
                      sx={{ color: "grey.main" }}
                    >
                      {data.email}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {" "}
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      fontSize="16px"
                      sx={{ color: "grey.main" }}
                    >
                      Address
                    </Typography>
                  </Grid>

                  <Grid item xs={6} textAlign={"right"}>
                    {" "}
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      fontSize="16px"
                      sx={{ color: "grey.main" }}
                    >
                      {data.address}
                    </Typography>
                  </Grid>
                  <hr />
                </Grid>
              ))}
            </>
          ) : (
            <>
              {props.restaurantData.map((data, index) => (
                <Grid
                  key={index}
                  container
                  spacing={2}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Grid item xs={6}>
                    {" "}
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      fontSize="16px"
                      sx={{ color: "grey.main" }}
                    >
                      <span style={{ fontWeight: "bold" }}> {index} - </span>{" "}
                      Owner Name
                    </Typography>
                  </Grid>

                  <Grid item xs={6} textAlign={"right"}>
                    {" "}
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      fontSize="16px"
                      sx={{ color: "grey.main" }}
                    >
                      {data.name}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    {" "}
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      fontSize="16px"
                      sx={{ color: "grey.main" }}
                    >
                      Mobile Number
                    </Typography>
                  </Grid>

                  <Grid item xs={6} textAlign={"right"}>
                    {" "}
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      fontSize="16px"
                      sx={{ color: "grey.main" }}
                    >
                      {data.phone}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {" "}
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      fontSize="16px"
                      sx={{ color: "grey.main" }}
                    >
                      Email
                    </Typography>
                  </Grid>

                  <Grid item xs={6} textAlign={"right"}>
                    {" "}
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      fontSize="16px"
                      sx={{ color: "grey.main" }}
                    >
                      {data.email}
                    </Typography>
                  </Grid>

                  <hr />
                </Grid>
              ))}
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default AsmRestaurantDetailsModal;
