import React, { createContext, useState } from "react";
export const AdminDataContext = createContext({
  updateAdminData: () => {},
});
const AdminContext = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [adminData, setAdminData] = useState({});
  const [selectCity, setSelectCity] = useState("");
  const [filterType, setFilterType] = useState({
    city: true,
    restaurant: false,
  });
  // Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const updateAdminData = (data) => {
    setAdminData(data);
  };

  const snackbar = (messageData, severityData) => {
    setOpenSnackbar(true);
    setMessage(messageData);
    setSeverity(severityData);
  };

  return (
    <AdminDataContext.Provider
      value={{
        updateAdminData,
        adminData,

        isAuthenticated,
        setIsAuthenticated,
        isLoading,
        setIsLoading,
        snackbar,
        openSnackbar,
        setOpenSnackbar,
        message,
        severity,
        selectCity,
        setSelectCity,
        filterType,
        setFilterType,
      }}
    >
      {props.children}
    </AdminDataContext.Provider>
  );
};

export default AdminContext;
