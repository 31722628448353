import React from "react";

import {
  Grid,
  Divider,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { AdminDataContext } from "../../../../../useContext/AdminContext";
import { useContext } from "react";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreTimeIcon from "@mui/icons-material/MoreTime";

import axios from "axios";

const weeks = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const Timing = ({ id, time, getRestaurantDetails }) => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  console.log("time", time);
  const [restaurantTime, setRestaurantTime] = useState(
    time.length > 0
      ? [...time]
      : [
          {
            day: 0,
            slots: [
              {
                to: "22:00",
                from: "10:00",
              },
            ],
            active: "1",
          },
          {
            day: 1,
            slots: [
              {
                to: "22:00",
                from: "10:00",
              },
            ],
            active: "1",
          },
          {
            day: 2,
            slots: [
              {
                to: "22:00",
                from: "10:00",
              },
            ],
            active: "1",
          },
          {
            day: 3,
            slots: [
              {
                to: "22:00",
                from: "10:00",
              },
            ],
            active: "1",
          },
          {
            day: 4,
            slots: [
              {
                to: "22:00",
                from: "10:00",
              },
            ],
            active: "1",
          },
          {
            day: 5,
            slots: [
              {
                to: "22:00",
                from: "10:00",
              },
            ],
            active: "1",
          },
          {
            day: 6,
            slots: [
              {
                to: "22:00",
                from: "10:00",
              },
            ],
            active: "1",
          },
        ]
  );

  const [isAvailable, setIsAvailable] = useState(
    time.length > 0 ? true : false
  );

  const handleTimeChange = (index, slotIndex, property, value) => {
    setRestaurantTime((prevRestaurantTime) => {
      const updatedTime = [...prevRestaurantTime];
      updatedTime[index].slots[slotIndex][property] = value;
      console.log(updatedTime);
      return updatedTime;
    });
  };
  const handleAddSlot = (index) => {
    setRestaurantTime((prevRestaurantTime) => {
      const updatedTime = [...prevRestaurantTime];
      const newSlot = { to: "", from: "" }; // Initialize a new time slot
      updatedTime[index].slots.push(newSlot); // Add the new time slot to the day's slots
      return updatedTime;
    });
  };
  const handleRemoveSlot = (index, slotIndex) => {
    if (slotIndex > 0) {
      setRestaurantTime((prevRestaurantTime) => {
        const updatedTime = [...prevRestaurantTime];
        updatedTime[index].slots.splice(slotIndex, 1); // Remove the specified time slot
        return updatedTime;
      });
    }
  };

  const onTimeSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const res = await axios.post(
        `/api/v1/admin/restaurant/time/?restaurantId=${id}`,
        { res_timings: restaurantTime }
      );

      setIsLoading(false);
      snackbar(res.data.message, "success");
      getRestaurantDetails();
      console.log(res);
    } catch (error) {
      snackbar(error.response.data.message, "error");
      setIsLoading(false);

      console.log(error);
    }
  };

  const addBtn = {
    textTransform: "capitalize",
    color: "white.main",
    fontWeight: 600,
    fontSize: "13px",
    backgroundColor: "blue.primary",
    px: 1,
    // marginRight: 10,
    "&:hover": {
      backgroundColor: "blue.primary",
    },
  };

  const timeTextField = {
    borderRadius: "5px",
    width: "150px",
    margin: "5px",
    fontSize: "18px",
    color: "black.primary",
    border: " 1px solid rgba(103, 103, 103, 0.75)",
    "& fieldset": {
      border: "none",
      color: "black.primary",
    },
  };
  return (
    <>
      <div>
        <form onSubmit={(e) => onTimeSubmit(e)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "25px",
                  color: "rgba(0, 0, 0, 0.8)",
                  textAlign: "center",
                }}
              >
                {" "}
                Restaurant Timing{" "}
                {isAvailable ? (
                  <FormControlLabel
                    mx={1}
                    value="veg"
                    control={<Checkbox />}
                    onChange={() => setIsAvailable(false)}
                    label="Update Timing"
                    labelPlacement="end"
                  />
                ) : (
                  false
                )}
              </Typography>
            </Grid>
          </Grid>

          {restaurantTime.map((day, index) => (
            <>
              <Grid container spacing={2} key={index}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: -2,
                    mt: 2,
                    mx: 2,
                  }}
                >
                  <Typography
                    key={index}
                    sx={{
                      color: "#FF8A00",
                      fontWeight: 700,
                      fontSize: "15px",
                      textTransform: "uppercase",
                    }}
                  >
                    {weeks[index]}
                  </Typography>

                  <Button
                    key={index}
                    disabled={isAvailable ? true : false}
                    variant="contained"
                    onClick={() => handleAddSlot(index)}
                    sx={addBtn}
                  >
                    {/* Add Timing */}
                    <MoreTimeIcon />
                  </Button>
                </Grid>

                <Grid item key={index} xs={12} md={7}>
                  {day.slots.map((slot, slotIndex) => (
                    <>
                      <Grid container key={slotIndex} spacing={2} my={1}>
                        <Grid
                          key={slotIndex}
                          item
                          xs={10}
                          sx={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="time"
                            style={timeTextField}
                            value={slot.from}
                            disabled={isAvailable ? true : false}
                            onChange={(e) =>
                              handleTimeChange(
                                index,
                                slotIndex,
                                "from",
                                e.target.value
                              )
                            }
                          />

                          <Typography
                            sx={{
                              color: "rgba(13, 30, 55, 0.63)",
                              fontWeight: 400,
                              fontSize: "16px",
                            }}
                          >
                            to
                          </Typography>
                          <input
                            type="time"
                            value={slot.to}
                            style={timeTextField}
                            disabled={isAvailable ? true : false}
                            onChange={(e) =>
                              handleTimeChange(
                                index,
                                slotIndex,
                                "to",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          sx={{ display: "flex", justifyContent: "end" }}
                        >
                          <Button
                            disabled={isAvailable ? true : false}
                            onClick={() => handleRemoveSlot(index, slotIndex)}
                          >
                            <DeleteIcon />
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  ))}
                </Grid>
              </Grid>

              {weeks[index] === "Saturday" ? (
                <></>
              ) : (
                <Divider sx={{ mt: 1, mb: 1 }} />
              )}
            </>
          ))}
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "end" },
            }}
          >
            <Button
              id="sign-in-button"
              sx={{
                mt: 3,
                background:
                  "linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)",
              }}
              variant="contained"
              type="submit"
              size="large"
              disabled={isAvailable ? true : false}

              // onClick={submitTimeHandler}
            >
              Submit
            </Button>
          </Grid>
        </form>
      </div>
    </>
  );
};

export default Timing;
