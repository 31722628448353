import {
  Box,
  Button,
  Input,
  Modal,
  Paper,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AdminDataContext } from "./../useContext/AdminContext";
import moment from "moment/moment";

import { Link, useNavigate } from "react-router-dom";
function DeliveryBoyNotAvailable() {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSearchDeliveryData([]);
    setCurrentOrderId("");
  };
  const [complain, setComplain] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchDeliveryData, setSearchDeliveryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentOrderId, setCurrentOrderId] = useState(true);
  const [restaurantLocationIs, setRestaurantLocationIs] = useState({
    type: "Point",
    coordinates: [81.0273469, 26.9379991],
  });

  const getTimeDeference = (first, second) => {
    var a = moment(first);
    var b = moment(second);

    return a.diff(b, "minutes");
  };
  const columns = [
    {
      field: "orderId",
      headerName: "Order Id",
      width: 100,
      renderHeader: () => <strong>{"Order Id"}</strong>,
    },
    {
      field: "Platform",
      headerName: "Platform",
      width: 100,
      renderHeader: () => <strong>{"Platform"}</strong>,
      renderCell: (params) => {
        return params.row.restaurant?.petPooja ? (
          <Typography>petPooja</Typography>
        ) : (
          <>Ybites</>
        );
      },
    },
    {
      field: "paymentMode",
      headerName: "Mode",
      width: 100,
      renderHeader: () => <strong>{"Mode"}</strong>,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderHeader: () => <strong>{"Status"}</strong>,
    },
    {
      field: "deliveryStatus",
      headerName: "Delivery Status",
      width: 150,
      renderHeader: () => <strong>{"Delivery Status"}</strong>,
    },

    {
      field: "createdAt",
      headerName: "Order Created At",
      width: 200,
      renderHeader: () => <strong>{"Order Created At"}</strong>,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
      //   renderCell: (params) => {
      //     return <Button>Download</Button>;
      //   },
    },

    {
      field: "accepted",
      headerName: "Accepted",
      width: 100,
      renderHeader: () => <strong>{"Accepted"}</strong>,
      renderCell: (params) => {
        return params.row.acceptedAt ? (
          <Typography>
            {getTimeDeference(params.row.acceptedAt, params.row.createdAt)}
          </Typography>
        ) : (
          <></>
        );
      },
    },

    {
      field: "view-details",
      headerName: "Nearest Delivery Boy",
      width: 150,
      renderHeader: () => <strong>{"Nearest Delivery Boy"}</strong>,
      renderCell: (params) => {
        return (
          <Link
            to={`/order/nearest-delivery-boy?data=${encodeURIComponent(
              JSON.stringify(params.row.restaurant?.location)
            )}&restaurant=${
              params.row.restaurant?.brand_display_name
            }&orderId=${params.row.orderId}`}
            target="_blank"
          >
            {" "}
            Nearest Delivery Boy
          </Link>
        );
      },
    },

    {
      field: "search",
      headerName: "Find Delivery",
      width: 150,
      renderHeader: () => <strong>{"Find Delivery"}</strong>,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              searchDelivery(params.row._id);
            }}
          >
            Find Delivery
          </Button>
        );
      },
    },
    {
      field: "ready",
      headerName: "ready",
      width: 100,
      renderHeader: () => <strong>{"Ready"}</strong>,
      renderCell: (params) => {
        return params.row.readyAt ? (
          <Typography>
            {getTimeDeference(params.row.readyAt, params.row.createdAt)}
          </Typography>
        ) : (
          <></>
        );
      },
    },

    {
      field: "Restaurant",
      headerName: "Restaurant",
      width: 300,
      renderHeader: () => <strong>{"Restaurant"}</strong>,
      renderCell: (params) => {
        return params.row.restaurant ? (
          <Typography>{params.row.restaurant?.brand_display_name}</Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "RestaurantContactPersonPhone",
      headerName: "R-Phone",
      width: 150,
      renderHeader: () => <strong>{"R-Phone"}</strong>,
      renderCell: (params) => {
        return params.row.restaurant ? (
          <Typography>{params.row.restaurant?.merchant_number}</Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "customer",
      headerName: "customer",
      width: 150,
      renderHeader: () => <strong>{"Customer"}</strong>,
      renderCell: (params) => {
        return params.row.customer ? (
          <Typography>{params.row.customer.name}</Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "customerContactPersonPhone",
      headerName: "C-Phone",
      width: 150,
      renderHeader: () => <strong>{"C-Phone"}</strong>,
      renderCell: (params) => {
        return params.row.customer ? (
          <Typography>{params.row.customer.phone}</Typography>
        ) : (
          <>-</>
        );
      },
    },

    {
      field: "orderItems",
      headerName: "Items ",
      width: 250,
      renderHeader: () => <strong>{"Items"}</strong>,
      renderCell: (params) => {
        return !(params.row.orderItems?.length === 0) ? (
          <Typography>
            {params.row.orderItems[0].itemTitle}
            {params.row.orderItems.length >= 2
              ? `, +${params.row.orderItems.length - 1} items`
              : ""}
          </Typography>
        ) : (
          <>-</>
        );
      },
    },
  ];
  // here is the code to find available delivery boy
  const searchDelivery = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/admin/orders/delivery/search?orderId=${id}`
      );
      setCurrentOrderId(id);
      setIsLoading(false);
      handleOpen();
      setSearchDeliveryData(res.data.data);
    } catch (error) {
      setIsLoading(false);
    }
  };
  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
  // assign order to delivery boy
  const assignOrderHandler = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/admin/orders/delivery/assign?orderId=${currentOrderId}&deliveryPartnerCurrentLocationId=${id}`
      );
      if (res.data.status === "busy") {
        snackbar(res.data.message, "error");
      }
      if (res.data.status === "success") {
        snackbar(res.data.message, "success");
      }
      // let response = false;
      // for (let index = 0; index < 12; index++) {
      //   const res2 = await axios.get(
      //     `/api/v1/admin/orders/delivery/assign/status?id=${res.data.data._id}`
      //   );
      //   if (res2.data.status === "rejected") {
      //     snackbar(res2.data.message, "error");
      //     break;
      //   } else if (res2.data.status === "success") {
      //     snackbar(res2.data.message, "success");

      //     break;
      //   }
      //   if (index === 12) {
      //     response();
      //   }
      //   await delay(10000);
      // }
      // snackbar("Delivery Partner not responded on order", "error");
      searchDelivery();
      // if (response) {
      // }
      handleClose();
      setIsLoading(false);
    } catch (error) {
      snackbar(error.response.data.message, "error");

      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllOrders(page, limit, "", "");
  }, []);
  // getting all orders which need to manually assign delivery boys
  const getAllOrders = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/orders/noDelivery?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}`
      );
      setComplain(res.data.data);
      setTotal(res.data.total);
      console.log("delivery boy not available", res);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSortModelChange = async (item) => {
    getAllOrders(page, limit, "", "");
    if (item.length === 0) {
      getAllOrders(page, limit, "", "");
    } else {
      getAllOrders(page, limit, item[0].sort, item[0].field);
    }
  };

  const onFilterChange = async (e) => {
    console.log(e.items);
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/admin/order/filter`, {
        key: e.items[0].columnField,
        value: e.items[0].value,
      });
      setComplain(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Box>
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        // autoHeight
        rows={complain}
        getRowId={(row) => row._id}
        rowCount={total}
        loading={loading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={page - 1}
        pageSize={limit}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        onPageChange={(newPage) => {
          getAllOrders(newPage + 1, limit, "", "");
          setPage(newPage + 1);
        }}
        onPageSizeChange={(newPageSize) => {
          getAllOrders(page, newPageSize, "", "");
          setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={columns}
        sx={{
          "& .bold-header": {
            fontWeight: "bold",
            color: "blue",
          },
          height: "600px",
          overflow: "auto",
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {!(searchDeliveryData.length === 0) ? (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell align="center">Distance(KM)</TableCell>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">Phone</TableCell>
                    <TableCell align="center">AssignOrder</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchDeliveryData.map((row, index) => (
                    <TableRow
                      key={row._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center" component="th" scope="row">
                        {index + 1}
                      </TableCell>

                      <TableCell align="center">
                        {row.distance.toFixed(2)}
                      </TableCell>
                      <TableCell align="center">
                        {row.deliveryPartner.name}
                      </TableCell>
                      <TableCell align="center">
                        {row.deliveryPartner.phone}
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          onClick={() => assignOrderHandler(row._id)}
                        >
                          Assign Order
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <>No Delivery Partner Found</>
          )}
        </Box>
      </Modal>
      lorem20000
    </Box>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default DeliveryBoyNotAvailable;
