import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Paper,
  useMediaQuery,
  Box,
  Typography,
  Grid,
  Autocomplete,
  TextField,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { AdminDataContext } from "../../../../useContext/AdminContext";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  containerStyle,
  imageBox,
  paperBox,
  paperStyle,
  restaurantDetailsGrid,
} from "../../../menuItem/itemCreate/CssStyle";
import SearchRestaurant from "./SearchByRestaurant";

const OwnerList = () => {
  const { snackbar, setIsLoading } = useContext(AdminDataContext);

  const [owner, setOwner] = useState([]);
  const [searchOwner, setSearchOwner] = useState([]);
  const [ownerId, setOwnerId] = useState("");
  const [singleOwner, setSingleOwner] = useState({});
  const [isSearchRestaurant, setIsSearchRestaurant] = useState(true);
  const [selectedOwner, setSelectOwner] = useState(false);
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setIsSearchRestaurant(event.target.checked);
  };
  const navigate = useNavigate();

  const getAllOwner = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`/api/v1/admin/owner`);
      setOwner(res.data.owner);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getSearchOwner = async (e) => {
    try {
      const res = await axios.get(
        `/api/v1/admin/owner/search/?query=${e.target.value}`
      );
      if (!(res.data.data.length === 0)) {
        setSearchOwner(res.data.data);
      } else {
        setSearchOwner([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllOwner();
    // getSearchOwner();
  }, []);

  console.log("setSearchOwner is ", searchOwner);
  // console.log(setSingleOwner);

  return (
    <>
      <Box>
        <FormControlLabel
          value="end"
          control={
            <Switch
              checked={isSearchRestaurant}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={
            isSearchRestaurant ? "Search By Restaurant" : "Search By Owner"
          }
          labelPlacement="end"
        />
        {isSearchRestaurant ? (
          <SearchRestaurant />
        ) : (
          <Box>
            {ownerId ? (
              <></>
            ) : (
              <Container fixed sx={containerStyle}>
                <Paper sx={paperStyle} elevation={10}>
                  <Box sx={paperBox}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "200px",
                      }}
                      component="img"
                      alt="logo"
                      src="/images/logoCreateRest.svg"
                    ></Box>
                    <Typography
                      sx={{ color: "grey.tertiary", fontWeight: 600, mt: 1 }}
                    >
                      SELECT OWNER
                    </Typography>
                  </Box>
                  <Grid
                    container
                    spacing={2}
                    mt={2}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Grid xs={12} md={6}>
                      <Autocomplete
                        fullWidth={true}
                        disableClearable
                        id="combo-box-demo"
                        size="small"
                        options={searchOwner}
                        getOptionLabel={(userData) =>
                          `${userData.name} Phone : ${userData.phone}`
                        }
                        onInputChange={getSearchOwner}
                        onChange={(event, value) => {
                          // console.log(event.target.value);
                          // console.log("owner is ", value);
                          console.log(value);
                          setSearchOwner(value);
                          setSingleOwner(value);
                          setSelectOwner(true);
                        }}
                        filterSelectedOptions
                        //   sx={{ width: 250 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Select Owner" />
                        )}
                      />
                    </Grid>
                  </Grid>

                  {selectedOwner ? (
                    <Grid
                      container
                      onClick={() => {
                        setOwnerId(singleOwner._id);
                        navigate(
                          `/restaurant/restaurant-list/${singleOwner._id}`
                        );
                        console.log(singleOwner._id);
                      }}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: 4,
                        borderRadius: "100px",
                      }}
                    >
                      <Grid item xs={8} sm={3} md={1}>
                        <Box
                          sx={imageBox}
                          component="img"
                          alt="logo"
                          src={"/images/defaultRes.svg"}
                        ></Box>
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        sm={6}
                        md={4}
                        sx={restaurantDetailsGrid}
                      >
                        <Typography
                          sx={{ color: "grey.main", fontWeight: 600, mt: 1 }}
                        >
                          {singleOwner.name}
                        </Typography>
                        <Typography
                          sx={{ color: "grey.main", fontWeight: 600, mt: 1 }}
                        >
                          {singleOwner.email}
                        </Typography>

                        <Typography
                          variant="h6"
                          fontWeight={500}
                          fontSize="15px"
                          lineHeight="20px"
                          sx={{ color: "rgba(13, 30, 55, 0.67)", mt: 1 }}
                        >
                          {singleOwner.phone}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Paper>
              </Container>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default OwnerList;
