import React, { useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import { Typography, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Stack } from "@mui/system";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AdminDataContext } from "../../useContext/AdminContext";

const Login = ({ setIsAuthenticated }) => {
  const [showLogo, setShowLogo] = React.useState(true);

  const navigate = useNavigate();

  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
  });

  useEffect(() => {
    let timer1 = setTimeout(() => setShowLogo(false), 1 * 1000);

    return () => {
      clearTimeout(timer1);
    };
  }, []);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const { updateAdminData, snackbar } = useContext(AdminDataContext);
  const LoginHandle = async () => {
    try {
      const res = await axios.post("/api/v1/admin/", {
        email: values.email,
        password: values.password,
      });
      updateAdminData(res.data.data.admin);

      navigate("/");
      setIsAuthenticated(true);
    } catch (error) {
      snackbar(error.response.data.message, "error");
    }
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Container
        sx={{
          alignContent: "center",
          height: "97vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <Stack sx={{ width: "100%" }}>
            <Paper
              sx={{ width: "100%", bgcolor: "bgColor.main" }}
              elevation={10}
            >
              {showLogo ? (
                <Box sx={{ width: "80vw", height: "80vh" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img alt="logo" width="80%" src="/images/logo.svg" />
                  </Box>
                </Box>
              ) : (
                <Box sx={{ width: "80vw", height: "80vh" }}>
                  <Grid
                    container
                    display="flex"
                    spacing={0}
                    alignItems="center"
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Grid
                      item
                      md={7}
                      sx={{
                        width: "100%",
                        height: "100%",
                        display: {
                          xs: "none",
                          md: "block",
                        },
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                        }}
                        component="img"
                        alt="logo"
                        width="70%"
                        src="/images/logoMain.svg"
                      ></Box>
                    </Grid>
                    <Grid
                      display="flex"
                      justifyContent="center"
                      item
                      alignItems="center"
                      md={5}
                      sm={12}
                      xs={12}
                      sx={{ margin: { md: 0 } }}
                    >
                      <Stack
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        spacing={0}
                      >
                        <img alt="logo" width="80%" src="/images/logo.svg" />

                        <Typography variant="h5" style={{ margin: "30px 0" }}>
                          Login into Ybites CRM
                        </Typography>
                        <FormControl sx={{ m: 1, width: "80%" }}>
                          <InputLabel htmlFor="component-outlined">
                            Email
                          </InputLabel>
                          <OutlinedInput
                            id="component-outlined"
                            value={values.email}
                            onChange={handleChange("email")}
                            label="Email"
                          />
                        </FormControl>
                        <FormControl
                          sx={{ m: 1, width: "80%" }}
                          variant="outlined"
                        >
                          <InputLabel htmlFor="outlined-adornment-password">
                            Password
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            type={values.showPassword ? "text" : "password"}
                            value={values.password}
                            onChange={handleChange("password")}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {values.showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                          />
                        </FormControl>
                        <Button
                          sx={{ m: 1, width: "80%" }}
                          variant="contained"
                          size="large"
                          onClick={LoginHandle}
                        >
                          Login
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Paper>
          </Stack>
        </div>
      </Container>
    </>
  );
};

export default Login;
