import { Box, IconButton, Modal, Stack, useMediaQuery } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { Typography } from "@mui/material";
import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const RefferedCustomersDetails = (props) => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "550px",
    width: matches ? "40%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 2,
    overflow: "auto",
  };
  console.log(
    "props.restaurantData props.restaurantData",
    props.restaurantData
  );

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 150,
    },

    {
      field: "phone",
      headerName: "Customer Phone",
      width: 200,
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
    },

    {
      field: "gender",
      headerName: "Gender",
      width: 150,
    },
    {
      field: "dob",
      headerName: "DOB",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
    },

    {
      field: "orderCount",
      headerName: "Order Count",
      width: 120,
    },
  ];

  return (
    <>
      <Modal
        open={props.openModal}
        onClose={props.handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
            gap={1}
            sx={{ position: "relative" }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "27px",
                color: "rgba(13, 30, 55, 0.67)",
                justifySelf: "start",
              }}
            >
              Referred Customers
            </Typography>

            <IconButton onClick={props.handleCloseModal} aria-label="delete">
              <CancelIcon />
            </IconButton>
          </Stack>

          <DataGrid
            components={{ Toolbar: GridToolbar }}
            autoHeight
            rows={props.data}
            getRowId={(row) => row._id}
            columns={columns}
          />

          {/* {props.restaurantData.map((data, index) => (
            <Grid
              key={index}
              container
              spacing={2}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item xs={6}>
                {" "}
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{ color: "grey.main" }}
                >
                  <span style={{ fontWeight: "bold" }}> {index} - </span> Owner
                  Name
                </Typography>
              </Grid>

              <Grid item xs={6} textAlign={"right"}>
                {" "}
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{ color: "grey.main" }}
                >
                  {data.owner_name}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                {" "}
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{ color: "grey.main" }}
                >
                  Restaurant Name
                </Typography>
              </Grid>

              <Grid item xs={6} textAlign={"right"}>
                {" "}
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{ color: "grey.main" }}
                >
                  {data.brand_display_name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {" "}
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{ color: "grey.main" }}
                >
                  Mobile Number
                </Typography>
              </Grid>

              <Grid item xs={6} textAlign={"right"}>
                {" "}
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{ color: "grey.main" }}
                >
                  {data.business_contact}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {" "}
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{ color: "grey.main" }}
                >
                  Email
                </Typography>
              </Grid>

              <Grid item xs={6} textAlign={"right"}>
                {" "}
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{ color: "grey.main" }}
                >
                  {data.email}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {" "}
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{ color: "grey.main" }}
                >
                  Address
                </Typography>
              </Grid>

              <Grid item xs={6} textAlign={"right"}>
                {" "}
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{ color: "grey.main" }}
                >
                  {data.address}
                </Typography>
              </Grid>
              <hr />
            </Grid>
          ))} */}
        </Box>
      </Modal>
    </>
  );
};

export default RefferedCustomersDetails;
