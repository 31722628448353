import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  accordianHeading,
  addBtn,
  textFieldBox,
  textHeading,
  textHeadingInputFields,
} from "../cssStyling/Style";

const DeliveryReport = () => {
  const [deliveryPartners, setDeliveryPartners] = useState([]);
  const [deliveryPartner, setDeliveryPartner] = useState({});
  const [dates, setDates] = useState({});
  const [logData, setLogData] = useState([]);

  const getDeliveryPartnerData = async (e) => {
    try {
      const res = await axios.get(
        `/api/v1/admin/delivery/partner/search?query=${e.target.value}`
      );

      if (!(res.data.data.length === 0)) {
        setDeliveryPartners(res.data.data);
      } else {
        setDeliveryPartners([]);
      }
    } catch (error) {}
  };
  function toHoursAndMinutes(totalMinutes) {
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  const getDeliveryPartnerLogData = async (e) => {
    try {
      const res = await axios.post(
        `/api/v1/admin/delivery/log/single?deliveryPartnerId=${deliveryPartner._id}`,
        dates
      );
      setLogData(res.data.data);
    } catch (error) {}
  };
  const onChangeDate = (event) => {
    setDates({ ...dates, [event.target.name]: event.target.value });
  };
  console.log(dates);
  return (
    <Box>
      {" "}
      <Autocomplete
        // multiple
        size="small"
        aria-required
        fullWidth
        id="tags-outlined"
        options={deliveryPartners}
        getOptionLabel={(userData) =>
          `${userData.name} Phone : ${userData.phone}`
        }
        onInputChange={getDeliveryPartnerData}
        onChange={(event, value) => {
          console.log(value);
          setDeliveryPartner(value);
        }}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            // label="Select Delivery Partner"
            placeholder="Select Delivery Partner"
          />
        )}
      />
      {deliveryPartner ? (
        <Box>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "space-around" }}
          >
            <Grid
              item
              xs={6}
              sm={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  ...textFieldBox,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: { xs: "center", sm: "start" },
                }}
              >
                <Typography variant="body1" sx={textHeadingInputFields}>
                  Start Date
                </Typography>

                <TextField
                  size="small"
                  id="outlined-basic"
                  type="date"
                  variant="outlined"
                  name="startDate"
                  onChange={onChangeDate}
                />
              </Box>
            </Grid>

            <Grid item xs={6} sm={3} sx={{ display: "flex" }}>
              <Box
                sx={{
                  ...textFieldBox,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: { xs: "center", sm: "start" },
                }}
              >
                <Typography variant="body1" sx={textHeadingInputFields}>
                  End Date
                </Typography>

                <TextField
                  size="small"
                  id="outlined-basic"
                  type="date"
                  variant="outlined"
                  name="endDate"
                  onChange={onChangeDate}
                />
              </Box>
            </Grid>

            <Grid
              item
              xs={6}
              sm={6}
              sx={{
                display: "flex",
                // justifyContent: { xs: "center", sm: "start" },
              }}
            >
              {dates.startDate && dates.endDate ? (
                <>
                  <Box
                    sx={{
                      ...textFieldBox,
                      display: "flex",
                      // flexDirection: "column",
                      justifyContent: { xs: "center", sm: "start" },
                      mt: 4.6,
                    }}
                  >
                    <Button
                      sx={{
                        ...addBtn,
                        m: 0,
                        height: "40px",
                        width: "max-content",
                      }}
                      onClick={getDeliveryPartnerLogData}
                      variant="contained"
                    >
                      Get Date
                    </Button>
                  </Box>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>

          {dates.startDate && dates.endDate ? (
            <>
              {/* <Button onClick={getDeliveryPartnerLogData} variant="contained">
                Get Date
              </Button> */}
              {logData.map((item, i) => {
                return (
                  <Stack spacing={2} key={item._id} sx={{ paddingTop: "10px" }}>
                    <Accordion
                      sx={{
                        mt: 2,

                        boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      <AccordionSummary
                        sx={{
                          // mt: 3,
                          background:
                            "linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)",
                          boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                          borderRadius: "2px",
                        }}
                        expandIcon={<ExpandMoreIcon color="white" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography sx={accordianHeading}>
                          {moment(item?.createdAt).format("DD/MM/YYYY")} work
                          for {toHoursAndMinutes(item.todayTotal)}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {item?.loginData.map((item2) => {
                          return (
                            <Typography
                              sx={{
                                borderBottom: "1px solid gray",
                                mt: 1,
                                fontWeight: 600,
                              }}
                            >
                              {moment(item2?.startTime).format("hh:mm A")} to{" "}
                              {moment(item2?.endTime).format("hh:mm A")} for{" "}
                              {toHoursAndMinutes(item2.difference)}
                            </Typography>
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default DeliveryReport;
