import {
  Box,
  Button,
  Input,
  Modal,
  Paper,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AdminDataContext } from "./../useContext/AdminContext";
import moment from "moment/moment";
import { imgUrl } from "../url";

function RestaurantNotAccepted() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setLog([]);
  };
  const [complain, setComplain] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [log, setLog] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTimeDeference = (first, second) => {
    var a = moment(first);
    var b = moment(second);

    return a.diff(b, "minutes");
  };
  const columns = [
    {
      field: "orderId",
      headerName: "Order Id",
      width: 100,
    },
    {
      field: "paymentMode",
      headerName: "Mode",
      width: 100,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
    },
    {
      field: "deliveryStatus",
      headerName: "Delivery Status",
      width: 150,
    },

    {
      field: "createdAt",
      headerName: "Order Created At",
      width: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
      //   renderCell: (params) => {
      //     return <Button>Download</Button>;
      //   },
    },
    {
      field: "pickUpImage",
      headerName: "Pick-Up Image",
      width: 100,

      renderCell: (params) => {
        return params.row.pickUpImage ? (
          <Box
            component="img"
            width="100%"
            src={`${imgUrl}/${params.row.pickUpImage}`}
          ></Box>
        ) : (
          <></>
        );
      },
    },
    {
      field: "accepted",
      headerName: "Accepted",
      width: 100,

      renderCell: (params) => {
        return params.row.acceptedAt ? (
          <Typography>
            {getTimeDeference(params.row.acceptedAt, params.row.createdAt)}
          </Typography>
        ) : (
          <></>
        );
      },
    },
    {
      field: "ready",
      headerName: "ready",
      width: 100,

      renderCell: (params) => {
        return params.row.readyAt ? (
          <Typography>
            {getTimeDeference(params.row.readyAt, params.row.createdAt)}
          </Typography>
        ) : (
          <></>
        );
      },
    },
    {
      field: "delivered",
      headerName: "delivered",
      width: 100,

      renderCell: (params) => {
        return params.row.deliveredAt ? (
          <Typography>
            {getTimeDeference(params.row.deliveredAt, params.row.createdAt)}
          </Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "picked Up",
      headerName: "picked Up",
      width: 100,

      renderCell: (params) => {
        return params.row.pickedUpAt ? (
          <Typography>
            {getTimeDeference(params.row.pickedUpAt, params.row.createdAt)}
          </Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "Restaurant",
      headerName: "Restaurant",
      width: 300,

      renderCell: (params) => {
        return params.row.restaurant ? (
          <Typography>{params.row.restaurant.name}</Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "RestaurantContactPersonPhone",
      headerName: "R-Phone",
      width: 150,

      renderCell: (params) => {
        return params.row.restaurant ? (
          <Typography>{params.row.restaurant.contactPersonPhone}</Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "customer",
      headerName: "customer",
      width: 150,

      renderCell: (params) => {
        return params.row.customer ? (
          <Typography>{params.row.customer.name}</Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "customerContactPersonPhone",
      headerName: "C-Phone",
      width: 150,

      renderCell: (params) => {
        return params.row.customer ? (
          <Typography>{params.row.customer.phone}</Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "deliveryPartner",
      headerName: "deliveryPartner",
      width: 150,

      renderCell: (params) => {
        return params.row.deliveryPartner ? (
          <Typography>{params.row.deliveryPartner.name}</Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "deliveryPartnerContactPersonPhone",
      headerName: "D-Phone",
      width: 150,

      renderCell: (params) => {
        return params.row.deliveryPartner ? (
          <Typography>{params.row.deliveryPartner.phone}</Typography>
        ) : (
          <>-</>
        );
      },
    },
    {
      field: "orderItems",
      headerName: "Items ",
      width: 250,

      renderCell: (params) => {
        return !(params.row.orderItems?.length === 0) ? (
          <Typography>
            {params.row.orderItems[0].menuItemTitle}
            {params.row.orderItems.length >= 2
              ? `, +${params.row.orderItems.length - 1} items`
              : ""}
          </Typography>
        ) : (
          <>-</>
        );
      },
    },
  ];

  useEffect(() => {
    getAllOrders(page, limit, "", "");
  }, []);

  const getAllOrders = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/orders/pending?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}`
      );
      setComplain(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSortModelChange = async (item) => {
    getAllOrders(page, limit, "", "");
    if (item.length === 0) {
      getAllOrders(page, limit, "", "");
    } else {
      getAllOrders(page, limit, item[0].sort, item[0].field);
    }
  };

  const onFilterChange = async (e) => {
    console.log(e.items);
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/admin/order/filter`, {
        key: e.items[0].columnField,
        value: e.items[0].value,
      });
      setComplain(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  // onClick={createComplainHandler}
  return (
    <Box>
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        autoHeight
        rows={complain}
        getRowId={(row) => row._id}
        rowCount={total}
        loading={loading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={page - 1}
        pageSize={limit}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        onPageChange={(newPage) => {
          getAllOrders(newPage + 1, limit, "", "");
          setPage(newPage + 1);
        }}
        onPageSizeChange={(newPageSize) => {
          getAllOrders(page, newPageSize, "", "");
          setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={columns}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Amount</TableCell>
                  <TableCell align="center">Date And Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {log.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {index + 1}
                    </TableCell>

                    <TableCell align="center">{row.amountSubmitted}</TableCell>
                    <TableCell align="center">
                      {new Date(row.date).toLocaleString("en-GB", {
                        timeZone: "IST",
                      })}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </Box>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default RestaurantNotAccepted;
