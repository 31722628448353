import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React from "react";
import {
  textFieldStyle,
  selectFieldStyle,
  tableColumnsHeading,
} from "./CssStyle";

const AddAddOns = ({ addOns, data, setData, addOn }) => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");

  const removeAddOns = (item) => {
    const array = data.addon;
    setData({
      ...data,
      addon: array.filter((e) => !(e.addon_group_id === item.addon_group_id)),
    });
  };

  const [open, setOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState({
    addon_item_selection_min: 0,
    addon_item_selection_max: 1,
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setModalData({
      addon_item_selection_min: 0,
      addon_item_selection_max: 1,
    });
  };
  const onChangeHandler = (e) => {
    setModalData({ ...modalData, [e.target.name]: e.target.value });
  };
  const onSubmitHandler = (e) => {
    const array = data.addon;

    setData({
      ...data,
      addon: [...array, modalData],
    });
    handleClose();
  };
  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "25%" : matchesDown ? "50%" : "80%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 2,
  };
  console.log(data);
  return (
    <div>
      <Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={tableColumnsHeading}>Group name</TableCell>
                <TableCell align="right" sx={tableColumnsHeading}>
                  Max
                </TableCell>
                <TableCell align="right" sx={tableColumnsHeading}>
                  Min
                </TableCell>
                <TableCell align="right" sx={tableColumnsHeading}>
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.addon?.map((item) => (
                <TableRow
                  key={item.key}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {addOn[item.addon_group_id]}
                  </TableCell>
                  <TableCell align="right">
                    {item.addon_item_selection_max}
                  </TableCell>
                  <TableCell align="right">
                    {item.addon_item_selection_min}
                  </TableCell>
                  <TableCell align="right">
                    <Button onClick={() => removeAddOns(item)}>Delete</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Button onClick={handleOpen} variant="contained">
        Add Addon Group
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
            gap={1}
            sx={{ position: "relative" }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "27px",
                color: "rgba(13, 30, 55, 0.67)",
                justifySelf: "start",
              }}
            >
              Add Addon
            </Typography>

            <IconButton onClick={handleClose} aria-label="delete">
              <CancelIcon />
            </IconButton>
          </Stack>

          <Stack spacing={2}>
            <Typography
              variant="h6"
              fontWeight={500}
              fontSize="16px"
              sx={{ color: "grey.main" }}
            >
              Group Name
            </Typography>

            <FormControl fullWidth>
              <Select
                sx={selectFieldStyle}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="addon_group_id"
                value={modalData.addon_group_id}
                size="small"
                required
                onChange={onChangeHandler}
              >
                {addOns.map((itemAddon) => (
                  <MenuItem key={itemAddon._id} value={itemAddon.addongroupid}>
                    {itemAddon.addongroup_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Typography
              variant="h6"
              fontWeight={500}
              fontSize="16px"
              sx={{ color: "grey.main" }}
            >
              Min
            </Typography>

            <TextField
              sx={textFieldStyle}
              size="small"
              id="outlined-basic"
              onChange={onChangeHandler}
              variant="outlined"
              type="number"
              required
              disabled
              value={modalData.addon_item_selection_min}
              defaultValue={0}
              name="addon_item_selection_min"
            />
            <Typography
              variant="h6"
              fontWeight={500}
              fontSize="16px"
              sx={{ color: "grey.main" }}
            >
              Max
            </Typography>
            <TextField
              sx={textFieldStyle}
              size="small"
              id="outlined-basic"
              onChange={onChangeHandler}
              variant="outlined"
              type="number"
              required
              value={modalData.addon_item_selection_max}
              defaultValue={5}
              name="addon_item_selection_max"
            />
            <Button onClick={onSubmitHandler}>Submit</Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default AddAddOns;
