import React from "react";
import {
  Typography,
  Modal,
  Backdrop,
  Fade,
  Box,
  useMediaQuery,
  Stack,
  Button,
  FormControlLabel,
  Grid,
  TextField,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  submitBtn,
  textFieldStyleForModal,
} from "../../../menuItem/itemCreate/CssStyle";

const AddAndEditVariantsModal = ({
  variant,
  setVariant,
  handleSubmitForm,
  addEditVariant,
  handleOpenAddEditVariantModal,
  rowId,
}) => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");

  const onChange = (e) => {
    setVariant({ ...variant, [e.target.name]: e.target.value });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "30%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={addEditVariant}
        onClose={handleOpenAddEditVariantModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={addEditVariant}>
          <Box noValidate sx={style}>
            <Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"space-between"}
                gap={1}
                sx={{ position: "relative" }}
              >
                <FormControlLabel
                  control={<ArrowBackIosNewIcon />}
                  label={
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 700,
                        fontSize: "18px",
                        lineHeight: "27px",
                        color: "rgba(13, 30, 55, 0.67)",
                        justifySelf: "start",
                      }}
                    >
                      {rowId ? "Edit Variation" : "Add Variation"}
                    </Typography>
                  }
                />

                <IconButton
                  onClick={handleOpenAddEditVariantModal}
                  aria-label="delete"
                >
                  <CancelIcon />
                </IconButton>
              </Stack>
              <Grid
                container
                spacing={2}
                component="form"
                onSubmit={handleSubmitForm}
              >
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    fontSize="16px"
                    sx={{ color: "grey.main" }}
                  >
                    Group Name *
                  </Typography>

                  <FormControl
                    size="small"
                    required
                    sx={textFieldStyleForModal}
                  >
                    <Select
                      labelId="menuItemType"
                      id="menuItemType"
                      required
                      name="groupname"
                      value={variant.groupname}
                      onChange={onChange}
                      // defaultValue="choose"
                    >
                      <MenuItem disabled value="choose">
                        Choose Option
                      </MenuItem>
                      <MenuItem value="Quantity">Quantity</MenuItem>
                      <MenuItem value="Size">Size</MenuItem>
                      <MenuItem value="Portion">Portion</MenuItem>
                      <MenuItem value="Portion Size">Portion Size</MenuItem>
                      <MenuItem value="Customisation">Customisation</MenuItem>
                      <MenuItem value="Preparation">Preparation</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sx={{ mt: 2 }}>
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    fontSize="16px"
                    sx={{ color: "grey.main" }}
                  >
                    Item Name*
                  </Typography>

                  <TextField
                    size="small"
                    name="name"
                    value={variant.name}
                    required
                    onChange={onChange}
                    placeholder="Title"
                    sx={textFieldStyleForModal}
                  />
                </Grid>

                <Grid container spacing={2} px={3} mt={3}>
                  <Grid item xs={12}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",

                        justifyContent: { xs: "center" },
                        alignItems: "end",
                      }}
                    >
                      <Button variant="contained" type="submit" sx={submitBtn}>
                        {rowId ? "Update" : "Add Variation"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default AddAndEditVariantsModal;
