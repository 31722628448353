import {
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  Modal,
  Backdrop,
  Fade,
  useMediaQuery,
  Stack,
  FormControlLabel,
  IconButton,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
  Checkbox,
} from "@mui/material";
import React from "react";

import { AdminDataContext } from "./../../../useContext/AdminContext";
import { useState } from "react";
import { useContext } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { useParams } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  textHeadingInputFields,
  topBox,
  topHeading,
  textFieldBox,
  submitBtn,
  textHeading,
  textFieldStyle,
} from "../itemCreate/CssStyle";

const AddNewAddOnGroup = ({ setPage, attributes, getData, restaurantName }) => {
  const { restaurantId } = useParams();
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [openAddAddonModal, setOpenAddAddonModal] = useState(false);
  const openHandleAddAddonModal = () => setOpenAddAddonModal(true);
  const closeHandleAddAddonModal = () => {
    setOpenAddAddonModal(false);
    setAddOnItemModalData({
      addonitem_name: "",
      addonitem_price: "",
      attributes: "",
    });
  };
  const [addongroup_name, setAddongroupName] = useState("");

  const [addongroupitems, setaddongroupitems] = useState([]);

  const [addonItemModalData, setAddOnItemModalData] = useState({
    addonitem_name: "",
    addonitem_price: "",
    attributes: "",
  });

  const createCustomizationHandler = () => {
    setaddongroupitems([...addongroupitems, addonItemModalData]);
    closeHandleAddAddonModal();
  };
  const deleteCustomizationHandler = (dd) => {
    setaddongroupitems(addongroupitems.filter((a, index) => index !== dd));
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await axios.post(
        `/api/v1/admin/addonGroup?restaurantId=${restaurantId}`,
        { addongroup_name, addongroupitems }
      );
      snackbar(res.data.message, res.data.status);
      closeHandleAddAddonModal();
      // getDate();
      setIsLoading(false);
      getData();
      setPage(0);
    } catch (error) {
      snackbar(error.response.data.message, error.response.data.status);
      setIsLoading(false);
    }
  };
  const onChange = (e) => {
    setAddOnItemModalData({
      ...addonItemModalData,
      [e.target.name]: e.target.value,
    });
  };

  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "30%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
  };
  return (
    <>
      <Box sx={topBox}>
        <Typography variant="body1" sx={topHeading}>
          Add Addon Group -{" "}
          <span style={{ color: "#0C1844" }}>{restaurantName} </span>
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        component={"form"}
        // onSubmit={onSubmitHandler}
        mt={1}
        sx={{ display: "flex", justifyContent: "start" }}
      >
        <Grid item xs={12} sm={12} md={5}>
          <Box sx={textFieldBox}>
            <Typography variant="body1" sx={textHeadingInputFields}>
              Addon Group Name *
            </Typography>

            <TextField
              size="small"
              name="addongroup_name"
              type="text"
              required
              placeholder="Name"
              autoFocus
              sx={textFieldStyle}
              onChange={(e) => setAddongroupName(e.target.value)}
              value={addongroup_name}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <div
            style={{
              width: "100%",
              marginTop: "20px",
            }}
          >
            {addongroupitems.map((item, index) => (
              <Grid
                container
                spacing={2}
                key={`${index}${item.addonitem_name}`}
                sx={{ display: "flex", justifyContent: "center", pb: 2 }}
              >
                <Grid
                  item
                  xs={5}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    // alignItems: "center",
                    mt: 2,
                  }}
                >
                  {item.addonitem_name}
                </Grid>

                <Grid
                  item
                  xs={5}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    // alignItems: "center",
                    mt: 2,
                  }}
                >
                  {item.addonitem_price}
                </Grid>

                <Grid
                  item
                  xs={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    // alignItems: "center",
                    mt: 2,
                  }}
                >
                  <DeleteIcon
                    color="primary"
                    sx={{ fontSize: "30px", mt: 1 }}
                    onClick={() => deleteCustomizationHandler(index)}
                  />
                </Grid>
              </Grid>
            ))}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={openHandleAddAddonModal}
            sx={submitBtn}
          >
            Add addons item
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              width: "100%",
              mt: 2,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={() => setPage(0)}
              sx={submitBtn}
            >
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmitForm}
              sx={submitBtn}
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openAddAddonModal}
        onClose={closeHandleAddAddonModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openAddAddonModal}>
          <Box noValidate sx={style}>
            <Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"space-between"}
                gap={1}
                sx={{ position: "relative" }}
              >
                <FormControlLabel
                  control={<ArrowBackIosNewIcon />}
                  label={
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 700,
                        fontSize: "18px",
                        lineHeight: "27px",
                        color: "rgba(13, 30, 55, 0.67)",
                        justifySelf: "start",
                      }}
                    >
                      Add Addon Item
                    </Typography>
                  }
                />

                <IconButton
                  onClick={closeHandleAddAddonModal}
                  aria-label="delete"
                >
                  <CancelIcon />
                </IconButton>
              </Stack>
              <Grid
                container
                spacing={2}
                // component="form"
                // onSubmit={editAddOnItem}
              >
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    fontSize="16px"
                    sx={{ color: "grey.main" }}
                  >
                    Item Name *
                  </Typography>

                  <TextField
                    size="small"
                    name="addonitem_name"
                    // defaultValue={props.addOnItem.title}
                    value={addonItemModalData.addonitem_name}
                    required
                    onChange={onChange}
                    placeholder="Title"
                    sx={textFieldStyle}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    fontSize="16px"
                    sx={{ color: "grey.main" }}
                  >
                    Price *
                  </Typography>

                  <TextField
                    size="small"
                    type="number"
                    name="addonitem_price"
                    // defaultValue={props.addOnItem.title}
                    value={addonItemModalData.addonitem_price}
                    required
                    onChange={onChange}
                    placeholder="Price"
                    sx={textFieldStyle}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box sx={textFieldBox}>
                    <FormControl required>
                      <FormLabel id="taxesType" sx={textHeading}>
                        Attributes
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="attributes"
                        defaultValue={"forward"}
                        onChange={onChange}
                      >
                        {attributes.map((attribute, index) => (
                          <FormControlLabel
                            key={attribute._id}
                            value={attribute.attributeid}
                            control={<Radio />}
                            defaultChecked={index === 0 ? true : false}
                            label={attribute.attribute}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Grid>

                <Grid container spacing={2} px={3} mt={3}>
                  <Grid item xs={12}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",

                        justifyContent: { xs: "center" },
                        alignItems: "end",
                      }}
                    >
                      <Button
                        variant="outlined"
                        type="submit"
                        onClick={createCustomizationHandler}
                      >
                        Add addons item
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default AddNewAddOnGroup;
